<script>
  import Button, { options as buttonsOptions } from '@ctm/ui/Button';
  import PrintIcon from './_PrintIcon.svelte';
  import Radio from '@ctm/ui/Radio';

  import { viewBookingPrintTotal } from '../../store/index';

  let showOptions = false;
  let optionsElem = null;

  function handleShowOptions(e) {
    e.detail.preventDefault();
    e.detail.stopPropagation();
    showOptions = !showOptions;
  }

  function onWindowClick(e) {
    if (!optionsElem || !showOptions) return;

    const path = e.composedPath();

    if (!path.includes(optionsElem)) {
      showOptions = false;
    }
  }
</script>

<style>
  .print-button {
    position: relative;
  }

  .print-options {
    position: absolute;
    bottom: -129px;
    right: 0;
    height: 129px;
    width: 180px;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 0 rgba(32, 43, 51, 0.16),
      0 2px 3px 0 rgba(32, 43, 51, 0.12);
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 12px 0;
    z-index: 1;
  }

  label {
    padding: 0 12px;
    color: #2d3840;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin: 0 0 6px;
  }

  :global(input[type='radio']) {
    -webkit-appearance: radio;
    margin: 0 6px 0 0;
  }

  .print-footer {
    display: flex;
    padding: 12px 12px 12px 114px;
    margin-top: 4px;
    border-top: 1px solid #ebecf0;
  }
</style>

<svelte:window on:click={onWindowClick} />

<div class="print-button">
  <Button
    on:click={(e) => handleShowOptions(e)}
    type={buttonsOptions.type.PRIMARY}
    Icon={PrintIcon}
    iconPosition={buttonsOptions.iconPosition.LEFT}>
    Print Itinerary</Button
  >

  {#if showOptions}
    <div class="print-options" bind:this={optionsElem}>
      <label>
        <Radio
          name="radioGroup"
          bind:selected={$viewBookingPrintTotal}
          value="include"
        />
        Include prices
      </label>

      <label>
        <Radio
          name="radioGroup"
          bind:selected={$viewBookingPrintTotal}
          value="exclude"
        />
        Exclude prices
      </label>

      <div class="print-footer">
        <Button
          on:click={() => window.print()}
          type={buttonsOptions.type.PRIMARY}
          size={buttonsOptions.size.COMPACT}>Print</Button
        >
      </div>
    </div>
  {/if}
</div>
