<script>
    import FailedFlight from './_FailedFlight.svelte';

    export let itinerary;
</script>


<FailedFlight {itinerary} on:refresh />

<style>
    :global(lb-booking-error) {
        display: contents;
    }
</style>