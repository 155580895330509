export default function trackTransactions(itinerary, location) {
  const track = window.ga;

  if(!track) {
    //error
  }

  itinerary.Passengers.forEach((pax) => {
    const paxId = `${pax.Id}-${location}`;

    track('ecommerce:addTransaction', {
      id: paxId,
      affiliation: itinerary.Search.Debtor,
      revenue: pax.TotalAmount,
      shipping: 0,
      tax: 0,
      currency: pax.CurrencyCode
    });

    pax.Hotels.forEach((hotel) => {
      if(hotel.Status === 'Booked') {
        track('ecommerce:addItem', {
          id: paxId,
          name: hotel.HotelCode,
          sku: hotel.RoomName,
          category: 'Hotel',
          price: hotel.Amount,
          quantity: '1'
        });
      }
    });

    pax.Flights.forEach((flight) => {
      if(flight.Status === 'Booked') {
        const flightName = `${flight.Carrier}${flight.FlightNumber}`;

        track('ecommerce:addItem', {
          id: paxId,
          name: flightName,
          sku: flightName,
          category: 'Flight',
          price: flight.Amount,
          quantity: '1'
        });
      }
    });

    pax.Rails.forEach((train) => {
      if(train.Status === 'Booked') {
        const trainName = `${train.CarrierName} ${train.RailNumber}`;

        track('ecommerce:addItem', {
          id: paxId,
          name: trainName,
          sku: trainName,
          category: 'Rail',
          price: train.Amount,
          quantity: '1'
        });
      }
    });

    pax.Cars.forEach((car) => {
      if(car.Status === 'Booked') {
        const carCode = `${car.Supplier.Code}${car.Vehicle.Code}`;
   
        track('ecommerce:addItem', {
          id: paxId,
          name: carCode,
          sku: car.Vehicle.Title,
          category: 'Car',
          price: car.Amount,
          quantity: '1'
        });
      }
    });

    track('ecommerce:send');
  });
}