<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import dayjs from "dayjs";

  const dispatch = createEventDispatcher();

  import {
    bookingsIsWaiting,
    bookingsSearchQuery,
    bookingsSearchDateFrom,
    bookingsSearchDateTo,
    canDoBookingsSearch,
    userDebtor,
    UserIsConsultant,
    UserRequiresDebtor,
    settings
  } from '../../store/index.js';

  import Button from '@ctm/ui/Button';
  import DatePicker from '@ctm/ui/DatePicker';
  import Search from '@ctm/ui/Search';
  import DebtorSelect from '../DebtorSelect/DebtorSelect.svelte';

  let selectedValue;

  function onDebtorSelect(event) {
    if($userDebtor) {
      $settings = event.detail.settings;
    } else {
      dispatch('clear');
    }
  }

  function onSubmit() {
    dispatch('search');
  }

  onMount(() => {
    const _selectedValue = $userDebtor;

    if(_selectedValue) {
      selectedValue = _selectedValue;
    }

    const pnrCancelled = sessionStorage.getItem('pnr-cancelled');
    
    if (pnrCancelled) {
      const {pnr, date} = JSON.parse(pnrCancelled);
      $bookingsSearchQuery = pnr || '';
      if (date && dayjs($bookingsSearchDateTo).diff(date) < 0) $bookingsSearchDateTo = date;
      onSubmit();
      sessionStorage.removeItem('pnr-cancelled');
    }
  });
</script>

<div class="search">
  <form on:submit|preventDefault={onSubmit}>
    <div class="formFields">

      { #if $UserIsConsultant }
      <div class="formRow">
        <label class="formLabel">Search for a debtor</label>
        <div class="formInput">
          <DebtorSelect
            bind:selectedValue
            on:select="{onDebtorSelect}"
            isDisabled="{ $bookingsIsWaiting }"
          >
          </DebtorSelect>
        </div>
      </div>
      {/if}

      { #if !$UserRequiresDebtor }
      <div class="formRow">
        <label class="formLabel">Search for a booking</label>

        <div class="formInput">
          <Search bind:value={$bookingsSearchQuery} placeholder="Booking #, PNR, or traveller name"></Search>
        </div>
      </div>

      <div class="formRow">
        <label class="formLabel">Travel dates</label>

        <div class="formInput">
          <DatePicker
            isRange
            dateFormat="Z"
            altFormat="d/m/Y"
            placeholder="dd/mm/yyyy - dd/mm/yyyy"
            bind:date="{$bookingsSearchDateFrom}"
            bind:toDate="{$bookingsSearchDateTo}"
            position="below">
          </DatePicker>
        </div>
      </div>

      <div class="formActions">
        <div class="formActions_item">
          <Button
            htmlType="submit"
            isBlock
            isDisabled="{!$canDoBookingsSearch}"
            isWaiting="{$bookingsIsWaiting}"
          >
            Search
          </Button>
        </div>
      </div>
      {/if}
    </div>
  </form>
</div>

<style>
  .formLabel {
    color: #2c3e50;
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  .formRow:not(:last-of-type) {
    margin-bottom: 11px;
  }

  .formActions {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 35px;
  }

  .formActions_item {
    margin: 0 0 5px 0;
  }

  @media (min-width: 768px) {
    .formActions {
      justify-content: flex-end;
      flex-direction: row;
    }

    .formActions_item {
      margin: 0 0 0 10px;
    }
  }
</style>
