<script>
  import dayjs from "dayjs";
  import advancedFormat from "dayjs/plugin/advancedFormat";
  dayjs.extend(advancedFormat);

  import { onMount, createEventDispatcher } from "svelte";
  import Header from "./_Header.svelte";
  import SearchForm from "./_SearchForm.svelte";
  import Footer from "./_Footer.svelte";
  import Confirmation from "./_Confirmation.svelte";
  import Link from "./Icons/Link.svelte";
  import { getItineraryById } from "../../http/itinerary";
  import { groupSectorsByDate } from "../../util/itinerary";

  import {
    airportTransferSelections,
    airportTransferItineraryId,
    airportTransferShowConfirmation,
    airportTransferItineraryData,
    airportTransferReset,
  } from "../../store/index.js";

  const dispatch = createEventDispatcher();

  export let itineraryId;

  let ready = false;
  let days = undefined;
  let paxData = [];

  $: noSectors = !(
    days &&
    Object.keys(days).find((key) => days[key] && days[key].activeSegments > 0)
  );

  onMount(async () => {
    airportTransferReset();

    $airportTransferItineraryId = itineraryId;
    $airportTransferShowConfirmation = false;
    const { data } = await getItineraryById(itineraryId);
    $airportTransferItineraryData = data;
    let paxSectors = [];

    data.Passengers.forEach((pax) => {
      paxSectors.push(groupSectorsByDate(pax));
      paxData = [...paxData, pax];
    });

    days = {};
    paxSectors.forEach((pax) => {
      pax.forEach((day) => {
        if (!days[day.date]) {
          days[day.date] = { activeSegments: 0 };
        }

        day.sectors.forEach((sector) => {
          if (sector.sectorType === "flight") {
            if (!days[day.date][sector.data.FlightIdentifier]) {
              let obj = sector;

              days[day.date][sector.data.FlightIdentifier] = obj;

              obj.departure =
                obj.data.Departure.CountryCode === "NZ"
                  ? obj.data.Departure
                  : null;
              if (obj.departure) ++days[day.date].activeSegments;
              obj.arrival =
                obj.data.Arrival.CountryCode === "NZ" ? obj.data.Arrival : null;
              if (obj.arrival) ++days[day.date].activeSegments;
            }
          }
        });
      });
    });

    dispatch("ready");
    ready = true;
  });

  function handleRemove(event) {
    days[event.detail.key].activeSegments -= 1;
  }

  function refreshPage() {
    location.reload();
    return false;
  }

  function handlePax(pnr) {
    window.location.hash = `/manage-bookings/${pnr}`;
  }
</script>

<style>
  .container {
    background: #f8f9fa;
    padding: 24px 15px;
    min-height: 100vh;
  }

  .container.hasSelection {
    min-height: 0;
  }

  .days {
    max-width: 1155px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .day {
    color: #79838d;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 8px;
  }

  .empty {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0 rgba(33, 43, 54, 0.15);
    padding: 24px 16px;
    text-align: center;
    margin: 0 0 24px;
  }

  .empty h2 {
    color: #212b36;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 0 8px;
  }

  .empty p {
    color: #576069;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 16px;
  }

  .empty button {
    height: 56px;
    border-radius: 4px;
    background-color: #1f78d1;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
    border: none;
    outline: none;
  }

  .empty.pax {
    padding: 16px;
    margin-bottom: 16px;
  }

  .pax button {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .pax button span {
    margin: 0 0 0 8px;
  }

  .pax h2 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin: 0;
    color: #4d555e;
  }
  .pax h3 {
    color: #79838d;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 16px;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    .container {
      padding-top: 56px;
    }

    .empty {
      max-width: 1155px;
      margin: 0 auto 56px;
    }

    .empty.pax {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      padding: 16px 24px;
    }

    .empty p {
      max-width: 669px;
      margin: 0 auto 32px;
    }

    .empty button {
      height: 40px;
    }

    .paxDetails {
      text-align: left;
      flex-grow: 1;
    }

    .paxDetails h3 {
      margin: 0;
    }
  }
</style>

<Header />

<div class="container" class:hasSelection={$airportTransferSelections && !airportTransferShowConfirmation}>
  {#if ready}
    {#if $airportTransferShowConfirmation}
      <Confirmation />
    {:else}
      {#if noSectors}
        <div class="empty">
          <h2>You have no airport transfers</h2>
          <p>
            There are no airport transfer options to show here. If you’d like to
            add a transfer, try searching again or go to the Manage Bookings
            page of each of the travellers.
          </p>

          <button on:click={refreshPage}>Search Airport Transfers</button>
        </div>

        {#each paxData as pax}
          <div class="empty pax">
            <div class="paxDetails">
              <h2>{pax.FullName}</h2>
              <h3>#{pax.Pnr}</h3>
            </div>
            <button on:click={() => handlePax(pax.Pnr)}>
              <Link />
              <span>View Booking</span>
            </button>
          </div>
        {/each}
      {/if}

      <div class="days">
        {#if days}
          {#each Object.keys(days) as key}
            {#if days[key].activeSegments > 0}
              <div class="day">
                {dayjs(key, 'YYYY-MM-DDTHH:mm').format('dddd, Do MMM YYYY')}
              </div>
            {/if}

            {#each Object.values(days[key]) as sector}
              {#if sector.sectorType === 'flight' && sector.departure}
                <SearchForm
                  flightType="departure"
                  {key}
                  {sector}
                  {paxData}
                  on:remove={handleRemove} />
              {/if}
              {#if sector.sectorType === 'flight' && sector.arrival}
                <SearchForm
                  flightType="arrival"
                  {key}
                  {sector}
                  {paxData}
                  on:remove={handleRemove} />
              {/if}
            {/each}
          {/each}
        {/if}
      </div>
    {/if}
  {/if}
</div>

{#if $airportTransferSelections && !$airportTransferShowConfirmation}
  <Footer />
{/if}
