<script>
    import TimeSelect from './_TimeSelect.svelte';
    import sedan from './sedan.png';
    import mercedes from './mercedes.png';
    import van from './van.png';
    import switchElectric from './switch.png';

    import { taxiSelected } from '../../store/index';
    import LocationDetails from './_LocationDetails.svelte';
    import TaxiItemHeader from './_TaxiItemHeader.svelte';
    import dayjs from 'dayjs';

    export let item = {};
    export let searchMode = false;

    $: active = item && $taxiSelected && item.Id === $taxiSelected.Id;

    let activeTab = 'details';

    function handleSelect() {
        $taxiSelected = item;
    }

    let PickUpTime = dayjs(item.PickUpTime, 'YYYY-MM-DDTHH:mm').format(
        'h:mm A'
    );

    $: {
        if (PickUpTime) {
            item.PickUpTime = PickUpTime;
        }
    }
</script>

<div class="result" class:taxi-selected={$taxiSelected}>
    <TaxiItemHeader {searchMode} code={item.Code} />

    <div class="tabs">
        <span
            class:active={activeTab === 'details'}
            on:click={() => (activeTab = 'details')}>Pick-up Details</span>
        <span
            class:active={activeTab === 'info'}
            on:click={() => (activeTab = 'info')}>Information</span>
    </div>

    <div class="content">
        {#if activeTab === 'details'}
            <div class="details">
                <div class="left-col">
                    <label
                        >Pick-up Time
                        <TimeSelect bind:value={PickUpTime} />
                    </label>
                </div>
                <LocationDetails {item} />
            </div>
        {:else}
            <div class="info">
                {#if item.Code === 'C1'}
                    <div class="left-col">
                        <img src={sedan} alt="Corporate cab" />
                    </div>
                    <div class="content">
                        <p class="intro">
                            Late model sedan with large luggage capacity,
                            leather interior and space for up to four
                            passengers.
                        </p>

                        <h3>Vehicle Information</h3>
                        <p>White Standard Sedan</p>
                        <p>Seating for up to 4 passengers</p>
                    </div>
                {:else if item.Code === 'M1'}
                    <div class="left-col">
                        <img src={mercedes} alt="Corporate cab" />
                    </div>

                    <div class="content">
                        <p class="intro">
                            Luxury Mercedes E class sedan with VIP service.
                        </p>

                        <h3>Vehicle Information</h3>
                        <p>Black Luxury Mercedes</p>
                        <p>Seating for up to 4 passengers</p>
                    </div>
                {:else if item.Code === 'E1'}
                    <div class="left-col">
                        <img src={switchElectric} alt="Corporate cab" />
                    </div>

                    <div class="content">
                        <p class="intro">
                            Fully electric taxi offering an environmentally
                            friendly transport option.
                        </p>

                        <h3>Vehicle Information</h3>
                        <p>Switch Electric Cabs</p>
                        <p>Seating for up to 4 passengers</p>
                    </div>
                {:else if item.Code === 'V1'}
                    <div class="left-col">
                        <img src={van} alt="Corporate cab" />
                    </div>
                    <div class="content">
                        <p class="intro">
                            Late model van with ample space for up to six
                            passengers.
                        </p>

                        <h3>Vehicle Information</h3>
                        <p>White Standard Van</p>
                        <p>Seating for up to 6 passengers</p>
                    </div>
                {:else}
                    <div class="left-col">
                        <img src={sedan} alt="Corporate cab" />
                    </div>
                    <div class="content">
                        <p class="intro">
                            Corporate Cabs will send the next available vehicle.
                            This is usually a standard sedan.
                        </p>

                        <h3>Vehicle Information</h3>
                        <p>Any Vehicle</p>
                    </div>
                {/if}
            </div>
        {/if}

        <div class="footer">
            <div class="price">
                {item.TotalFare.toFixed(2)} <span>NZD</span>
            </div>
            <button class:active on:click={handleSelect}
                >{#if active} Selected {:else}Select Transfer {/if}</button>
        </div>
    </div>
</div>

<style>
    .result {
        margin: 0 0 24px;
        border-bottom: 1px solid #ebecf0;
    }

    .tabs {
        display: flex;
        gap: 12px;
        margin: 0 0 24px;
    }

    .tabs span {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        padding: 0 8px 10px;
        border-bottom: 2px solid #fff;
        color: #6d7880;
        cursor: pointer;
    }

    span.active {
        border-bottom: 2px solid #156af4;
        color: #2d3840;
    }

    label {
        color: #6d7880;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 0 24px;
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .info {
        display: flex;
        flex-direction: column;
        margin: 0 0 24px;
    }

    .info h3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        margin: 0 0 4px;
        color: #abb1bb;
    }

    .info p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 0 4px;
    }

    .info img {
        width: 100%;
        height: auto;
        margin: 0 0 24px;
        border-radius: 4px;
    }

    .info p.intro {
        margin: 0 0 24px;
    }

    .footer {
        display: flex;
        flex-direction: column;
        margin: 0 0 24px;
    }

    .footer .price {
        font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        text-align: center;
        margin: 0 0 16px;
    }

    .footer .price span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .footer button {
        border: 1px solid #156af4;
        color: #156af4;
        background: #fff;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
    }

    .footer button.active {
        color: #fff;
        background: #156af4;
    }

    @media (min-width: 928px) {
        .result {
            padding-bottom: 24px;
        }

        .result:not(.taxi-selected):last-child {
            border: none;
            margin: 0;
        }

        .details,
        .info {
            display: flex;
            flex-grow: 1;
            gap: 32px;
        }

        .left-col {
            min-width: 277px;
            padding: 0 32px 0 0;
            border-right: 1px solid #ebecf0;
            display: flex;
            flex-direction: column;
        }

        .content {
            display: flex;
        }

        .info {
            flex-direction: row;
            width: 277px;
        }

        .info img {
            width: 245px;
            min-height: 175px;
        }

        .info .content {
            flex-direction: column;
        }

        .footer button {
            width: 122px;
            height: 34px;
            font-size: 12px;
            font-weight: 600;
        }

        .footer button.active {
            color: #156af4;
            border: 1px solid #156af4;
            background: #d0e2ff;
        }
    }
</style>
