<script>
    import { tick } from 'svelte';
    import dayjs from 'dayjs';
    import Select from 'svelte-select/src/Select.svelte';
    import HotelIcon from './_HotelIcon.svelte';
    import PlaneIcon from './_PlaneIcon.svelte';
    import SearchIcon from './_SearchIcon.svelte';
    import { locationPoi } from '../../http/taxis';
    import {
        taxiItinerarySectors,
        taxiSelectedSuggestion,
    } from '../../store';

    export let placeholder = '';
    export let id = '';
    export let value = null;

    let listOpen = false;
    let filterText = '';

    let suggestionSearch = false;
    function handleSuggestion(sectorType, data, flightDirection) {
        suggestionSearch = true;
        $taxiSelectedSuggestion = { sectorType, flightDirection, data, id };
        if (sectorType === 'flight') {
            filterText = `${data[flightDirection].Location}, ${data[flightDirection].City}, ${data[flightDirection].Country}`;
        } else
            filterText = `${data.HotelName}, ${data.Location.City}, ${data.Location.Country}`;
    }

    async function loadOptions() {
        if (filterText.length < 3) return [];

        let res = await locationPoi(filterText);

        if (res.length === 1 && suggestionSearch) {
            setTimeout(() => handleAutoSelection(res[0]));
        }

        suggestionSearch = false;

        return res.map((item) => {
            return {
                label: item.Title,
                value: item,
            };
        });
    }

    async function handleAutoSelection(item) {
        value = {
            label: item.Title,
            value: item,
        };

        listOpen = false;
        await tick();
        filterText = '';
    }
</script>

<div class="location-select">
    <Select
        bind:value
        {loadOptions}
        {id}
        Icon={SearchIcon}
        {placeholder}
        bind:listOpen
        bind:filterText
        hideEmptyState
        on:clear />

    {#if listOpen && !filterText}
        <div class="suggestions">
            {#each $taxiItinerarySectors as { sectorType, data }}
                {#if sectorType === 'flight'}
                    {#if data.Departure.CountryCode === 'NZ'}
                        <div
                            class="suggestion"
                            on:click={() =>
                                handleSuggestion(
                                    sectorType,
                                    data,
                                    'Departure'
                                )}>
                            <i><PlaneIcon /></i>
                            <div class="suggestion-details">
                                <div class="suggestion-title">
                                    {data.Departure.Location} ({data.Departure
                                        .Code})
                                </div>
                                <span class="suggestion-details"
                                    >Scheduled Departure: {dayjs(
                                        data.Departure.Date,
                                        'YYYY-MM-DDTHH:mm'
                                    ).format('h:mm A, dddd D MMMM YYYY')}</span>
                            </div>
                        </div>
                    {/if}
                    {#if data.Arrival.CountryCode === 'NZ'}
                        <div
                            class="suggestion"
                            on:click={() =>
                                handleSuggestion(sectorType, data, 'Arrival')}>
                            <i><PlaneIcon /></i>
                            <div class="suggestion-details">
                                <div class="suggestion-title">
                                    {data.Arrival.Location} ({data.Arrival
                                        .Code})
                                </div>
                                <span class="suggestion-details">
                                    Scheduled Arrival: {dayjs(
                                        data.Arrival.Date,
                                        'YYYY-MM-DDTHH:mm'
                                    ).format('h:mm A, dddd D MMMM YYYY')}</span>
                            </div>
                        </div>
                    {/if}
                {:else if sectorType === 'hotel' && data.Location.CountryCode === 'NZ'}
                    <div
                        class="suggestion"
                        on:click={() => handleSuggestion(sectorType, data)}>
                        <i><HotelIcon /></i>
                        <div class="suggestion-details">
                            <div class="suggestion-title">
                                {data.HotelName}, {data.Location.City}
                            </div>
                            <span class="suggestion-details"
                                >Check out: {dayjs(
                                    data.DateTo,
                                    'YYYY-MM-DDTHH:mm'
                                ).format('h:mm A, dddd D MMMM YYYY')}</span>
                        </div>
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
</div>

<style>
    .location-select {
        --inputPadding: 0 12px 0 56px;
        --selectedItemPadding: 0 20px 0 18px;
        --spinnerLeft: 14px;
        --inputColor: #2d3840;
        --inputFontSize: 16px;
        --inputLetterSpacing: 0;

        position: relative;
        letter-spacing: 0 !important;
    }

    .suggestions {
        top: 54px;
        position: absolute;
        background: #ffffff;
        border: 1px solid #ced3da;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        width: 100%;
        z-index: 10;
        overflow: hidden;
    }

    .suggestion {
        display: flex;
        gap: 20px;
        padding: 10px 20px;
        max-height: 180px;
        overflow-x: auto;
    }

    .suggestion:hover {
        background: #e5effc;
    }

    .suggestion i {
        position: relative;
        top: 3px;
    }

    .suggestion-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #2d3840;
    }

    .suggestion-details {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #6d7880;
    }
</style>
