import axios from "axios";
import { CONFIG } from "../store/index.js";
import { getHeaders } from "./headers";

export function addressSearch({ location = "", cityCode = "" }) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/shuttle/search/address?location=${location}&cityCode=${cityCode}`;

    const options = {
      url,
      method: "GET",
      headers: getHeaders(true),
      apiVersion: 21,
    };

    axios(options)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err);
      });
  });
}

export function transferOptions(data) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/shuttle/search/options`;

    const options = {
      url,
      method: "POST",
      headers: getHeaders(true),
      apiVersion: 21,
      data: JSON.stringify(data),
    };

    axios(options)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err);
      });
  });
}

export function bookTransfer({itineraryId, bodyData}) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/shuttle/${itineraryId}/book`;

    const options = {
      url,
      method: "POST",
      headers: getHeaders(true),
      apiVersion: 21,
      data: JSON.stringify(bodyData),
    };

    axios(options)
      .then((res) => resolve(res.data))
      .catch((err) => {
        reject(err);
      });
  });
}

