<script>
  import Card from '@ctm/ui/Card';

  import {
    userDebtor,
    UserIsConsultant,
  } from '../../store/index.js';
</script>

<Card>
  <div class="noResults">
    <div class="noResults_content">
      <h3>No results</h3>
      { #if $UserIsConsultant && !$userDebtor }
      <p>Please select a debtor to begin</p>
      { :else }
      <p>There are no items matching your criteria, try checking your spelling or changing the selected dates</p>
      { /if }
    </div>
  </div>
</Card>


<style>
  .noResults {
    color: #52616f;
    font-size: 14px;
    text-align: center;
    padding: 42px;
    display: flex;
    justify-content: center;
  }

  .noResults_content {
    width: 100%;
    max-width: 385px;
  }

  h3 {
    color: #2c3e50;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  p {
    margin: 0;
  }
</style>
