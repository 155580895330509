import { writable, readable, get, derived } from 'svelte/store';
import dayjs from 'dayjs';
import { modal as modalService } from '@ctm/ui/Modal';

import _trackingEvents from './tracking/events';
import trackingMethods from './tracking/methods';
import { creditCardCodeByName } from '../util/payment';
import ErrorModal from '../components/ErrorModal/ErrorModal.svelte';


// ITINERARY
export let manageBookingsItinerary = writable(undefined);

export function hasManageBookingsItinerary(pnr) {
  const _manageBookingsItinerary = get(manageBookingsItinerary);

  if (_manageBookingsItinerary && (!pnr || pnr && _manageBookingsItinerary.Passengers[0].Pnr === pnr)) {
    return _manageBookingsItinerary;
  }

  return false;
}

// Itinerary Id types: search, manage, confirmation
export function getItineraryId(type) {
  return sessionStorage.getItem(`${type}:itineraryId`);
}
export function setItineraryId(type, id) {
  sessionStorage.setItem(`${type}:itineraryId`, id);
}
export function removeItineraryId(type) {
  sessionStorage.removeItem(`${type}:itineraryId`);
}

// CONFIG
export const CONFIG = {
  API_VERSION: 18,
  ANALYTICS_ENABLED: window.ANALYTICS_ENABLED,
  MIXPANEL_APP_NAME: window.MIXPANEL_APP_NAME,
  MIXPANEL_TOKEN: window.MIXPANEL_TOKEN,
  MIXPANEL_DEBUG: window.MIXPANEL_DEBUG,
  MIXPANEL_ENABLED: window.MIXPANEL_ENABLED,
  API_URL: window.apiUrl,
  CLIENT_ID: window.CLIENT_ID,
  CLIENT_SECRET: window.CLIENT_SECRET,
  LB_VERSION: window.lbVersion,
};

const configs = {
  iata: {
    url: () => {
      return `${CONFIG.API_URL}api/iata`;
    },
    apiVersion: 20
  },

  iataSct: {
    url: () => {
      return `${CONFIG.API_URL}api/Itinerary/IataSearchSct`;
    },  
    apiVersion: 18
  },

  itinerary: {
    url: (params) => {
      return params && params.itineraryId ? `${CONFIG.API_URL}api/itinerary/${params.itineraryId}` : `${CONFIG.API_URL}api/itinerary`;
    },
    apiVersion: 21
  },

  summary: {
    url: () => {
      return `${CONFIG.API_URL}api/itinerary/summary`;
    },
    apiVersion: 21
  },

  itineraryApprovers: {
    url: (itineraryId) => {
      return `${CONFIG.API_URL}api/itinerary/${itineraryId}/approvers`;
    },
    apiVersion: 19
  },

  itineraryCompanyReference: {
    url: (itineraryId) => {
      return `${CONFIG.API_URL}api/itinerary/${itineraryId}/companyreference`;
    },
    apiVersion: 19
  },

  itineraryPassenger: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger${params.passengerId ? '/' + params.passengerId : ''}`;
    },
    apiVersion: 20
  },

  itineraryPassengerApprovers: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/approvers/${params.approverId}`;
    },
    apiVersion: 19
  },

  itineraryPassengerChange: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/change`;
    },
    apiVersion: 20
  },

  itineraryPassengerChangeFees: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/change-fees`;
    },
    apiVersion: 20
  },

  itineraryPassengerCar: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/car/${params.sectorId}`;
    },
    apiVersion: 19
  },

  itineraryPassengerFlight: {
    url: (params, target) => {
      const url = `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/flight/${params.sectorId}`;
      return target ? `${url}/${target}` : url;
    },
    apiVersion: 19
  },

  itineraryPassengerHotel: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/hotel/${params.sectorId}`;
    },
    apiVersion: 19
  },

  itineraryPassengerMembership: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/membership`;
    },
    apiVersion: 19
  },

  itineraryPassengerRail: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/rail/${params.sectorId}`;
    },
    apiVersion: 19
  },

  itineraryPaymentMethod: {
    url: (itineraryId) => {
      return `${CONFIG.API_URL}api/itinerary/${itineraryId}/paymentmethod`;
    },
    apiVersion: 19
  },

  loyaltyProgram: {
    url: () => {
      return `${CONFIG.API_URL}api/loyaltyprograms`;
    },
    apiVersion: 20
  },

  itinerarySearch: {
    url: (itineraryId) => {
      return `${CONFIG.API_URL}api/itinerary/${itineraryId}/search`;
    },
    apiVersion: 19
  },

  ticketsOnHold: {
    url: (itineraryId) => {
      return `${CONFIG.API_URL}api/itinerary/${itineraryId}/unusedflighttickets`;
    },
    apiVersion: 19
  },

  travellers: {
    url: () => {
      return `${CONFIG.API_URL}api/travellers`;
    },
    apiVersion: 20
  },

  travelSearch: {
    url: () => {
      return `${CONFIG.API_URL}api/travel/search`;
    },
    apiVersion: 20
  },

  itineraries: {
    url: (params) => {
      const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      return `${CONFIG.API_URL}api/itineraries?${qs}`;
    },
    apiVersion: 21
  },

  addFlights: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger`
    },
    apiVersion: 20
  },

  searchTravellers: {
    url: (params) => {
      return `${CONFIG.API_URL}api/travellers?query=${params.query}`
    },
    apiVersion: 20
  },

  itineraryForChange: {
    url: (params) => {
      const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      return `${CONFIG.API_URL}api/itineraries/GetItineraryForChange?${qs}`;
    },
    apiVersion: 21
  },

  cancelItinerary: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/cancel/${params.itineraryId}`
    },
    apiVersion: 21
  },

  cancelSegment: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/${params.sectorType}/${params.sectorId}/status`
    },
    apiVersion: 21
  },

  cancelFlightSegments: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/${params.passengerId}/flight/status`
    },
    apiVersion: 21
  },

  passengerHotel: {
    url: (params) => {
      return `${CONFIG.API_URL}api/itinerary/${params.itineraryId}/passenger/hotel`
    },
    apiVersion: 20
  },
};

export function apiConfig(key, params, target) {
  return {
    url: getUrl(key, params, target),
    version: getVersion(key),
  }
}

function getUrl(key, params, target) {
  return configs[key].url(params, target);
}

function getVersion(key) {
  return configs[key] ? configs[key].apiVersion : CONFIG.API_VERSION;
}




// TRACKING
export const trackingEvents = _trackingEvents;

export function trackingInit() {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  mixpanel.init(CONFIG.MIXPANEL_TOKEN, {
    track_pageview: false
  });

  mixpanel.register({
    'Application': CONFIG.MIXPANEL_APP_NAME
  });

  if (CONFIG.MIXPANEL_DEBUG) {
    mixpanel.set_config({ debug: true });
  }
}


export function track(name, ...data) {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  const trackMethod = trackingMethods[name];

  if (typeof trackMethod === 'function') {
    trackMethod(...data);
  } else {
    mixpanel.track(name);
  }
}

export function setTrackingIdentity(id) {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  mixpanel.identify(id);
}

export function trackLogin() {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  mixpanel.people.set_once({
    'First Login': new Date()
  });

  mixpanel.people.set({
    'Last Login': new Date()
  });

  mixpanel.people.increment({
    'Total Logins': 1
  });

  track('LOGIN', true);
}

export function trackUser(firstName, lastName = '', email = '', debtorId = '') {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  if (firstName.includes('@') && !email) {
    email = firstName;
  }

  mixpanel.people.set({
    '$email': email,
    '$first_name': firstName,
    '$last_name': lastName,
    'Debtor identifier': debtorId
  });
}

export function time(name) {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  mixpanel.time_event(name);
}


export function trackSearch(data) {
  if (!CONFIG.MIXPANEL_ENABLED) return;

  mixpanel.time_event(_trackingEvents.BOOKING_SUBMIT);

  trackingMethods.SEARCH(data);
}


// CHANGE BOOKINGS
export let changeBookingsPnr = writable(undefined);
export let changeBookingsItinerary = writable(undefined);
export let changeBookingsPaymentMethods = writable([]);
export let changeBookingsPaymentGroups = writable([]);
export let changeBookingsPaymentIsInProgress = writable(false);
export let changeBookingsPaymentIsComplete = writable(false);
export let changeBookingHotelPaymentItems = writable([]);
export let changeBookingHotelPayment = writable(null);
export let changeBookingHotelGroupIdentifier = writable(null);

export let flightsForChange = writable([]);
export let carsForChange = writable([]);
export let hotelsForChange = writable([]);
export let itineraryByDate = writable([]);
export let notesToConsultant = writable('');
export let reasonForChange = writable('');

function createTemporaryCard(data) {
  return {
    Reference: 'Temporary',
    ExpiryMonth: data.expiry.substr(0, 2),
    ExpiryYear: data.expiry.substr(2, 4),
    Holder: data.name,
    Number: data.number.replace(/ /g,''),
    PaymentType: 'Card',
    SecurityCode: data.cvc,
    SecurityCodeRequired: false,
    Type: {
      Code: creditCardCodeByName(data.cardType),
      Value: null
    }
  };
}

export function addTemporaryCardToChangeBookingsPaymentGroups(temporaryCard) {
  const temporaryCardForPayment = createTemporaryCard(temporaryCard);

  const _changeBookingsPaymentGroups = get(changeBookingsPaymentGroups).map((group) => {
    const paymentItems = group.paymentMethods.filter(item => !item.temporary);
    group.paymentMethods = [temporaryCardForPayment, ...paymentItems];
    group.selectedPaymentMethod = temporaryCardForPayment;

    return group;
  });

  changeBookingsPaymentGroups.set(_changeBookingsPaymentGroups);
}

export function addTemporaryCardToChangeBookingsHotelPayment(temporaryCard) {
  const temporaryCardForPayment = createTemporaryCard(temporaryCard);
  const paymentItems = get(changeBookingHotelPaymentItems).filter(item => item.Reference != 'Temporary');
  changeBookingHotelPaymentItems.set([temporaryCardForPayment, ...paymentItems]);
  changeBookingHotelPayment.set(temporaryCardForPayment)
}

export function changeBookingComplete() {
  changeBookingsItinerary.set(undefined);
  changeBookingsPaymentMethods.set([]);
  changeBookingsPaymentGroups.set([]);
  changeBookingsPaymentIsInProgress.set(false);
  changeBookingsPaymentIsComplete.set(true);
  manageBookingsItinerary.set(undefined);
}

export function selectChangeBookingPaymentMethod(groupIdentifier, paymentMethod) {
  const _changeBookingsPaymentGroups = get(changeBookingsPaymentGroups).map((group) => {
    if (group.groupIdentifier === groupIdentifier) {
      group.selectedPaymentMethod = paymentMethod;
    }

    return group;
  });

  changeBookingsPaymentGroups.set(_changeBookingsPaymentGroups);
}

export function getChangeBookingSession() {
  const session = sessionStorage.getItem('changeBooking');
  if (!session) return;
  
  let data = JSON.parse(session);

  changeBookingsItinerary.set(data.itinerary);
  itineraryByDate.set(data.itineraryByDate);
  flightsForChange.set(data.flights);
  hotelsForChange.set(data.hotels);
  carsForChange.set(data.cars);
  notesToConsultant.set(data.notesToConsultant);
  reasonForChange.set(data.reasonForChange);

  return data;
}

export function saveChangeBookingSession() {
  const session = {
      itinerary: get(changeBookingsItinerary),
      itineraryByDate: get(itineraryByDate),
      flights: get(flightsForChange),
      hotels: get(hotelsForChange),
      cars: get(carsForChange),
      notesToConsultant: get(notesToConsultant),
      reasonForChange: get(reasonForChange)
  }

  sessionStorage.setItem('changeBooking', JSON.stringify(session));
}


// SEARCH
const defaultSearch = {
  query: '',
  dateFrom: dayjs().subtract(1, 'month').format(),
  dateTo: dayjs().add(11, 'month').format()
};

export let bookings = writable([]);

export let bookingsSearchQuery = writable('');
export let bookingsSearchDateFrom = writable(defaultSearch.dateFrom);
export let bookingsSearchDateTo = writable(defaultSearch.dateTo);

export let bookingsCurrentPage = writable(1);
export let bookingsTotalItemsCount = writable(100);
export let bookingsPageSize = writable(20);
export let bookingsIsWaiting = writable(false);
export let bookingsActiveFilter = writable('All');
export let bookingsActiveSearchText = writable('');
export let bookingsActiveDateFrom = writable(defaultSearch.dateFrom);
export let bookingsActiveDateTo = writable(defaultSearch.dateTo);

export function resetBookingSearch() {
  bookingsSearchQuery.set('');
  bookingsSearchDateFrom.set(defaultSearch.dateFrom);
  bookingsSearchDateTo.set(defaultSearch.dateTo);
}

// BOOKINGS
export let canDoBookingsSearch = derived([bookingsSearchDateFrom, bookingsSearchDateTo], (dates) => dates[0] && dates[1]);

// VIEW BOOKING
export let viewBookingPrintTotal = writable('include');

// USER
export let userInfo = writable(undefined);
export let userDebtor = writable(JSON.parse(sessionStorage.getItem('CURRENT_USER_debtor')));

userDebtor.subscribe(value => {
  if (value) {
    resetChangeBookings();
    sessionStorage.setItem('CURRENT_USER_debtor', JSON.stringify(value));
  } else {
    sessionStorage.removeItem('CURRENT_USER_debtor');
  }
});

export let UserIsConsultant = derived(userInfo, $userInfo => $userInfo && $userInfo.Role === 'Consultant');
export let isAnonymousUser = writable(false);
export let usersName = writable('');
export let usersEmail = writable('');
export let usersDebtor = writable(null);
export let usersInitials = writable('');
export let UserRequiresDebtor = derived([userDebtor, UserIsConsultant], (userSettings) => !userSettings[0] && userSettings[1]);


// LOGOUT & RESET STORES
function resetUser() {
  userInfo.set(undefined);
  userDebtor.set(undefined);
  isAnonymousUser.set(false);
  usersName.set('');
  usersEmail.set('');
  usersEmail.set('');
  usersDebtor.set(null);
  usersInitials.set('');
}

function resetChangeBookings() {
  changeBookingsItinerary.set(undefined);
  changeBookingsPaymentMethods.set([]);
  changeBookingsPaymentGroups.set([]);
  changeBookingsPaymentIsInProgress.set(false);
  changeBookingsPaymentIsComplete.set(false);
}

export function logout() {
  resetUser();
  resetChangeBookings();
  resetBookingSearch();
  bookings.set([]);
  bookingsCurrentPage.set(1);
  bookingsTotalItemsCount.set(100);
  bookingsPageSize.set(20);
  bookingsIsWaiting.set(false);
  bookingsActiveFilter.set('All');
  bookingsActiveSearchText.set('');
  bookingsActiveDateFrom.set(defaultSearch.dateFrom);
  bookingsActiveDateTo.set(defaultSearch.dateTo);
}

// SETTINGS
export let settings = writable(undefined);

// CONSTANTS
export const CURRENT_YEAR = readable(new Date().getFullYear());
export const DISABLE_SEATMAPS = readable(false);
export const NO_POLICY_BREAK = readable('Within Policy');

export const dateFormat = readable({
  DATE_DAY: 'ddd, DD MMM',
  DATE_EXTENDED: 'ddd, DD MMM YYYY',
  DATE_EXTENDED_NO_COMMA: 'ddd DD MMM YYYY',
  DATE_TIME_FULL: 'DD MMM YYYY hh:mma',
  DATE_TIME_EXTENDED: 'ddd, DD MMM YYYY hh:mm A',
  DATE_TIME_THEN_DATE: 'hh:mma - ddd, DD MMM YYYY'
});

// MODAL
export function showErrorModal(messages = []) {
  modalService.open(ErrorModal,
    {
      message: messages.join('')
    }
  );
}

// FARE CLASSES
export function orderFareClasses(fareClasses) {
  const buckets = window.settings.FlightConfiguration.FareBucketClasses;

  fareClasses = fareClasses.map(fareClass => {
    let bucket = buckets.find(bucket => bucket.FareClasses.indexOf(fareClass)) > -1;
    return { name: fareClass, sort: bucket.Sort};
  });

  return fareClasses.sort((a, b) => {
    a.Sort - b.Sort
  }).map(fareClass => fareClass.name);
}


// AIRPORT TRANSFERS 
export let airportTransferSelections = writable(null);
export let airportTransferItineraryId = writable(null);
export let airportTransferShowConfirmation = writable(false);
export let airportTransferItineraryData = writable(null);
export let airportTransferBooked = writable([]);
export let airportTransferError = writable(false);

export function airportTransferReset() {
  airportTransferSelections.set(null);
  airportTransferItineraryId.set(null);
  airportTransferShowConfirmation.set(false);
  airportTransferItineraryData.set(null);
  airportTransferBooked.set([]);
  airportTransferError.set(false);
}

export function airportTransfersPrepData() {
  let transfers = get(airportTransferSelections);
  let itinerary = get(airportTransferItineraryData)

  let payload = [];

  Object.keys(transfers).forEach(key => {
    let sectorId = key.split('_')[0];
    let sectorDetails = itinerary.Passengers[0].Flights.find(flight => flight.Identifier === sectorId);
    let transfer = transfers[key];
    
    const {
      TravellerFirstName,
      TravellerLastName,
      Reference,
      ServiceTime,
      Gst,
      ServiceType,
      LocationCode,
      Pax,
      PaymentType,
      ServiceLevel,
      RetailFare,
      FareType,
      ReservationStatus
    } = transfer.data;

    const {
      PrimaryContactPassengerIdentifier,
      PassengerIdentifiers,
      TravellerTitle,
      TravellerEmail,
      TravellerPhone,
      PickupTime,
      DropoffTime,
      AdditionalBags,
      Bikes,
      GolfClubs,
      SkiSnowboards,
      Surfboards,
      OtherOversized,
      Notes,
      PromoCode,
    } = transfer.metadata;


    let item = {
      PassengerIdentifier: PrimaryContactPassengerIdentifier,
      PrimaryContactPassengerIdentifier,
      PassengerIdentifiers,
      TravellerFirstName,
      TravellerSurname: TravellerLastName,
      TravellerTitle,
      TravellerEmail,
      TravellerPhone,
      Reference,
      ServiceTime,
      PickupTime,
      DropoffTime,
      PickupAddress: transfer.data.PickupAddress,
      DropoffAddress: transfer.data.DropoffAddress,
      TotalFare: RetailFare,
      Gst,
      ServiceType,
      LocationCode,
      Pax,
      PaymentType,
      ServiceLevel,
      RetailFare,
      FareType,
      ReservationStatus,
      ExtraItems: AdditionalBags + Bikes + GolfClubs + SkiSnowboards + Surfboards + OtherOversized,
      AdditionalBags,
      Bikes,
      GolfClubs,
      SkiSnowboards,
      Surfboards,
      OtherOversized,
      Notes,
      PromoCode,
      ServiceCode: sectorDetails.Carrier + sectorDetails.FlightNumber,
      Airline: sectorDetails.Carrier,
    };

    payload.push(item);
  })

  return {
    itineraryId: get(airportTransferItineraryId),
    bodyData: payload
  };
}


// Taxis
export const taxiTravellerName = writable(null);
export const taxiPickUp = writable(null);
export const taxiDropOff = writable(null);
export const taxiTravellerPnr = writable(null);
export const taxiSelected = writable(null);
export const taxiSearchResults = writable(null);
export const taxiShowConfirm = writable(false);
export const taxiItinerarySectors = writable(null);
export const taxiPaxDetails = writable(null);
export const taxiSearching = writable(false);
export const taxiPaxId = writable(null);
export const taxiSelectedSuggestion = writable(null);

export const taxiSelectedPickUpTime = derived(
    taxiSelected,
    ($taxiSelected, set) => {
        if (!$taxiSelected) return set(null);

        const date = $taxiSelected.ServiceTime.substring(0, 10);
        const time = dayjs($taxiSelected.PickUpTime.value, 'h:mm A').format(
            'HH:mm:ss'
        );
        const offset = $taxiSelected.ServiceTime.substring(20);

        set(`${date}T${time}+${offset}`);
    }
);
