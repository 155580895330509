<script>
    import { createEventDispatcher } from 'svelte';
    import ConfirmDetails from './_ConfirmDetails.svelte';

    import {
        taxiPaxId,
        taxiSelected,
        taxiSelectedPickUpTime,
        taxiShowConfirm,
        taxiTravellerName,
        taxiTravellerPnr,
    } from '../../store';

    import { taxiBook } from '../../http/taxis';
    import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';

    const dispatch = createEventDispatcher();

    async function handleConfirm() {
        isBooking = true;

        const data = {
            PassengerIdentifier: $taxiPaxId,
            PickUpTime: $taxiSelectedPickUpTime,
            Origin: $taxiSelected.Origin,
            Destination: $taxiSelected.Destination,
            VehicleType: $taxiSelected.CorporateCabsOptions.VehicleType,
        };

        const res = await taxiBook(data).catch(err => {
          isBooking = false;
        });

        if (res && res[0] && res[0].Identifier)
            dispatch('bookingMade', res[0].Identifier);
    }

    async function handleCancel() {
        $taxiShowConfirm = false;
    }

    let isBooking = false;
</script>

<div class="confirm-modal">
    <div class="content">
        <div class="header">
            Confirm Booking for {$taxiTravellerName} - #{$taxiTravellerPnr}
        </div>
        <h2>Transfer Details:</h2>

        <ConfirmDetails details={$taxiSelected} searchMode={true} />

        <div class="footer">
            <button
                class="confirm"
                on:click={handleConfirm}
                disabled={isBooking}
                >{#if isBooking}<FlexSpinner white />
                {:else}
                    Confirm{/if}</button>
            {#if !isBooking}
                <button
                    class="cancel"
                    on:click={handleCancel}
                    disabled={isBooking}>Cancel</button>
            {/if}
        </div>
    </div>
</div>

<style>
    .confirm-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.6);
        padding: 12px;
    }

    .content {
        background: #fff;
        min-height: 450px;
        width: 100%;
        border-radius: 8px;
        padding: 24px;
        box-shadow: 0px 12px 24px -4px rgba(32, 43, 51, 0.24),
            0px 8px 60px rgba(32, 43, 51, 0.32);
    }

    .header {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
    }

    h2 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #6d7880;
    }

    .footer {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    button.confirm,
    button.cancel {
        border: none;
        color: #fff;
        background: #156af4;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
    }

    button.cancel {
        border: 1px solid #156af4;
        color: #156af4;
        background: #fff;
    }

    @media (min-width: 928px) {
        .content {
            min-height: 0;
            width: 690px;
        }

        .header {
            font-size: 26px;
            line-height: 39px;
        }

        .footer {
            justify-content: flex-start;
            flex-direction: row-reverse;
        }

        button.confirm,
        button.cancel {
            width: 115px;
            height: 34px;
            font-weight: 600;
            font-size: 12px;
        }

        button.cancel {
            width: 73px;
        }
    }
</style>
