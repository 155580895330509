<script>
  import { createEventDispatcher } from "svelte";

  import Close from "./Icons/Close.svelte";

  const dispatch = createEventDispatcher();

  export let title = "Are you sure you don’t need a transfer?";
  export let copy =
    "This will remove the transfer from your itinerary.";
  export let actionButton = "red";
  export let confirmText = "Remove";

  function handleCancel() {
    dispatch("cancel");
  }

  function handleConfirm() {
    dispatch("confirm");
  }
</script>

<style>
  .mask {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }

  .container {
    border-radius: 10px;
    background-color: #ffffff;
    padding: 16px;
    box-shadow: 1px 1px 5px 0 rgba(33, 43, 54, 0.15);
    position: fixed;
    left: 15px;
    right: 15px;
    top: 15px;
    min-height: 200px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 99999;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 8px;
  }

  .title {
    color: #212b36;
    font-size: 26px;
    font-weight: 600;
    margin: 0 0 8px;
    line-height: 32px;
  }

  .copy {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 40px;
  }

  .footer {
    height: 56px;
    color: #4d555e;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .footer button {
    width: calc(50% - 8px);
    color: #fff;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 500;
  }

  .footer button.cancel {
    background: #fff;
    color: #4d555e;
  }

  .footer button.red {
    background-color: #cf311e;
  }

  .footer button.red:hover {
    background-color: #a41313;
  }

  .footer button.blue {
    background-color: #1f78d1;
  }

  .footer button.blue:hover {
    background-color: #084e8a;
  }

  @media (min-width: 768px) {
    .container {
      width: 752px;
      max-width: 752px;
      padding: 30px;
      margin: 0 auto;
    }

    .footer {
      justify-content: flex-end;
      height: auto;
    }

    .footer button {
      max-width: 95px;
      height: 40px;
    }

    .close {
      cursor: pointer;
    }
  }
</style>

<div class="mask" on:click={handleCancel} />

<div class="container">

  <div class="close" on:click={handleCancel}>
    <Close />
  </div>

  <div class="title">{title}</div>
  <div class="copy">{copy}</div>

  <div class="footer">
    <button class="cancel" on:click={handleCancel}>Cancel</button>
    <button class={actionButton} on:click={handleConfirm}>{confirmText}</button>
  </div>
</div>
