<script>
  import sortBy from 'lodash/sortBy';
  import CarSectorStatusRow from '../SectorStatusRow/CarSectorStatusRow.svelte';
  import HotelSectorStatusRow from '../SectorStatusRow/HotelSectorStatusRow.svelte';
  import FlightSectorStatusRow from '../SectorStatusRow/FlightSectorStatusRow.svelte';
  import RailSectorStatusRow from '../SectorStatusRow/RailSectorStatusRow.svelte';

  export let sectors;
  export let hotelFailure = false;

  function orderSectors(sectors) {
    const sectorTypeOrder = ['flight', 'hotel', 'car', 'rail'];
    return sortBy(sectors, (sector) => {
      return sectorTypeOrder.indexOf(sector.sectorType);
    });
  }
</script>

<div class="sectorStatusList">
  {#each orderSectors(sectors) as sector}
  <div class="sectorStatusList_item">
    { #if sector.sectorType === 'car' }
    <CarSectorStatusRow sector="{ sector.data }"></CarSectorStatusRow>
    { /if }
    { #if sector.sectorType === 'hotel' }
    <HotelSectorStatusRow {hotelFailure} sector="{ sector.data }"></HotelSectorStatusRow>
    { /if }
    { #if sector.sectorType === 'flight' }
    <FlightSectorStatusRow sector="{ sector.data }"></FlightSectorStatusRow>
    { /if }
    { #if sector.sectorType === 'rail' }
    <RailSectorStatusRow sector="{ sector.data }"></RailSectorStatusRow>
    { /if }
  </div>
  {/each}
</div>

<style>
  .sectorStatusList_item:not(:last-of-type) {
    margin-bottom: 16px;
  }
</style>
