<script>
  import { onMount } from "svelte";
  import Link from "./Icons/Link.svelte";

  import {
    airportTransferBooked,
    airportTransferItineraryData,
    airportTransferError
  } from "../../store/index.js";

  function handlePax(pnr) {
    window.location.hash = `/manage-bookings/${pnr}`;
  }

  let passengers = [];
  let ready = false;
  let bookingFailed = false;

  onMount(() => {
    if (typeof $airportTransferBooked === 'string') {
      $airportTransferError = true;
      passengers = [$airportTransferItineraryData.Passengers[0]];
      ready = true;
      return;
    }

    if ($airportTransferBooked)
      $airportTransferBooked.forEach((pax) => {
        if (pax.ReservationStatus !== 'Confirmed') $airportTransferError = true;
        if (
          !passengers.find(
            (item) => item.Identifier === pax.PassengerIdentifier
          )
        ) {
          passengers = [
            ...passengers,
            $airportTransferItineraryData.Passengers.find(
              (item) => item.Identifier === pax.PassengerIdentifier
            ),
          ];
        }
      });

    ready = true;
  });
</script>

<style>
  .pax {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0 rgba(33, 43, 54, 0.15);
    padding: 24px 16px;
    text-align: center;
    margin: 0 0 16px;
    padding: 16px;
  }

  .pax h2 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin: 0;
    color: #4d555e;
  }

  .pax h3 {
    color: #79838d;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 16px;
    font-weight: 500;
  }

  .pax button {
    height: 56px;
    border-radius: 4px;
    background-color: #1f78d1;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 0 16px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  .pax button span {
    margin: 0 0 0 8px;
  }

  @media (min-width: 768px) {
    .pax {
      max-width: 1155px;
      margin: 0 auto 16px;
      display: flex;
      align-items: center;
      padding: 16px 24px;
    }

    .pax button {
      height: 40px;
    }

    .paxDetails {
      text-align: left;
      flex-grow: 1;
    }

    .paxDetails h3 {
      margin: 0;
    }
  }
</style>

{#if ready}
  {#each passengers as pax}
    <div class="pax">
      <div class="paxDetails">
        <h2>{pax.FullName}</h2>
        <h3>#{pax.Pnr}</h3>
      </div>
      <button on:click={() => handlePax(pax.Pnr)}>
        <Link />
        <span>View Booking</span>
      </button>
    </div>
  {/each}
{/if}
