import Card from 'creditcards/card';
import cardTypes from 'creditcards-types';
import Type from 'creditcards-types/type';

const card = Card(cardTypes);

const creditCards = [
  {
    name: 'American Express',
    code: 'AX'
  },
  {
    name: 'Diners Club',
    code: 'DC'
  },
  {
    name: 'Mastercard',
    code: 'CA'
  },
  {
    name: 'Visa',
    code: 'VI'
  },
  {
    name: 'UATP',
    code: 'TP'
  }
];

export function maskCardNumberByType(cardNumber, cardType, maskChar = 'X') {
  const creditCard = card.types.find((creditCardType) => {
    return creditCardType.name === cardType;
  });

  const groupPattern = creditCard.groupPattern.source.replace(/\\d/g, `[\\d\\${maskChar}]`);

  const maskedCard = new Type({
    name: 'Masked Card',
    pattern: creditCard.pattern,
    groupPattern
  });

  const maskedCardNumber = cardNumber.replace(/ /g,'').replace(/^(\d{4})(\d{1,})(\d{4})$/, (matched, start, middle, end) => {
    return `${start}${middle.replace(/./g, maskChar)}${end}`;
  });

  return maskedCard.group(maskedCardNumber).join(' ');
}

export function creditCardTypeNames() {
  return creditCards.map((cardType) => {
    return cardType.name;
  });
}

export function creditCardNameByCode(code) {
  const card = creditCards.find((c) => {
    return c.code === code;
  });

  return card ? card.name : null;
}

export function creditCardCodeByName(name) {
  const card = creditCards.find((c) => {
    return c.name === name;
  });

  return card ? card.code : null;
}

export function creditCardCodeByNumber(number) {
  const cardTypeName = card.type(number);
  return cardTypeName ? creditCardCodeByName(cardTypeName) : null;
}

export function allPaymentGroupsHaveInvoice(paymentGroups, paymentMethods) {
  return paymentGroups.every((group) => {
    return paymentMethods.some((paymentMethod) => {
      return paymentMethod.Payment.PaymentType === 'Invoice' && paymentMethod.SectorGroups.includes(group.groupIdentifier);
    });
  });
}
