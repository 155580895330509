<script>
    import dayjs from 'dayjs';

    export let showTime = false;
    export let item = null;
    export let pickUpTime = null;

    $: time = pickUpTime && dayjs(pickUpTime, 'YYYY-MM-DDTHH:mm').format('h:mm A');
    $: date = pickUpTime && dayjs(pickUpTime, 'YYYY-MM-DD').format('ddd, D MMMM YYYY');

</script>

<div class="location">
    {#if showTime}
        <div class="time">
            <h3 class="time-header">Pick-up Time</h3>

            <p class="time-details">
                {time}<br />{date}
            </p>
        </div>
    {/if}

    <div class="details">
        <h3>Pick-up Location</h3>
        <p>{item.Origin.Title}</p>
        <span>{item.Origin.Address}</span>

        <h3>Drop-off Location</h3>
        <p>{item.Destination.Title}</p>
        <span>{item.Destination.Address}</span>

        {#if item.ConfirmationNumber}
          <h3>Supplier Confirmation Code</h3>
          <p>{item.ConfirmationNumber}</p>
        {/if}
    </div>
</div>

<style>
    .location {
        display: flex;
        flex-direction: column;
    }

    .location h3 {
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        margin: 0 0 4px;
        color: #abb1bb;
    }

    .location p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 0 4px;
    }

    .location span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 0 0 24px;
        color: #6d7880;
    }

    .details {
        display: flex;
        flex-direction: column;
    }

    .time {
        margin: 0 0 24px;
    }

    p.time-details {
        margin: 0;
    }

    @media (min-width: 928px) {
        .location {
            flex-direction: row;
            flex-grow: 1;
        }

        .time {
            width: 112px;
            margin: 0 14px 0 0;
        }
    }
</style>
