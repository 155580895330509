<script>
  import SectorStatusRow from './SectorStatusRow.svelte';
  import SectorStatusRowOriginal from './SectorStatusRowOriginal.svelte';
  import { formatFromApiDate } from '../../util/date';
  import {
    dateFormat,
  } from '../../store/index.js';

  export let sector;

  function date(sector, format) {
    return formatFromApiDate(sector.Departure.Date, format);
  }

  function desc(sector) {
    return `${sector.CarrierName} ${sector.Carrier}${sector.FlightNumber}`;
  }

  function title(sector) {
    return `${sector.Departure.City} (${sector.Departure.Code}) to ${sector.Arrival.City} (${sector.Arrival.Code})`;
  }
</script>

<SectorStatusRow status="{ sector.Status }">
  <div slot="icon"><img src="{ sector.CarrierImage }" alt="{ sector.CarrierImage }" /></div>
  <div slot="title">{title(sector)}</div>
  <div slot="desc">{desc(sector)}</div>

  { #if sector.Parent }
    <SectorStatusRowOriginal>
      <div slot="icon"><img src="{ sector.Parent.CarrierImage }" alt="{ sector.Parent.Carrier }" /></div>
      <div slot="title">{title(sector.Parent)}</div>
      <div slot="desc">{desc(sector.Parent)} - {date(sector.Parent, $dateFormat.DATE_TIME_EXTENDED)}</div>
    </SectorStatusRowOriginal>
  { /if }
</SectorStatusRow>
