<script>
  import { onMount } from 'svelte';

  import Modal from '@ctm/ui/Modal';
  import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
  import { getFareRules } from '../../http/itinerary.js';

  let ruleData = '';
  let isWaiting = true;
  let ruleType = '';
  let text = '';

  export let complete = () => { };
  export let cancel = () => { };

  export let params;

  onMount(() => {
    getFareRules(params)
      .then((response)=> {
        ruleData = response.data.Data, ruleType = response.data.Type;
      })
      .catch(() => {
        error = 'Sorry, could not load the fare conditions. Please close and try again.';
      })
      .finally(() => {
        isWaiting = false;
      });
  });


  let FareRulesLink;
  $: {
    const urlReg = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    FareRulesLink = ruleData.replace(urlReg, (str) => {
      return `<a href="${str}" target="_blank" rel="noreferrer noopener">Click here to view fare rules</a>`;
    });
  }

  function onModalOk() {
    complete();
  }

  export function onModalCancel() {
    complete();
  }

  export function onButtonClick() {
    complete();
  }
</script>

<Modal
  hasCancelButton="{false}"
  on:ok="{onModalOk}"
  okText="Close"
  title="Full fare conditions"
  on:cancel="{onModalCancel}"
>
  { #if isWaiting }
    <div class="spinner">
      <FlexSpinner />
    </div>
  { :else }
    { #if ruleType && ruleData }
      { #if ruleType === 'ExternalUrl' }
        { @html FareRulesLink }
      { :else }
        <pre>{ ruleData }</pre>
      { /if }
    { :else }
      <p>Sorry, could not load the fare conditions. Please close and try again.</p>
    { /if }
  { /if }
</Modal>

<style>
  .spinner {
    height: 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
</style>
