<script>
  export let wide = false;
</script>

<svg
    class:wide
    width="100%"
    height="100%"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.159 4.95488L12.0508 1.78873C11.7488 0.926114 10.9314 0.346191 10.0171 0.346191H4.23292C3.31862 0.346191 2.50123 0.926114 2.19969 1.78819L1.09154 4.95435C0.499769 5.34365 0.125 6.01619 0.125 6.75604V10.577C0.125 11.172 0.606923 11.6539 1.20192 11.6539C1.79692 11.6539 2.27885 11.172 2.27885 10.577V9.89796C2.27885 9.89796 5.12246 10.0385 7.125 10.0385C9.12754 10.0385 11.9712 9.89796 11.9712 9.89796V10.577C11.9712 11.172 12.4531 11.6539 13.0481 11.6539C13.6431 11.6539 14.125 11.172 14.125 10.577V6.75604C14.125 6.01619 13.7497 5.34365 13.159 4.95488ZM2.59923 3.90704L3.21631 2.14411C3.36762 1.71227 3.77523 1.42311 4.23292 1.42311H10.0171C10.4748 1.42311 10.8824 1.71227 11.0337 2.14411L11.6508 3.90704C11.7477 4.18435 11.5167 4.46381 11.2265 4.41696C10.1705 4.24627 8.81308 4.11542 7.125 4.11542C5.43692 4.11542 4.07946 4.24627 3.02354 4.41696C2.73331 4.46381 2.50231 4.18435 2.59923 3.90704ZM2.54808 7.88465C2.10223 7.88465 1.74038 7.52281 1.74038 7.07696C1.74038 6.63111 2.10223 6.26927 2.54808 6.26927C2.99392 6.26927 3.35577 6.63111 3.35577 7.07696C3.35577 7.52281 2.99392 7.88465 2.54808 7.88465ZM8.74039 7.34619H5.50962C5.21238 7.34619 4.97115 7.10496 4.97115 6.80773C4.97115 6.5105 5.21238 6.26927 5.50962 6.26927H8.74039C9.03762 6.26927 9.27885 6.5105 9.27885 6.80773C9.27885 7.10496 9.03762 7.34619 8.74039 7.34619ZM11.7019 7.88465C11.2561 7.88465 10.8942 7.52281 10.8942 7.07696C10.8942 6.63111 11.2561 6.26927 11.7019 6.26927C12.1478 6.26927 12.5096 6.63111 12.5096 7.07696C12.5096 7.52281 12.1478 7.88465 11.7019 7.88465Z"
        fill="#141B22" />
</svg>


<style>
  svg {
    width: 15px;
    height: 12px;
  }

  svg.wide {
    width: 20px;
    height: 17.5px;
  }
</style>
