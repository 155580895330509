<script>
  import startCase from 'lodash/startCase';
  import classnames from 'classnames';

  export let state = '';
  export let showLabel = true;
  export let autoBooked = false;

  let ClassNames;
  $: {
    ClassNames = classnames(
      `approvalStateLabel-${state}`
    );
  }

  let Label;
  $: {
    switch (state) {
      case 'Auto':
        Label = autoBooked ? 'Booked' : 'Approved';
        break;
      case 'Booked':
        Label = 'Approved';
        break;
      case 'Declined':
        Label = 'Denied';
        break;
      case 'Pending':
        Label = 'Awaiting approval';
        break;
      default:
        Label = startCase(state);
    }
  }
</script>

<div class="approvalStateLabel { ClassNames }">
  <span class="approvalStateLabel_indicator"></span>
  { #if showLabel }
  <span class="approvalStateLabel_label">{ Label }</span>
  { /if }
</div>

<style>
  .approvalStateLabel {
    display: flex;
    align-items: center;
  }

  .approvalStateLabel_label {
    font-style: italic;
  }

  .approvalStateLabel-Approved,
  .approvalStateLabel-Auto {
    color: #51ce6c;
  }

  .approvalStateLabel-Pending {
    color: #ff9500;
  }

  .approvalStateLabel-Declined,
  .approvalStateLabel-Expired,
  .approvalStateLabel-Cancelled {
    color: #ff0035;
  }

  .approvalStateLabel_indicator {
    background-color: currentColor;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    margin-right: 12px;
  }
</style>
