export default {
  'add': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm389 230c10 0 15 9 15 26c0 17-5 26-15 26c0 0-107 0-107 0c0 0 0 107 0 107c0 10-9 15-26 15c-17 0-26-5-26-15c0 0 0-107 0-107c0 0-107 0-107 0c-10 0-15-9-15-26c0-17 5-26 15-26c0 0 107 0 107 0c0 0 0-107 0-107c0-10 9-15 26-15c17 0 26 5 26 15c0 0 0 107 0 107c0 0 107 0 107 0'
  },
  'check': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm192 420c-11 0-22-5-30-13l-121-118c-17-17-17-43 0-59c16-16 43-16 59 0l92 90l220-215c16-17 43-17 59 0c17 16 17 42 0 58l-249 244c-8 8-19 13-30 13z'
  },
  'menu': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm475 384l0 37c0 5-1 9-5 12-4 4-8 6-13 6l-402 0c-5 0-9-2-13-6-4-3-5-7-5-12l0-37c0-5 1-9 5-13 4-3 8-5 13-5l402 0c5 0 9 2 13 5 4 4 5 8 5 13z m0-146l0 36c0 5-1 10-5 13-4 4-8 6-13 6l-402 0c-5 0-9-2-13-6-4-3-5-8-5-13l0-36c0-5 1-10 5-13 4-4 8-6 13-6l402 0c5 0 9 2 13 6 4 3 5 8 5 13z m0-147l0 37c0 5-1 9-5 13-4 3-8 5-13 5l-402 0c-5 0-9-2-13-5-4-4-5-8-5-13l0-37c0-5 1-9 5-12 4-4 8-6 13-6l402 0c5 0 9 2 13 6 4 3 5 7 5 12z'
  },
  'cancel': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm288 256l143-142c4-5 6-10 6-16c0-12-11-23-23-23c-6 0-11 1-16 6l-142 142l-142-142c-5-5-10-6-16-6c-12 0-23 11-23 23c0 6 2 11 6 16l143 142l-143 142c-4 5-6 10-6 16c0 12 11 23 23 23c6 0 11-1 16-6l142-142l142 142c5 5 10 6 16 6c12 0 23-11 23-23c0-6-2-11-6-16z'
  },
  'down': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm402 201c0 5-2 9-5 13l-128 128c-4 4-8 5-13 5-5 0-9-1-13-5l-128-128c-3-4-5-8-5-13 0-5 2-9 5-13 4-3 8-5 13-5l256 0c5 0 9 2 13 5 3 4 5 8 5 13z'
  },
  'up': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm402 347c0 5-2 10-5 13-4 4-8 6-13 6l-256 0c-5 0-9-2-13-6-3-3-5-8-5-13 0-5 2-9 5-12l128-128c4-4 8-6 13-6 5 0 9 2 13 6l128 128c3 3 5 7 5 12z'
  },
  'oneway': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm494 255c0 3-1 5-3 7l-110 101c-3 3-6 3-10 2-3-2-5-5-5-8l0-64-357 0c-3 0-5-1-6-3-2-2-3-4-3-7l0-54c0-3 1-5 3-7 1-2 3-3 6-3l357 0 0-64c0-4 2-6 5-8 4-1 7-1 10 2l110 100c2 1 3 4 3 6z'
  },
  'return': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm512 338l0 55c0 3-1 5-3 7-2 1-4 2-6 2l-393 0 0 55c0 3-1 5-3 7-2 1-4 2-6 2-3 0-5-1-7-3l-91-91c-2-2-3-4-3-6 0-3 1-5 3-7l91-91c2-2 4-3 7-3 2 0 4 1 6 3 2 2 3 4 3 6l0 55 393 0c2 0 4 1 6 3 2 2 3 4 3 6z m0-155c0 3-1 5-3 6l-91 92c-2 2-4 2-7 2-2 0-4 0-6-2-2-2-3-4-3-7l0-55-393 0c-2 0-4 0-6-2-2-2-3-4-3-7l0-55c0-2 1-4 3-6 2-2 4-3 6-3l393 0 0-55c0-2 1-4 3-6 2-2 4-3 6-3 3 0 5 1 7 3l91 91c2 2 3 4 3 7z'
  },
  'denied': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm492 65l-45-45-191 191-191-191-45 45 191 191-191 191 45 45 191-191 191 191 45-45-191-191z'
  },
  'train' : {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm256 39c-101 0-183 12-183 92l0 216c0 44 36 80 80 80l-34 34 0 12 274 0 0-12-34-34c44 0 80-36 80-80l0-216c0-80-82-92-183-92z m-103 342c-19 0-34-15-34-34 0-19 15-34 34-34 19 0 35 15 35 34 0 19-16 34-35 34z m80-136l-114 0 0-114 114 0z m126 136c-19 0-35-15-35-34 0-19 16-34 35-34 19 0 34 15 34 34 0 19-15 34-34 34z m34-136l-114 0 0-114 114 0z'
  },
  'allotment': {
    viewBox: '0 0 512 512',
    type: 'path',
    data: 'm405 64l-89 0c-9-25-32-43-60-43-28 0-51 18-60 43l-89 0c-24 0-43 19-43 43l0 298c0 24 19 43 43 43l298 0c24 0 43-19 43-43l0-298c0-24-19-43-43-43z m-149 0c12 0 21 10 21 21 0 12-9 22-21 22-12 0-21-10-21-22 0-11 9-21 21-21z m43 299l-150 0 0-43 150 0z m64-86l-214 0 0-42 214 0z m0-85l-214 0 0-43 214 0z'
  }
};
