<script>
  import dayjs from "dayjs";
  import { onMount, createEventDispatcher, tick } from "svelte";

  const dispatch = createEventDispatcher();

  import PlaneDepart from "./Icons/PlaneDepart.svelte";
  import PlaneArrive from "./Icons/PlaneArrive.svelte";
  import Close from "./Icons/Close.svelte";
  import Calendar from "./Icons/Calendar.svelte";
  import Tick from "./Icons/Tick.svelte";
  import DownChevron from "./Icons/DownChevron.svelte";
  import Select from 'svelte-select/src/Select.svelte';
  import DatePicker from "@ctm/ui/DatePicker";
  import Modal from "./_Modal.svelte";
  import Loader from "./Icons/Loader.svelte";
  import Pencil from "./Icons/Pencil.svelte";
  import SearchResult from "./_SearchResult.svelte";
  import CheckBox from "./_Checkbox.svelte";

  import { addressSearch, transferOptions } from "../../http/airportTransfers";

  import { airportTransferSelections } from "../../store/index.js";

  export let sector;
  export let paxData;
  export let key;
  export let flightType;

  let resultsContainer;

  let searchObj = {
    pickUp: undefined,
    dropOff: undefined,
  };

  const nzAirports = {
    AKL: {
      code: "AKL",
      D: "Airport - Auckland Domestic",
      I: "Airport - Auckland International",
    },
    CHC: {
      code: "CHC",
      D: "Airport - Christchurch Domestic",
      I: "Airport - Christchurch International",
    },
    DUD: {
      code: "DUN",
      D: "Airport - Dunedin Domestic",
      I: "Airport - Dunedin International",
    },
    HLZ: {
      code: "HLZ",
      D: "Airport - Hamilton Domestic",
    },
    NPE: {
      code: "HKB",
      D: "Airport - Hawkes Bay Domestic",
    },
    ZQN: {
      code: "ZQN",
      D: "Airport - Queenstown Domestic",
    },
    NSN: {
      code: "NSN",
      D: "Airport - Nelson Domestic",
    },
    ROT: {
      code: "ROT",
      D: "Airport - Rotorua Domestic",
      I: "Airport - Rotorua International",
    },
    KKE: {
      code: "KKE",
      D: "Airport - Kerikeri Domestic",
    },
    PMR: {
      code: "PMR",
      D: "Airport - Palmerston North Domestic",
    },
    WLG: {
      code: "WLG",
      D: "Airport - Wellington Domestic",
      I: "Airport - Wellington International",
    },
    WRE: {
      code: "AKL",
      D: "Whangarei Airport",
    },
  };

  let removed = false;
  let isSearching = false;
  let activePassengers = [];
  let showSearchResults = false;
  let searchResults = null;

  onMount(async () => {
    activePassengers = paxData.map((pax, i) => {
      return {
        Identifier: pax.Identifier,
        Title: pax.Title,
        Email: pax.Email,
        Mobile: pax.Mobile,
        name: pax.FullName,
        active: true,
        primary: i === 0,
      };
    });

    if (flightType === "departure") {
      if (!nzAirports[sector.departure.Code]) {
        removed = true;
        return;
      }

      let isDomestic = sector.data.Arrival.CountryCode === "NZ";
      const opts = await addressSearch({
        location: nzAirports[sector.departure.Code][isDomestic ? "D" : "I"],
        cityCode: nzAirports[sector.departure.Code].code,
      });

      searchObj.dropOff = opts[0];
    } else {
      if (!nzAirports[sector.arrival.Code]) {
        removed = true;
        return;
      }

      let isDomestic = sector.data.Departure.CountryCode === "NZ";
      const opts = await addressSearch({
        location: nzAirports[sector.arrival.Code][isDomestic ? "D" : "I"],
        cityCode: nzAirports[sector.arrival.Code].code,
      });
      searchObj.pickUp = opts[0];
    }
  });

  $: id = sector.data.Identifier + "_" + flightType;
  $: locationAttributes = { id: `location_${id}` };
  let pickUpLocation;
  let dropOffLocation;
  let getLocationLabel = (option) => `${option.Address}`;

  let showExtras = false;
  let extrasItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  $: bagAttributes = { id: `bag_${id}` };
  let bagValue;

  $: bikeAttributes = { id: `bike_${id}` };
  let bikeValue;

  $: golfAttributes = { id: `golf_${id}` };
  let golfValue;

  $: snowAttributes = { id: `snow_${id}` };
  let snowValue;

  $: surfAttributes = { id: `surf_${id}` };
  let surfValue;

  $: otherAttributes = { id: `other_${id}` };
  let otherValue;

  $: primaryContact = activePassengers.find((pax) => pax.primary);
  $: canSearch = primaryContact && searchObj.pickUp && searchObj.dropOff;
  let showModal = false;

  let modalText;

  let modalOptions = [
    {
      title: "Are you sure you don’t need a transfer?",
      copy: "This will remove the transfer from your itinerary.",
      actionButton: "red",
      confirmText: "Remove",
    },
    {
      title: "Assign as primary contact",
      copy:
        "By assigning this traveller as the primary contact, their contact details will be used for the airport transfer booking and the booking will be made under their name.",
      actionButton: "blue",
      confirmText: "Assign",
    },
  ];

  let modalData = modalOptions[0];

  let noteToDriver = "";
  let promoCode = "";
  let filterByIndex = null;

  function handleExtrasToggle() {
    showExtras = !showExtras;
  }

  async function locationLoadOptions(arg) {
    const opts = await addressSearch({
      location: arg,
      cityCode:
        flightType === "departure"
          ? nzAirports[sector.departure.Code].code
          : nzAirports[sector.arrival.Code].code,
    });
    return opts;
  }

  function handleRemove() {
    modalData = modalOptions[0];
    showModal = true;
  }

  function handleCancel() {
    showModal = false;
  }

  function handleConfirm() {
    showModal = false;

    if (modalData.confirmText === "Assign") {
      activePassengers = activePassengers.map((pax, i) => {
        pax.primary = pendingAssign === i;
        if (pax.primary) pax.active = true;
        return pax;
      });
    } else {
      removeSelection();
      removed = true;
      dispatch("remove", { key });
    }
  }

  let pendingAssign = 0;
  function handleAssign(PaxIndex) {
    pendingAssign = PaxIndex;
    modalData = modalOptions[1];
    showModal = true;
  }

  function handleToggleActivePax(i) {
    activePassengers[i].active = !activePassengers[i].active;
  }

  function sectorDetails() {
    if (sectorType === "flight" && flightType === "departure") {
      return `${sector.data.Carrier + sector.data.FlightNumber} departing ${
        sector.data.Departure.Location
      } at ${dayjs(sector.data.Departure.Date, "YYYY-MM-DDTHH:mm").format(
        "h:mm A"
      )}`;
    }

    if (sectorType === "flight" && flightType === "arrival") {
      return `${sector.data.Carrier + sector.data.FlightNumber} arriving ${
        sector.data.Arrival.Location
      } at ${dayjs(sector.data.Arrival.Date, "YYYY-MM-DDTHH:mm").format(
        "h:mm A"
      )}`;
    }
  }

  function sectorTime() {
    if (sectorType === "flight" && flightType === "departure") {
      return dayjs(sector.data.Departure.Date, "YYYY-MM-DDTHH:mm").format(
        "h:mm A"
      );
    }

    if (sectorType === "flight" && flightType === "arrival") {
      return dayjs(sector.data.Arrival.Date, "YYYY-MM-DDTHH:mm").format(
        "h:mm A"
      );
    }
  }

  async function handleSearch() {
    isSearching = true;
    filterByIndex = null;
    showSearchResults = false;
    searchResults = null;

    let body = {
      FirstName: primaryContact.name.split(" ")[0],
      LastName: primaryContact.name.split(" ")[1],
      PaxCount: activePassengers.filter((pax) => pax.active).length,
      ServiceTime:
        flightType === "departure"
          ? dayjs(sector.data.Departure.Date, "YYYY-MM-DDTHH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            )
          : dayjs(sector.data.Arrival.Date, "YYYY-MM-DDTHH:mm:ss").format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
      PickupAddress: searchObj.pickUp,
      DropoffAddress: searchObj.dropOff,
      AdditionalBags: bagValue ? bagValue.value : 0,
      Bikes: bikeValue ? bikeValue.value : 0,
      GolfClubs: golfValue ? golfValue.value : 0,
      SkiSnowboards: snowValue ? snowValue.value : 0,
      Surfboards: surfValue ? surfValue.value : 0,
      OtherOversized: otherValue ? otherValue.value : 0,
      PromoCode: promoCode.length > 0 ? promoCode : null,
    };

    searchResults = await transferOptions(body).catch((err) => {
      showSearchResults = false;
      isSearching = false;
    });

    if (!isSearching) return;

    showSearchResults = true;
    isSearching = false;

    await tick();
    if (resultsContainer)
      resultsContainer.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  $: sectorType = sector.sectorType;
  $: date = dayjs(sector.data.Departure.Date, "YYYY-MM-DDTHH:mm").format(
    "dddd, Do MMM YYYY"
  );
  $: dropOff =
    sectorType === "flight" ? `${sector.data.Departure.Location}` : null;

  function handleTimeChange(event) {
    let PickupTime = event.detail.selectedValueTime.PickupTime;
    let DropoffTime = event.detail.selectedValueTime.ArriveBefore;
    $airportTransferSelections[id].metadata.PickupTime = PickupTime;
    $airportTransferSelections[id].metadata.DropoffTime = DropoffTime;
  }

  function handleSelection(event) {
    filterByIndex = event.detail.index;
    let PickupTime = event.detail.selectedValueTime.PickupTime;
    let DropoffTime = event.detail.selectedValueTime.ArriveBefore;
    let primaryPax = activePassengers.find((pax) => pax.primary);

    if (!$airportTransferSelections) $airportTransferSelections = {};

    $airportTransferSelections[id] = {
      data: searchResults[filterByIndex],
      RetailFare: searchResults[filterByIndex].RetailFare,
      metadata: {
        // PassengerIdentifier: activePassengers.find(pax => pax.active).Identifier,
        PrimaryContactPassengerIdentifier: primaryPax.Identifier,
        PassengerIdentifiers: activePassengers
          .filter((pax) => pax.active)
          .map((pax) => pax.Identifier),
        TravellerTitle: primaryPax.Title,
        TravellerEmail: primaryPax.Email,
        TravellerPhone: primaryPax.Mobile,
        PickupTime,
        DropoffTime,
        // ExtraItems: null,
        AdditionalBags: bagValue ? bagValue.value : 0,
        Bikes: bikeValue ? bikeValue.value : 0,
        GolfClubs: golfValue ? golfValue.value : 0,
        SkiSnowboards: snowValue ? snowValue.value : 0,
        Surfboards: surfValue ? surfValue.value : 0,
        OtherOversized: otherValue ? otherValue.value : 0,
        Notes: noteToDriver,
        PromoCode: promoCode.length > 0 ? promoCode : null,
      },
    };
  }

  $: searchResultsFiltered = searchResults
    ? filterByIndex !== null
      ? [searchResults[filterByIndex]]
      : [...searchResults]
    : null;

  function handleReselect() {
    filterByIndex = null;

    removeSelection();
  }

  function removeSelection() {
    if (searchResults)
      searchResults = searchResults.map((item) => {
        item.selected = false;
        return item;
      });

    if (!$airportTransferSelections) return;

    delete $airportTransferSelections[id];

    if (Object.keys($airportTransferSelections).length === 0) {
      $airportTransferSelections = null;
    } else {
      $airportTransferSelections = $airportTransferSelections;
    }
  }
</script>

<style>
  .container {
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0 rgba(33, 43, 54, 0.15);
    padding: 15px;
    margin: 0 auto 16px auto;
    max-width: 1155px;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: row;
    margin: 0 0 24px;
    align-items: center;
  }

  .header .SectorIcon {
    margin: 0 10px 0 0;
    display: flex;
  }

  .header .details {
    color: #4d555e;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 16px 0 0;
    flex-grow: 1;
  }

  .field {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;
  }

  .field label {
    color: #79838d;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 4px;
  }

  .disabledField {
    height: 56px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    background-color: #f9fafb;
    display: flex;
    padding: 16px;
    align-items: center;
  }

  .field textarea {
    min-height: 160px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    background-color: #fff;
    padding: 8px 16px;
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    outline: none;
  }

  .field textarea:focus {
    border: 2px solid #007aff;
    padding: 7px 15px;
  }

  .field input {
    height: 56px;
    border: 1px solid #dfe3e8;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 16px;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    color: #576069;
  }

  .field input:focus {
    border: 2px solid #007aff;
    padding: 0 15px;
  }

  .calendar {
    margin: 0 19px 0 0;
  }

  .disabledField span {
    color: #a2a9b2;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .passengers {
    display: flex;
    flex-direction: column;
  }

  .passenger {
    display: flex;
    flex-direction: row;
    margin: 0 0 24px;
  }

  .primary,
  .assign {
    color: #4d555e;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
  }

  .assign {
    color: #1f78d1;
    cursor: pointer;
  }

  .passengersTitle,
  .extrasTitle {
    color: #4d555e;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin: 0 0 16px;
    cursor: pointer;
  }

  .passengerName {
    line-height: 24px;
    cursor: pointer;
  }

  .passengerName.disable {
    pointer-events: none;
  }

  .promoContainer {
    margin-bottom: 32px;
  }

  .extras {
    margin: 0 0 15px;
    border-bottom: 1px solid #dfe3e8;
    padding-bottom: 30px;
  }

  .extraHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .extrasTitle {
    margin: 0 8px 0 0;
  }

  .downChevron {
    display: flex;
    transition: transform 0.2s ease-in-out;
  }

  .downChevron.active {
    transform: rotate(180deg);
  }

  .extrasContent {
    display: flex;
    flex-direction: column;
  }

  .extrasIntro {
    color: #4d555e;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .searchButton {
    height: 56px;
    width: 100%;
    border-radius: 4px;
    background-color: #1f78d1;
    outline: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: none;
  }

  .searchButton:hover {
    background-color: #084e8a;
  }

  .searchButton.disabled,
  .searchButton.disabled:hover {
    background: #c8e0f3;
    pointer-events: none;
  }

  .searchButton.searching,
  .searchButton.searching:hover {
    background-color: rgba(31, 120, 209, 0.2);
  }

  .loader {
    width: 32px;
    height: 32px;
    animation: lds-dual-ring 0.8s linear infinite;
    margin: 0 auto;
  }

  @keyframes lds-dual-ring {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .empty {
    color: #4d555e;
    text-align: center;
    border-top: 1px solid #dfe3e8;
    padding: 30px 0;
  }
  .empty h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin: 0 0 6px;
  }

  .empty p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .searchFooter {
    padding-bottom: 15px;
  }

  .reselect {
    border-top: 1px solid #dfe3e8;
    padding: 24px 0 8px;
    color: #1f78d1;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .reselect i {
    width: 16px;
    height: 16px;
    display: flex;
    margin: 0 8px 0 0;
  }

  @media (min-width: 768px) {
    .container {
      padding: 32px 32px 2px 32px;
    }

    .field {
      max-width: 540px;
    }

    .disabledField {
      height: 40px;
    }

    .passengerContainer {
      display: flex;
      flex-direction: column;
    }

    .passengerName {
      line-height: 16px;
    }

    .locationFields {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 705px;
    }

    .locationFields .field {
      min-width: 250px;
      max-width: 250px;
    }

    .locationFields .field.location {
      min-width: 410px;
      max-width: 410px;
      flex-grow: 1;
    }

    .locationFields .field:nth-child(odd) {
      margin: 0 10px 0 0;
    }

    .disabledField span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .field input {
      height: 40px;
      line-height: 40px;
    }

    .promoContainer {
      max-width: 282px;
    }

    .searchFooter {
      display: flex;
      justify-content: flex-end;
      padding: 15px 0 30px;
    }

    .searchFooter button {
      width: 144px;
      height: 40px;
      font-size: 16px;
      font-weight: 500;
    }

    .loader {
      width: 24px;
      height: 24px;
    }

    .close {
      cursor: pointer;
    }

    .results {
      margin: 0 0 32px;
    }

    .empty {
      text-align: left;
    }
    .empty h2 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
    }
    .empty p {
      font-size: 16px;
      line-height: 24px;
    }

    .reselect {
      padding: 32px 0;
    }

    .extrasFields {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .extrasFields .field {
      width: calc(33.3333333333% - 9.33333333px);
      margin-bottom: 24px;
    }
  }
</style>

{#if !removed}
  <div class="container">
    <div class="header">
      <div class="SectorIcon">
        {#if flightType === 'departure'}
          <PlaneDepart />
        {:else}
          <PlaneArrive />
        {/if}
      </div>
      <span class="details">{sectorDetails()}</span>
      <div class="close" on:click={handleRemove}>
        <Close />
      </div>
    </div>

    {#if filterByIndex === null}
      <div class="locationFields">

        <div class="location field">
          <label for="location_{flightType === 'departure' ? id : ''}">
            Pick-up Location
          </label>

          {#if flightType === 'departure'}
            <Select
              listAutoWidth={false}
              placeholder=""
              bind:value={searchObj.pickUp}
              inputAttributes={locationAttributes}
              loadOptions={locationLoadOptions}
              getOptionLabel={getLocationLabel}
              getSelectionLabel={getLocationLabel}
              optionIdentifier="Location"
              isClearable={true} />
          {:else}
            <div class="disabledField">
              <span>{searchObj.pickUp ? searchObj.pickUp.Location : ''}</span>
            </div>
          {/if}
        </div>

        <div class="field">
          <label>Date</label>
          <div class="disabledField">
            <div class="calendar">
              <Calendar />
            </div>
            <span>{date}</span>
          </div>
        </div>

        {#if flightType === 'departure'}
          <div class="field location">
            <label>Drop-off Location</label>
            <div class="disabledField">
              <span>{searchObj.dropOff ? searchObj.dropOff.Location : ''}</span>
            </div>
          </div>
        {/if}

        <div class="field">
          <label>
            Flight {flightType === 'departure' ? 'Departure' : 'Arrival'} Time
          </label>
          <div class="disabledField">
            <span>{sectorTime()}</span>
          </div>
        </div>

        {#if flightType === 'arrival'}
          <div class="location field">
            <label for="location_{id}">Drop-off Location</label>

            <Select
              listAutoWidth={false}
              placeholder=""
              bind:value={searchObj.dropOff}
              inputAttributes={locationAttributes}
              loadOptions={locationLoadOptions}
              getOptionLabel={getLocationLabel}
              getSelectionLabel={getLocationLabel}
              optionIdentifier="Location"
              isClearable={true} />
          </div>
        {/if}

      </div>

      <div class="passengers">
        <div class="passengersTitle">Passengers</div>

        {#each activePassengers as pax, i}
          <div class="passenger">
            <CheckBox
              disabled={pax.primary}
              checked={pax.active}
              on:toggled={() => handleToggleActivePax(i)} />
            <div class="passengerContainer">
              <span
                class:disable={pax.primary}
                class="passengerName"
                on:click={() => handleToggleActivePax(i)}>
                {pax.name}
              </span>
              {#if pax.primary}
                <div class="primary">Primary Contact</div>
              {:else}
                <div class="assign" on:click={() => handleAssign(i)}>
                  Assign as Primary Contact
                </div>
              {/if}
            </div>
          </div>
        {/each}
      </div>

      <div class="field">
        <label for="note_{id}">Note to Driver</label>
        <textarea id="note_{id}" bind:value={noteToDriver} maxlength="500" />
      </div>

      <div class="field promoContainer">
        <label for="promo_{id}">Promo Code</label>
        <input
          type="text"
          id="promo_{id}"
          bind:value={promoCode}
          maxlength="22" />
      </div>

      <div class="extras">
        <div class="extraHeader" on:click={handleExtrasToggle}>
          <div class="extrasTitle">Optional Extras</div>
          <div class="downChevron" class:active={showExtras}>
            <DownChevron />
          </div>
        </div>

        {#if showExtras}
          <div class="extrasContent">
            <p class="extrasIntro">
              Fare includes 2 standard suitcases (max. 23kg) per person with a
              maximum capacity of 15 suitcases per transfer.
            </p>

            <div class="extrasFields">
              <div class="pickUpLocation field">
                <label for="bag_{id}">Additional Bags (23kg)</label>
                <Select
                  placeholder=""
                  bind:value={bagValue}
                  inputAttributes={bagAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>

              <div class="pickUpLocation field">
                <label for="bike_{id}">Bike</label>
                <Select
                  placeholder=""
                  bind:value={bikeValue}
                  inputAttributes={bikeAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>

              <div class="pickUpLocation field">
                <label for="golf_{id}">Golf Clubs</label>
                <Select
                  placeholder=""
                  bind:value={golfValue}
                  inputAttributes={golfAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>

              <div class="pickUpLocation field">
                <label for="snow_{id}">Ski's/Snowboard</label>
                <Select
                  placeholder=""
                  bind:value={snowValue}
                  inputAttributes={snowAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>

              <div class="pickUpLocation field">
                <label for="surf_{id}">Surfboard</label>
                <Select
                  placeholder=""
                  bind:value={surfValue}
                  inputAttributes={surfAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>

              <div class="pickUpLocation field">
                <label for="other_{id}">Other oversized</label>
                <Select
                  placeholder=""
                  bind:value={otherValue}
                  inputAttributes={otherAttributes}
                  items={extrasItems}
                  isSearchable={false}
                  isClearable={false}
                  showChevron={true} />
              </div>
            </div>
          </div>
        {/if}
      </div>
      <div class="searchFooter" bind:this={resultsContainer}>
        <button
          on:click={handleSearch}
          class="searchButton"
          class:searching={isSearching}
          class:disabled={!canSearch}>
          {#if isSearching}
            <div class="loader">
              <Loader />
            </div>
          {:else}Find Transfers{/if}
        </button>
      </div>
    {/if}

    {#if showSearchResults}
      <div class="results">
        {#each searchResultsFiltered as result, i (result.RetailFare)}
          <SearchResult
            data={result}
            index={i}
            on:selectTimeChange={handleTimeChange}
            on:select={handleSelection}
            bind:selected={result.selected} />
        {:else}
          <div class="empty">
            <h2>No transfers found</h2>
            <p>
              We could not find any transfers for the above details. Try editing
              details like the location(s) or time and search again.
            </p>
          </div>
        {/each}
      </div>
    {/if}

    {#if filterByIndex !== null}
      <div class="reselect" on:click={handleReselect}>
        <i>
          <Pencil />
        </i>
        <span>Edit and Reselect Transfer</span>
      </div>
    {/if}
  </div>
{/if}

{#if showModal}
  <Modal {...modalData} on:cancel={handleCancel} on:confirm={handleConfirm} />
{/if}
