<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    import TaxiIcon from './_TaxiIcon.svelte';
    import Button, { options as buttonsOptions } from '@ctm/ui/Button';
    import { ExternalLink } from '@ctm/ui/Icons';
    import { taxiTravellerPnr } from '../../store';

    export let noPadding = false;
    export let code = 'C1';
    export let searchMode = false;

    const codes = {
        C1: 'White Standard Sedan',
        M1: 'Black Luxury Mercedes',
        V1: 'White Standard Van (6 Seater)',
        E1: 'Switch Electric Cabs',
    };

    function handleViewBooking() {
        dispatch('viewBooking', $taxiTravellerPnr);
    }
</script>

<div class="header" class:no-padding={noPadding} class:confirm={!searchMode}>
    <div class="header-text"><TaxiIcon /> Corporate Cabs {#if searchMode} - {codes[code]} {/if}</div>

    {#if !searchMode}
        <Button
            on:click={handleViewBooking}
            type={buttonsOptions.type.PRIMARY}
            size={buttonsOptions.size.COMPACT}
            Icon={ExternalLink}
            iconPosition={buttonsOptions.iconPosition.LEFT}>
            View booking
        </Button>
    {/if}
</div>

<style>
    .header {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 24px;
    }

    .header-text {
        display: flex;
        align-items: center;
        gap: 18px;
    }

    .no-padding {
        margin: 0;
    }

    .confirm {
        justify-content: space-between;
    }
</style>
