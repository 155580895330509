import axios from 'axios';
import {
  CONFIG,
} from '../store/index.js';
import { getHeaders } from './headers';


export async function getSettings() {
  const url = `${CONFIG.API_URL}api/Setting`;

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true)
  };

  return axios(options)
}
