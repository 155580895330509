<script>
  import { onDestroy } from 'svelte';

  import classnames from 'classnames';
  import Button from '@ctm/ui/Button';
  import Icon from '../Icon/Icon.svelte';
  import {modal as modalService} from '@ctm/ui/Modal';
  import { Edit } from '@ctm/ui/Icons';

  import ReallocateModal from './_ReallocateModal.svelte';

  export let items;
  export let passengerItinerary;

  let modalActive;

  function onReallocate() {
    const params = {
      passengerItinerary
    };

    const modal = modalService.open(ReallocateModal, {
      params
    });

    modalActive = true;

    modal
      .then((res) => {
        passengerItinerary = res.data;
        modalActive = false;
      })
      .catch(() => {});
  }

  function itemClassNames(status, finalStep) {
    return classnames({
      'progressList_item-isActive': status === 'active',
      'progressList_item-isPending': status === 'pending',
      'progressList_item-isComplete': status === 'complete',
      'progressList_item-isSuccess': status === 'success',
      'progressList_item-isFailed': status === 'failed',
      'progressList_item-isExpired': status === 'expired',
      'bookingApproved': finalStep
    });
  }

  onDestroy(() => {
    if (modalActive) {
      modalService.remove();
    }
  });
</script>

<div class="progressList">
  { #each items as {iconType, status, title, desc, finalStep, CanReallocate} }
  <div class="progressList_item { itemClassNames(status, finalStep) }">
    <span class="progressList_item_indicator">
      {#if iconType }
      <Icon type="{ iconType }"></Icon>
      {/if}
      {#if status === 'success' }
      <Icon type="check"></Icon>
      {/if}
      {#if status === 'failed' ||  status === 'expired'}
      <div class="deniedIcon">
        <Icon type="denied"></Icon>
      </div>
      { /if }
    </span>

    <strong class="progressList_item_title">
      { title }
    </strong>

    { #if desc }
    <span class="progressList_item_desc">
       { @html desc }
    </span>
    { /if }

    {#if CanReallocate && (status === 'pending' || status === 'active') && !finalStep}
    <div class="editButton" on:click="{onReallocate}">
      <Button type="subtle" size="compact" Icon={Edit} iconPosition="left">Edit</Button>
    </div>
    {/if}

  </div>
  { /each }
</div>

<style>
  .progressList {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .progressList_item {
    padding: 0 0 0 42px;
    position: relative;
  }

  .progressList_item:not(:first-of-type) {
    margin-top: 51px;
  }

  .progressList_item:after {
    border-left: 1px solid #a6aeb6;
    content: " ";
    position: absolute;
    top: 0;
    bottom: -52px;
    left: 10px;
    width: 1px;
    z-index: 1;
  }

  .progressList_item-isActive:after,
  .progressList_item-isPending:after,
  .progressList_item-isFailed:after,
  .progressList_item-isExpired:after {
    border-left: 1px dashed #a6aeb6;
  }

  .progressList_item:last-of-type:after {
    display: none;
  }

  .progressList_item_indicator {
    background: #fff;
    border: 2px solid #adb4bb;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    padding: 2px;
    z-index: 2;
  }

  .progressList_item-isComplete .progressList_item_indicator {
    background: #2c3e50;
    border-color: #2c3e50;
  }

  .progressList_item-isSuccess .progressList_item_indicator {
    background: #51ce6c;
    border-color: #51ce6c;
  }

  .progressList_item-isPending .progressList_item_indicator {
    border-color: #adb4bb;
  }

  .progressList_item-isActive .progressList_item_indicator {
    border-color: #ff9500;
  }

  .progressList_item-isFailed .progressList_item_indicator {
    background: #FF3A5F;
    border-color: #FF3A5F;
  }

  .progressList_item-isExpired .progressList_item_indicator {
    background: #2C3E50;
    border-color: #2C3E50;
  }

  .progressList_item_title {
    color: #2c3e50;
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }

  .progressList_item-isActive .progressList_item_title {
    color: #ff9500;
  }

  .progressList_item-isFailed .progressList_item_title {
    color: #FF3A5F;
  }

  .progressList_item-isSuccess.bookingApproved .progressList_item_title {
    color: #51ce6c;
  }

  .progressList_item-isPending .progressList_item_title {
    opacity: 0.35;
  }

  .progressList_item_desc {
    color: #8d97a0;
    font-size: 12px;
    display: block;
  }

  .progressList_item-isPending .progressList_item_desc {
    opacity: 0.35;
  }

  .deniedIcon {
      width: 9px;
      position: relative;
  }

  .editButton {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (min-width: 768px) {
    .editButton {
      margin-top: 10px;
      position: relative;
    }
  }

  @media (min-width: 1550px) {
    .editButton {
      margin-top: 0;
      position: absolute;
    }
  }

</style>
