<script>
    import { carsForChange, flightsForChange, hotelsForChange } from '../../store';
    import Step from './_Step.svelte';

    export let sector = 'flights';
    export let confirm = false;

    $: flights = !!$flightsForChange.length;
    $: hotels = !!$hotelsForChange.length;
    $: cars = !!$carsForChange.length;
</script>

<div class="container">
    {#if flights}
        <Step name="Flights" active={sector === 'flights'} complete={confirm || sector != 'flights'} />
    {/if}

    {#if hotels}
        <Step name="Hotels" active={sector === 'hotels'} complete={confirm || sector === 'cars'} />
    {/if}

    {#if cars}
        <Step name="Cars" active={sector === 'cars'} complete={confirm} />
    {/if}

    <Step name="Confirm and payment" active={confirm} final />
</div>

<style>
    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 115px;
        min-width: 200px;
    }
</style>
