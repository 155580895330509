<script>
  import BookingStatusLabel from '../BookingStatusLabel/BookingStatusLabel.svelte';

  export let status;
</script>

<div class="sectorStatusRow">
  <div class="sectorStatusRow_current">
    <div class="sectorStatusRow_icon">
      <slot name="icon"></slot>
    </div>

    <div class="sectorStatusRow_text">
      <div class="sectorStatusRow_title">
        <slot name="title"></slot>
      </div>
      <div class="sectorStatusRow_desc">
        <slot name="desc"></slot>
      </div>
    </div>

    <div class="sectorStatusRow_statusLabel">
      <BookingStatusLabel { status }></BookingStatusLabel>
    </div>
  </div>

  <slot></slot>
</div>

<style>
  .sectorStatusRow {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #f3f6fc;
    padding: 14px;
  }

  .sectorStatusRow_current {
    display: flex;
    align-items: center;
  }

  .sectorStatusRow_icon {
    color: #2C3E50;
    width: 28px;
    height: 28px;
  }

  .sectorStatusRow_icon :global(img) {
    width: 100%;
  }

  .sectorStatusRow_text {
    color: #2C3E50;
    flex-grow: 1;
    padding-left: 10px;
  }

  .sectorStatusRow_title {
    font-size: 15px;
    font-weight: 600;
  }

  .sectorStatusRow_desc {
    font-size: 12px;
  }
</style>
