<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";

  const dispatch = createEventDispatcher();

  import axios from "axios";
  import dayjs from "dayjs";
  import Pagination from "@ctm/ui/Pagination";
  import xss from 'xss'

  import FlexSpinner from "../FlexSpinner/FlexSpinner.svelte";
  import LiveSummary from "../ItinerarySummaryCard/LiveSummary.svelte";
  import FilterDropdown from "./_FilterDropdown.svelte";
  import FilterTabs from "./_FilterTabs.svelte";
  import NoResults from "./_NoResults.svelte";
  import Search from "./_Search.svelte";
  import { searchItineraries } from "../../http/itinerary";

  import {
    bookings,
    bookingsIsWaiting,
    bookingsActiveFilter,
    bookingsCurrentPage,
    bookingsPageSize,
    bookingsActiveSearchText,
    bookingsActiveDateFrom,
    bookingsActiveDateTo,
    bookingsSearchQuery,
    bookingsSearchDateFrom,
    bookingsSearchDateTo,
    bookingsTotalItemsCount,
    dateFormat,
    manageBookingsItinerary,
    userDebtor,
    UserIsConsultant,
    track,
    trackingEvents,
  } from "../../store/index.js";

  let source;

  let dateFromFormatted;
  $: {
    dateFromFormatted = dayjs($bookingsActiveDateFrom).format(
      $dateFormat.DATE_EXTENDED_NO_COMMA
    );
  }

  let dateToFormatted;
  $: {
    dateToFormatted = dayjs($bookingsActiveDateTo).format(
      $dateFormat.DATE_EXTENDED_NO_COMMA
    );
  }

  function doSearch() {
    if (source && source.cancel) {
      source.cancel();
    }

    $bookingsIsWaiting = true;

    const params = {
      query: $bookingsActiveSearchText,
      dateFrom: $bookingsActiveDateFrom,
      dateTo: $bookingsActiveDateTo,
      skip: ($bookingsCurrentPage - 1) * $bookingsPageSize,
      top: $bookingsPageSize,
    };

    if ($bookingsActiveFilter !== "All") {
      params.approvalStatus = $bookingsActiveFilter;
    }

    if (params.dateFrom) {
      params.dateFrom = dayjs(params.dateFrom).format("YYYY-MM-DDZ");
    } else {
      delete params.dateFrom;
    }

    if (params.dateTo) {
      params.dateTo = dayjs(params.dateTo).format("YYYY-MM-DDZ");
    } else {
      delete params.dateTo;
    }

    const CancelToken = axios.CancelToken;
    source = CancelToken.source();

    if (!$userDebtor && $UserIsConsultant) {
      $bookingsIsWaiting = false;
      return;
    }

    searchItineraries(params, source.token)
      .then((response) => {
        const { data } = response;
        $bookings = data.Results;
        $bookingsTotalItemsCount = data.Count;
        $bookingsIsWaiting = false;
      })
      .catch((err) => {});
  }

  function onItinerarySelect(event) {
    const selectedItinerary = event.detail;
    dispatch("goToBooking", selectedItinerary);
    track(trackingEvents.BOOKING_SELECTED, selectedItinerary);
  }

  function onPageChange(event) {
    track(trackingEvents.BOOKING_RESULTS_PAGE_SELECTED, event.detail.current);
  }

  function onSearch() {
    showResults = true;

    $bookingsActiveSearchText = $bookingsSearchQuery;
    $bookingsActiveDateFrom = $bookingsSearchDateFrom;
    $bookingsActiveDateTo = $bookingsSearchDateTo;
    $bookingsCurrentPage = 1;
    $bookings = [];

    doSearch();
  }

  function handleFilterChange() {
    $bookingsCurrentPage = 1;
    $bookings = [];
    $bookingsSearchQuery = $bookingsActiveSearchText;
    $bookingsSearchDateFrom = $bookingsActiveDateFrom;
    $bookingsSearchDateTo = $bookingsActiveDateTo;

    doSearch();
  }

  let mounted = false;
  let showResults = false;

  onMount(() => {
    document.body.style.overflowY = "scroll";
    $manageBookingsItinerary = undefined;
    mounted = true;
  });

  const bookingsActiveFilterUnsubscribe = bookingsActiveFilter.subscribe(
    (value) => {
      if (mounted) {
        handleFilterChange();
      }
    }
  );

  const bookingsCurrentPageUnsubscribe = bookingsCurrentPage.subscribe(
    (value) => {
      if (mounted) {
        doSearch();
      }
    }
  );

  onDestroy(() => {
    bookingsActiveFilterUnsubscribe();
    bookingsCurrentPageUnsubscribe();
    document.body.style.overflowY = "auto";
    if (!source) return;
    source.cancel();
  });
</script>

<style>
  .myBookings {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding: 64px 0 0 0;
  }

  .myBookings_aside {
    padding: 32px 16px;
  }

  .myBookings_main {
    background: #f3f6fc;
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .results {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
  }

  .filters {
    display: flex;
    margin-bottom: 32px;
  }

  .filterDropdown {
    width: 100%;
  }

  .filterTabs {
    display: none;
  }

  .pagination {
    padding: 0 0 20px;
  }

  .summaryCard:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .search_description {
    color: #2c3e50;
    font-size: 19px;
    letter-spacing: -0.21px;
    line-height: 23px;
    text-align: left;
    margin: 0 0 49px;
  }

  :global(.sct-awaiting-validation .myBookings_aside .search) {
    padding-top: 50px;
  }

  @media (min-width: 768px) {
    .myBookings {
      flex-direction: row;
    }

    .myBookings_aside {
      width: 30%;
      min-width: 320px;
      max-width: 467px;
    }

    .myBookings_main {
      padding-left: 32px;
      padding-right: 32px;
    }

    .search {
      position: fixed;
      left: 0;
      top: 64px;
      padding: 32px 16px;
      width: 30%;
      min-width: 320px;
      max-width: 467px;
    }

    .filters {
      display: flex;
      justify-content: flex-end;
    }

    .filterDropdown {
      width: 244px;
    }

    .results {
      max-width: 1112px;
    }
  }

  @media (min-width: 1025px) {
    .myBookings_main {
      padding-top: 56px;
      padding-bottom: 56px;
    }

    .filters {
      justify-content: flex-start;
    }

    .filterDropdown {
      display: none;
    }

    .filterTabs {
      display: block;
    }

    .results {
      position: relative;
    }

    :global(.sct-awaiting-validation .myBookings_main) {
      padding-top: 106px;
    }
  }

  @media (min-width: 1585px) {
    .pagination.top {
      position: absolute;
      top: 69px;
      right: 0;
    }

    .pagination.bottom {
      margin-top: 20px;
    }
  }
</style>

<div class="myBookings">
  <div class="myBookings_aside">
    <div class="search">
      <Search on:search={onSearch} />
    </div>
  </div>

  <div class="myBookings_main">
    <div class="results">

      {#if showResults}
        {#if !$UserIsConsultant || $userDebtor}
          <p class="search_description">
            Showing results for
            <strong>
              {#if $bookingsActiveSearchText.length > 0}
                {@html xss($bookingsActiveSearchText)},
              {/if}
              {dateFromFormatted} - {dateToFormatted}
            </strong>
          </p>

          <div class="filters">
            <div class="filterTabs">
              <FilterTabs />
            </div>
            <div class="filterDropdown">
              <FilterDropdown />
            </div>
          </div>
        {/if}

        {#if $bookings.length > 0}
          <div class="pagination top">
            <Pagination
              on:change={onPageChange}
              bind:current={$bookingsCurrentPage}
              bind:pageSize={$bookingsPageSize}
              bind:total={$bookingsTotalItemsCount} />
          </div>
        {/if}

        {#if $bookingsIsWaiting}
          <FlexSpinner />
        {:else}
          {#each $bookings as itinerary}
            <div class="summaryCard">
              <LiveSummary {itinerary} on:select={onItinerarySelect} />
            </div>
          {:else}
            <NoResults />
          {/each}

          {#if $bookings.length > 0}
            <div class="pagination bottom">
              <Pagination
                bind:current={$bookingsCurrentPage}
                bind:pageSize={$bookingsPageSize}
                bind:total={$bookingsTotalItemsCount} />
            </div>
          {/if}
        {/if}
      {/if}
    </div>
  </div>
</div>
