<script>
  import Dropdown from '@ctm/ui/Dropdown';
  import Icon from '../Icon/Icon.svelte';

  import {
    bookingsActiveFilter,
  } from '../../store/index.js';

  let Items = [
    {
      value: 'All',
      label: `All bookings`
    },
    {
      value: 'Pending',
      label: `Awaiting approvals`
    },
    {
      value: 'Approved',
      label: `Approved`
    },
    {
      value: 'Declined',
      label: `Denied`
    },
    {
      value: 'Expired',
      label: `Expired`
    }
  ];
  let dropdownIsActive = false;

  let SelectedItem;
  $: {
    SelectedItem = Items.find((item) => {
      return item.value === $bookingsActiveFilter;
    });
  }

  function onSelect(selected) {
    $bookingsActiveFilter = selected.value
  }
</script>

<Dropdown
  items="{ Items }"
  bind:isActive="{dropdownIsActive}"
  on:select="{onSelect}"
  selectedItem="{ SelectedItem }"
  isBlock
  width="100%"
>
  <div class="toggle { dropdownIsActive ? 'toggle-active' : '' }">
    {#if SelectedItem}
    { SelectedItem.label }
    { :else }
    Filter by approval state
    {/if}

    {#if dropdownIsActive}
    <div class="toggle_icon toggle_icon-up">
      <Icon type="up"></Icon>
    </div>
    { :else }
    <div class="toggle_icon toggle_icon-down">
      <Icon type="down"></Icon>
    </div>
    {/if}
  </div>
</Dropdown>

<style>
  .toggle {
    background: #fff;
    border: 1px solid #d8dbdf;
    border-radius: 3px;
    color: #3f4f5f;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    padding: 14px 52px 14px 16px;
    width: 100%;
    white-space: nowrap;
    position: relative;
    transition: background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38);
  }

  .toggle:hover {
    border-color: #b2b8bf;
  }

  .toggle-active {
    border-color: #006fe8;
  }

  .toggle_icon {
    color: #293e4f;
    position: absolute;
    top: 12px;
    right: 14px;
    width: 20px;
    height: 20px;
  }

  .toggle_icon-up {
    top: 11px;
  }
</style>
