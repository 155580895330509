<script>
    import { createEventDispatcher, onMount} from "svelte";
    const dispatch = createEventDispatcher();

    import AddIcon from './../TaxisConfirmation/AddIcon.svelte';
    import { getItineraryById } from '../../http/itinerary';
    import { taxiSelected, taxiTravellerPnr } from '../../store';
    import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
    import ConfirmDetails from '../Taxis/_ConfirmDetails.svelte';

    export let itineraryId;
    export let pnr;
    export let taxiId;

    let taxiBooking = null;
    $taxiTravellerPnr = pnr;

    onMount(async () => {
        $taxiSelected = null;
        const { data } = await getItineraryById(itineraryId);
        let pax = data.Passengers.find((pax) => pax.Pnr === pnr);
        taxiBooking = pax.Taxis.find((taxi) => taxi.Identifier === taxiId);

        details = {
            PickUpTime: taxiBooking.PickUpTime,
            Origin: {
                Address: taxiBooking.PickupAddress.FullAddress,
                Title: taxiBooking.PickupAddress.Title,
            },
            Destination: {
                Address: taxiBooking.DropOffAddress.FullAddress,
                Title: taxiBooking.DropOffAddress.Title,
            },
            TotalFare: taxiBooking.TaxiSegmentInvoices[0].TotalAmount,
            Code: taxiBooking.VehicleType,
            ConfirmationNumber: taxiBooking.SupplierIdentifier
        };
    });

    let details = null;
</script>

<div class="taxi-confirmation">
    {#if taxiBooking}
        <div class="taxi-confirmation-header">
            <h1>Your taxi has been booked</h1>

            <p>
                Your booking has been sent to the service provider and no
                further action is required. The provider will monitor any
                changes to flight schedules if your pick-up time was
                automatically generated based on a flight departure/arrival
                time.
            </p>
        </div>

        <div class="taxi-confirmation-content">
            <div class="details">
                <ConfirmDetails {details} on:viewBooking on:bookAnother />
            </div>

            <div class="add-another">
              <button on:click={() => dispatch('bookAnother')}><AddIcon /> Book another transfer</button>
          </div>
        </div>
    {:else}
        <FlexSpinner />
    {/if}
</div>

<style>
    .taxi-confirmation {
        background: #fff;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        padding: 64px 0 0;
        min-height: 100vh;
    }

    .taxi-confirmation-header {
        padding: 24px 16px;
    }

    .taxi-confirmation-header h1 {
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        margin: 0;
        color: #141b22;
    }

    .taxi-confirmation-content {
        background: #fff;
        padding: 16px;
        flex-grow: 1;
    }

    .add-another {
        display: flex;
        justify-content: center;
    }

    .add-another button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        border: none;
        width: 245px;
        height: 48px;
        color: #fff;
        background: #156af4;
        border-radius: 4px;
    }

    @media (min-width: 928px) {
        .taxi-confirmation-header {
            align-self: center;
            width: 928px;
        }

        .taxi-confirmation-header h1 {
            font-size: 36px;
            line-height: 54px;
            align-items: center;
        }

        .taxi-confirmation-header p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .taxi-confirmation-content {
            display: flex;
            padding-top: 48px;
            background: #f3f6fc;
            flex-direction: column;
            align-items: center;
        }

        .details {
            width: 932px;
            margin-bottom: 24px;
        }
    }
</style>
