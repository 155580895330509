<script>
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();

  import Select from 'svelte-select/src/Select.svelte';
  import { getSettings } from "../../http/setting";
  import { searchDebtors } from "../../http/debtorSearch";

  import {
    bookings,
    bookingsCurrentPage,
    bookingsIsWaiting,
    resetBookingSearch,
    userDebtor
  } from '../../store/index.js';

  export let isDisabled = false;

  let init = false;
  let items = [];
  let loadOptions = searchDebtors;
  let optionIdentifier = "Name";
  let getOptionLabel = option => `${option.Title} (${option.Name})`;
  let getSelectionLabel = option => `${option.Title} (${option.Name})`;
  let noOptionsMessage = "No Debtors found";

  function onRemove() {
    dispatch("select", {
      settings: null,
      debtor: null
    });

    $userDebtor = null;
    items = [];
    $bookings = [];
    $bookingsCurrentPage = 1;
    resetBookingSearch();
  }

  function onSelect() {
    handleSettings();
  }

  function handleSettings() {
    $bookings = [];
    $bookingsIsWaiting = true;

    resetBookingSearch();

    getSettings().then(response => {
      $bookingsIsWaiting = false;
      
      dispatch("select", {
        settings: response.data,
        debtor: $userDebtor
      });
    });
  }

  onMount(() => {
    init = true;
  });
</script>

{ #if init }
<Select on:clear="{onRemove}" on:select="{onSelect}" bind:value={$userDebtor} placeholder="Debtor name or number" {items}
  {loadOptions} {optionIdentifier} {getOptionLabel} {getSelectionLabel} {isDisabled} {noOptionsMessage}>
</Select>
{ /if }
