<script>
  import { onMount, createEventDispatcher, tick } from "svelte";
  import dayjs from "dayjs";
  import Select from 'svelte-select/src/Select.svelte';
  import Shuttle from "./Icons/Shuttle.svelte";
  import shutteImage from "./images/shuttle.jpg";
  import shuttePrimaryImage from "./images/shuttle-premium.jpg";
  import CircleTick from "./Icons/CircleTick.svelte";

  const dispatch = createEventDispatcher();

  export let data = null;
  export let index;
  export let selected = false;

  let container;
  let showDetails = true;

  let getLabel = (option) =>
    dayjs(option.PickupTime, "YYYY-MM-DDTHH:mm").format("h:mm A");

  let selectedValueTime;

  onMount(() => {
    selectedValueTime = data.AlternativePickupOptions[0];
  });

  async function handleSelect() {
    selected = true;
    dispatch("select", { index, selectedValueTime });
    await tick();
    container.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  let title;
  $: {
    if (
      data.FareType === "Shared Ride" &&
      data.ServiceLevel === "Supershuttle"
    ) {
      title = "Shared Ride - Super Shuttle";
    }

    if (data.FareType === "Charter" && data.ServiceLevel === "Supershuttle") {
      title = "Exclusive Use - Super Shuttle";
    }

    if (data.FareType === "Charter" && data.ServiceLevel === "Platinum") {
      title = "Exclusive Use - Platinum";
    }
  }

  function handlePickUpTimeSelect() {
    if (selected) {
      dispatch("selectTimeChange", { index, selectedValueTime });
    }
  }
</script>

<style>
  .container {
    border-top: 1px solid #dfe3e8;
    padding: 32px 0 0 0;
  }

  .header {
    color: #4d555e;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
  }

  .header i {
    margin: 0 8px 0 0;
    display: flex;
  }

  .toggle {
    display: flex;
    margin: 0 0 32px;
  }

  .option {
    height: 37px;
    border-radius: 4px;
    color: #4d555e;
    font-size: 16px;
    line-height: 37px;
    width: 50%;
    text-align: center;
    margin: 0 6px 0 0;
    cursor: pointer;
  }

  .toggle .option:last-child {
    margin: 0 0 0 6px;
  }

  .option.active {
    background-color: #cbe2f5;
  }

  .field {
    display: flex;
    flex-direction: column;
    margin: 0 0 16px;
  }

  .field label {
    color: #79838d;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 4px;
  }

  .pickUpDetails .details {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .shuttleInfo {
    margin: 0 0 32px;
  }

  .shuttleImage {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 0 0 16px;
  }

  .shuttleInfo .description {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
  }

  .footer {
    padding: 24px 0 0 0;
    border-top: 3px solid #dfe3e8;
  }

  .price {
    display: flex;
    justify-content: center;
    margin: 0 0 26px;
  }

  .currency {
    color: #212b36;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
  }

  .amount {
    color: #212b36;
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
  }

  .select {
    height: 56px;
    width: 100%;
    border: 1px solid #1f78d1;
    border-radius: 4px;
    background-color: #ffffff;
    color: #1f78d1;
    font-size: 16px;
    font-weight: 500;
    line-height: 56px;
    margin: 0 0 31px;
    outline: none;
    padding: 0;
  }

  .selected {
    color: #fff;
    height: 32px;
    width: 157px;
    border-radius: 20px;
    background-color: #1f78d1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 8px;
    margin: 0 auto 24px auto;
  }

  .selected i {
    display: flex;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }

  .selected span {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .pickUpTime {
    --disabledBackground: #f9fafb;
    --disabledBorderColor: #dfe3e8;
  }

  :global(.selectContainer.disabled .selection) {
    color: #a2a9b2 !important;
  }

  @media (min-width: 768px) {
    .option {
      width: 150px;
    }

    .pickUpDetails {
      display: flex;
      flex-grow: 1;
    }

    .pickUpTime {
      width: 240px;
      padding: 0 32px 0 0;
    }

    .detailsAndFooter {
      display: flex;
    }

    .footer {
      border: none;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      max-width: 157px;
      min-width: 157px;
      width: 157px;
    }

    .select {
      height: 40px;
      line-height: 40px;
    }

    .otherFields {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .otherFields .field {
      margin: 0 32px 16px 0;
    }

    .shuttleInfo {
      display: flex;
    }

    .shuttleImage {
      min-width: 313px;
      max-width: 313px;
      width: 313px;
      height: 159px;
      margin: 0 32px 0 0;
    }

    .shuttleInfo .description {
      flex-grow: 1;
      margin: 0 32px 0 0;
    }
  }
</style>

<div class="container" bind:this={container}>
  <div class="header">
    <i>
      <Shuttle />
    </i>
    <span>{title}</span>
  </div>

  <div class="toggle">
    <div
      class="option"
      on:click={() => (showDetails = true)}
      class:active={showDetails}>
      Pick-up Details
    </div>
    <div
      class="option"
      on:click={() => (showDetails = false)}
      class:active={!showDetails}>
      Information
    </div>
  </div>

  <div class="detailsAndFooter">
    {#if showDetails}
      <div class="pickUpDetails">
        {#if selectedValueTime}
          <div class="col">
            <div class="field pickUpTime">
              <label>Pick-up Time</label>
              <Select
                on:select={handlePickUpTimeSelect}
                isDisabled={data.AlternativePickupOptions.length === 1}
                listAutoWidth={false}
                optionIdentifier="PickupTime"
                getOptionLabel={getLabel}
                getSelectionLabel={getLabel}
                bind:value={selectedValueTime}
                items={data.AlternativePickupOptions}
                isClearable={false}
                isSearchable={false}
                showChevron={true} />
            </div>

            <div class="field">
              <label>Estimated Arrival Time</label>
              <p class="details">
                {dayjs(selectedValueTime.ArriveBefore, 'YYYY-MM-DDTHH:mm').format('h:mm A')}
              </p>
            </div>
          </div>

          <div class="otherFields">

            <div class="field">
              <label>Pick-up Location</label>
              <p class="details">{data.PickupAddress.Address}</p>
            </div>

            <div class="field">
              <label>Drop-off Location</label>
              <p class="details">{data.DropoffAddress.Address}</p>
            </div>

          </div>
        {/if}
      </div>
    {:else}
      <div class="shuttleInfo">
        {#if data.ServiceLevel === 'Supershuttle'}
          <img src={shutteImage} alt="shuttle" class="shuttleImage" />
          <div class="description">
            <p>
              Super Shuttle shared ride is a door-to-door, on-demand (it goes
              when you want it to), fixed-price service that you share with
              other travellers. We use journey routing rules to ensure that you
              get to your destination in good time and our luggage trailer will
              carry all of your normal or out-sized luggage items. Super
              Shuttle’s shared ride service offers significant benefits by way
              of reduced CO<sub class="co2">2</sub> emissions per traveller journey and fixing your
              fare at a great price.
            </p>
            <ul>
              <li>Vehicle Type: 11-seater vans</li>
              <li>Maximum number of passengers: 11</li>
              <li>Maximum number of suitcases: 15</li>
            </ul>
          </div>
        {:else}
          <img src={shuttePrimaryImage} alt="shuttle" class="shuttleImage" />
          <div class="description">
            <p>
              Book all 11 passenger seats in the Super Shuttle vehicle for your
              group’s exclusive use. Your service also includes the (optional)
              use of our bespoke luggage trailer which will accommodate all of
              your outsized luggage items at no extra cost.
            </p>
            <ul>
              <li>Vehicle Type: 11-seater vans</li>
              <li>Maximum number of passengers: 11</li>
              <li>Maximum number of suitcases: 15</li>
            </ul>
          </div>
        {/if}
      </div>
    {/if}

    <div class="footer">
      <div class="price">
        <span class="currency">NZD</span>
        <span class="amount">{data.RetailFare.toFixed(2)}</span>
      </div>
      {#if !selected}
        <button class="select" on:click={handleSelect}>Select</button>
      {:else}
        <div class="selected">
          <i>
            <CircleTick />
          </i>
          <span>Ride Selected</span>
        </div>
      {/if}
    </div>
  </div>
</div>
