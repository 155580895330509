<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import {
        track,
        manageBookingsItinerary,
        changeBookingsPnr,
        flightsForChange,
        hotelsForChange,
        carsForChange,
        changeBookingsItinerary as itinerary,
        getItineraryId,
        getChangeBookingSession,
        saveChangeBookingSession,
    } from '../../store/index.js';
    import { setSearchChanges } from '../../util/itinerary';
    import { getItineraryById, newSearch, itinerarySearch } from '../../http/itinerary';
    import ProgressTracker from './ProgressTracker.svelte';
    import SectorSearchForm from '../ChangeBooking/_SectorSearchForm.svelte';
    import ActionBar from '../ActionBar/ActionBar.svelte';
    import Button from '@ctm/ui/Button';
    import { Back } from '@ctm/ui/Icons';

    const dispatch = createEventDispatcher();

    export let pnr;
    export let sector;

    let isWaiting = false;
    let searchData = {};

    $changeBookingsPnr = pnr;
    $: canSkip = computeCanSkip(sector);

    onMount(() => {
        let changeItineraryId = sessionStorage.getItem('change:itineraryId');
        let sessionData = getChangeBookingSession();

        if (!sessionData || (changeItineraryId && sector === 'flights')) {
            window.location.hash = `/manage-bookings/${$changeBookingsPnr}/change`;
            return;
        }

        if (sector === 'hotels' && sessionStorage.getItem('change:HotelIds')) {
          window.location.hash = `/manage-bookings/${$changeBookingsPnr}/change`;
          return;
        }
    });

    function handleSkip() {
      if (sector === 'flights') $flightsForChange = [];
      if (sector === 'hotels') $hotelsForChange = [];
      if (sector === 'cars') $carsForChange = [];
      saveChangeBookingSession();
    }

    function handleContinue(skip) {
        isWaiting = true;
        if (skip) handleSkip();
        
        if (sector === 'flights') {
            if (skip) {
                window.location.hash = `/manage-bookings/${pnr}/change/${ $hotelsForChange.length > 0 ? 'hotels' : 'cars' }`;
            } else {
                search({sectorType: 'flights', createItinerary: true});
            }
        } else if (sector === 'hotels') {
            if (skip) {
                if ($carsForChange.length > 0) window.location.hash = `/manage-bookings/${pnr}/change/cars`;
                else window.location.hash = `/manage-bookings/change/payment`;
            } else {
                sessionStorage.setItem(`change:itineraryId`, $itinerary.Identifier);
                search( { sectorType: 'hotels', createItinerary: $flightsForChange.length === 0 } );
            }
        } else {
            sessionStorage.setItem(`change:cars`, JSON.stringify(searchData));
            sessionStorage.setItem(`change:itineraryId`, $itinerary.Identifier);
            window.location.hash = `/manage-bookings/change/payment`;
        }
    }

    async function search({sectorType, createItinerary}) {

      if (sectorType === 'flights' && $hotelsForChange.length > 0) {
            sessionStorage.setItem(
                'returnToChangeBookingWizard',
                JSON.stringify({
                    sector: 'hotels',
                    pnr: $changeBookingsPnr,
                })
            );
        } else if (sectorType === 'hotels' && $carsForChange.length > 0) {
              sessionStorage.setItem(
                  'returnToChangeBookingWizard',
                  JSON.stringify({
                      sector: 'cars',
                      pnr: $changeBookingsPnr,
                  })
              );
        } else if ($carsForChange.length > 0) {
            sessionStorage.setItem(
                'returnToChangeBookingWizard',
                JSON.stringify({
                    sector: 'cars',
                    pnr: $changeBookingsPnr,
                })
            );
        } else {
          sessionStorage.removeItem('returnToChangeBookingWizard')
        }

        sessionStorage.setItem(`change:itineraryId`, $itinerary.Identifier);

        sessionStorage.setItem(
            'excludeChangePNRFromDuplicates',
            $changeBookingsPnr
        );

        isWaiting = true;

        const itineraryWithSearchChanges = setSearchChanges({ 
          itinerary: $itinerary,
          searchData,
          sectorType
        });


        if (createItinerary) {
          newSearch(itineraryWithSearchChanges)
            .then((response) => {
                const newItinerary = response.data;
                $manageBookingsItinerary = null;
                track('CHANGE_BOOKING_SEARCH', sectorType);
                dispatch('changeSuccess', newItinerary);
            })
            .finally(() => {
              isWaiting = false;
            });
        } else {
          const justSearchObject = itineraryWithSearchChanges.Search;
          justSearchObject.ItineraryIdentifier = getItineraryId('search');
          const newSearch = await itinerarySearch({data: justSearchObject, id: justSearchObject.ItineraryIdentifier});
          const newItinerary = await getItineraryById(newSearch.data.Identifier);
          dispatch('changeSuccess', newItinerary.data);
        }
    }

    function handleBack() {
        window.location.hash = `/manage-bookings/${pnr}/change`;
    }

    function computeCanSkip(sector) {
      if (sector === 'flights' && ($hotelsForChange.length || $carsForChange.length)) return true;
      if (sector === 'hotels' && $carsForChange.length) return true;
      return false;
    }
</script>

<ActionBar>
    <div class="actionBar_back">
        <Button on:click={handleBack} Icon={Back} type="link" />
    </div>
</ActionBar>

<div class="change-booking-wizard">
    <div class="tracker">
        <ProgressTracker {sector} />
    </div>

    <div class="content">
        {#if sector === 'flights'}
            <div class="content-header">
                <h2>Change Flights</h2>

                <p>
                    Airline and services fees will be calculated on selection,
                    please check the fare rules for a comprehensive breakdown on
                    any applicable fees. Additional baggage and paid seats may
                    be non-refundable or unavailable on the new flight.
                </p>
            </div>

            {#each $flightsForChange as sector}
                <SectorSearchForm
                    type="flight"
                    sector={sector.data}
                    bind:search={searchData[sector.data.Identifier]} />
            {/each}
        {/if}


        {#if sector === 'hotels'}
            <div class="content-header">
                <h2>Change Hotels</h2>

                <p>
                  List any hotel booking changes that you would like the consultant to make - including rooms/suites, dates, and special requests.
                </p>
            </div>
            {#each $hotelsForChange as sector}
                <SectorSearchForm
                    type="hotel"
                    sector={sector.data}
                    bind:search={searchData[sector.data.Identifier]} />
            {/each}
        {/if}

        {#if sector === 'cars'}
            <div class="content-header no-border">
                <h2>Change Cars</h2>
            </div>
            {#each $carsForChange as sector}
                <SectorSearchForm
                    type="car"
                    sector={sector.data}
                    bind:search={searchData[sector.data.Identifier]} />
            {/each}
        {/if}

        <div class="continue">
          <div class="left">
            <Button
                isOutlined
                type="primary"
                size="large"
                on:click={handleBack}>Back</Button>
          {#if canSkip }
            <Button
                isOutlined
                type="primary"
                size="large"
                on:click={() => handleContinue(true)}>Skip</Button>
          {/if}
        </div>
            <Button
                {isWaiting}
                type="primary"
                size="large"
                on:click={() => handleContinue(false)}>{ sector === 'flights' ? 'Search' : 'Continue' }</Button>
        </div>
    </div>
</div>

<style>
    .actionBar_back {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 16px;
        top: 15px;
    }

    .tracker {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
    }

    .content-header {
        border-bottom: 1px solid #ebecf0;
        margin-bottom: 24px;
    }

    .content-header.no-border {
      border: none;
    }

    .content-header h2 {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        margin: 0 0 8px;
    }

    .content-header p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 24px;
    }

    .continue {
        display: flex;
        justify-content: space-between;
    }

    .continue .left {
      display: flex;
      gap: 17px;
    }

    @media (min-width: 768px) {
        .change-booking-wizard {
            width: 690px;
            margin: 0 auto;
            position: relative;
            display: flex;
        }

        .tracker {
            margin: 0;
            padding: 56px 0 0 0;
            position: absolute;
            width: 214px;
            left: -214px;
            top: 115px;
        }

        .content {
            flex-grow: 1;
            margin-top: 128px;
            padding: 32px 0;
        }
    }
</style>
