<script>
  import Select from 'svelte-select/src/Select.svelte';
  import { tick, createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let value;
  export let items;
  export let isVirtualList;
  export let placeholder;
  export let hasError;

  async function handleSelect(event) {
    await tick();
    dispatch('select', event.detail);
  }

  async function handleClear(event) {
    await tick();
    dispatch('select', undefined);
  }
</script>

<Select on:select="{handleSelect}" on:clear="{handleClear}" value={value} {items} {isVirtualList} {placeholder}
  {hasError}></Select>

{#if hasError}
<p class="error">Required for this booking</p>
{/if}

<style>
.error {
    font-size: 12px;
    letter-spacing: -0.13px;
    line-height: 14px;
    color:  #FF2D55;
    margin: 4px 0;
    position: absolute;
}
</style>
