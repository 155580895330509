import axios from 'axios';

import {
  apiConfig,
} from '../store/index.js';

import { getHeaders } from './headers';

export function getItineraryById(id) {
  const { url, version } = apiConfig('itinerary', { itineraryId: id });

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}

export function searchItineraries(params, cancelToken) {
  const { url, version } = apiConfig('summary', null, null, false);

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    params,
    cancelToken,
    apiVersion: version
  };

  return axios(options);
}

export function newSearch(search) {
  const { url, version } = apiConfig('itinerary', null, null, false);
  const data = JSON.stringify(search);

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    data,
    apiVersion: version
  };

  return axios(options);
}

export function itinerarySearch({data, id}) {
  const { url, version } = apiConfig('itinerarySearch', id, null, false);

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    data: JSON.stringify(data),
    apiVersion: version
  };

  return axios(options);
}

export function getFareRules(params) {
  const url = `${config.API_URL}api/Itinerary/${params.itineraryId}/passenger/${params.passengerId}/${params.sectorType}/${params.sectorId}/farerules`;

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true)
  };

  return axios(options);
}

export function makeBooking(itinerary, preventGlobalError) {
  const { url, version } = apiConfig('itinerary', { itineraryId: itinerary.Identifier });
  const data = JSON.stringify(itinerary);

  const options = {
    url,
    method: 'PUT',
    headers: getHeaders(true),
    data,
    apiVersion: version,
    preventGlobalError
  };

  return axios(options);
}


export function addFlights(itineraryId, flights) {
  const { url, version } = apiConfig('addFlights', { itineraryId });
  const data = JSON.stringify(flights);

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    data,
    apiVersion: version
  };

  return axios(options);
}

export function cancelItinerary(itineraryId) {
  const { url, version } = apiConfig('cancelItinerary', { itineraryId });

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    data: null,
    apiVersion: version,
    preventGlobalError: true
  };

  return axios(options);
}

export function removeFlight(itineraryId, passengerId, sectorId) {
  const { url, version } = apiConfig('itineraryPassengerFlight', { itineraryId, passengerId, sectorId });

  const options = {
    url,
    method: 'DELETE',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}

export function cancelSegment(itineraryId, passengerId, sectorType, sectorId) {
  const { url, version } = apiConfig('cancelSegment', { itineraryId, passengerId, sectorType, sectorId });

  const options = {
    url,
    method: 'PUT',
    headers: getHeaders(true),
    data: '{"Status": "cancel"}',
    apiVersion: version,
    preventGlobalError: true
  };

  return axios(options);
}

export function cancelFlightSegments(itineraryId, passengerId, segments) {
  const { url, version } = apiConfig('cancelFlightSegments', { itineraryId, passengerId });
  
  const body = {
    Status: "cancel", 
    Segments: segments
  }
  
  const options = {
    url,
    method: 'PUT',
    headers: getHeaders(true),
    data: JSON.stringify(body),
    apiVersion: version,
    preventGlobalError: true
  };

  return axios(options);
}

export function postPassengerHotel(itineraryId, data) {
  const { url, version } = apiConfig('passengerHotel', { itineraryId });
  
  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    data: JSON.stringify(data),
    apiVersion: version,
  };

  return axios(options);
}
