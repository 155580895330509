<script>
  export let itinerary = undefined;
  export let settings = undefined;
</script>

{#if itinerary && settings}
<div class="container">
  {#if itinerary.AgencyReasonTitle}
  <div class="reference">
    <div class="reference_header">
      {settings.agencyReasonCodesLabel}
    </div>
    <div class="reference_content">
      {itinerary.AgencyReasonTitle}
    </div>
  </div>
  {/if}
  <div class="reference">
    <div class="reference_header">
      Comment to travel agent
    </div>
    <div class="reference_content">
      {itinerary.BookingComment || '-'}
    </div>
  </div>
  { #if itinerary.OrderNumber }
  <div class="reference">
    <div class="reference_header">
      { settings.orderNumberLabel }
    </div>
    <div class="reference_content">
      {itinerary.OrderNumber}
    </div>
  </div>
  { /if }
  {#if itinerary.Configurations.CompanyReferences.length > 0}
  <div class="reference">
    <div class="reference_header">
      Company Reference
    </div>
    <div class="reference_content">
      {#each itinerary.Configurations.CompanyReferences as CompanyReference}
      <div class="companyRef">
        <div class="companyRefTitle">{CompanyReference.Title}</div>
        <div class="companyRefValue">{CompanyReference.Value}</div>
      </div>
      {/each}
    </div>
  </div>
  {/if}
</div>
{/if}

<style>
  .container {
    margin-top: 32px;
  }

  .reference {
    border-radius: 4px;
    background-color: #F3F6FC;
    color: #2C3E50;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .reference_header {
    height: 52px;
    line-height: 52px;
    border-bottom: 1px solid #fff;
    letter-spacing: 0.31px;
    padding: 0 15px;
  }

  .reference_content {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.31px;
    padding: 14px 15px 23px;
    overflow-wrap: break-word;
  }

  .companyRefTitle {
    color: #A2AAB2;
    font-size: 14px;
    letter-spacing: 0.11px;
    margin-bottom: 4px;
  }

  .companyRefValue {
    color: #2C3E50;
    font-weight: 600;
    letter-spacing: 0.31px;
    margin-bottom: 24px;
  }

  .companyRef:last-child .companyRefValue {
    margin-bottom: 0;
  }
</style>
