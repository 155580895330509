<script>
  import Footer from '../Footer/Footer.svelte';
  import logo from './logo.svg';

  export let header;
  export let message;
  export let showExclaim = true;

  import exclaim from './exclaim.png'; 
</script>

<div class="container">
  <div class="panel">
    <div class="panelHeader">
      <div class="message">
        <h1>{header}</h1>
        <p>{message}</p>
      </div>
    </div>

    <div class="footer">
      <div class="footer_logo">
        <img src="{ logo }" alt="Lightning Book">
      </div>

      <Footer></Footer>
    </div>
  </div>

  {#if showExclaim}
    <div class="exclaim" style="background: transparent url({exclaim}) no-repeat;"></div>
  {/if}
</div>

<style>
  .container {
    background: #202e38;
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    padding: 20px;
    position: relative;
    width: 100%;
    z-index: 1;
    min-height: 100vh;
  }

  .panelHeader {
    max-width: 700px;
    width: 100%;
  }

  .message h1 {
    color: #fff;
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 0.39px;
    margin: 0 0 36px 0;
  }

  .message p {
    color: #fff;
    font-size: 21px;
    margin: 0;
  }

  .footer {
    padding-top: 30px;
    max-width: 700px;
    width: 100%;
  }

  .footer_logo {
    width: 147px;
    margin-bottom: 17px;
  }

  .footer_logo img {
    width: 100%;
  }

  .exclaim {
    display: none;
    
    background-size: cover;
    width: 671px;
    height: 2448px;
    bottom: -40px;
    right: -400px;
    position: fixed;
    z-index: 0;
  }

  @media (min-width: 1050px) {
    .message h1 {
      font-size: 44px;
    }

    .panel {
      padding: 40px;
    }

    .exclaim {
      display: block;
    }
  }

  @media (min-width: 1475px) {
    .exclaim {
      right: -170px;
    }
  }
</style>
