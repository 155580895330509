<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import dayjs from 'dayjs';

  const dispatch = createEventDispatcher();

  import Button, { options as buttonsOptions } from '@ctm/ui/Button';
  import ItinerarySummaryCard from '../ItinerarySummaryCard/ItinerarySummaryCard.svelte';
  import { getItineraries } from '../../http/itineraries.js';
  import { formatDate } from '../../util/date.js';
  import { dateFormat, track, trackingEvents } from '../../store/index.js';
  
  export let itinerary;

  let show = false;
  let dupes;

  onMount(() => {
    const clientIds = itinerary.Passengers.filter(pax => pax.Reference !== "0").map(pax => pax.Reference).join(',');
    const dateFrom = encodeURIComponent(dayjs(itinerary.FromDate, 'YYYY-MM-DD').format());
    const dateTo = encodeURIComponent(dayjs(itinerary.ToDate, 'YYYY-MM-DD').format());

    if(clientIds.length > 0) {
      getItineraries({ dateFrom, dateTo, clientIds }).then(res => {
        const pnrToExclude = sessionStorage.getItem('excludeChangePNRFromDuplicates');
          
        if (pnrToExclude && window.location.hash.includes('#/manage-bookings/')) {
          dupes = res.data.filter(itinerary => itinerary.Passengers[0].Pnr !== pnrToExclude);  
        } else {
          dupes = res.data;
        } 

        if (dupes.length > 0) {
          track(trackingEvents.DUPLICATE_BOOKINGS_FOUND);
          document.body.style.height = '100%';
          document.body.style.overflow = 'hidden';
          show = true;
          dispatch('show');
        }
      }).catch(() => {});
    }
  });

  onDestroy(() => {
    document.body.style.height = '';
    document.body.style.overflow = '';
  });
</script>

{#if show}
<div class="duplicateBookings">
  <div class="header">

    <h2>Duplicate booking</h2>
    <p>The traveller(s) below have existing bookings for the selected travel dates ({ formatDate(itinerary.FromDate, $dateFormat.DATE_EXTENDED) } - { formatDate(itinerary.ToDate,
        $dateFormat.DATE_EXTENDED) }). If you choose to continue with the current booking it may result in overlapping or duplicate bookings for
      the same traveller. Alternatively you can abandon the current booking and start a new booking. </p>
  </div>

  <div class="bookings">
    <div class="bookings_details">
        {#each dupes as dupe}
        <div class="booking">
          <ItinerarySummaryCard
            itinerary={dupe}
            showManageBookingButton="{true}"
            showApprovalStatus="{false}"
            isClickDisabled="{true}">
          </ItinerarySummaryCard>
        </div>
        {/each}
    </div>
  </div>

  <div class="footer">
    <div class="button">
      <Button on:click="{() => dispatch('destroy')}" size="{buttonsOptions.size.LARGE}" isBlock="{true}">Continue</Button>
    </div>
    <div class="button">
      <Button on:click="{() => dispatch('abandon')}" size="{buttonsOptions.size.LARGE}" type="{buttonsOptions.type.LINK}" isBlock="{true}">Abandon Booking
      </Button>
    </div>
  </div>

</div>
{/if}

<style>
  .duplicateBookings {
    position: fixed;
    top: 64px;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 1002;
    overflow-y: auto;
  }

  .header,
  .footer {
    padding: 64px 20px 0 20px;
    max-width: 1254px;
    margin: 0 auto;
  }

  h2 {
    color: #2C3E50;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0.36px;
  }

  p {
    color: #3F4F5F;
    font-size: 14px;
    letter-spacing: -0.15px;
    line-height: 19px;
    margin-bottom: 64px;
  }

  .bookings {
    background: #F1F4FC;
  }

  .bookings_details {
    max-width: 1254px;
    margin: 0 auto;
    padding: 64px 20px;
  }

  .booking {
    margin-bottom: 16px;
  }

  .footer {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 100px;
  }

  .button {
    margin: 0 5px 0 0;
    width: 228px;
  }

  .button:last-child {
    margin: 0;
  }

</style>
