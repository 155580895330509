<script>
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();

  import Avatar from '@ctm/ui/Avatar';
  import Dropdown from '@ctm/ui/Dropdown';
  import NavMenu from './NavMenu.svelte';
  import getInitials from '../../util/getInitials';

  import {
    userInfo,
    isAnonymousUser,
    usersName,
    usersEmail,
    usersDebtor,
    usersInitials
  } from '../../store/index.js';

  import LbLogo from './LBLogo.svelte';
  import Beamer from './Beamer.svelte';
  import ChevronDownIcon from './ChevronDownIcon.svelte';

  export let settings;

  let activeRoute;
  let getMenuContainer = () => document.getElementById('nav') || document.body;
  let isNavMenuActive = undefined;

  function onDropdownSelect(event) {
    if (event.detail === 'SIGN_OUT') {
      dispatch('signOut');
    }
    else {
      dispatch('goto', event.detail);
    }
  }

  function onNavItemClick(stateName, event) {
    event.preventDefault();
    dispatch('goto', stateName);
  }

  function onWindowHashChange() {
    activeRoute = window.location.hash.substring(2).split('/')[0];
  }

  function init() {
    const _userInfo = JSON.parse(sessionStorage.getItem('CURRENT_USER_userInfo'));

    if ($userInfo && _userInfo && _userInfo.Email && $userInfo.Email !== _userInfo.Email) {
      window.location.pathname = '/';
      window.location.hash = '#/search';
      location.reload();
    }

    if (_userInfo && _userInfo.Name) {
      const names = _userInfo.Name.split(' ');

      $userInfo = _userInfo;
      $isAnonymousUser = _userInfo.IsAnonymousUser;
      $usersName = _userInfo.Name;
      $usersEmail = _userInfo.Email;
      $usersDebtor = _userInfo.Debtor;
      $usersInitials = names.length > 1 ? getInitials(names[0], names[1]) : '--';

    } else {
      setTimeout(() => {
        dispatch('signOut');
      }, 0);
    }
  }

  onMount(() => {
    onWindowHashChange();
    init();
  });

  $: show_beamer = window && window.Beamer && settings && settings.IsBeamerEnabled;
</script>

<svelte:window on:hashchange="{onWindowHashChange}" on:focus={init} />

<div id="nav" class="nav { $isAnonymousUser ? 'hideContents' : ''}">
  <div class="layout">
    <div class="logo">
      <LbLogo />
    </div>

    {#if !$isAnonymousUser}
    <div class="menu">
      <div class="menu_items">
        <a
          href="/#/search"
          on:click="{event => onNavItemClick('app.home.search', event)}"
          class="new-booking { activeRoute === 'search' ? 'active' : '' }"
          title="New Booking"
        >
          <span>New Booking</span>
        </a>
        <a
          href="/#/manage-bookings"
          on:click="{event => onNavItemClick('app.home.manage', event)}"
          class="manage-bookings { ['manage-bookings', 'airport-transfers'].includes(activeRoute) ? 'active' : '' }"
          title="Manage Bookings"
        >
          <span>Manage Bookings</span>
        </a>
      </div>
    </div>
    {/if}

    {#if show_beamer}
        <Beamer />
    {/if}

    <Dropdown
      MenuComponent="{ NavMenu }"
      placement="bottomRight"
      { getMenuContainer }
      bind:isActive="{isNavMenuActive}"
      on:select="{onDropdownSelect}"
      width="275px">
      <div class="avatarMenu {isNavMenuActive ? 'active' : ''}" title="{$usersName}">
        <Avatar size="32" alt="{$usersInitials}" textColour="#2D3840" bgColour={isNavMenuActive ? '#fff' : '#89949C'}></Avatar>
        <span>{$usersName}</span>
        <div class="chevron">
          <ChevronDownIcon />
        </div>
      </div>
    </Dropdown>
  </div>
</div>

<style>
  .nav {
    background: #141B22;
    height: 64px;
    padding: 0 32px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1003;
  }

  .layout {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  .logo {
    margin-right: 105px;
  }

  .menu {
    display: none;
  }

  .hideContents .menu {
    display: none;
  }

  .avatarMenu {
    display: flex;
    align-items: center;
    color: #707C84;
    height: 64px;

  }

  .avatarMenu span {
    padding-left: 8px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: none;
    white-space: nowrap;
    display: none;
  }

  .avatarMenu .chevron {
    width: 16px;
    height: 16px;
    position: relative;
    display: none;
  }

  .avatarMenu.active {
    color: #fff;
  }

  @media (min-width: 768px) {
    .menu {
      display: flex;
      align-items: stretch;
      align-self: stretch;
      flex-grow: 1;
      justify-content: space-between;
      padding-left: 32px;
    }

    .menu_items {
      display: flex;
      align-items: stretch;
    }

    .menu_items a {
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 14px;
      margin-right: 36px;
      text-align: center;
      justify-content: center;
      font-weight: 400;
    }

    .menu_items a.new-booking {
      width: 91px;  
    } 

    .menu_items a.manage-bookings {
      width: 124px;
    }

    .menu_items a:hover,
    .menu_items a.active {
      box-shadow: 0 -8px 0 -4px #51ce6c inset;      
      font-weight: 600;
      letter-spacing: -0.25px;
    }

    .avatarMenu span {
      margin-right: 8px;
    }

    .avatarMenu span,
    .avatarMenu .chevron {
      display: block;
    }

    .menu_items a {
      position: relative;
    }
  }

  @media print {
    .nav { 
      display: none; 
    }
  }
</style>
