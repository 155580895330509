<script>
    import { onMount } from 'svelte';
    import { fade } from 'svelte/transition';

    import Select from 'svelte-select/src/Select.svelte';
    import Button from '@ctm/ui/Button';
    import { Back } from '@ctm/ui/Icons';
    import ActionBar from '../ActionBar/ActionBar.svelte';
    import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
    import ChevronIcon from './ChevronIcon.svelte';
    import Checkbox from './Checkbox.svelte';
    import SectorMessage from './_SectorMessage.svelte';

    import { groupSectorsByDate } from '../../util/itinerary';
    import { getItineraryForChange } from '../../http/itineraries';
    import {
        track,
        trackingEvents,
        changeBookingsPnr,
        changeBookingsItinerary as itinerary,
        flightsForChange,
        carsForChange,
        hotelsForChange,
        itineraryByDate,
        changeBookingHotelPayment,
        notesToConsultant,
        reasonForChange,
        saveChangeBookingSession
    } from '../../store/index.js';

    export let pnr;
    $changeBookingsPnr = pnr;

    let isWaiting = true;
    let step = 0;
    let reasonForChangeOptions = [];
    let supportNumber = window.settings
        ? window.settings.DebtorSupport.Phone
        : '';
    let searching = false;

    $: notesLength = $notesToConsultant.length;
    $: changeDisabled = $itinerary && !$itinerary.Passengers[0].HasChangeableContent;

    onMount(() => {
        $itineraryByDate = [];
        $itinerary = undefined;
        $flightsForChange = [];
        $carsForChange = [];
        $hotelsForChange = [];
        $changeBookingHotelPayment = null;
        $notesToConsultant = '';
        $reasonForChange = '';
        sessionStorage.removeItem('confirmation:itineraryId');
        sessionStorage.removeItem('changeBooking');
        sessionStorage.removeItem('returnToChangeBookingWizard');
        sessionStorage.removeItem('change:itineraryId');
        sessionStorage.removeItem('excludeChangePNRFromDuplicates');
        sessionStorage.removeItem('change:cars');
        sessionStorage.removeItem('change:HotelIds');
        sessionStorage.removeItem('locationService:searchIndex');
        sessionStorage.removeItem('locationService:currentSearchType');
        sessionStorage.removeItem('search:itineraryId');
        sessionStorage.removeItem('change:preventReShop');

        getItinerary();
    });

    function getItinerary() {
        getItineraryForChange(pnr)
            .then((response) => {
                setItinerary(response.data);
            })
            .catch(() => {})
            .finally(() => {
                isWaiting = false;
            });
    }

    function setItinerary(itineraryToSet) {
        $itinerary = itineraryToSet;
        $itineraryByDate = groupSectorsByDate($itinerary.Passengers[0]);
        reasonForChangeOptions = [
            ...$itinerary.Passengers[0].ReasonForChangeOptions,
        ];
    }

    function handleAddSectorForChange(e, sector, dayIndex, sectorIndex) {
        const checked = e.detail;
        $itineraryByDate[dayIndex].sectors[sectorIndex].isChecked = checked;

        if (sector.sectorType === 'flight') {
            if (checked) {
                $flightsForChange = [...$flightsForChange, sector];
            } else {
              $flightsForChange = $flightsForChange.filter(
                  (s) => s.data.Identifier != sector.data.Identifier
              );
            }
        }

        if (sector.sectorType === 'hotel') {
          if (checked) {
            $hotelsForChange = [...$hotelsForChange, sector];
          } else {
            $hotelsForChange = $hotelsForChange.filter(
              (s) => s.data.Identifier != sector.data.Identifier
            );
          }
        }
        
        if (sector.sectorType === 'car') {
            if (checked) {
                $carsForChange = [...$carsForChange, sector];
            } else {
              $carsForChange = $carsForChange.filter(
                  (s) => s.data.Identifier != sector.data.Identifier
              );
            }
        }
    }

    function handleContinue() {
        saveChangeBookingSession();
        let next = $flightsForChange.length > 0 ? 'flights' : null;
        if (!next) next = $hotelsForChange.length > 0 ? 'hotels': 'cars';
        window.location.hash = `/manage-bookings/${pnr}/change/${next}`;
    }

    function onBackClick() {
        if (!pnr) pnr = $itinerary.Passengers[0].Pnr;
        window.location.hash = `/manage-bookings/${pnr}`;
        track(trackingEvents.CHANGE_BOOKING_BACK_CLICKED);
    }

    function handleReasonForChange(e) {
        $reasonForChange = e.detail.Title;
    }

    $: disableContinue = handleDisableContinue({
        searching,
        isWaiting,
        $flightsForChange,
        $hotelsForChange,
        $carsForChange,
        $reasonForChange,
        reasonForChangeOptions,
    });

    function handleDisableContinue({
        searching,
        isWaiting,
        $flightsForChange,
        $hotelsForChange,
        $carsForChange,
        $reasonForChange,
        reasonForChangeOptions
    }) {
        return (
            searching ||
            isWaiting ||
            ($flightsForChange.length === 0 && $carsForChange.length === 0 && $hotelsForChange.length === 0) ||
            (reasonForChangeOptions.length > 0 && $reasonForChange.length === 0)
        );
    }
</script>

<div class="changeBooking">
    <ActionBar>
        <div class="actionBar_back">
            <Button on:click={onBackClick} Icon={Back} type="link" />
        </div>
    </ActionBar>

    <div class="content">
        <div class="content-header">
            <h2>Change Booking</h2>

            <p>
                Airline and services fees will be calculated on selection,
                please check the fare rules for a comprehensive breakdown on any
                applicable fees. Additional baggage and paid seats may be
                non-refundable or unavailable on the new flight.
            </p>

            <p>
                For hotel changes Lightning will cancel and rebook the hotel to
                the new selected dates. Please check the cancellation policy for
                any applicable fees before proceeding with the change.
            </p>
        </div>
        {#if isWaiting}
            <FlexSpinner />
        {:else if step === 0}
            <div in:fade|local={{ duration: 250 }}>
                <div class="change-list">
                    {#if changeDisabled}
                        <h3 class="no-margin">
                            Sorry, this booking cannot be changed online
                        </h3>
                        <p>
                            To make changes to this booking please contact your
                            dedicated CTM Consultant or call {supportNumber}
                        </p>
                    {:else}
                        <h3>What would you like to change?</h3>
                    {/if}

                    <div class="list-items">
                        {#each $itineraryByDate as day, dayIndex}
                            {#each day.sectors as sector, sectorIndex}
                                <div
                                    class="list-items-sector"
                                    class:disabled={changeDisabled ||
                                        sector.data.NoChangeReason ===
                                            'Departed' ||
                                        !sector.data.IsChangeable}>
                                    <Checkbox
                                        disabled={
                                            (changeDisabled ||
                                                sector.data.NoChangeReason ===
                                                    'Departed' ||
                                                !sector.data.IsChangeable)}
                                        id={`sector${dayIndex}:${sectorIndex}`}
                                        on:change={(checked) =>
                                            handleAddSectorForChange(
                                                checked,
                                                sector,
                                                dayIndex,
                                                sectorIndex
                                            )} />

                                    <SectorMessage
                                        bind:sector
                                        {dayIndex}
                                        {sectorIndex}
                                    />
                                </div>
                            {/each}
                        {/each}
                    </div>
                </div>

                <div class="notes-consultant">
                    <h3>Notes to consultant</h3>
                    <p>
                        Add any additional notes regarding booking changes that
                        you would like to send to your consultant.
                    </p>

                    <div class="textarea">
                        <label>{notesLength}/1000</label>
                        <textarea
                            placeholder="Enter notes for the consultant"
                            bind:value={$notesToConsultant}
                            maxlength="1000" />
                    </div>
                </div>

                {#if reasonForChangeOptions.length > 0}
                  <div class="reason-for-change">
                      <h3>Reason for change</h3>
                      <p>You are required to provide a reason for this change.</p>

                      <div class="select">
                          <i><ChevronIcon /></i>
                          <Select
                              placeholder="Please select"
                              items={reasonForChangeOptions}
                              optionIdentifier="Code"
                              labelIdentifier="Title"
                              isClearable={false}
                              isDisabled={changeDisabled}
                              on:select={handleReasonForChange} />
                      </div>
                  </div>
                {/if}
            </div>
        {/if}
        {#if !isWaiting}
            <div class="continue">
                <Button
                    isWaiting={searching}
                    isDisabled={disableContinue}
                    type="primary"
                    size="large"
                    on:click={handleContinue}>Continue</Button>
            </div>
        {/if}
    </div>
</div>

<style>
    .changeBooking {
        background: #fff;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        overflow: hidden;
        padding-top: 128px;
        text-align: left;
    }

    .actionBar_back {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 16px;
        top: 15px;
    }

    .content {
        display: flex;
        flex-direction: column;
        color: #3f4f5f;
        font-size: 14px;
        padding: 48px 20px;
        width: 100%;
        max-width: 693px;
        margin: 0 auto;
    }

    .content h2,
    .content h3 {
        color: #141b22;
        font-size: 26px;
        font-weight: 600;
        line-height: 39px;
        margin: 0 0 2px;
    }

    .content h3 {
        font-size: 18px;
        line-height: 27px;
        margin: 0 0 4px;
    }

    .content p {
        margin: 0 0 24px 0;
        color: #2d3840;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .content-header {
        border-bottom: 1px solid #ebecf0;
        margin-bottom: 24px;
    }

    .content-header h2 {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        margin: 0 0 8px;
    }

    .content-header p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 24px;
    }

    .change-list {
        padding: 0 0 2px;
        margin: 0 0 24px;
        border-bottom: 1px solid #ebecf0;
        margin-bottom: 29px;
    }

    .change-list h3 {
        margin: 0 0 24px;
    }

    .change-list h3.no-margin {
        margin: 0;
    }

    .list-items-sector {
        margin: 0 0 24px;
        display: flex;
        align-items: flex-start;
    }

    .list-items-sector :global(.checkbox) {
        position: relative;
        top: 4px;
    }

    .reason-for-change {
        margin: 0 0 56px;
    }

    .reason-for-change .select {
        max-width: 420px;
    }

    .continue {
        display: flex;
        justify-content: flex-end;
    }

    .notes-consultant {
        padding: 0 0 24px;
    }

    .notes-consultant p {
      margin: 0;
    }

    .textarea {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
    }
    
    .textarea textarea {
        min-width: 100%;
        max-width: 100%;
        min-height: 83px;
        border: 1px solid #ced3da;
        border-radius: 4px;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 21px;
    }

    .select {
        position: relative;
        --background: transparent;
        --height: 48px;
    }

    .select i {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 8px;
        width: 14px;
        right: 20px;
        top: 20px;
    }
</style>
