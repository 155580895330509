<script>
  import dayjs from "dayjs";
  import { createEventDispatcher } from 'svelte';
  import Button from '@ctm/ui/Button';
  import { Delete } from '@ctm/ui/Icons';
  import Icon from '../Icon/Icon.svelte';
  import { cancelFlightSegments as cancelFlightSegmentsRequest, cancelItinerary, cancelSegment as cancelSegmentRequest } from '../../http/itinerary';
  import CloseIcon from './_CloseIcon.svelte';
  import Triangle from './_Triangle.svelte';
  import AlertIcon from './_AlertIcon.svelte';

  const dispatch = createEventDispatcher();

  export let passengerIdentifier = null;
  export let pnr = null;
  export let date = null;
  export let disabled = false;
  export let hasNonRefundableContent = false;
  export let segment = null;
  export let sectorType = null;
  export let itinerary = null;
  export let isCancelling = false;

  let cancelDialog = null;
  let errorDialog = null;
  let successDialog = null;
  let isWaiting = false;
  let partialCancel = false;
  let apiError = false;

  $: flights_shared_ticket_number = sectorType === 'flight' && segment && segment.TicketNumber != null ? itinerary.Flights.filter(f => f.TicketNumber === segment.TicketNumber) : [];
  $: flights_shared_carrier = sectorType === 'flight' && segment ? itinerary.Flights.filter(f => f.Carrier === segment.Carrier && f.IsNdc === segment.IsNdc) : [];
  $: flights_combined = [...flights_shared_ticket_number, ...flights_shared_carrier].sort((a, b) => new Date(a.Departure.Date) - new Date(b.Departure.Date));
  $: flights =  flights_combined.filter((flight, i, self) => self.findIndex(f => f.Identifier === flight.Identifier) === i);
  $: flightIds = flights && flights.filter(f => f.has_departed === false).map(f => f.Identifier);

  function cancelSegment() {
    cancelSegmentRequest(segment.ItineraryIdentifier, segment.PassengerIdentifier, sectorType, segment.Identifier)
    .then(res => {
      if (res.data.Success) successDialog.showModal();
      else {
        showErrorDialog();
      }
    })
    .catch(handleError)
    .finally(() => {
      cancelDialog.close();
    });
  }

  function cancelFlightSegments() {
    cancelFlightSegmentsRequest(segment.ItineraryIdentifier, segment.PassengerIdentifier, flightIds)
    .then(res => {
      if (res.data.Success) successDialog.showModal();
      else {
        showErrorDialog();
      }
    })
    .catch(handleError)
    .finally(() => {
      cancelDialog.close();
    });
  }

  function showErrorDialog() {
    isCancelling = false;
    isWaiting = false;
    errorDialog.showModal();
  }

  function cancel() {
    isWaiting = true;
    partialCancel = false;
    apiError = false;

    if (segment) {
      if (sectorType === 'flight') cancelFlightSegments();
      else cancelSegment();
      return;
    }

    cancelItinerary(passengerIdentifier)
    .then((res) => {
      const { SabreSuccess, TaxiCancellationResponse } = res.data;
      cancelDialog.close();
      if (SabreSuccess && TaxiCancellationResponse.Success) {
        successDialog.showModal();
      } else if (SabreSuccess && !TaxiCancellationResponse.Success) {
        partialCancel = true;
        successDialog.showModal();
      } else {
        showErrorDialog();
      }
    }).catch(handleError)
      .finally(() => {
        isWaiting = false;
    })
  }

  function handleError() {
      cancelDialog.close();
      showErrorDialog();
      apiError = true;  
  }

  function complete() {
    sessionStorage.setItem('pnr-cancelled', JSON.stringify({ pnr, date }));
    if (sectorType) {
      dispatch('cancelled');
      successDialog.close();
    } else {
      dispatch('goToManageBookings');
    }
  }
</script>

<div class="cancel">
  <Button
    isDisabled={disabled || isCancelling}
    type="destructive"
    Icon={Delete}
    size="default"
    isOutlined
    iconPosition="left"
    isWaiting={isWaiting}
    on:click={() => { cancelDialog.showModal() }}>
      <slot name="button-text">Cancel Booking</slot>
  </Button>

  {#if disabled}
    <div class="hover">
      <i><Triangle /></i>
      <div class="hover-content">To cancel this booking please contact your travel consultant</div>
    </div>
  {/if}
</div>

<dialog id="cancel-dialog" bind:this={cancelDialog} on:cancel={(e) => { if (isWaiting) e.preventDefault() }}>
  <button disabled={isWaiting} on:click={!isWaiting && cancelDialog.close()}><CloseIcon /></button>
  {#if sectorType === 'flight' && flights.length > 1}
    <h2>Partial cancellation is not allowed</h2>
    <p class="less-margin">By proceeding, the following flights will be cancelled:</p>
    <ol>
      {#each flights as flight}
        <li>
          <span class:departed={flight.has_departed}>{dayjs(flight.Departure.Date, 'YYYY-MM-DDTHH:mm').format('D MMM YYYY')} {flight.Carrier}{flight.FlightNumber}: {dayjs(flight.Departure.Date, 'YYYY-MM-DDTHH:mm').format('h.mma')} {flight.Departure.Code} - {flight.Arrival.Code}</span>
          {#if flight.has_departed}<span>(Already departed)</span>{/if}
        </li>
      {/each}
    </ol>

    <footer>
      <Button isDisabled={isWaiting} type="primary" size="default" isOutlined on:click={cancelDialog.close()}>No, go back</Button>
      <Button type="destructive" size="default" on:click={cancel} {isWaiting}>Yes, cancel flights</Button>
    </footer>
  {:else}
    <h2><slot name="dialog-title-text">Do you want to cancel this booking?</slot></h2>
    <p><slot name="dialog-text">By proceeding, every segment in this booking will be cancelled. Cancellation fees may apply if the booking is within the cancellation policy period.</slot></p>
    {#if hasNonRefundableContent}
      <p>This itinerary includes non-refundable content.</p>
    {/if}

    <footer>
      <Button isDisabled={isWaiting} type="primary" size="default" isOutlined on:click={cancelDialog.close()}>No, go back</Button>
      <Button type="destructive" size="default" on:click={cancel} {isWaiting}>Yes, cancel { segment ? 'segment' : 'booking'}</Button>
    </footer>
  {/if}
</dialog>

<dialog id="success-dialog" bind:this={successDialog} on:cancel={(e) => { if (isWaiting) e.preventDefault() }}>
  <h2>
    {#if partialCancel}
      <i class="partial"><AlertIcon /></i>
      Partially Successful
    {:else}
      <i class="success"><Icon type="check" /></i>
      Successful Cancellation
    {/if} 
    {#if !sectorType}- #{pnr}{/if}
  </h2>

  {#if partialCancel}
    <p>Cancellation of your booking #{pnr} was partially successful. A notification has been sent to your Consultant</p>
  {:else}
    <p><slot name="success-text">Your booking #{pnr} has been successfully cancelled.</slot></p>
  {/if}

  <footer>
    <Button type="primary" size="default" on:click={complete}><slot name="success-button">Return to Manage Bookings</slot></Button>
  </footer>
</dialog>

<dialog id="error-dialog" bind:this={errorDialog}>
  <button on:click={errorDialog.close()}>
    <CloseIcon />
  </button>

  <h2><i class="failed"><Icon type="cancel"></Icon></i> Unsuccessful Cancellation {#if !sectorType}- #{pnr}{/if}</h2>

  <p>Cancellation of your booking {#if !sectorType}#{pnr}{/if} was unsuccessful. {#if !apiError}A notification has been sent to your Consultant.{/if}</p>

  <footer>
    <Button type="primary" size="default" isOutlined on:click={errorDialog.close()}>Close</Button>
  </footer>
</dialog>

<style>
  dialog:modal {
    position: fixed;
    border: none;
    border-radius: 8px;
    width: 572px;
    padding: 24px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 12px 24px -4px rgba(32, 43, 51, 0.24), 0px 8px 60px rgba(32, 43, 51, 0.32);
    text-align: left;
  }

  button {
    outline: none;
    border: none;
    padding: 0;
    background: transparent;
    display: flex;
    position: absolute;
    top: 11px;
    right: 11px;
  }

  button:disabled {
    opacity: .3;
  }

  h2 {
    display: flex;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #141b22;
    margin-bottom: 16px;
    gap: 10px;
  }

  i.success {
    width: 27px;
    display: flex;
    border-radius: 100%;
    background: var(--green_4);
    color: #fff;
    padding: 5px;
  }

  i.failed {
    width: 27px;
    display: flex;
    border-radius: 100%;
    background: var(--red_4);
    color: #fff;
    padding: 5px;
  }

  i.partial {
    width: 27px;
    display: flex;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 27px;
  }

  footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 10px;
  }

  dialog::backdrop {
    background: rgba(0, 0, 0, 0.4); 
  }

  dialog#cancel-dialog::backdrop {
    animation: fade-in .2s ease-in;
  }

  .cancel {
    display: inline-block;
    position: relative;
  }

  .hover {
    display: none;
    background: #156AF4;
    box-shadow: 0px 4px 8px rgba(32, 43, 51, 0.1), 0px 8px 24px rgba(32, 43, 51, 0.05);
    border-radius: 4px;
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    position: absolute;
    text-align: left;
    width: 180px;
    left: -180px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .hover i {
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
  }

  .cancel:hover .hover {
    display: block;
    animation: fade-in .2s ease-in;
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  p.less-margin {
    margin-bottom: 12px
  }

  ol, li {
    margin: 0;
    list-style: none;
    padding: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  ol {
    margin-bottom: 27px;
  }

  .departed {
    text-decoration: line-through;
  }
</style>
