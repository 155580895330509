<script>
  import { createEventDispatcher } from 'svelte';
  import Avatar from '@ctm/ui/Avatar';
  import { _DropdownMenu as DropdownMenu, _DropdownMenuItem as DropdownMenuItem } from '@ctm/ui/Dropdown';
  import { Group, Logout } from '@ctm/ui/Icons';
  
  import {
    isAnonymousUser,
    usersInitials,
    usersName,
    usersEmail,
    userDebtor,
    usersDebtor,
  } from '../../store/index.js';

  const dispatch = createEventDispatcher();

  export let width;
  export let items;
  export let selectedItem;
  export let itemKey;
  export let isMulti;
  export let isSearchable;
  export let maxHeight;

  function onClick(selected) {
    dispatch('select', selected);
  }
</script>

<DropdownMenu {width}>
  <div class="userDetails {$isAnonymousUser ? 'noBorder' : ''}">
    <Avatar alt="{$usersInitials}" textColour="#b0b7be" bgColour="#ebedef"></Avatar>
    <div class="userDetailsText">
      <span class="name" title="{$usersName}">{$usersName}</span>
      <span class="email" title="{$usersEmail}">{$usersEmail}</span>
      {#if $userDebtor }
        <div class="debtorDetails" title="{$userDebtor.Title}">
          <div class="debtorIcon">
            <Group />
          </div>      
          <span class="debtorName">{$userDebtor.Title}</span>
        </div>
      {/if}
      {#if $usersDebtor}
          <div class="debtorDetails" title="{$usersDebtor}">
            <div class="debtorIcon">
              <Group />
            </div>      
            <span class="debtorName">{$usersDebtor}</span>
          </div>
        {/if}
      </div>
  </div>

  {#if !$isAnonymousUser}
    <div class="mobileNav">
      <DropdownMenuItem on:click="{() => onClick('app.home.search')}">New Booking</DropdownMenuItem>
      <DropdownMenuItem on:click="{() => onClick('app.home.manage')}">Manage Bookings</DropdownMenuItem>
    </div>
  {/if}

  <div class="navItem" on:click="{() => onClick('SIGN_OUT')}">
    <span>Sign out</span>
    <div class="logOutIcon">
      <Logout />
    </div>
  </div>
</DropdownMenu>
  
  <style>
    .userDetails {
      padding: 14px 16px;
      border-bottom: 1px solid #d8dbdf;
      height: 95px;
    }

    .userDetails.noBorder {
      border: none
    }
  
    .userDetails {
      display: flex;
    }
  
    .userDetailsText .name,
    .userDetailsText .email { 
      display: block;
      padding-left: 7px;
      overflow: hidden;
      max-width: 211px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: none;
      white-space: nowrap;
    }
  
    .name {
      color: #2C3E50;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: -0.15px;
      line-height: 20px;
    }
  
    .email {
      color: #78848F;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 6px;
    }
  
    .debtorDetails {
      background: #BFEDC9;
      color: #194423;
      display: inline-flex;
      width: auto;
      align-items: center;
      border-radius: 2px;
      height: 24px;
      margin-left: 7px;
      padding: 0 5px;
    }

    .debtorIcon {
      position: relative;
      top: 1px;
      width: 15px;
      margin-right: 3px;
    }

    .debtorName {
      font-size: 12px;
      font-weight: 500;
      color: #2C3E50;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: -0.15px;
      line-height: 24px;
      max-width: 178px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: none;
      white-space: nowrap;
    }
  
    .navItem {
      position: relative;
    }
  
    .navItem:hover {
      background: #e7f2ff;
      border-radius: 0 0 4px 4px;
      cursor: pointer;
    }
  
    .navItem span {
      padding: 0 16px;
      height: 48px;
      line-height: 48px;
      display: block;
      color: #3F4F5F;
      font-size: 14px;
      letter-spacing: -0.15px;
    }
  
    .logOutIcon {
      position: absolute;
      top: 12px;
      right: 14px;
      width: 24px;
      height: 24px;
      color: #2C3E50;
    }
  
    .mobileNav {
      border-bottom: 1px solid #d8dbdf;
    }
  
    @media (min-width: 768px) {
      .mobileNav {
        display: none;
      }
    }
  
  </style>
