<script>
    import {
      CURRENT_YEAR,
  } from '../../store/index.js';
</script>

<div class="footer">
  <p class="copyright">
    &copy Copyright { $CURRENT_YEAR } Corporate Travel Management.<br />
    Need help? Contact your dedicated CTM consultant for any technical support.
  </p>

  <p class="links">
    <a href="https://www.travelctm.com/" target="_blank">About</a> &bull; <a href="https://www.travelctm.com/global-privacy-policy/" target="_blank">Privacy</a> &bull; <a href="https://www.travelctm.com/terms-conditions/" target="_blank">Terms</a>
  </p>
</div>

<style>
  .footer {
    flex-shrink: 0;
    color: #8d97a0;
    font-size: 12px;
  }

  .copyright {
    line-height: 20px;
    margin: 0 0 17px 0;
  }

  .links {
    color: #fff;
    margin: 0;
  }

  .links a {
    color: #007AFF;
    display: inline-block;
    margin: 0 3px;
  }

  .links a:first-child {
    margin-left: 0;
  }

  .links a:hover {
    text-decoration: underline;
  }
</style>
