<script>
  import { Clock, Alert, Check } from "@ctm/ui/Icons";
  import startCase from "lodash/startCase";
  import classnames from "classnames";

  export let status = "";
  export let showLabel = true;

  export let ClassNames;
  $: {
    ClassNames = classnames(`bookingStatusLabel-${status}`);
  }

  export let Label;
  $: {
    switch (status) {
      case "Booked":
        Label = "Confirmed";
      case "PartialBooked":
        Label = "Pending";
      case "OnRequest":
        Label = "On Request";
      default:
        Label = startCase(status);
    }
  }
</script>

<style>
  .bookingStatusLabel {
    border-radius: 2px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 2px 9px;
  }

  .bookingStatusLabel-Booked {
    background: #bfedc9;
    color: #183c20;
  }

  .bookingStatusLabel-Failed {
    background-color: #ffb2c1;
    color: #5d0014;
  }

  .bookingStatusLabel-Pending,
  .bookingStatusLabel-PartialBooked {
    background: #ffe2b9;
    color: #5f3800;
  }

  .bookingStatusLabel-OnRequest {
    background: #FFD8A2;
    color: #864E00;
  }

  .bookingStatusLabel_icon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
</style>

<div class="bookingStatusLabel {ClassNames}">
  {#if status != 'OnRequest'}
    <span class="bookingStatusLabel_icon">
      {#if status === 'Booked'}
        <Check />
      {:else if status === 'Failed'}
        <Alert />
      {:else}
        <Clock />
      {/if}
    </span>
  {/if}
  {#if showLabel}<span class="bookingStatusLabel_label">{Label}</span>{/if}
</div>
