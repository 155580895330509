<script>
  import { createEventDispatcher, onDestroy } from 'svelte';

  const dispatch = createEventDispatcher();

  import dayjs from 'dayjs';
  import Button, { options as buttonsOptions } from '@ctm/ui/Button';
  import { Edit, Back, Seat } from '@ctm/ui/Icons';
  import Card from '@ctm/ui/Card';
  import Tabs from '@ctm/ui/Tabs';
  import { modal as modalService } from '@ctm/ui/Modal';
  import { approvalStates, groupSectorsByDate } from '../../util/itinerary.js';
  import { formatDate as _formatDate } from '../../util/date.js';
  import { getItineraryById } from "../../http/itinerary";

  import ActionBar from '../ActionBar/ActionBar.svelte';
  import AddToCalendar from './_AddToCalendar.svelte';
  import Approvals from './_Approvals.svelte';
  import ApprovalModal from './_ApprovalModal.svelte';
  import ItineraryCard from './_ItineraryCard.svelte';
  import PaymentDetails from './_PaymentDetails.svelte';
  import References from './_References.svelte';
  import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
  import AirportTransfersBanner from '../AirportTransfersBanner/AirportTransfersBanner.svelte';
  import PrintButton from './_PrintButton.svelte';
  import Cancel from './_Cancel.svelte';
  
  import {
    dateFormat,
    track,
    trackingEvents
  } from '../../store/index.js';

  export let settings = {
    orderNumberLabel: '',
    agencyReasonCodesLabel: ''
  };
  export let itinerary = undefined;
  export let isPta = false;
  export let isCancelling = false;

  let subNavActive = 0;
  let modalActive = false;
  let showShuttleBanner = false;
  let carbonEmissionSetting = JSON.parse(window.sessionStorage.getItem('CarbonEmissionSetting'));
  
  $: emissionsTotal = carbonEmissionSetting && 
    carbonEmissionSetting.EnableEmissionsTotal &&
    paxItinerary && paxItinerary.CarbonEmissionAmountTotal > 0 ? Math.round(paxItinerary.CarbonEmissionAmountTotal) : false;

  function onChangeClick() {
    track(trackingEvents.CHANGE_BOOKING_CLICKED, paxItinerary);
    window.location.hash = `/manage-bookings/${paxItinerary.Pnr}/change`;
  }

  function onTabChange(event) {
    track(trackingEvents.BOOKING_TAB_CLICKED, event.detail.label);
  }

  export function handleApprove(approve) {
    modalActive = true;

    const modal = modalService.open(ApprovalModal, {
      approve,
      itinerary: paxItinerary,
      isPta
    });
    
    modal.then(() => {
      modalActive = false;
      dispatch('approvalUpdated');
    }).catch(() => {
      modalActive = false;
    });
  }

  export function goToSeatmaps() {
    dispatch('goToSeatmaps', paxItinerary);
  }

  let tabData;
  $: {
    if (!paxItinerary || !sectors || !settings) {
      tabData = undefined;
    } else {
      tabData = [
        {
          label: 'Payment',
          component: PaymentDetails,
          attributes: {
            itinerary: paxItinerary
          }
        },
        {
          label: 'References',
          component: References,
          attributes: {
            itinerary: paxItinerary,
            settings: settings
          }
        },
      ];

      if (paxItinerary.ApprovalLevel !== 'None') {
        tabData.unshift({
          label: 'Approval',
          component: Approvals,
          attributes: {
            itinerary: paxItinerary
          }
        })
      }
    }
  }

  $: paxItinerary = itinerary ? itinerary.Passengers[0] : undefined;
  $: sectors = itinerary ? groupSectorsByDate(itinerary.Passengers[0]) : undefined;
  $: currentStatus = paxItinerary ? approvalStates[paxItinerary.ApprovalState] : undefined;
  $: hasNonRefundableContent = paxItinerary && paxItinerary.HasChangeableContent && [...paxItinerary.Hotels, ...paxItinerary.Cars].some(sector => !sector.Refundable);
  $: has_departed_flights = paxItinerary && paxItinerary.Flights.find(f => f.has_departed);

  let timeLeftToApprove = undefined;
  $: {
    if (!paxItinerary) {
      timeLeftToApprove = undefined;
    } else {
      const ticketingTimeLimit = dayjs(paxItinerary.TicketingTimeLimit);
      const minutesLeft = ticketingTimeLimit.diff(dayjs(), 'minutes');
      const hoursLeft = Math.floor((minutesLeft / 60));
      const daysLeft = Math.floor((hoursLeft / 24));
      const days = daysLeft > 0 ? `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}` : '';
      const hoursOffset = hoursLeft - (daysLeft * 24);
      const minutesOffset = minutesLeft - (hoursLeft * 60);

      if (hoursOffset < 1 && minutesOffset < 1) {
        timeLeftToApprove = '-';
      } else if (daysLeft > 0) {
        timeLeftToApprove = days;
      } else {
        timeLeftToApprove = `${hoursOffset} hours ${minutesOffset} mins`;
      }
    }
  }

  let lostSavingsAmount = undefined;
  $: {
    if (!paxItinerary) {
      lostSavingsAmount = undefined;
    } else {
      let total = 0;
      const allSectors = [].concat(paxItinerary.Flights, paxItinerary.Rails, paxItinerary.Hotels, paxItinerary.Cars);

      allSectors.forEach(sector => {
        total += sector.LostSavingsAmount;
      });

      lostSavingsAmount = total.toFixed(2);
    }
  }

  let travelPolicy = undefined;
  $: {
    if (!paxItinerary) {
      travelPolicy = undefined;
    } else {
      const allSectors = [].concat(paxItinerary.Flights, paxItinerary.Rails, paxItinerary.Hotels, paxItinerary.Cars);

      travelPolicy = !allSectors.some(sector => {
        return sector.Policy.Code !== null;
      });
    }
  }

  let SeatSelectionSupported = undefined;
  $: {
    SeatSelectionSupported = paxItinerary && paxItinerary.Flights.some((flight) => {
      return flight.Status === 'Booked' && flight.IsSeatSelectionSupported;
    });
  }

  let loggedInUserApproverMessage = undefined;
  $: {
    if (!paxItinerary) {
      loggedInUserApproverMessage = undefined;
    } else {

      if (paxItinerary.LoggedInUserApproverTier) {
        const loggedInUserApprover = paxItinerary.Configurations.Approvers.find((approver) => {
          return approver.Tier === paxItinerary.LoggedInUserApproverTier;
        });

        if (loggedInUserApprover.ApprovalStatus === 'Approved') {
          loggedInUserApproverMessage = {
            approved: true,
            text: 'You have approved this request',
            ApprovalStatusModifiedOn: loggedInUserApprover.ApprovalStatusModifiedOn
          };
        } else if (loggedInUserApprover.ApprovalStatus === 'Declined') {
          loggedInUserApproverMessage = {
            approved: false,
            text: 'You have denied this request',
            ApprovalStatusModifiedOn: loggedInUserApprover.ApprovalStatusModifiedOn
          };
        } else {
          loggedInUserApproverMessage = false;
        }
      } else {
        loggedInUserApproverMessage = false;
      }
    }
  }

  const formatDate = (dateTime, format) => {
    return dateTime ? _formatDate(dateTime.substring(0, 16), format) : '-';
  };

  $: {
    if (paxItinerary && paxItinerary.IsShuttleAvailable) checkItineraryForBookedShuttles();
  }

  async function checkItineraryForBookedShuttles() {
    const { data } = await getItineraryById(paxItinerary.ItineraryIdentifier);
    let shuttlesAlreadyBooked = false;

    data.Passengers.forEach(pax => {
      if (!shuttlesAlreadyBooked) shuttlesAlreadyBooked = pax.Shuttles.length > 0;
    })

    if (!shuttlesAlreadyBooked) showShuttleBanner = true;
  }
  
  
  $: showTaxiBanner = itinerary && itinerary.IsTaxiAvailable;

  $: noSectors = sectors && sectors.length === 0;

  $: if (noSectors && paxItinerary && paxItinerary.Pnr) {
    sessionStorage.setItem('pnr-cancelled', JSON.stringify({ pnr: paxItinerary.Pnr, date: paxItinerary.FromDate }));
    dispatch('goToManageBookings');
  }

  onDestroy(() => {
    if (modalActive) {
      modalService.remove();
    }
  });
</script>

<style>
  .actionBar_button {
    margin-left: 8px;
  }

  .actionBar_back {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 15px;
  }

  .content {
    padding: 129px 0 0;
    background: #f1f5fc;
    position: relative;
    color: #2C3E50;
    flex-grow: 1;
  }

  .content_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 0;
  }

  .leftPanel {
    padding: 0 16px;
  }

  .pnr {
    color: var(--neutral_7);
    font-size: 21px;
    font-weight: 900;
  }

  .approvalStatus {
    border-radius: 16px;
    background-color: #FF9500;
    text-align: center;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    padding: 7px 9px;
    width: 116px;
  }

  .approvalStatus.approvalApproved {
    background: #48c761;
  }

  .approvalStatus.approvalPending {
    background: #ff8a05;
  }

  .approvalStatus.approvalDenied {
    background: #ff274b;
  }

  .approvalStatus.approvalExpired {
    background: #2C3E50;
  }

  .mobileSubNav {
    border: 1px solid var(--blue_4);
    border-radius: 3px;
    margin-bottom: 32px;
    display: flex;
  }

  .mobileSubNav_option {
    color: var(--blue_4);
    font-size: 14px;
    font-weight: 500;
    padding: 7px 0;
    text-align: center;
    user-select: none;
    flex-grow: 1;
    width: 50%;
  }

  .mobileSubNav_option.active {
    background: var(--blue_4);
    color: #fff;
  }

  .hide {
    display: none;
  }

  .itinerary {
    padding: 0 16px;
  }

  .bookingDetails {
    background: #fff;
    padding: 32px 19px;
    margin: 0 -16px;
  }

  .approvalSnapshot {
    margin-bottom: 32px;
  }

  .itinerary_buttons {
    margin-bottom: -24px;
    text-align: right;
  }

  .itinerary_buttons_button {
    display: inline-block;
    margin: 0 0 5px 5px;
  }

  .approvalSnapshot_item {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.29px;
    line-height: 19px;
    text-align: center;
    border-bottom: 1px solid #E9EBED;
    padding: 10px 0;
  }

  .approvalSnapshot_item_details {
    color: #007AFF;
  }

  .approvalSnapshot_item_details span {
    font-size: 10px;
  }

  .itineraries {
    padding-top: 40px;
  }

  .itinerary_group {
    margin-bottom: 32px;
  }

  .itinerary_item_date {
    color: #2C3E50;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 20px;
    margin-bottom: 8px;
  }

  .itinerary_item:not(:last-child) {
    margin-bottom: 32px;
  }

  .paxDetails {
    margin-bottom: 40px;
  }

  .paxDetail {
    margin-bottom: 16px;
  }

  .paxDetail_title {
    color: #2C3E50;
    font-size: 14px;
    font-weight: 500;
  }

  .paxDetail_content {
    color: #3F4F5F;
    font-size: 14px;
  }

  .noItinerary {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    color: #3f4f5f;
    font-size: 14px;
  }

  .transfersBanner {
    margin-top: 40px;
  }

  .change_booking_button {
    display: none;
  }

  @media (min-width: 768px) {
    .change_booking_button {
      display: inline-block;
    }

    .mobileSubNav {
      display: none;
    }

    .hide {
      display: block;
    }

    .content {
      display: flex;
      flex-direction: row;
      min-height: auto;
    }

    .leftPanel {
      width: 304px;
      min-width: 304px;
      background: #fff;
      padding: 0 35px;
    }

    .content_header {
      position: relative;
      padding: 29px 0 24px 0;
    }

    .pnr {
      margin: 0;
    }

    .bookingDetails {
      padding: 0;
      margin: 0;
    }

    .approvalStatus {
      position: relative;
      right: -16px;
    }

    .itinerary {
      flex-grow: 1;
      padding-top: 29px;
    }
  }

  @media (min-width: 1025px) {
    .leftPanel {
      min-width: 367px;
      width: 467px;
      max-width: 467px;

    }

    .pnr {
      font-size: 27px;
      letter-spacing: 0.29px;
      line-height: 33px;
    }

    .bookingDetails {
      padding: 0;
    }

    .itinerary {
      max-width: 1112px;
      margin: 0 auto;
      width: 100%;
    }

    .itinerary_buttons {
      margin-bottom: 25px;
    }

    .approvalSnapshot_items {
      display: flex;
      flex-direction: row;
      min-height: 134px;
    }

    .approvalSnapshot_item {
      flex: 1;
      border-right: 1px solid #EBEBEB;
      padding: 32px 16px 16px;
      border-bottom: none;
    }

    .approvalSnapshot_item:last-child {
      border: none;
    }

    .approvalSnapshot_item_header {
      margin-bottom: 23px;
    }

    .approvalSnapshot_item_details {
      font-size: 16px;
    }

    .transfersBanner {
      margin-top: 0;
    }

    .itineraries {
      padding-top: 0;
    }
  }

  @media (min-width: 1300px) {
    .itinerary {
      padding-top: 39px;
      padding-bottom: 39px;
    }

    .content_header {
      padding-top: 39px;
    }

    .leftPanel {
      padding: 0 3%;
    }
  }
  
  .print-header-container {
    display: none;
  }

  @media print {
    .print-header-container {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .print-title {
      color: #000000;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.21px;
      line-height: 24px;
    }

    .print-header {
      -webkit-print-color-adjust: exact !important;
      display: flex;
      background: #F2F6FD;
      padding: 0 8px;
      height: 30px;
      width: 100%;
      align-items: center;
      border-radius: 4px;
      margin: 0 0 18px;
    }

    .print-header h2,
    .print-header span {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
    }

    .print-header h2 {
      flex-grow: 1;
    }

    .content,
    .itinerary {
      padding: 0;
    }

    :global(.actionBar),
    .leftPanel,
    .itinerary_buttons,
    .approvalSnapshot {
      display: none;
    }
    .transfersBanner {
      display: none;
    }
    .itinerary_group {
      break-inside: avoid;
    }
    .itineraries {
      padding-top: 0;
    }
  }

</style>

{#if paxItinerary}
<div class="print-header-container">
  <h1 class="print-title">Itinerary</h1>

  <div class="print-header">
    <h2>{paxItinerary.FullName}</h2>
    <span>#{paxItinerary.Pnr}</span>
  </div>
</div>

<ActionBar>
  {#if !isPta}
  <div class="actionBar_back">
    <Button Icon={Back} type="link" on:click="{() => dispatch('goToManageBookings')}"></Button>
  </div>
  {/if}

  {#if paxItinerary.CanApproveBooking && paxItinerary.ApprovalState === 'Pending'}
    <div class="actionBar_button">
      <Button on:click="{() => handleApprove(true)}" isWide size="{buttonsOptions.size.LARGE}">Approve</Button>
    </div>
    <div class="actionBar_button">
      <Button on:click="{() => handleApprove(false)}" isWide size="{buttonsOptions.size.LARGE}" type="{buttonsOptions.type.DESTRUCTIVE}">Deny</Button>
    </div>
  {/if}
</ActionBar>

<div class="content">
  <div class="leftPanel">
    <div class="content_header">
      <div class="pnr">
        #{ paxItinerary.Pnr }
      </div>

      {#if paxItinerary.ApprovalLevel === 'None' && paxItinerary.ApprovalState === 'Auto'}
        <div class="approvalStatus approvalApproved">
          Booked
        </div>
      {:else if currentStatus}
        <div class="approvalStatus { currentStatus.class }">
          { currentStatus.message }
        </div>
      {/if}
    </div>

    <div class="mobileSubNav">
      <div
        class="mobileSubNav_option { subNavActive === 0 ? 'active' : '' }"
        on:click="{() => subNavActive = 0}"
      >
        Itinerary
      </div>
      <div
        class="mobileSubNav_option { subNavActive === 1 ? 'active' : '' }"
        on:click="{() => subNavActive = 1}"
      >
        Booking details
      </div>
    </div>

    <div class="bookingDetails { subNavActive === 1 ? '' : 'hide' }">
      <div class="paxDetails">
        <div class="paxDetail">
          <div class="paxDetail_title">
            Traveller
          </div>
          <div class="paxDetail_content">
            {paxItinerary.FullName}
          </div>
        </div>
        <div class="paxDetail">
          <div class="paxDetail_title">
            Requested by
          </div>
          <div class="paxDetail_content">
            {paxItinerary.User}
          </div>
        </div>
        <div class="paxDetail">
          <div class="paxDetail_title">
            Department
          </div>
          <div class="paxDetail_content">
            {paxItinerary.DepartmentName}
          </div>
        </div>
        <div class="paxDetail">
          <div class="paxDetail_title">
            Cost Centre
          </div>
          <div class="paxDetail_content">
            {paxItinerary.CostCentreName}
          </div>
        </div>
        <div class="paxDetail">
          <div class="paxDetail_title">
            Booking #
          </div>
          <div class="paxDetail_content">
            {paxItinerary.Id}
          </div>
        </div>
        <div class="paxDetail">
          <div class="paxDetail_title">
            Ticketing time limit
          </div>
          <div class="paxDetail_content">
            { formatDate(paxItinerary.TicketingTimeLimit, $dateFormat.DATE_TIME_THEN_DATE) }
          </div>
        </div>
      </div>

      {#if tabData}
      <Tabs tabs={tabData} activeTab="{tabData[0]}" on:change="{onTabChange}" />
      {/if}
    </div>
  </div>

  <div class="itinerary { subNavActive === 0 ? '' : 'hide' }">
    <div class="approvalSnapshot">
      <Card>
        <div class="approvalSnapshot_items">
          <div class="approvalSnapshot_item">
            <div class="approvalSnapshot_item_header">
              { settings ? settings.agencyReasonCodesLabel : '' }
            </div>
            <div class="approvalSnapshot_item_details">
              { paxItinerary.AgencyReasonTitle || '-' }
            </div>
          </div>

          {#if paxItinerary.ApprovalLevel !== 'None'}
          <div class="approvalSnapshot_item">
            <div class="approvalSnapshot_item_header">
              Time left to approve
            </div>
            <div class="approvalSnapshot_item_details">
              { currentStatus && currentStatus.id === 'pending' ? timeLeftToApprove : '-' }
            </div>
          </div>
          {/if}

          <div class="approvalSnapshot_item">
            <div class="approvalSnapshot_item_header">
              Total price
            </div>
            <div class="approvalSnapshot_item_details">
              {paxItinerary.TotalAmount.toFixed(2)}<span>{paxItinerary.CurrencyCode}</span>
            </div>
          </div>

          <div class="approvalSnapshot_item">
            <div class="approvalSnapshot_item_header">
              Lost savings
            </div>
            <div class="approvalSnapshot_item_details">
              {lostSavingsAmount}<span>{paxItinerary.CurrencyCode}</span>
            </div>
          </div>

          <div class="approvalSnapshot_item">
            <div class="approvalSnapshot_item_header">
              Travel policy
            </div>
            <div class="approvalSnapshot_item_details">
              {travelPolicy ? 'In policy' : 'Out of policy'}
            </div>
          </div>

          {#if emissionsTotal }
            <div class="approvalSnapshot_item">
              <div class="approvalSnapshot_item_header">
                Carbon Footprint
              </div>
              <div class="approvalSnapshot_item_details">
                {emissionsTotal}kg
              </div>
            </div>
          {/if}
        </div>
      </Card>
    </div>

    {#if !isPta && paxItinerary.ApprovalState !== 'Declined' && paxItinerary.ApprovalState !== 'Expired'}
    <div class="itinerary_buttons itinerary_buttons-screen">
      {#if paxItinerary.HasCancellableContent && !has_departed_flights}
        <Cancel passengerIdentifier={paxItinerary.Identifier} pnr={paxItinerary.Pnr} date={itinerary.FromDate} {hasNonRefundableContent} on:goToManageBookings />
      {/if}
      {#if SeatSelectionSupported}
      <div class="itinerary_buttons_button">
        <Button
          on:click="{goToSeatmaps}"
          type="primary"
          Icon="{Seat}"
          size="default"
          iconPosition="left">
          Select seats
        </Button>
      </div>
      {/if}
      <div class="itinerary_buttons_button">
        <AddToCalendar itinerary={ paxItinerary } />
      </div>
      {#if paxItinerary.HasChangeableContent}
      <div class="itinerary_buttons_button change_booking_button">
        <Button
          on:click="{onChangeClick}"
          type="{buttonsOptions.type.PRIMARY}"
          Icon="{Edit}"
          iconPosition="{buttonsOptions.iconPosition.LEFT}">
          Change booking
        </Button>
      </div>
      {/if}
      <div class="itinerary_buttons_button">
        <PrintButton />
      </div>
    </div>
      {#if showShuttleBanner}
        <div class="transfersBanner">
          <AirportTransfersBanner itineraryId={paxItinerary.ItineraryIdentifier}></AirportTransfersBanner>
        </div>
      {/if}

      {#if showTaxiBanner}
      <div class="transfersBanner">
        <AirportTransfersBanner taxiMode pnr={paxItinerary.Pnr} itineraryId={paxItinerary.ItineraryIdentifier}></AirportTransfersBanner>
      </div>
    {/if}
    {/if}
    <div class="itineraries">
      {#each sectors as group }
      <div class="itinerary_group">
        <div class="itinerary_item_date">{ formatDate(group.date, $dateFormat.DATE_EXTENDED) }</div>
          {#each group.sectors as sector (sector.data.Identifier)}
          <div class="itinerary_item">
            <ItineraryCard itinerary={paxItinerary} sector="{ sector.data }" sectorType="{ sector.sectorType }" { isPta } {isCancelling} disableCancel={sectors.length === 1 && group.sectors.length === 1} on:refreshItinerary></ItineraryCard>
          </div>
          {/each}
        </div>
      {/each}
    </div>
  </div>
</div>

{:else if itinerary === null}

<div class="noItinerary">
  <strong>Sorry, looks like something went wrong.</strong><br />
  Please check the URL and try refreshing the page. If you continue to have issues contact your dedicated CTM consultant.
</div>

{:else}

<FlexSpinner/>

{/if}
