<script>
  import Spinner from "@ctm/ui/Spinner";

  import CheckBox from "./_Checkbox.svelte";
  import {
    airportTransferSelections,
    airportTransfersPrepData,
    airportTransferBooked,
    airportTransferShowConfirmation,
    airportTransferError,
  } from "../../store/index.js";
  import { bookTransfer } from "../../http/airportTransfers";

  let accepted = false;
  let booking = false;
  let y;

  function toggleAccept() {
    if (booking) return;
    accepted = !accepted;
  }

  async function handleBooking() {
    if (booking) return;
    
    booking = true;
    let data = airportTransfersPrepData();

    $airportTransferBooked = await bookTransfer(data).catch((err) => {
      booking = false;
      $airportTransferError = true;
    });

    $airportTransferShowConfirmation = true;
    booking = false;
    y = 0;
  }

  let total = 0;

  $: {
    total = 0;

    if ($airportTransferSelections) {
      Object.values($airportTransferSelections).forEach((selection) => {
        total += selection.RetailFare;
      });
    }
  }
</script>

<style>
  .container {
    padding: 16px 31px;
    background: #fff;
  }

  .intro p {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
  }

  .intro p a {
    color: #007aff;
  }

  .accept {
    border-radius: 8px;
    padding: 16px;
    background-color: #f4f6f8;
    display: flex;
    margin: 0 0 24px;
    align-items: center;
    cursor: pointer;
  }

  .accept p {
    color: #4d555e;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }

  .price {
    border-top: 1px solid #dfe3e8;
    padding: 24px 0 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 16px;
  }

  .total {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    margin: 0 4px 0 0;
  }

  .currency {
    color: #212b36;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    align-self: flex-start;
  }

  .amount {
    height: 32px;
    min-width: 84px;
    color: #212b36;
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
    align-self: flex-start;
    margin: 0;
  }

  .confirmBooking {
    height: 56px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: #51ce6c;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 16px;
    position: relative;
    overflow: hidden;
  }

  .confirmBooking:disabled {
    user-select: none;
    opacity: 0.2;
  }

  .smallPrint {
    color: #79838d;
    font-size: 13px;
    line-height: 18px;
  }

  .booking {
    width: 20px;
    height: 20px;
    display: flex;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    .inner {
      max-width: 1155px;
      margin: 0 auto;
    }

    .intro p {
      color: #212b36;
      font-size: 14px;
      line-height: 22px;
    }

    .priceBooking {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #dfe3e8;
      margin: 0 0 40px;
      padding: 23px 0;
      align-items: center;
    }

    .price {
      border: none;
      padding: 0;
      margin: 0 24px 0 0;
      align-items: baseline;
    }

    .amount {
      color: #212b36;
      font-size: 36px;
      font-weight: 600;
      line-height: 36px;
    }

    .total {
      color: #4d555e;
      font-size: 16px;
      line-height: 16px;
      margin: 0 4px 0 0;
      align-self: flex-end;
    }

    .confirmBooking {
      max-width: 160px;
      height: 40px;
      margin: 0;
    }
  }
</style>

<svelte:window bind:scrollY={y}/>

<div class="container">
  <div class="inner">
    <div class="intro">
      <p>
        Your shuttle bus booking will be charged to your account with CTM.
      </p>

      <p>
        Please note: Your airport transfer booking may be non-refundable. Please
        review the supplier's terms and conditions
        <a href="https://www.supershuttle.co.nz/Terms/" target="_blank">here</a>.
        Please confirm that you understand and accept the terms and conditions
        of the content that you have selected. Fares and cancellation conditions
        are defined and managed by the supplier of the service and cannot be
        guaranteed.
      </p>
    </div>

    <div class="accept" on:click={toggleAccept}>
      <CheckBox checked={accepted} disabled={booking} />
      <p>
        I accept the terms and conditions and would like to proceed with this
        booking.
      </p>
    </div>

    <div class="priceBooking">
      <div class="price">

        <div class="total">Total:</div>
        <div class="currency">NZD</div>
        <div class="amount">{total.toFixed(2)}</div>
      </div>

      <button
        class="confirmBooking"
        on:click={handleBooking}
        disabled={!accepted}>
        {#if !booking}
          Confirm Booking
        {:else}
          <div class="booking">
            <Spinner />
          </div>
        {/if}
      </button>
    </div>

    <p class="smallPrint">
      All prices are quoted in NZD unless otherwise stated. NZD rates are
      calculated on todays exchange rate. Any payments that are to be settled at
      the time of the arrival will need to be made in local currency and will be
      subject to the exchange rate of the day.
    </p>
  </div>
</div>
