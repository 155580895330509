<script>
  import reduce from 'lodash/reduce';
  import { itineraryHasPreTripApproval, itineraryHasOnRequestHotel } from '../../util/itinerary';
  import WarnIcon from '../ChangeBooking/WarnIcon.svelte';

  export let itinerary;
  export let supportEmail;
  export let hotelFailure = null;
  export let Messages;
  $: {
    Messages = reduce(itinerary.Passengers, (messages, pax) => {
      return messages.concat(pax.BookingInformation);
    }, []);
  }

  $:requestOrConfirmedMessage = itineraryHasOnRequestHotel(itinerary) ? 'request has been received' : 'has been confirmed';
</script>

<div class="confirmationIntro">
  <div class="confirmationIntro_details">
    {#if hotelFailure}
      <h1 class="confirmationIntro_title hotel_failure">Your hotel bookings need to be amended</h1>
      <div class="hotel_failure_detail">
        <i class="warning"><WarnIcon /></i>
        <p class="confirmationIntro_desc">
          Your hotel booking was partially successful due to room availability.<br />
          Please select from the revised property list below.
        </p>
      </div>
    {:else}
      { #if itinerary.Status === 'Booked' }
        { #if itinerary.ChangeStatus === 'Exchanged' || itinerary.ChangeStatus === 'Changing' }
          <h1 class="confirmationIntro_title">Your booking has been successfully changed</h1>
        { :else if itinerary.ChangeStatus === 'PartialExchanged' }
          <h1 class="confirmationIntro_title">Sorry, your changes could only be partially booked.</h1>

          <p class="confirmationIntro_desc">Your changes were only partially booked, check the travellers details for
            more detail. Your consultant <strong>{ supportEmail }</strong> has been notified and will be in touch to
            complete your booking.</p>

          <p class="confirmationIntro_desc">Note: Original bookings on failed changes have NOT been changed or cancelled.</p>
        { :else if itineraryHasPreTripApproval(itinerary) }
          <h1 class="confirmationIntro_title">Great news, your booking has been sent for approval</h1>

          <p class="confirmationIntro_desc">
            Your booking {requestOrConfirmedMessage} and sent off for approval. All bookings need to be fully approved before they expire in order to avoid any applicable cancellation fees, check the details below for specific expiry times.
          </p>
        { :else }
          <h1 class="confirmationIntro_title">Great news! Your booking was successful</h1>
        { /if }
      { :else }
        <h1 class="confirmationIntro_title">Sorry, your itinerary could only be partially booked.</h1>

        <p class="confirmationIntro_desc">
          Check the travellers details for more detail. Your consultant <strong>{ supportEmail }</strong> has been notified and will be in touch to complete your booking.
        </p>

        { #if itineraryHasPreTripApproval(itinerary) }
        <p class="confirmationIntro_desc">
          Your {requestOrConfirmedMessage} and sent off for approval. All bookings need to be fully approved before they expire in order to avoid any applicable cancellation fees, check the details below for specific expiry times.
        </p>
        { /if }
      { /if }

      { #if Messages.length > 0 }
      <p class="confirmationIntro_messages">
        <strong>Please note:</strong>
        {#each Messages as message, messageIndex}
          { message }
          { #if messageIndex < Messages.length - 1 }
          <br />
          { /if }
        {/each}
      </p>
      { /if }
    {/if}
  </div>
</div>


<style>
  .confirmationIntro {
    color: #3f4f5f;
    font-size: 14px;
    letter-spacing: -0.15px;
  }

  .confirmationIntro_title {
    color: #2c3e50;
    font-size: 21px;
    font-weight: 700;
    margin: 0;
  }

  .confirmationIntro p {
    margin: 16px 0 0 0;
  }

  h1.hotel_failure {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    color: #141B22;
  }

 .hotel_failure_detail {
    border: 1px solid #D0E2FF;
    border-radius: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    display: flex;
    gap: 8px;
    color: #2D3840;
  }

  .hotel_failure_detail p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .warning {
    position: relative;
    top: 5px;
    color: #DA1E28;
  }
</style>
