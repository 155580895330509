<script>
  import Card from '@ctm/ui/Card';

  export let itinerary = {};
  export let supportEmail = '';
</script>

<Card>
  <div class="failedBookingMessage">
    <div class="failedBookingMessage_content">
      <h3>Booking failed</h3>
      <p>
        Unfortunately, the booking request for { itinerary.Firstname } { itinerary.Lastname } failed to be confirmed. 
        <br /> Your dedicated consultant { supportEmail } has been notified and will be in touch to shortly to process this booking.
      </p>
    </div>
  </div>
</Card>

<style>
  .failedBookingMessage {
    color: #52616f;
    font-size: 14px;
    text-align: center;
    padding: 42px;
    display: flex;
    justify-content: center;
  }

  .failedBookingMessage_content {
    width: 100%;
  }

  h3 {
    color: #2c3e50;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  p {
    margin: 0;
  }
</style>