import axios from 'axios';
import { getHeaders } from './headers';

import {
  CONFIG
} from '../store/index.js';

export async function getApprovalItinerary(hash) {
  const url = `${CONFIG.API_URL}api/Approval/${hash}`;

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(),
    apiVersion: 21
  };

  return axios(options);
}

export async function updateApproval(params) {
  const url = `${CONFIG.API_URL}api/Approval/${params.approve ? 'approve' : 'decline'}/${params.itinerary.hash}`;

  const headers = getHeaders(true);
  delete headers.Authorization;

  const options = {
    url,
    method: 'POST',
    headers,
    apiVersion: 21,
    data: '"' + params.comment + '"'
  };

  return axios(options);
}

export async function approveItinerary(params) {
  const { approve, comment, itinerary } = params;

  const approverId = itinerary.Configurations.Approvers.find((approver) => {
    return approver.ApprovalStatus === 'Pending';
  }).Identifier;

  const url = `${CONFIG.API_URL}api/Approval/${itinerary.ItineraryIdentifier}/${itinerary.Identifier}/${approverId}/${approve ? 'approve' : 'decline'}`;

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(true),
    apiVersion: 21,
    data: '"' + comment + '"'
  };

  return axios(options);
}
