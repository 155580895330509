import { notification } from '@ctm/ui/Notification';
import { modal as modalService } from '@ctm/ui/Modal';

import AirportTransfers from './components/AirportTransfers/AirportTransfers.svelte';
import ChangeBooking from './components/ChangeBooking/ChangeBooking.svelte';
import CompanyReferences from './components/CompanyReferences/CompanyReferences.svelte';
import Confirmation from './components/Confirmation/Confirmation.svelte';
import DuplicateBookings from './components/DuplicateBookings/DuplicateBookings.svelte';
import HotelReasonModal from './components/HotelReasonModal/HotelReasonModal.svelte';
import HotelInfoModal from './components/HotelInfoModal/HotelInfoModal.svelte';
import Login from './components/Login/Login.svelte';
import ManageBookings from './components/ManageBookings/ManageBookings.svelte';
import Nav from './components/Nav/Nav.svelte';
import LoggedOut from './components/LoggedOut/LoggedOut.svelte';
import ViewBooking from './components/ManageBookings/ViewBooking.svelte';
import Taxis from './components/Taxis/Taxis.svelte';
import TaxisConfirmation from './components/TaxisConfirmation/TaxisConfirmation.svelte';
import ChangeBookingWizard from './components/ChangeBookingWizard/ChangeBookingWizard.svelte';
import BookingError from './components/BookingError/BookingError.svelte';

import './http/interceptors';
import './public/flatpickr.css';
import './public/ie11.css';

import {
  apiConfig,
  getItineraryId,
  setItineraryId,
  removeItineraryId,
  userInfo,
  userDebtor,
  manageBookingsItinerary,
  time,
  track,
  trackingEvents,
  trackUser,
  trackLogin,
  trackSearch,
  trackingInit,
  setTrackingIdentity,
  settings,
  logout
} from './store/index';

window.svelteStore = {
  apiConfig,
  getItineraryId,
  setItineraryId,
  removeItineraryId,
  userInfo,
  userDebtor,
  manageBookingsItinerary,
  time,
  track,
  trackingEvents,
  trackUser,
  trackLogin,
  trackSearch,
  trackingInit,
  setTrackingIdentity,
  settings,
  logout
};

window.svelteUI = {
  AirportTransfers,
  ChangeBooking,
  CompanyReferences,
  Confirmation,
  DuplicateBookings,
  HotelReasonModal,
  HotelInfoModal,
  Login,
  ManageBookings,
  Nav,
  LoggedOut,
  ViewBooking,
  modalService,
  notification,
  Taxis,
  TaxisConfirmation,
  ChangeBookingWizard,
  BookingError
}

window.loadSvelteComponent = function (componentName, element, props) {
  return new Promise(function (resolve, reject) {
    const component = new window.svelteUI[componentName]({
      target: element,
      props
    });

    resolve(component);
  });
}
