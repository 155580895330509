<script>
  import { paymentCardName } from '../../util/itinerary';
  import formatCurrency from '../../util/currency';

  export let groupType;
  export let itinerary;
  export let group = null;

  $: total = group && group.total;

  $: {
    if (group && groupType === 'flight') group.payments.map((payment) => {
      if (payment.sectors) return payment.sectors.sort((a,b) => new Date(a.Departure.Date) - new Date(b.Departure.Date))
    });
  }

  let taxis = null;
  $: {
    if (groupType === 'taxi') {
      itinerary.Taxis.filter(item => item.Status === 'Booked').forEach(item => {
        taxis += item.TaxiSegmentInvoices[0].TotalAmount;
      })
    }
  }

</script>


{#if taxis != null}
<div class="payment">
  <div class="payment_header">
    <div class="payment_type">
      Taxi Total
    </div>

    <div class="payment_amount taxi">
      payment to be invoiced* 
    </div>
  </div>

  <div class="payment_details">
    <div class="detail">
      <div class="detail_header">
        <span class="detail_header_titles">
          Corporate Cabs
        </span>
        <span class="detail_header_amount">
          {formatCurrency(taxis,2)}<span>{itinerary.CurrencyCode}</span>
        </span>
      </div>
    </div>
  </div>
</div>
{:else}
<div class="payment">
  <div class="payment_header">
    <div class="payment_type">
      {groupType} Total
    </div>
    <div class="payment_amount">
      {formatCurrency(total, 2)}<span>{itinerary.CurrencyCode}</span>
    </div>
  </div>
  {#if groupType === 'shuttle'}
    <div class="payment_details">
        <div class="detail">
          <div class="detail_header">
            <span class="detail_header_titles">
              <span>Payment</span>
            </span>
            <span class="detail_header_amount">
              {formatCurrency(group.total,2)}<span>{itinerary.CurrencyCode}</span>
            </span>
          </div>
          <div class="detail_content">
              Invoice
          </div>
        </div>
      </div>
  {:else}
    {#each group.payments as payment, i}
      {#if payment.sectors}
        <div class="payment_details">
          <div class="detail">
            {#each payment.sectors as sectorItem}
              <div class="detail_header">
                <span class="detail_header_titles">
                  <span>{sectorItem.title}</span>
                </span>
                <span class="detail_header_amount">
                  {formatCurrency(sectorItem.total,2)}<span>{itinerary.CurrencyCode}</span>
                </span>
              </div>
            {/each}
            <div class="detail_content">
              {#if payment.Card && payment.Card.PaymentType === 'Card'}
                {payment.Card.CardDisplayName || payment.Card.Holder}<br/>
                {paymentCardName(payment.Card.Type.Code)} - {payment.Card.Number}
              {:else}
                Invoice
              {/if}
            </div>
          </div>
        </div>
      {/if}
    {/each}
  {/if}
</div>
{/if}

<style>
  .payment {
    border-radius: 4px;
    background-color: #F3F6FC;
    margin-bottom: 8px;
  }

  .payment_header {
    border-bottom: 1px solid #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 15px;
  }

  .payment_type {
    text-transform: capitalize;
    color: #2C3E50;
    font-size: 14px;
    letter-spacing: 0.31px;
    line-height: 16px;
  }

  .payment_amount {
    color: #2C3E50;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.29px;
  }

  .payment_amount span {
    font-size: 10px;
  }

  .payment_amount.taxi {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .payment_details {
    padding: 20px 15px;
    border-bottom: 1px solid #fff;
  }

  .payment_details:last-child {
    border-bottom: none;
  }

  .detail_header {
    display: flex;
    justify-content: space-between;
    color: #A2AAB2;
    font-size: 14px;
    letter-spacing: 0.31px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .detail_header_titles {
    display: flex;
    flex-direction: column;
  }

  .detail_header_amount {
    font-size: 12px;
  }

  .detail_header_amount span {
    font-size: 10px;
  }

  .detail_content {
    color: #2C3E50;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.31px;
    line-height: 16px;
  }
</style>
