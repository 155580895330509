<script>
    import ResultsItem from './_ResultsItem.svelte';

    import { taxiSelected, taxiSearchResults } from '../../store/index';
</script>

{#if $taxiSearchResults && $taxiSearchResults.length > 0}
    <div class="search-results" class:no-bottom-radius={$taxiSelected}>
        {#each $taxiSearchResults as item}
            <ResultsItem {item} searchMode />
        {/each}
    </div>
{:else}
    <div class="search-results no-results">
        No taxis are available for this selection. Please try again or contact
        your consultant.
    </div>
{/if}

<style>
    @media (min-width: 928px) {
        .search-results {
            border-radius: 4px;
            background: #fff;
            width: 928px;
            padding: 32px 32px 0;
        }

        .search-results.no-bottom-radius {
            border-radius: 4px 4px 0 0;
        }

        .search-results.no-results {
          padding-bottom: 32px;
        }
    }
</style>
