<script>
  import Spinner from '@ctm/ui/Spinner';
  export let white = false;
</script>

<div class="flexSpinner">
  <div class="spinner" class:white>
    <Spinner></Spinner>
  </div>
</div>

<style>
  .flexSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
  }

  .spinner {
    color: #38ce72;
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }

  .spinner.white {
    color: #fff;
  }
</style>
