<script>
    import formatCurrency from '../../util/currency';
    import { changeBookingsItinerary } from '../../store/index.js';
    export let flightsTotal = null;
    export let hotelsTotal = null;
    export let additionalFeesTotal;
    export let serviceFeesTotal;
    export let car = null;
    export let TBD = false;
    export let failed = false;
</script>

{#if car || TBD}
    <div class="totalsTable">
        <div class="totalsTable_row">
          <span class="totalsTable_label">Difference</span>
          <span class="totalsTable_amount">
            {#if TBD}
              TBD
            {:else if failed}
              &nbsp;
            {:else}
              {formatCurrency(car.AmountChangeDifference, 2)}
              <em>{car.CurrencyCode}</em>
            {/if}
          </span>
        </div>
    </div>
{:else if hotelsTotal != null}
  <div class="totalsTable">
    <div class="totalsTable_row">
        <span class="totalsTable_label">Hotels</span>
        <span class="totalsTable_amount">
            {formatCurrency(hotelsTotal, 2)}<em
                >{$changeBookingsItinerary.CurrencyCode}</em>
        </span>
    </div>
  </div>
{:else}
    <div class="totalsTable">
        <div class="totalsTable_row">
            <span class="totalsTable_label">Flights</span>
            <span class="totalsTable_amount">
                {formatCurrency(flightsTotal, 2)}<em
                    >{$changeBookingsItinerary.CurrencyCode}</em>
            </span>
        </div>
        <div class="totalsTable_row">
            <span class="totalsTable_label">Additional Fees</span>
            <span class="totalsTable_amount">
                {formatCurrency(additionalFeesTotal, 2)}<em
                    >{$changeBookingsItinerary.CurrencyCode}</em>
            </span>
        </div>
        {#if serviceFeesTotal}
            <div class="totalsTable_row">
                <span class="totalsTable_label"
                    >CTM Service Fee <sup>1</sup></span>
                <span class="totalsTable_amount">
                    {formatCurrency(serviceFeesTotal, 2)}<em
                        >{$changeBookingsItinerary.CurrencyCode}</em>
                </span>
            </div>
        {/if}
    </div>
{/if}

<style>
    .totalsTable {   
        background-color: #f3f6fc;
        border-radius: 4px;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
    }

    .totalsTable_row {
        height: 48px;
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #141b22;
    }

    .totalsTable_label {
        padding-right: 20px;
    }

    .totalsTable_label sup {
        font-size: 8px;
    }

    .totalsTable_amount {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #141b22;
    }

    .totalsTable_amount em {
        font-weight: 400;
        font-size: 8px;
        line-height: 21px;
        color: #141b22;
        font-style: normal;
        padding-left: 2px;
    }

</style>
