<script>
  import LeftChevron from "./Icons/LeftChevron.svelte";

  import {
    airportTransferShowConfirmation,
    airportTransferError,
  } from "../../store/index.js";

  import { getSettings } from "../../http/setting";

  let settings;

  $: {
    if ($airportTransferError && !settings) {
      getSettings().then((res) => {
        settings = res.data;
      });
    }
  }

  $: showEmail =
    settings && settings.DebtorSupport && settings.DebtorSupport.Email;
</script>

<style>
  .header {
    margin: 64px 0 0 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px 15px 32px;
    align-items: center;
  }

  .back {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 32px;
    color: #1f78d1;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .back span {
    padding: 0 0 0 8px;
  }

  .title {
    color: #212b36;
    font-size: 30px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    margin: 0 auto 16px;
    max-width: 620px;
  }

  .intro {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    max-width: 620px;
  }

  .intro a {
    font-weight: bold;
    color: #606b74;
  }

  @media (min-width: 768px) {
    .container {
      background: #fff;
      margin: 64px 0 0 0;
    }

    .header {
      max-width: 1155px;
      margin: 0 auto;
      padding: 16px 32px 34px;
    }

    .back {
      margin: 0 0 36px;
      cursor: pointer;
    }

    .title {
      text-align: left;
      font-size: 36px;
      line-height: 42px;
      width: 100%;
      color: #23313e;
      font-weight: 600;
      text-align: center;
    }

    .intro {
      text-align: left;
      color: #606b74;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      width: 100%;
      max-width: 620px;
      text-align: center;
      margin: 0 auto;
    }
  }
</style>

<div class="container">
  <div class="header">
    <div class="back" on:click={() => history.back()}>
      <LeftChevron />
      <span>Back</span>
    </div>

    {#if !$airportTransferShowConfirmation}
      <h2 class="title">
        Search and book airport transfers for your travellers
      </h2>

      <p class="intro">
        Choose from a shared ride or exclusive use Super Shuttle buses, for one
        traveller or an entire group. Simply specify when and where you require
        the Super Shuttle bus to pick you up or drop you off.
      </p>
    {:else if $airportTransferError}
      <h2 class="title">
        Sorry, your airport transfer(s) could not be confirmed
      </h2>
      <p class="intro">
        Your dedicated CTM consultant
        {#if showEmail}
          <a href={'mailto:' + settings.DebtorSupport.Email}>
            {settings.DebtorSupport.Email}
          </a>
        {/if}
        has been notified and will be in touch shortly to complete this booking.
        Do not attempt to rebook as this could result in duplicate bookings
        and/or charges.
      </p>
    {:else}
      <h2 class="title">
        Congratulations, your transfer has been successfully booked
      </h2>
      <p class="intro">
        You can check the details of the transfer at any time from the Manage
        Bookings page. If you need to make amendents or cancel the transfer
        booking, please contact your CTM consultant.
      </p>
    {/if}

  </div>
</div>
