import axios from 'axios';
import { apiConfig } from '../store/index.js';
import { getHeaders } from './headers';

export async function getItineraryByPassengerId(itineraryId, passengerId) {
  const { url, version } = apiConfig('itineraryPassenger', { itineraryId, passengerId });

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}

export async function getItineraryChangeByPassengerId(itineraryId, passengerId) {
  const { url, version } = apiConfig('itineraryPassengerChange', { itineraryId, passengerId });

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}
