<script>
  import Button from "@ctm/ui/Button";
  import { Date as DateIcon } from "@ctm/ui/Icons";

  import downloadjs from "downloadjs";
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  dayjs.extend(utc);

  import { formatDate } from "../../util/date.js";
  import { track, trackingEvents } from "../../store/index.js";

  export let itinerary = undefined;

  $: title = itinerary && itinerary.Title;
  $: titleKebab = title.replaceAll(/[/ ]/g, '-');

  const vehicles = {
    C1: 'White Standard Sedan',
    M1: 'Black Luxury Mercedes',
    E1: 'Switch Electric Cabs',
    V1: 'White Standard Van'
  }

  function createICSfiles() {
    let events = [];

    function createHotelDate({date, humanReadable, isCheckOut}) {
      if (humanReadable) {
        return formatDate(dayjs(date, 'YYYY-MM-DDTHH:mm'), 'ddd, DD MMM YYYY');
      } else {
        let hours = isCheckOut ? 10 : 12;
        return formatDate(dayjs(date, 'YYYY-MM-DDTHH:mm').add(hours, 'hour'), 'YYYYMMDDTHHmmss');
      }
    }

    function createLocalTime(time) {
      let dateTime = dayjs.utc(time).local().format();
      return formatDate(dateTime, "YYYYMMDDTHHmmss");
    }

    function createTaxiTime(dateTime) {
      return dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYYMMDDTHHmmss');
    }

    itinerary.Flights.forEach((sector) => {
      events.push({
        type: "Flight",
        title: `${title}, Flight: ${sector.Carrier}${sector.FlightNumber} ${sector.Departure.City} (${sector.Departure.Code}) to ${sector.Arrival.City} (${sector.Arrival.Code})`,
        dateStart: `${createLocalTime(sector.Departure.Date)}`,
        timeZoneStart: `${sector.Departure.Country}/${sector.Departure.City}`,
        dateEnd: `${createLocalTime(sector.Arrival.Date)}`,
        timeZoneEnd: `${sector.Arrival.Country}/${sector.Arrival.City}`,
        description: `${title}, Flight: ${sector.Carrier}${sector.FlightNumber} ${
          sector.Departure.City
        } (${sector.Departure.Code}) ${formatDate(
          sector.Departure.Date,
          "hh:mma - ddd, DD MMMM YYYY"
        )} to ${sector.Arrival.City} (${sector.Arrival.Code}) ${formatDate(
          sector.Arrival.Date,
          "hh:mma - ddd, DD MMMM YYYY"
        )} PNR: ${sector.PassengerPnr}, Status: ${sector.VendorStatus}`,
        showAsBusy: true,
      });
    });

    itinerary.Hotels.forEach((sector) => {
      events.push({
        type: "Hotel",
        title: `${title}, Hotel: ${sector.HotelName}, ${sector.Location.City}`,
        dateStart: `${createHotelDate({date: sector.DateFrom, humanReadable: false, isCheckOut: false})}`,
        timeZoneStart: `${sector.Location.Country}/${sector.Location.City}`,
        dateEnd: `${createHotelDate({date: sector.DateTo, humanReadable: false, isCheckOut: true})}`,
        timeZoneEnd: `${sector.Location.Country}/${sector.Location.City}`,
        description: `${title}, Supplier Confirmation Code: ${
          sector.ConfirmationCode || "None"
        }. Check in: ${createHotelDate({date: sector.DateFrom, humanReadable: true, isCheckOut: false})}. Check out: ${createHotelDate({date: sector.DateTo, humanReadable: true, isCheckOut: true})}`,
        showAsBusy: false,
      });
    });

    itinerary.Cars.forEach((sector) => {
      events.push({
        type: "Car",
        title: `${title}, Car Hire: ${sector.Supplier.Title}`,
        dateStart: `${createLocalTime(sector.PickUp.Date)}`,
        timeZoneStart: `${sector.PickUp.Country}/${sector.PickUp.City}`,
        dateEnd: `${createLocalTime(sector.DropOff.Date)}`,
        timeZoneEnd: `${sector.DropOff.Country}/${sector.DropOff.City}`,
        description: `${title}, Supplier Confirmation Code: ${
          sector.ConfirmationCode || "Pending"
        }, Pick Up location: ${sector.PickUp.Location}, Drop Off Location: ${
          sector.DropOff.Location
        }, Car Type: ${sector.Vehicle.Title}`,
        showAsBusy: false,
      });
    });

    itinerary.Rails.forEach((sector) => {
      events.push({
        type: "Rail",
        title: `${title}, Rail: ${sector.SupplierName} #${sector.RailNumber} ${sector.Departure.Code} to ${sector.Arrival.Code}`,
        timeZone: `${sector.Country}/${sector.City}`,
        dateStart: `${createLocalTime(sector.Departure.Date)}`,
        timeZoneStart: `${sector.Departure.Country}/${sector.Departure.City}`,
        dateEnd: `${createLocalTime(sector.Arrival.Date)}`,
        timeZoneEnd: `${sector.Departure.Country}/${sector.Departure.City}`,
        description: `${title}, PNR: ${sector.PassengerPnr}, Departure: ${sector.Departure.Location}, Arrival: ${sector.Arrival.Location}`,
        showAsBusy: false,
      });
    });

    itinerary.Shuttles.forEach((sector) => {
      let timeOffsetHack = '+12:00';
      if (dayjs(sector.PickupTime).isAfter(dayjs('2020-09-27T02:00:00'))) {
        timeOffsetHack = '+13:00';
      }

      events.push({
        type: "Supershuttle",
        title: `${title}, Shuttle Bus: Supershuttle ${sector.PickupAddress.Location}: ${sector.DropoffAddress.Location}`,
        timeZone: `New Zealand/Auckland`,
        dateStart: `${createLocalTime(sector.PickupTime+timeOffsetHack)}`,
        timeZone: `New Zealand/Auckland`,
        dateEnd: `${createLocalTime(sector.DropoffTime+timeOffsetHack)}`,
        timeZoneEnd: `New Zealand/Auckland`,
        description: `${title}, Date: ${formatDate(
          sector.PickupTime+timeOffsetHack,
          "ddd, DD MMMM YYYY"
        )}, Pick up Time: ${formatDate(
          sector.PickupTime+timeOffsetHack,
          "hh:mma"
        )}, Number of Passengers: ${sector.Pax}, PNR: ${
          itinerary.Pnr
        }${sector.ConfirmationCode ? ', Supplier Confirmation Number: ' + sector.ConfirmationCode : ''}`,
        showAsBusy: false,
      });
    });

    itinerary.Taxis.forEach((sector) => {
      events.push({
        type: "Taxi",
        title: `${title} - Taxi: Corporate Cabs`,
        timeZone: `New Zealand/Auckland`,
        dateStart: `${createTaxiTime(sector.PickUpTime)}`,
        timeZone: `New Zealand/Auckland`,
        dateEnd: `${createTaxiTime(sector.PickUpTime)}`,
        timeZoneEnd: `New Zealand/Auckland`,
        description: `${title},
        Supplier Confirmation Code: ${sector.SupplierIdentifier},
        Pick Up Location: ${sector.PickupAddress.Title}
        ${sector.PickupAddress.FullAddress},
        Drop Off Location: ${sector.DropOffAddress.Title}
        ${sector.DropOffAddress.FullAddress},
        Vehicle Type: ${vehicles[sector.VehicleType] || 'Any Vehicle'}`,
        showAsBusy: false,
      });
    });

    events.forEach((event) => {
      const fileName = `Lightning_${itinerary.Pnr}_${titleKebab}_${event.type}.ics`;
      downloadjs(getIcsCalendar(event), fileName, "application/octet-stream");
    });
  }

  function getIcsCalendar(event) {
    const elements = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      "CLASS:PUBLIC",
      "DTSTART;VALUE=DATE:" + event.dateStart,
      "DTEND;VALUE=DATE:" + event.dateEnd,
      // `DTSTART;TZID="${event.timeZoneStart}";VALUE=DATE:${event.dateStart}`,  hold off on timezones not supported on Outlook 2016
      // `DTEND;TZID="${event.timeZoneEnd}";VALUE=DATE:${ event.dateEnd}`, hold off on timezones not supported on Outlook 2016
      "SUMMARY:" + event.title,
      "DESCRIPTION:" + event.description,
      "TRANSP:" + (event.showAsBusy ? "OPAQUE" : "TRANSPARENT"),
      "END:VEVENT",
      "END:VCALENDAR",
    ];

    return elements.join("\n");
  }

  function handleClick() {
    createICSfiles();
    track(trackingEvents.BOOKING_ADD_TO_CALENDAR_CLICKED);
  }
</script>

<Button
  on:click={handleClick}
  type="primary"
  Icon={DateIcon}
  size="default"
  iconPosition="left">
  Add to calendar
</Button>
