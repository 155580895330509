<script>
    import Line from "./Line.svelte";
    import Tick from "./Tick.svelte";

    export let name = '';
    export let active = false;
    export let complete = false;
    export let final = false;
</script>

<div class="step-container" class:confirm={final && active}>
    <div class="step">
        <div class="stepCircle" class:active class:complete>
            {#if complete}
                <Tick />
            {/if}

            {#if !complete && active}
                <bullet />
            {/if}
        </div>
        <span class="stepName" class:activeName={active} class:completeName={complete}>
            {name}
        </span>
    </div>
    {#if !final}
        <div class="bar">
            <Line solid={complete} />
        </div>
    {/if}
</div>

<style>
    :root {
        --accent-color: #156af4;
    }

    .step-container {
        display: flex;
        flex-direction: column;
    }
    .stepName {
        display: block;
        height: 16px;
        color: var(--step-name-color, #a6aaaf);
        font-size: 16px;
        line-height: 16px;
    }

    .activeName,
    .completeName {
        color: var(--accent-color);
    }

    .step {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .stepCircle {
        position: relative;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        border: var(--step-circle-border, 2px solid #d8dbe0);
        background-color: #ffffff;
        z-index: 1;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .complete {
        border-color: var(--accent-color);
        background-color: var(--accent-color);
        transition: none;
    }

    .active {
        border: 2px solid;
        border-color: var(--accent-color);
    }
    
    .bar {
        width: 24px;
        display: flex;
        justify-content: center;
        margin: 4px 0;
    }

    bullet {
        width: 12px;
        height: 12px;
        background: var(--accent-color);
        display: block;
        border-radius: 50%;
    }

    .confirm {
        --accent-color: #f1c21b;
    }
</style>
