<script>
    import { getChangeBookingSession, flightsForChange, hotelsForChange, changeBookingsItinerary } from '../../store';
    import SectorSummary from '../ChangePayment/_SectorSummary.svelte';
    
    export let itinerary = null;
    export let changed = null;

    const changeBooking = getChangeBookingSession();
    
    let flights = [];
    if ($flightsForChange.length) flights = itinerary.Flights.filter(flight => flight.Parent && changeBooking.flights.find(f => f.data.Identifier === flight.Parent.Identifier));

    let hotelIds = [];
    let hotels = [];
    
    if ($hotelsForChange.length) {
      hotelIds = JSON.parse(sessionStorage.getItem('change:HotelIds'));
      hotels = itinerary.Hotels.filter(hotel => hotel.Status === 'Booked' && hotelIds.includes(hotel.HotelId)).map(hotel => {
        hotel.original = changeBooking.hotels.find(h => h.data.HotelId === hotel.HotelId).data;
        return hotel;
      });
    }

    const changedCars = sessionStorage.getItem('change:cars');
    const changedCarsData = changedCars && JSON.parse(changedCars);
    const cars = changedCarsData && itinerary.Cars.map(car => {
      const changedCar = changedCarsData[car.Identifier];
      if (changedCar) {
        car.original = changedCar.sector;
      }
      
      return car;
    });
</script>

<div class="summaries">
{#if itinerary}
  {#if flights && flights.length > 0}
    <SectorSummary {flights} noBorder showPrice />
  {/if}

  {#if hotels && hotels.length > 0}
    <SectorSummary {hotels} noBorder showPrice {changed} />
  {/if}

  {#if cars}
    <SectorSummary {cars} noBorder showPrice {changed} />
  {/if}
{/if}
</div>

<style>
  .summaries {
    display: flex;
    flex-direction: column;
  }
</style>
