<script>
  import { beforeUpdate, onMount } from 'svelte';

  import Modal from '@ctm/ui/Modal';
  import Select from 'svelte-select/src/Select.svelte';
  import { getItineraryByPassengerId } from '../../http/itineraryPassenger';
  import { getApprovers } from '../../http/itineraryApprovers';
  import { updateApprover } from '../../http/itineraryPassengerApprovers';

  export let params;
  export let complete = () => { };
  export let cancel = () => { };
  export let targetElem = undefined;

  let approverSelections = [];
  let isWaiting = false;
  let filteredApprovers = [];
  let selectionFields = [];
  let isLoading = true;
  let inputStyles = `padding-left: 43px`;
  let containerStyles = `padding-left: 43px`;
  let approverSelectionsCopy = [];
  let approversMaster = [];


  export let isOkDisabled;
  $: {
    isOkDisabled = approverSelections.findIndex(approver => approver === undefined) > -1
  }

  onMount(() => {
    const { passengerItinerary } = params;
    const { ItineraryIdentifier } = passengerItinerary;
    selectionFields = [];
    approverSelections = [];
    let disabledApprovers = [];
    passengerItinerary.Configurations.Approvers.forEach(approver => {
      const isDisabled = approver.ApprovalStatus !== 'Pending';
      selectionFields.push({ isDisabled });
      approverSelections.push({
        label: approver.FullName,
        value: approver.DebtorApproverId,
      });
      if (isDisabled) disabledApprovers.push(approver)
    });

    selectionFields = selectionFields;
    approverSelections = approverSelections;
    approverSelectionsCopy = [].concat(approverSelections);
    disabledApprovers = disabledApprovers;
    filteredApprovers = Array.from({ length: passengerItinerary.Configurations.Approvers.length }, () => []);

    getApprovers(ItineraryIdentifier).then(res => {
      const { data } = res;

      const filterDisabledApprovers = data.filter(approver => {
        return !disabledApprovers.find(disabledApprover => disabledApprover.DebtorApproverId === approver.DebtorApproverId)
      });

      const _filteredApprovers = [];
      approverSelections.forEach((approver, i) => {
        const paxIdentifier = passengerItinerary.Identifier;
        const filterApprovers = filterDisabledApprovers.filter(a => {
          const paxIndex = a.Passengers.findIndex(pax => pax.PassengerIdentifier === paxIdentifier);
          if (paxIndex > -1) {
            return a.Passengers[paxIndex].Tiers.includes(i + 1);
          }
        });
        _filteredApprovers.push(filterApprovers.map(approver => {
          return {
            label: approver.FullName,
            value: approver.DebtorApproverId
          }
        }))
      });

      approversMaster = data, isLoading = false;
      filteredApprovers = _filteredApprovers;
      
    }).catch(err => {
      console.warn('Error: ', err);
    });
  });


  function onModalOk() {
    const approvers = params.passengerItinerary.Configurations.Approvers.sort((a, b) => a.Tier - b.Tier);
    const approversToUpdate = [];

    approverSelections.forEach((selection, i) => {
      if (selection.value !== approvers[i].DebtorApproverId) {
        selection.Identifier = approvers[i].Identifier;
        selection.Tier = approvers[i].Tier;
        approversToUpdate.push(selection);
      }
    });

    const reallocateParams = [];

    approversToUpdate.forEach(approver => {
      const newApprover = approversMaster.find(a => approver.value === a.DebtorApproverId);
      newApprover.Tier = approver.Tier;
      reallocateParams.push({
        itineraryId: params.passengerItinerary.ItineraryIdentifier,
        passengerId: params.passengerItinerary.Identifier,
        approverId: approver.Identifier,
        newApprover
      })
    });

    const promises = reallocateParams.map(param => updateApprover(param));

    isWaiting = true;
    Promise.all(promises).then(() => {
      getItineraryByPassengerId(params.passengerItinerary.ItineraryIdentifier, params.passengerItinerary.Identifier).then((res) => {
        complete(res);
      });
    });
  }

  function onModalCancel() {
    cancel();
  }

  function onButtonClick() {
    complete();
  }

  function checkForDuplicateApprovers(approverIndex) {
    const newDebtorApprover = approverSelections[approverIndex];
    if (!newDebtorApprover) return;

    const newDebtorApproverId = newDebtorApprover.value;
    const findDupIndex = approverSelections.findIndex((approver, i) => {
      if (!approver) return false;
      return approverIndex !== i && approver.value === newDebtorApproverId
    });
    if (findDupIndex > -1) {
      approverSelections[findDupIndex] = undefined;
    }
    approverSelections = approverSelections, approverSelectionsCopy = [].concat(approverSelections);
  }

  let previous = false;
  beforeUpdate(() => {
    if (previous && approverSelections) {
      const newlySelectedApproverIndex = approverSelections.findIndex((approver, i) => {
        return JSON.stringify(approver) !== JSON.stringify(approverSelectionsCopy[i])
      });
      if (newlySelectedApproverIndex > -1) checkForDuplicateApprovers(newlySelectedApproverIndex);
    }
    previous = true;
  });

  function handleSelect(event, i) {
    approverSelections[i] = event ? event.detail : undefined;
  }
</script>

<Modal isClosable="{false}" hasCancelButton="{true}" {isWaiting} on:ok="{onModalOk}" okText="Submit"
  title="Re-allocate Approval" on:cancel="{onModalCancel}" width="95%" maxWidth="950px" isOkDisabled="{isOkDisabled}">

  <p class="intro">You can reallocate any current or upcoming approvers. An email will be sent to the new approver
    overriding the
    existing approver and preventing them from actioning this booking.</p>


  {#if filteredApprovers.length > 0}
  <div class="approvers">
    {#each selectionFields as field, i}
    <div class="approvers_select">
      <div class="approverIcon {isLoading || field.isDisabled ? 'disabled' : ''}">
        {i+1}
      </div>
      <Select items="{ filteredApprovers[i] }"
              isDisabled="{isLoading || field.isDisabled}"
              value="{approverSelections[i]}"
              on:select="{(event) => handleSelect(event, i)}"
              on:clear="{() => handleSelect(undefined, i)}"
              {inputStyles} {containerStyles}
      ></Select>
    </div>
    {/each}
  </div>
  {/if}


</Modal>

<style>
  .intro {
    margin-bottom: 20px;
  }

  .approvers_select {
    position: relative;
    margin-bottom: 20px;
  }

  .approverIcon {
    height: 24px;
    width: 24px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.15px;
    line-height: 24px;
    text-align: center;
    background: #51CE6C;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .approverIcon.disabled {
    background: #B2B8BF;
  }

  @media (min-width: 768px) {
    .intro {
      margin-bottom: 60px;
    }

    .approvers {
      display: flex;
      flex-wrap: wrap;
    }

    .approvers_select {
      flex-grow: 1;
      width: 33%;
      padding: 0 20px 0 0;
      max-width: 290px;
    }
  }
</style>
