<script>
    export let solid = true;
</script>

{#if solid}
    <svg
        width="2"
        height="42"
        viewBox="0 0 2 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <line
            x1="1"
            y1="1"
            x2="1"
            y2="41"
            stroke="#156AF4"
            stroke-width="2"
            stroke-linecap="round" />
    </svg>
{:else}
    <svg
        width="2"
        height="42"
        viewBox="0 0 2 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <line
            x1="1"
            y1="1"
            x2="1"
            y2="3"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
        <line
            x1="1"
            y1="39"
            x2="1"
            y2="41"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
        <line
            x1="1"
            y1="7"
            x2="1"
            y2="11"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
        <line
            x1="1"
            y1="15"
            x2="1"
            y2="19"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
        <line
            x1="1"
            y1="23"
            x2="1"
            y2="27"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
        <line
            x1="1"
            y1="31"
            x2="1"
            y2="35"
            stroke="#C9D1DB"
            stroke-width="2"
            stroke-linecap="round" />
    </svg>
{/if}
