<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import dayjs from 'dayjs';
  import utc from "dayjs/plugin/utc";
  import BookingDetails from '../ManageBookings/BookingDetails.svelte';
  import { getItineraryByPNR } from '../../http/itineraries';
  import {
    manageBookingsItinerary,
    hasManageBookingsItinerary,
    settings
  } from '../../store/index.js';

  dayjs.extend(utc);   
  export let pnr = undefined;
  const dispatch = createEventDispatcher();
  let bookingDetailsConfig = undefined;
  let isCancelling = false;

  function getItinerary() {
    isCancelling = true;
    const _manageBookingsItinerary = hasManageBookingsItinerary(pnr);

    getItineraryByPNR(pnr)
      .then((response) => {
        response.data.Passengers[0].Flights = response.data.Passengers[0].Flights.map(flight => { 
          flight.has_departed = dayjs(flight.Departure.Date).isBefore(dayjs().utc()); 
          return flight
        });

        $manageBookingsItinerary = response.data;
      })
      .catch(() => {
        $manageBookingsItinerary = undefined
      }).finally(() => {
        isCancelling = false;
      })
  }

  function onApprovalUpdated() {
    $manageBookingsItinerary = undefined;
    getItinerary();
  }

  onMount(() => {
    $manageBookingsItinerary = undefined;
    
    bookingDetailsConfig = {
      orderNumberLabel: $settings && $settings.OrderNumber.DisplayName,
      agencyReasonCodesLabel: $settings && $settings.AgencyReasonCodes.DisplayName
    };

    getItinerary();
  });

  $: cancelled = $manageBookingsItinerary && $manageBookingsItinerary.Status === 'Cancelled';

  $: if (cancelled) {
    sessionStorage.setItem('pnr-cancelled', JSON.stringify({ pnr, date: $manageBookingsItinerary.FromDate }));
    dispatch('goToManageBookings');
  }
</script>

{#if !cancelled}
  <BookingDetails isPta="{false}" itinerary="{ $manageBookingsItinerary }" settings="{ bookingDetailsConfig }" {isCancelling}
    on:goToManageBookings on:goToSeatmaps on:approvalUpdated="{onApprovalUpdated}" on:refreshItinerary={getItinerary}>
  </BookingDetails>
{/if}
