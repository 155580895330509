<script>
  import { Train } from '@ctm/ui/Icons';
  import SectorStatusRow from './SectorStatusRow.svelte';

  export let sector;
</script>

<style>
  .icon {
    width: 100%;
    height: 100%;
  }
</style>

<SectorStatusRow iconType="train" status="{ sector.Status }">
  <div slot="icon" class="icon"><Train /></div>
  <div slot="title">
    { sector.Departure.City } ({ sector.Departure.Code }) to { sector.Arrival.City } ({ sector.Arrival.Code })
  </div>
  <div slot="desc">{ #if sector.CarrierName}{ sector.CarrierName } { /if }{ sector.Carrier }{ sector.RailNumber }</div>
</SectorStatusRow>
