<script>
  import { beforeUpdate, createEventDispatcher, onDestroy, onMount, tick } from 'svelte';

  const dispatch = createEventDispatcher();

  import Avatar from '@ctm/ui/Avatar';
  import Button from '@ctm/ui/Button';
  import { Alert, Group, Person, Back } from '@ctm/ui/Icons';
  import Card from '@ctm/ui/Card';

  import getInitials from '../../util/getInitials';

  import ActionBar from '../ActionBar/ActionBar.svelte';
  import SwitchNav from '../SwitchNav/SwitchNav.svelte';
  import SelectInput from '../SelectInput/SelectInput.svelte';
  import TextInput from '../TextInput/TextInput.svelte';

  export let itinerary = undefined;
  export let companyReferences = undefined;

  const pattern = /^[A-Za-z0-9*\-\/.& ]*$/i;
  let formHasErrors = false;
  let companyRefViewMode = 'all';
  let formDataAll = [];
  let formDataPax = {};
  let formSubmitted = false;
  let validFields = [];
  let dontCopy = false;

  function handleSelect(event, i, id) {
    if (id) {
      formDataPax[id][i].value = event.detail;
    } else {
      formDataAll[i].value = event.detail;
    }
  }

  function getProps(ref) {
    return {
      items: ref.Options,
      isVirtualList: ref.Options.length > 500,
      maxLength: 50,
      placeholder: ''
    }
  };

  function handleValidation() {
    let isValid = true;
    let _validFields = {};
    passengers.forEach(pax => {
      _validFields[pax.Identifier] = [];
    });

    if (companyRefViewMode === 'all') {
      formDataAll = formDataAll.map(field => {
        let fieldIsValid = true;

        if (field.show && field.IsMandatory) {
          const hasOptions = field.Options.length > 0;
          if (hasOptions) fieldIsValid = !!field.value;
          if (!hasOptions) fieldIsValid = field.value.length > 0;
          field.isValid = fieldIsValid;
        }
        if (fieldIsValid && field.show && field.value) {
          passengers.forEach(pax => {
            _validFields[pax.Identifier].push(field)
          })
        }

        if (!fieldIsValid && isValid) {
          isValid = false;
        }


        return field;
      })

      formHasErrors = !isValid;
      formDataAll = formDataAll;
      validFields = _validFields;

    } else {
      passengers.forEach(pax => {
        formDataPax[pax.Identifier] = formDataPax[pax.Identifier].map(field => {
          let fieldIsValid = true;

          if (field.show && field.IsMandatory) {
            const hasOptions = field.Options.length > 0;
            if (hasOptions) fieldIsValid = !!field.value;
            if (!hasOptions) fieldIsValid = field.value.length > 0;
            field.isValid = fieldIsValid;
          }

          if (!fieldIsValid && isValid) {
            isValid = false;
          }

          if (fieldIsValid && field.show && field.value) _validFields[pax.Identifier].push(field)
          return field;
        })
      })

      formHasErrors = !isValid;
      formDataPax = formDataPax;
      validFields = _validFields;
    }
  }

  function formDataAllToFormDataPax() {
    const _formDataPax = JSON.parse(JSON.stringify(formDataPax));

    formDataAll.forEach(field => {
      passengers.forEach(pax => {
        const fieldIndex = _formDataPax[pax.Identifier].findIndex(paxField => {
          if (field.Parent) {
            return field.Code === paxField.Code && field.Parent === paxField.Parent && field.Match === paxField.Match
          } else {
            return field.Code === paxField.Code
          }
        });
        _formDataPax[pax.Identifier][fieldIndex] = buildFormElem(field);
      })
    });

    return _formDataPax;
  }

  function handleShow(ref, formData) {
    if (!ref.Parent) return true;
    const parent = formData.find(f => f.Code === ref.Parent);
    if (parent && parent.value && parent.value.value === ref.Match) return true;
  }

  function populateFormData(copyData) {
    if (copyData) {
      formDataPax = copyData;
    }

    formDataAll = formDataAll.map(field => {
      field.show = handleShow(field, formDataAll);
      return field;
    })

    passengers.forEach(pax => {
      formDataPax[pax.Identifier] = formDataPax[pax.Identifier].map(field => {
        field.show = handleShow(field, formDataPax[pax.Identifier]);
        return field;
      })
    })

    formDataAll = formDataAll;
    formDataPax = formDataPax;
  }

  function buildFormElem(ref) {
    let value = ref.Options.length > 0 ? undefined : ''
    if (ref.value) {
      value = ref.value
    }

    return {
      props: getProps(ref),
      Code: ref.Code,
      Options: ref.Options,
      Title: ref.Title,
      Parent: ref.Parent,
      Match: ref.Match,
      IsMandatory: ref.IsMandatory,
      isValid: true,
      show: true,
      value
    }
  }

  function buildForm() {
    const fields = [];
    const allSame = {};
    const paxData = {};
    let showPaxTab = false;

    passengers.forEach(pax => {
      paxData[pax.Identifier] = {};
      pax.Configurations.CompanyReferences.forEach(ref => {
        const match = ref.Match || '';
        const parent = ref.Parent || '';
        const refMatch = ref.Code + '*~*' + match + '*~*' + parent;

        if (!allSame[refMatch]) {
          allSame[refMatch] = [];
        }
        allSame[refMatch].push(ref.Value);
        paxData[pax.Identifier][refMatch] = ref.Value;
      })
    });

    Object.keys(allSame).forEach(code => {
      if (allSame[code].length !== passengers.length || !allSame[code].every((val, i, arr) => val === arr[0])) {
        delete allSame[code];
        showPaxTab = true;
      }
    });

    companyReferences.forEach(ref => {
      ref.value = ref.Options.length > 0 ? undefined : '';

      fields.push(buildFormElem(ref));
      ref.Children.forEach(childRef => {
        childRef.Parent = ref.Code;
        fields.push(buildFormElem(childRef))
      })
    });

    passengers.forEach(pax => {
      const paxFields = [];
      fields.forEach(field => {

        paxFields.push(buildFormElem(field));
      })
      formDataPax[pax.Identifier] = paxFields;
    });

    Object.keys(allSame).forEach(code => {
      const codeSplit = code.split('*~*');
      const _code = codeSplit[0];
      const _match = codeSplit[1] || undefined;
      const _parent = codeSplit[2] || undefined;

      const field = fields.find(f => {
        return _code === f.Code && _match === f.Match && _parent === f.Parent
      });

      const value = allSame[code][0];
      if (field && value) field.value = field.Options.length > 0 ? { value, label: value } : value
    })

    Object.keys(paxData).forEach(paxId => {
      Object.keys(paxData[paxId]).forEach(code => {
        const codeSplit = code.split('*~*');
        const _code = codeSplit[0];
        const _match = codeSplit[1] || undefined;
        const _parent = codeSplit[2] || undefined;

        const field = formDataPax[paxId].find(f => {
          return _code === f.Code && _match === f.Match && _parent === f.Parent
        });

        const value = paxData[paxId][code];
        if (field && value) field.value = field.Options.length > 0 ? { value, label: value } : value
      })
    })

    formDataAll = fields;
    formDataPax = formDataPax;
    dontCopy = showPaxTab;
    companyRefViewMode = showPaxTab ? 'individuals' : 'all';
  }

  function handleBack() {
    window.scrollTo(0, 0);
    dispatch('back');
  }

  function handleSwitch(event) {
    companyRefViewMode = event.detail === 'left' ? 'all' : 'individuals'
  }

  function handleSave() {
    formSubmitted = true;
    handleValidation();
    if (formHasErrors) return;

    itinerary.Passengers = itinerary.Passengers.map(pax => {
      pax.Configurations.CompanyReferences = validFields[pax.Identifier].map(field => {
        return {
          Code: field.Code,
          Value: field.value && field.value.value ? field.value.value : field.value,
          Title: field.Title,
          Parent: field.Parent,
          Match: field.Match
        }
      })

      return pax;
    });

    window.scrollTo(0, 0);
    dispatch('save', itinerary)
  }

  $: passengers = itinerary.Passengers;

  onMount(async () => {
    window.scrollTo(0, 0);
    document.body.className = document.body.className.replace('section-itinerary', '');
    buildForm();
    await tick();
    populateFormData();
    previous = true;
  });


  let previous = false;
  let prev_companyRefViewMode = undefined;

  beforeUpdate(() => {
    if (!previous) return;

    if (formSubmitted) {
      handleValidation();
    }

    if (companyRefViewMode !== prev_companyRefViewMode) {
      formSubmitted = false;
      formHasErrors = false;

      if (dontCopy) {
        dontCopy = false;
        return;
      }

      if (companyRefViewMode === 'individuals') {
        const copyData = formDataAllToFormDataPax();
        populateFormData(copyData);
      } else {
        populateFormData();
      }
    } else {
      populateFormData();
    }

    prev_companyRefViewMode = companyRefViewMode;
  });

  onDestroy(() => {
    document.body.className = 'section-itinerary';
  });
</script>

<div class="container">
  <ActionBar>
    <div class="actionBar_back">
      <Button on:click="{handleBack}" Icon={Back} type="link"></Button>
    </div>

    <Button on:click="{handleSave}" isDisabled="{false}" isWide>Save and close</Button>

  </ActionBar>

  <div class="intro">
    <div class="intro_content">

      {#if formHasErrors}
            <div class="errorMessages">
                <div class="errorMessages_content">
                    <span class="icon"><Alert /></span>
                    <span>Please provide missing details</span>
                </div>
            </div>
            {/if}

            <h3>Company References</h3>
            <p class="{ passengers.length === 1 ? 'noMargin' : ''}">You can assign a cost code or product code to all travellers for this itinerary. Alternatively you can
                select cost and product codes individually for each traveller.</p>

            {#if passengers.length > 1}
            <div class="switchNav">
                <SwitchNav on:select="{handleSwitch}" optionActive="{companyRefViewMode === 'all' ? 'left' : 'right'}">
                    <span slot="left">
                        <div class="switchNav_option">
                            <div class="switchNav_icon">
                                <Group />
                            </div>
                            <div class="switchNav_content">All travellers</div>
                        </div>
                    </span>
                    <span slot="right">
                        <div class="switchNav_option">
                            <div class="switchNav_icon">
                                <Person />
                            </div>
                            <div class="switchNav_content">Individuals</div>
                        </div>
                    </span>
                </SwitchNav>
            </div>
            {/if}
        </div>
    </div>

    <div class="main">
        <div class="main_content {formHasErrors ? 'formHasErrors' : ''}">
            {#if companyRefViewMode === 'all'} 
            <div class="card">
                <Card>
                    <div class="pax">
                        <div class="pax_header">
                            {#if passengers.length > 1}
                            <div class="avatar">
                                <Avatar alt="" textColour="#b0b7be" bgColour="#ebedef"></Avatar>
                                <div class="avatar_icon">
                                    <Group />
                                </div>
                            </div>    
                            <p>All travellers</p>
                            {:else}
                            <div class="avatar">
                                <Avatar alt="{ getInitials(passengers[0].Firstname, passengers[0].Lastname) }" textColour="#b0b7be" bgColour="#ebedef"></Avatar>
                            </div>
                            <p>{passengers[0].FullName}</p>
                            {/if}
                        </div>

                        <div class="pax_inputs">
                            {#each formDataAll as formField, i}
                                {#if formField.show}
                                <div class="pax_input">
                                    <label class="label">{formField.Title} {!formField.IsMandatory ? '(optional)' : '' }</label>
                                    {#if formField.Options.length > 0}
                                    <svelte:component 
                                        this="{SelectInput}" 
                                        items="{formField.props.items}"
                                        isVirtualList="{formField.props.isVirtualList}"
                                        pattern="{pattern}"
                                        placeholder="{formField.props.placeholder}"
                                        value="{formField.value}"
                                        on:select="{(event) => handleSelect(event, i)}"
                                        name="{formField.Title}"
                                        isRequired={formField.IsMandatory}
                                        hasError={formHasErrors && !formField.isValid}
                                    />
                                    {:else}
                                    <svelte:component 
                                        this="{TextInput}" 
                                        pattern="{pattern}"
                                        maxLength="{formField.props.maxLength}"
                                        placeholder="{formField.props.placeholder}"
                                        bind:value="{formField.value}"
                                        name="{formField.Title}"
                                        isRequired={formField.IsMandatory}
                                        hasError={formHasErrors && !formField.isValid}
                                    />
                                    {/if}
                                </div>
                                {/if}
                            {/each}
                        </div>
                    </div>
                </Card>
            </div>
            {:else}
            {#each passengers as {Identifier, FullName, Firstname, Lastname} }
            <div class="card">
                <Card>
                    <div class="pax">
                        <div class="pax_header">
                            <div class="avatar">
                                <Avatar alt="{ getInitials(Firstname, Lastname) }" textColour="#b0b7be" bgColour="#ebedef"></Avatar>
                            </div>

                            <p>{FullName}</p>
                        </div>

                        <div class="pax_inputs">
                            {#if formDataPax[Identifier]}
                            {#each formDataPax[Identifier] as formField, i}
                                {#if formField.show}
                                <div class="pax_input">
                                    <label class="label">{formField.Title} {!formField.IsMandatory ? '(optional)' : '' }</label>
                                    {#if formField.Options.length > 0}
                                    <svelte:component 
                                        this="{SelectInput}" 
                                        items="{formField.props.items}"
                                        isVirtualList="{formField.props.isVirtualList}"
                                        pattern="{pattern}"
                                        placeholder="{formField.props.placeholder}"
                                        value={formField.value} 
                                        on:select="{(event) => handleSelect(event, i, Identifier)}"
                                        name="{formField.Title}"
                                        isRequired={formField.IsMandatory}
                                        hasError={formHasErrors && !formField.isValid}
                                    />
                                    {:else}
                                    <svelte:component 
                                        this="{TextInput}" 
                                        pattern="{pattern}"
                                        maxLength="{formField.props.maxLength}"
                                        placeholder="{formField.props.placeholder}"
                                        bind:value={formField.value} 
                                        name="{formField.Title}"
                                        isRequired={formField.IsMandatory}
                                        hasError={formHasErrors && !formField.isValid}
                                    />
                                    {/if}
                                </div>
                                {/if}
                            {/each}
                            {/if}
                        </div>
                    </div>
                </Card>
            </div>
            {/each}
            {/if}
        </div>
    </div>
</div>

<style>
    .container {
        background: #f3f6fc;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-top: 128px;
        min-height: 101vh;
    }

    .actionBar_back {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 16px;
        top: 15px;
    }

    .intro {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        color: #3F4F5F;
        font-size: 14px;
        padding: 48px 20px;
        width: 100%;
    }

    .intro h3 {
        color: #2C3E50;
        font-size: 21px;
        font-weight: bold;
        margin: 0 0 16px 0;
    }

    .intro p {
        margin: 0 0 50px;
    }

    .intro_content {
        width: 100%;
        max-width: 1254px;
    }

    .switchNav {
        width: 265px;
    }

    .switchNav_option {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
    }

    .switchNav_icon {
        width: 20px;
        margin-right: 3px;
        align-items: center
    }

    .switchNav_content {
        align-items: center
    }

    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #3F4F5F;
        font-size: 14px;
        padding: 48px 20px;
        width: 100%;
    }

    .main_content {
        width: 100%;
        max-width: 1254px;
    }

    .card {
        margin-bottom: 16px;
    }

    .pax {
        padding: 23px 41px;
    }

    .avatar {
        position: relative;
        margin-right: 7px;
    }

    .pax_header {
        display: flex;
        align-items: center;
        margin-bottom: 35px;
    }

    .pax_header p {
	    color: #2C3E50;
	    font-size: 16px;
	    font-weight: 600;
	    letter-spacing: 0.35px;
    }

    .avatar_icon {
        position: absolute;
        width: 18px;
        color: #2C3E50;
        top: 6px;
        left: 6px;
        opacity: .32;
        height: 18px;
    }

    .label {
        color: #2C3E50;
	    font-size: 14px;
	    font-weight: 500;
	    letter-spacing: -0.15px;
	    line-height: 19px;
        margin-bottom: 5px;
        display: block
    }

    .pax_input {
        margin-bottom: 32px;
        max-width: 655px;
    }

    .errorMessages {
        border: 1px solid #FF2D55;
        color: #FF2D55;
        font-size: 14px;
        padding: 15px;
        border-radius: 4px;
        margin-bottom: 43px;
    }

    .errorMessages .errorMessages_content {
        display: flex;
        align-items: center;
    }

    .errorMessages_content span {
        line-height: 20px;
    }

    .errorMessages .icon {
        display: block;
        height: 24px;
        width: 24px;
        margin-right: 8px;
    }

    .formHasErrors {
        border-color: #FF2D55;
    }

    .intro_content .noMargin {
        margin-bottom: 0;
    }

</style>
