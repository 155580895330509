<script>
  import dayjs from 'dayjs';
  import orderBy from 'lodash/orderBy';
  import ProgressList from '../ProgressList/ProgressList.svelte';
  
  import {
    dateFormat,
  } from '../../store/index.js';
  
  export let passengerItinerary = null;

  export let ProgressListItems;
  $: {
    const items = [];
    const approvals = orderBy(passengerItinerary.Configurations.Approvers, ['Tier']);

    items.push({
      title: 'Approval requested',
      desc: `${ passengerItinerary.User },<br />
      ${ dayjs(passengerItinerary.PnrCreatedOn).format($dateFormat.DATE_TIME_THEN_DATE) }`,
      status: 'complete',
      iconType: 'add',
      CanReallocate: false
    });

    approvals.forEach((approval, approvalIndex, approvalsList) => {
      const nextApproval = approvalsList[approvalIndex + 1];
      const approvalIsExpired = nextApproval && nextApproval.ApprovalStatus === 'Expired';

      if(!approvalIsExpired) {
        items.push(approvalToProgress(approval, approvalsList[approvalIndex - 1], $dateFormat.DATE_TIME_THEN_DATE));
      }
    });

    items.push({
      title: 'Booking approved',
      status: ['Approved', 'Auto'].includes(passengerItinerary.ApprovalState) ? 'success' : 'pending',
      finalStep: true
    });

    ProgressListItems = items;
  }

  function approvalToProgress(approval, prevApproval, dateFormat) {
    const progress = {
      title: `Awaiting approval`,
      desc: `${approval.FirstName} ${approval.LastName}`,
      CanReallocate: approval.CanReallocate
    };

    if(approval.ApprovalStatus === 'Pending') {
      if(!prevApproval || prevApproval.ApprovalStatus &&
        prevApproval.ApprovalStatus !== 'Pending' &&
        prevApproval.ApprovalStatus !== 'Declined' &&
        prevApproval.ApprovalStatus !== 'Expired'
      ) {
        progress.status = 'active';
      } else {
        progress.status = 'pending';
      }
    } else {      
      progress.desc += `,<br /> ${dayjs(approval.ApprovalStatusModifiedOn).format(dateFormat)}`;

      if(approval.ApprovalStatus === 'Approved' || approval.ApprovalStatus === 'Auto') {
        progress.title = `Approved`;
        progress.status = 'success';
        if (approval.Reason) {
          progress.desc += `<br />Comment: ${approval.Reason}`
        }
      }

      if(approval.ApprovalStatus === 'Declined') {
        progress.title = 'Approval denied';
        progress.status = 'failed';
        progress.desc += `<br />Comment: ${approval.Reason}`
      }

      if(approval.ApprovalStatus === 'Expired') {
        progress.title = 'Booking expired';
        progress.status = 'expired';
      }

    }

    return progress;
  }
</script>

<ProgressList items="{ ProgressListItems }" bind:passengerItinerary></ProgressList>
