<script>
  import ApprovalProgressList from '../ApprovalProgressList/ApprovalProgressList.svelte';

  export let itinerary = undefined;
</script>

{#if itinerary}
<div class="container">
  {#if itinerary.Configurations.Approvers.length > 0}
  <div class="approvers">
    <ApprovalProgressList passengerItinerary="{itinerary}"/>
  </div>
  {/if}
</div>

{#if itinerary.Configurations.ApproverComment}
<div class="additionalInfo">
  <div class="info">
    <div class="info_title">
      Comment to approver
    </div>
    <div class="info_content">
      {itinerary.Configurations.ApproverComment}
    </div>
  </div>
</div>
{/if}
{/if}

<style>
  .container {
    margin-top: 32px;
  }

  .approvers {
    background: #f3f6fc;
    border-radius: 4px;
    margin-bottom: 32px;
    padding: 27px 29px;
  }

  .info {
    margin-bottom: 16px;
  }

  .info_title {
    color: #a2aab2;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .info_content {
    color: #2C3E50;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.31px;
    line-height: 16px;
  }

  .additionalInfo {
    padding-bottom: 40px;
  }

</style>
