<script>
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();

  import Alert from '@ctm/ui/Alert';
  import Button from '@ctm/ui/Button';
  import TextInput from '@ctm/ui/TextInput';
  import Form from '@ctm/ui/Form';
  import Footer from '../Footer/Footer.svelte';
  import { login } from '../../http/user';
  import constraints from './validation.constraints';
  import { track, trackingEvents, logout, CONFIG } from '../../store/index.js';

  import logo from './logo.svg';
  import sky from './sky.jpg';
  import bolt from './bolt.png';

  let hasError = false;
  let form = {
    username: '',
    password: ''
  };
  let isInvalid = false;
  let isWaiting = false;
  let lockedOut = false;

  function onSubmit(username, password) {
    if (isInvalid) {
      hasError = true;

      return;
    }

    hasError = false, isWaiting = true;

    login(username, password)
      .then(() => {
        sessionStorage.setItem('loginPageSignIn', 'true');
        dispatch('success');
      })
      .catch((err) => {
        lockedOut = err.response && err.response.data && err.response.data.error_description === 'Login in lockout.';
        hasError = true;
        track(trackingEvents.LOGIN, false);
      })
      .finally(() => {
        isWaiting = false;
      });
  }

  onMount(() => {
    logout()
  });
</script>

<div class="login">
  <div class="panel">
    <div class="form">
      <div class="panelHeader">
        <div class="logo">
          <img src="{logo}" alt="Lightning Book">
        </div>

        <div class="welcome">
          <h1>Welcome back!</h1>
          <p>Sign in to your account here</p>
        </div>
      </div>

      <div class="errorAlert">
        { #if hasError && lockedOut  }
          <Alert showIcon>Account has been locked. Please try and sign in again later</Alert>
        { :else if hasError  }
          <Alert showIcon>Login failed, please try again</Alert>
        { /if }
      </div>

      <Form on:submit="{() => onSubmit(form.username, form.password)}" { constraints } bind:isInvalid>
        <div class="formRow">
          <TextInput name="userName" bind:value="{form.username}" placeholder="Email address" isRequired>
          </TextInput>
        </div>

        <div class="formRow">
          <TextInput htmlType="password" name="password" bind:value="{form.password}" placeholder="Password" isRequired>
          </TextInput>
        </div>

        <div class="formActions">
          <Button isBlock { isWaiting } htmlType="submit" size="large">
            Sign in
          </Button>
        </div>

        <div class="formHelper">
          { CONFIG.LB_VERSION }
        </div>
      </Form>
    </div>

    <Footer></Footer>
  </div>

  <div class="mask" style="background: #009c70 url('{sky}') 0 0 repeat-x;">
    <div class="maskInner" style="background: url('{bolt}') 0 0 no-repeat;"></div>
  </div>
</div>

<style>
  .login {
    background: #202e38;
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .mask {
    animation: welcomeBgAnimation 120s linear infinite;
    display: none;
    position: relative;
    flex-grow: 1;
    max-width: 987px;
  }

  .maskInner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .panel {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    padding: 20px;
    position: relative;
  }

  .form {
    flex-shrink: 0;
    width: 100%;
    max-width: 380px;
  }

  .logo {
    margin-bottom: 46px;
    width: 205px;
  }

  .logo img {
    width: 100%;
  }

  .welcome {
    margin-bottom: 63px;
  }

  .welcome h1 {
    color: #fff;
    font-size: 34px;
    font-weight: 900;
    letter-spacing: 0.39px;
    margin: 0 0 9px 0;
  }

  .welcome p {
    color: #636f7d;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.22px;
    margin: 0;
  }

  .errorAlert {
    margin-bottom: 20px;
    display: block;
  }

  .form {
    margin-bottom: 30px;
  }

  .formRow:not(:last-of-type) {
    margin-bottom: 19px;
  }

  .formActions {
    margin-top: 32px;
  }

  .formHelper {
    color: #65727f;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.15px;
    margin-top: 24px;
    text-align: center;
  }

  @media (min-width: 1050px) {
    .welcome h1 {
      font-size: 44px;
    }

    .panel {
      padding: 40px;
    }

    .mask {
      display: block;
      min-width: 500px;
    }
  }

  @media (min-width: 1475px) {
    .mask {
      min-width: 981px;
    }
  }

  @keyframes welcomeBgAnimation {
    0% {
      background-position: 0 0;
    }

    100% {
      background-position: -1585px 0;
    }
  }
</style>
