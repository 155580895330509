<script>
    import WarnIcon from './WarnIcon.svelte';
    import { Hotel, Car } from '@ctm/ui/Icons';
    import Plane from './Plane.svelte';
    import dayjs from 'dayjs';

    export let sector;
    export let dayIndex;
    export let sectorIndex;

    const dateTimeFormat = 'YYYY-MM-DD[T]HH:mm';
    const _airline = `This airline doesn't allow online changes. To make changes contact your dedicated travel consultant.`;
    const _flight = `This flight cannot be changed online. To make changes contact your dedicated travel consultant.`;
    const noChangeReasons = {
        CarrierChangesNotAllowed: _airline,
        CountryPairChangesNotAllowed: _airline,
        OutsideBookingLeadTime: _flight,
        GdsChangesNotAllowed: _flight,
        Unticketed: _flight,
        None: _flight,
        Departed: 'This flight has already departed.',
    };
</script>

<style>
    label {
        color: #2d3840;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 0 8px;
    }
    .sector {
        display: flex;
        align-items: center;
    }
    .change-warning {
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #6d7880;
        margin: 8px 0 0 0;
    }

    .change-warning {
        color: #dd631e;
    }

    .change-warning i {
        display: flex;
        margin: 0 5px 0 0;
    }
    .carrier {
        font-size: 12px;
        font-weight: 400;
        margin: 0 0 0 4px;
        line-height: 18px;
    }
    i.icon {
        width: 20px;
        height: 20px;
        margin: 0 4px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sector-segments {
        display: flex;
        margin: 8px 0 0 0;
    }
    .sector-segment {
        display: flex;
        flex-direction: column;
        width: 200px;
        overflow-x: auto;
        word-wrap: normal;
    }
    .sector-segment-title {
        font-size: 14px;
        font-weight: 600;
    }
    .sector-segment-info {
        font-size: 14px;
        font-weight: 400;
    }
    .sector-segment:first-child {
        margin: 0 16px 0 0;
    }
</style>

{#if sector.sectorType === 'flight'}
    <label for={`sector${dayIndex}:${sectorIndex}`}>
        <div class="sector">
            <i class="icon"><Plane /></i>
            {sector.data.Departure.City} - {sector.data.Arrival.City}
        </div>
        <div class="carrier">
            {sector.data.CarrierName}, {sector.data.Carrier +
                sector.data.FlightNumber}
        </div>
        <div class="sector-segments">
            <div class="sector-segment">
                <div class="sector-segment-title">Depart</div>
                <div class="sector-segment-info">
                    {sector.data.Departure.Location}
                </div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.Departure.Date, dateTimeFormat).format(
                        'hh:mma - ddd, DD MMM YYYY'
                    )}
                </div>
            </div>
            <div class="sector-segment">
                <div class="sector-segment-title">Arrive</div>
                <div class="sector-segment-info">
                    {sector.data.Arrival.Location}
                </div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.Arrival.Date, dateTimeFormat).format(
                        'hh:mma - ddd, DD MMM YYYY'
                    )}
                </div>
            </div>
        </div>
        {#if sector.data.NoChangeReason === 'Departed'}
            <div class="change-warning">
                <i><WarnIcon /></i>{noChangeReasons[sector.data.NoChangeReason]}
            </div>
        {/if}
    </label>
{:else if sector.sectorType === 'hotel'}
    <label for={`sector${dayIndex}:${sectorIndex}`}>
        <div class="sector">
            <i class="icon"><Hotel /></i>
            {sector.data.HotelName}
        </div>
        <div class="carrier">
            {sector.data.Location.City}
        </div>

        <div class="sector-segments">
            <div class="sector-segment">
                <div class="sector-segment-title">Check in</div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.DateFrom, dateTimeFormat).format('ddd, DD MMM YYYY')}
                </div>
            </div>
            <div class="sector-segment">
                <div class="sector-segment-title">Check out</div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.DateTo, dateTimeFormat).format('ddd, DD MMM YYYY')}
                </div>
            </div>
        </div>
        
        <div class="change-warning">
          <i><WarnIcon /></i>
          {#if sector.data.IsChangeable }
            Cancellation fees may apply as the original booking will be cancelled and re-booked.
          {:else}
            This hotel cannot be changed online. To make changes contact your dedicated travel consultant.
          {/if}
      </div>
    </label>
{:else if sector.sectorType === 'car'}
    <label for={`sector${dayIndex}:${sectorIndex}`}>
        <div class="sector">
            <i class="icon"><Car /></i>
            {sector.data.Supplier.Title} - {sector.data.Vehicle.Class}
        </div>
        <div class="carrier">
            {sector.data.Vehicle.Title}
        </div>
        
        <div class="sector-segments">
            <div class="sector-segment">
                <div class="sector-segment-title">Pick up</div>
                <div class="sector-segment-info">
                    {sector.data.PickUp.City}
                </div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.PickUp.Date, dateTimeFormat).format(
                        'hh:mma - ddd, DD MMM YYYY'
                    )}
                </div>
            </div>
            <div class="sector-segment">
                <div class="sector-segment-title">Drop off</div>
                <div class="sector-segment-info">
                    {sector.data.DropOff.City}
                </div>
                <div class="sector-segment-title">
                    {dayjs(sector.data.DropOff.Date, dateTimeFormat).format(
                        'hh:mma - ddd, DD MMM YYYY'
                    )}
                </div>
            </div>
        </div>
        {#if !sector.data.IsChangeable }
          <div class="change-warning">
              <i><WarnIcon /></i>This car cannot be changed online. To make
              changes contact your dedicated travel consultant.
          </div>
        {/if}
    </label>
{/if}
