import dayjs from 'dayjs';

import trackingEvents from './events';
import { airlineFeeTotal, flightChangeTotal, itinerarySearchTripType } from '../../util/itinerary';

export default {
  ABANDON_BOOKING_FROM_NAV(selectedItem) {
    mixpanel.track(trackingEvents.ABANDON_BOOKING_FROM_NAV, {
      'Selected Item': selectedItem
    });
  },

  BREAK_POLICY_WINDOW_ACTIONED(sectorType, selectedValue) {
    mixpanel.track(trackingEvents.BREAK_POLICY_WINDOW_ACTIONED, {
      'Sector Type': sectorType,
      'Selected Value': selectedValue
    });
  },

  CART_SKIP_CLICKED(sectorType) {
    mixpanel.track(trackingEvents.CART_SKIP_CLICKED, {
      'Sector Type': sectorType
    });
  },

  CART_CONTINUE_CLICKED(sectorType) {
    mixpanel.track(trackingEvents.CART_CONTINUE_CLICKED, {
      'Sector Type': sectorType
    });
  },

  CART_ITEM_REMOVED(sectorType) {
    mixpanel.track(trackingEvents.CART_ITEM_REMOVED, {
      'Sector Type': sectorType
    });
  },

  CHANGE_BOOKING_CLICKED(itinerary) {
    mixpanel.track(trackingEvents.CHANGE_BOOKING_CLICKED, {
      'Change Booking Clicked': itinerary
    });
  },

  CHANGE_BOOKING_COMPLETE(itinerary) {
    const flightIdsForChange = itinerary.Search.Flights.map((flightSearch) => {
      return flightSearch.ParentIdentifier;
    });

    const flightsForChange = itinerary.Passengers[0].Flights.filter((flight) => {
      return flightIdsForChange.includes(flight.Identifier);
    });

    mixpanel.track(trackingEvents.CHANGE_BOOKING_COMPLETE, {
      PNR: itinerary.Passengers[0].Pnr,
      Carriers: flightsForChange.map((flight) => {
        return flight.Carrier;
      }),
      'Total Changes': flightsForChange.length,
      'Lead Time Hours': flightsForChange.map((flight) => {
        const msTillFlight = dayjs(flight.Departure.Date).diff(dayjs());
        return Math.round(msTillFlight / (60 * 60 * 1000));
      }),
      'Is Pre Trip Change': dayjs().isBefore(dayjs(itinerary.FromDate)),
      'Cost of Change': flightChangeTotal(itinerary),
      'Airline Fees': airlineFeeTotal(itinerary)
    });
  },

  CHANGE_BOOKING_RESULTS(totalResults) {
    mixpanel.track(trackingEvents.CHANGE_BOOKING_RESULTS, {
      'Total Results': totalResults
    });
  },

  FARE_FORECASTER_ITEM_SELECTED(day, flight) {
    mixpanel.track(trackingEvents.FARE_FORECASTER_ITEM_SELECTED, {
      'Is Cheapest': day.Price === flight.minPrice
    });
  },

  FLIGHT_FARE_SELECTED(data, fare, isAdditionalFare) {
    mixpanel.track(trackingEvents.FLIGHT_FARE_SELECTED, {
      'Origin': data.OriginCityCode,
      'Destination': data.DestinationCityCode,
      'Departure Date': data.DepartureDateTimeOffset,
      'Arrival Date': data.ArrivalDateTimeOffset,
      'Is Additional Fare': isAdditionalFare
    });
  },

  FLIGHT_FARE_DETAILS_CLICKED(data, fareClass) {
    mixpanel.track(trackingEvents.FLIGHT_FARE_DETAILS_CLICKED, {
      'Origin': data.OriginCityCode,
      'Destination': data.DestinationCityCode,
      'Total Fares': fareClass.AdditionalFares.length
    });
  },

  FLIGHT_RESULTS_SORT_ACTIONED(selectedOption) {
    mixpanel.track(trackingEvents.FLIGHT_RESULTS_SORT_ACTIONED, {
      'Selected Item': selectedOption.label
    });
  },

  FLIGHT_RESULTS_LOAD_MORE_CLICKED(totalResultsDisplayed, totalResults) {
    mixpanel.track(trackingEvents.FLIGHT_RESULTS_LOAD_MORE_CLICKED, {
      'Total Results Displayed': totalResultsDisplayed,
      'Total Results': totalResults
    });
  },

  SEARCH_UPDATED(sectorType, changes) {
    mixpanel.track(trackingEvents.SEARCH_UPDATED, {
      'Sector Type': sectorType,
      'Changes': changes
    });
  },

  FLIGHT_TRIP_DETAILS_CLICKED(data) {
    mixpanel.track(trackingEvents.FLIGHT_TRIP_DETAILS_CLICKED, {
      'Origin': data.OriginCityCode,
      'Destination': data.DestinationCityCode,
      'Total Fares': data.TotalFares
    });
  },

  FLIGHT_TAB_CLICKED(data, tabName) {
    mixpanel.track(trackingEvents.FLIGHT_TAB_CLICKED, {
      'Origin': data.OriginCityCode,
      'Destination': data.DestinationCityCode,
      'Selected Item': tabName
    });
  },

  HOTEL_TAB_CLICKED(tabName) {
    mixpanel.track(trackingEvents.HOTEL_TAB_CLICKED, {
      'Selected Item': tabName
    });
  },

  HOTEL_RESULTS_VIEW_CLICKED(viewName) {
    mixpanel.track(trackingEvents.HOTEL_RESULTS_VIEW_CLICKED, {
      'Selected Item': viewName
    });
  },

  HOTEL_RESULTS_TAB_CLICKED(search, tab) {
    mixpanel.track(trackingEvents.HOTEL_RESULTS_TAB_CLICKED, {
      'Location': search.Location.Code,
      'Selected Item': tab.name
    });
  },

  HOTEL_RESULTS_SEARCH_FILTER_ACTIONED(searchText) {
    mixpanel.track(trackingEvents.HOTEL_RESULTS_SEARCH_FILTER_ACTIONED, {
      'Search Text': searchText
    });
  },

  HOTEL_RESULTS_SORT_ACTIONED(selectedOption) {
    mixpanel.track(trackingEvents.HOTEL_RESULTS_SORT_ACTIONED, {
      'Selected Item': selectedOption.label
    });
  },

  PAGE_VIEW(data) {
    mixpanel.track(trackingEvents.PAGE_VIEW, {
      'Page Title': data.title,
      'Page Path': data.path
    });
  },

  POI_SEARCH_ITEM_SELECTED(data) {
    mixpanel.track(trackingEvents.POI_SEARCH_ITEM_SELECTED, {
      'Search Text': data.searchText,
      'Item Title': data.itemTitle,
      'Item Index': data.itemIndex,
      'Item Search Score': data.itemSearchScore,
      'Item Type': data.itemType
    });
  },

  LOGIN(wasSuccessful) {
    mixpanel.track(trackingEvents.LOGIN, {
      'Was Successful': wasSuccessful
    });
  },

  SELECTED_TRAVELLER_MISSING_CLIENT_ID(data) {
    mixpanel.track(trackingEvents.SELECTED_TRAVELLER_MISSING_CLIENT_ID, {
      data
    })
  },

  NEW_SEARCH_TRAVELLER_MISSING_CLIENT_ID(data) {
    mixpanel.track(trackingEvents.NEW_SEARCH_TRAVELLER_MISSING_CLIENT_ID, {
      data
    })
  },

  SEARCH(itinerary, isRecentSearch = false) {
    mixpanel.track(trackingEvents.SEARCH, {
      'From Date': itinerary.FromDate,
      'To Date': itinerary.ToDate,
      'Travellers': itinerary.Passengers.map((p) => p.FullName),
      'Total Travellers': itinerary.Passengers.length,
      'Origin': itinerary.OriginLocationCode,
      'Destination': itinerary.DestinationLocationCode,
      'Trip Type': itinerarySearchTripType(itinerary),
      'Total Flights': itinerary.Search.Flights.length,
      'Total Hotels': itinerary.Search.Hotels.length,
      'Total Cars': itinerary.Search.Cars.length,
      'Total Rails': itinerary.Search.Rails.length,
      'Is International': itinerary.Search.InternationalSearch,
      'Is Recent Search': isRecentSearch,
      'Search Identifier': itinerary.Search.Identifier,
      'Reason For Travel': itinerary.Passengers.map((p) => `${p.FullName} ${p.AgencyReasonTitle}: ${p.AgencyReasonCode}`)
    });
  },

  RECENT_SEARCHES_PAGE_CLICKED(pageNumber) {
    mixpanel.track(trackingEvents.RECENT_SEARCHES_PAGE_CLICKED, {
      'Page Number': pageNumber
    });
  },

  BOOKING_APPROVAL_ACTIONED(wasApproved) {
    mixpanel.track(trackingEvents.BOOKING_APPROVAL_ACTIONED, {
      'Selected Item': wasApproved ? 'approve' : 'deny'
    });
  },

  BOOKING_RESULTS_PAGE_SELECTED(pageNumber) {
    mixpanel.track(trackingEvents.BOOKING_RESULTS_PAGE_SELECTED, {
      'Selected Item': pageNumber
    });
  },

  BOOKING_RESULTS_FILTER_ACTIONED(filter) {
    mixpanel.track(trackingEvents.BOOKING_RESULTS_FILTER_ACTIONED, {
      'Selected Item': filter
    });
  },

  BOOKING_TAB_CLICKED(tabLabel) {
    mixpanel.track(trackingEvents.BOOKING_TAB_CLICKED, {
      'Selected Item': tabLabel
    });
  },

  BOOKING_SELECTED(itinerary) {
    mixpanel.track(trackingEvents.BOOKING_SELECTED, {

    });
  },

  ITINERARY_HOTEL_PROMPT_ACTIONED(reason, isOther, searchLocation) {
    mixpanel.track(trackingEvents.ITINERARY_HOTEL_PROMPT_ACTIONED, {
      'Reason': reason,
      'Is Other Reason': isOther,
      'Search Location': searchLocation
    });
  },

  ITINERARY_SECTOR_ADDED(sectorType) {
    mixpanel.track(trackingEvents.ITINERARY_SECTOR_ADDED, {
      'Sector Type': sectorType
    });
  },

  ITINERARY_SECTOR_REMOVED(sectorType) {
    mixpanel.track(trackingEvents.ITINERARY_SECTOR_REMOVED, {
      'Sector Type': sectorType
    });
  },

  ITINERARY_FARE_SWAPPED(swapOffer) {
    mixpanel.track(trackingEvents.ITINERARY_FARE_SWAPPED, {
      'Swap Offer': swapOffer.toLowerCase()
    });
  },

  ITINERARY_BAGGAGE_SELECTED(selectedOption) {
    mixpanel.track(trackingEvents.ITINERARY_BAGGAGE_SELECTED, {
      'Selected Item': selectedOption.Description,
      'Price': selectedOption.Amount
    });
  },

  REGISTRATION_SKIPPED(sectionName) {
    mixpanel.track(trackingEvents.REGISTRATION_SKIPPED, {
      'Section Name': sectionName
    });
  },

  ONBOARDING_CARD_CLICKED(cardTitle) {
    mixpanel.track(trackingEvents.ONBOARDING_CARD_CLICKED, {
      'Selected Item': cardTitle
    });
  },

  UPDATE_ALLOW_GUESTS_RULE(isEnabled) {
    mixpanel.track(trackingEvents.UPDATE_ALLOW_GUESTS_RULE, {
      'Is Enabled': isEnabled
    });
  },

  UPDATE_COMPANY_REFERENCE_RULE(isEnabled, options) {
    mixpanel.track(trackingEvents.UPDATE_COMPANY_REFERENCE_RULE, {
      'Is Enabled': isEnabled,
      'Options': options
    });
  },

  UPDATE_RESTRICT_CLASS_TYPE_RULE(isEnabled, options) {
    mixpanel.track(trackingEvents.UPDATE_RESTRICT_CLASS_TYPE_RULE, {
      'Is Enabled': isEnabled,
      'Options': options
    });
  },

  AIRPORT_TRANSFERS_BANNER_DISPLAYED(debtorCode) {
    mixpanel.track(trackingEvents.AIRPORT_TRANSFERS_BANNER_DISPLAYED, {
      debtorCode
    });
  },

  CHANGE_BOOKING_SEARCH(sectorType) {
    console.log('sectorType :>> ', sectorType);
    mixpanel.track(trackingEvents.CHANGE_BOOKING_SEARCH, {
      'Sector Type': sectorType
    });
  }
};
