<script>
  import Modal from "@ctm/ui/Modal";

  export let targetElem;
  export let complete = () => {};
  export let cancel = () => {};
  export let isWaiting = false;
  export let hotelData = undefined;

  function onModalCancel() {
    cancel();
  }
</script>

<style>
  h2 {
    color: #a4c04e;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.15px;
    line-height: 26px;
    margin: 0;
  }

  p {
    line-height: 22px;
    margin: 0 0 10px;
  }
</style>

<Modal title={hotelData.HotelName} hasFooter={false} on:cancel={onModalCancel}>
  {#if hotelData.CheckinBeginTime || hotelData.CheckoutTime}
    <h2>Check-In</h2>
    <p>
      {#if hotelData.CheckinBeginTime}
        Check-in time: {hotelData.CheckinBeginTime}
        <br />
      {/if}
      {#if hotelData.CheckoutTime}Check-out time: {hotelData.CheckoutTime}{/if}
    </p>
  {/if}

  {#if hotelData.CheckinInstructions || hotelData.CheckinSpecialInstructions}
    <h2>Room Information</h2>

    <p>
      {@html hotelData.CheckinInstructions || ''}
      {@html hotelData.CheckinSpecialInstructions || ''}
    </p>
  {/if}

  {#if hotelData.HealthMeasures && hotelData.HealthMeasures.length > 0}
    <h2>Health Measures</h2>

    <ul>
      {#each hotelData.HealthMeasures as item}
        <li>{item.Description}</li>
      {/each}
    </ul>
  {/if}

</Modal>
