import axios from 'axios';
import { CONFIG } from '../store/index.js';
import { getHeaders } from "./headers";


export function searchDebtors(query) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/DebtorSearch?debtor=${query}`;

    const options = {
      credentials: 'include',
      url,
      method: 'GET',
      headers: getHeaders(true),
      action: 'searching debtors'
    };

    axios(options).then(res => resolve(res.data));
  })
}
