<script>
    import { onMount } from 'svelte';

    import TextInput from '@ctm/ui/TextInput';

    export let hasError;
    export let value = '';
    export let maxLength;
    export let pattern;

    onMount(() => {
        if (value && value.hasOwnProperty('value')) {
            value = value.value
        }
    });
</script>

<div class="container {hasError ? 'hasError' : ''}">
    {#if value !== undefined && maxLength}
    <span class="maxLength">{value.length} / {maxLength}</span>
    {/if}
    <TextInput bind:value { pattern } { maxLength } />

    {#if hasError}
    <p class="error">Required for this booking</p>
    {/if}
</div>

<style>
    .container {
        position: relative;
    }

    .maxLength {
        position: absolute;
        top: -20px;
        right: 0;
	    color: #78848F;
        font-size: 12px;
	    font-weight: 600;
    }
    
    .error {
        font-size: 12px;
        letter-spacing: -0.13px;
        line-height: 14px;
        color:  #FF2D55;
        margin: 4px 0;
        position: absolute;
    }
    
    .hasError :global(input){
        border-color: #FF2D55;
    }
</style>
