<script>
    import { Star } from '@ctm/ui/Icons';
    import Button from '@ctm/ui/Button';
    import WarnIcon from '../ChangeBooking/WarnIcon.svelte';
    import dayjs from 'dayjs';
    import {postPassengerHotel} from '../../http/itinerary';
    import NonRefundableIcon from './NonRefundableIcon.svelte';
    import TriangleIcon from './TriangleIcon.svelte';
    import noImageSupplied from './no-image-supplied.png';

    export let sector;

    let loading = false;
    let rates = [];
    let slice = 10;

    $: RoomNotAvailable = sector.Errors.find((e) => e.Type === 'RoomNotAvailable');
    $: hotel = RoomNotAvailable && RoomNotAvailable.FallbackOption.Hotel;
    $: rooms = hotel && hotel.Rooms;    
    $: {
        rooms.forEach(room => {
            const _rates = room.Rates.map(rate => {
                rate.RoomName = room.RoomName;
                return rate;
            });

            rates = [..._rates, ...rates];
        });
        rates = rates.sort((a, b) => parseFloat(a.Amount) - parseFloat(b.Amount));
    }
 
    async function handleSelectRoomRate(rate) {
        loading = true;
        const payload = rate;
        payload.HotelCode = sector.HotelCode;
        payload.HotelName = sector.HotelName;
        payload.ChainCode = sector.ChainCode;
        payload.DateFrom = sector.DateFrom;
        payload.DateTo = sector.DateTo;
        payload.Location = { Code: sector.Location.Code };
        payload.LostSavingsAmount = sector.LostSavingsAmount;
        payload.PaymentNarrative = sector.PaymentNarrative;
        payload.Policy = sector.Policy;
        payload.RoomName = rate.RoomName;
        payload.SearchIdentifier = sector.SearchIdentifier;
        payload.SpecialRequirements = sector.SpecialRequirements;

        try {
            await postPassengerHotel(sector.ItineraryIdentifier, [payload]);

            sessionStorage.removeItem('makingBooking');
            sessionStorage.removeItem('confirmation:itineraryId');
            sessionStorage.setItem('search:itineraryId', sector.ItineraryIdentifier);
            window.location.hash = `/itinerary`;
        } catch (_) {
        } finally {
            loading = false;
        }
    }

    function ratingToStars(num) {
        const stars = [];
        const fullStar = 1;
        const halfStar = 0.5;
        const fullStarsCount = Math.floor(num);

        for (let i = 0; i < fullStarsCount; i++) {
            stars.push(fullStar);
        }

        if (num - fullStarsCount >= 0.5) {
            stars.push(halfStar);
        }

        return stars;
    }
</script>

<div class="hotel-reshop-container">
    <div class="message">
        <i><WarnIcon /></i>
        <div class="message-details">
            <h2>This booking needs to be amended</h2>
            <p>Please select an alternative room configuration</p>
        </div>
    </div>

    <div class="hotel-reshop">
        <div class="hotel-reshop-details">
            <img src={hotel.Images[0] || noImageSupplied} alt="hotel-image" />

            <div class="hotel-reshop-detail">
                <h3>
                    {sector.HotelName}
                    <div class="rating">
                        {#each ratingToStars(hotel.StarRating) as star}
                            {#if star === 1}
                                <i><Star /></i>
                            {:else}
                                <i class="half-star"><Star /></i>
                            {/if}
                        {/each}
                    </div>
                </h3>

                <p class="address">
                    {hotel.Address}, <br />
                    {sector.Location.City}, <br />
                    {sector.Location.Country}
                </p>
            </div>

            <div class="dates">
                <div class="date">
                    <span>Check in</span>
                    {dayjs(sector.DateFrom, 'YYYY-MM-DDTHH:mm').format(
                        'ddd D MMM YYYY'
                    )}{#if sector.CheckinBeginTime}, {sector.CheckinBeginTime}{/if}
                </div>
                <div class="date">
                    <span>Check out</span>
                    {dayjs(sector.DateTo, 'YYYY-MM-DDTHH:mm').format(
                        'ddd D MMM YYYY'
                    )}{#if sector.CheckoutTime}, {sector.CheckoutTime}{/if}
                </div>
            </div>
        </div>

        <div class="hotel-room-options">
            <h2>Room configurations</h2>

            <div class="rooms">
                <div class="row header">
                    <div class="col name">Room name</div>
                    <div class="col refund">&nbsp;</div>
                    <div class="col price">Price (AUD)</div>
                    <div class="col cta" />
                </div>
                
                    {#each rates.slice(0, slice) as rate}
                        <div class="row">
                            <div class="col name" title={rate.RoomName}>{rate.RoomName}</div>
                            <div class="col refund">
                                <i class:no-refund={!rate.Refundable}><NonRefundableIcon /></i>
                                <div class="tool-top"><i class="triangle"><TriangleIcon /></i>{rate.Cancellation}</div>
                            </div>
                            <div class="col price">
                                ${rate.Amount.toFixed(2)}
                            </div>
                            <div class="col cta">
                                <Button
                                    isDisabled={loading}
                                    type="primary"
                                    isOutlined
                                    on:click={() => handleSelectRoomRate(rate)}>
                                Select</Button>
                            </div>
                        </div>
                    {/each}
                    {#if slice < rates.length}<button class="show-all" on:click={() => slice = rates.length }>Show more</button>{/if}
            </div>
        </div>
    </div>
</div>

<style>
    .hotel-reshop-container {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .message {
        border: 1px solid #d0e2ff;
        border-radius: 8px;
        padding: 16px;
        display: flex;
        gap: 8px;
        color: #2d3840;
    }

    .message i {
        position: relative;
        top: 5px;
        color: #da1e28;
    }

    .message-details h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
    }

    .message-details p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
    }

    .hotel-reshop {
        border: 1px solid #ced3da;
        padding: 24px;
        border-radius: 8px;
        display: flex;
        gap: 12px;
        flex-direction: column;
    }

    .hotel-reshop img {
        width: 288px;
        height: 179px;
        border-radius: 4px;
        object-fit: cover;
        object-position: top;
    }

    .hotel-reshop-details {
        display: flex;
        gap: 12px;
        padding: 0 0 18px;
        border-bottom: 1px solid #ebecf0;
    }

    .hotel-reshop-detail i {
        display: flex;
        width: 16px;
    }

    .hotel-reshop-detail i.half-star {
        left: -6px;
        position: relative;
        overflow: hidden;
    }

    .hotel-reshop-detail i.half-star :global(svg) {
        position: relative;
        left: 7px;
    }

    .hotel-reshop-detail h3 {
        display: flex;
        gap: 16px;
        margin: 0;
    }

    .rating {
        display: flex;
        gap: 4px;
        align-items: flex-start;
    }

    .hotel-reshop-detail p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }

    .dates {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-self: flex-end;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        flex-shrink: 0;
    }

    .date {
        display: flex;
        justify-content: space-between;
        gap: 12px;
    }

    .date span {
        font-weight: 400;
    }

    .rooms {
        border: 1px solid #ced3da;
        border-radius: 4px;
        padding: 24px;
        display: flex;
        flex-direction: column;
    }

    .row {
        display: flex;
        gap: 10px;
        height: 54px;
        align-items: center;
        border-bottom: 1px solid #ebecf0;
    }

    .row:last-of-type {
        border: none;
    }

    .row.header {
        height: 32px;
        border: none;
    }

    .row.header .col {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #6d7880;
    }

    .row .col {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    .col.name {
        width: 570px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .col.price {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        gap: 5px;
    }

    .col.refund {
        width: 20px;
        display: flex;
        position: relative;
    }

    .col.refund > i {
        display: flex;
        align-items: center;
        opacity: .2;
    }

    .col.refund i.no-refund {
        opacity: 1;
        color: #da1e28;
    }

    .tool-top {
        position: absolute;
        width: 240px;
        background: #156AF4;
        color: #fff;
        box-shadow: 0px 8px 24px 0px rgba(32, 43, 51, 0.24),0px 4px 8px 0px rgba(32, 43, 51, 0.16);
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        padding: 4px 8px;
        bottom: 35px;
        left: -112px;
        z-index: 10;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s ease-in;
        pointer-events: none;
    }

    .triangle {
        position: absolute;
        bottom: -19px;
        left: calc(50% - 7px);
    }

    .col.refund:hover .tool-top {
        visibility: visible;
        opacity: 1;
    }

    .cta {
        text-align: right;
    }

    .show-all {
        padding: 10px;
        border: none;
        background: #f3f6fc;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }
</style>
