<script>
    import dayjs from 'dayjs';

    import FlightIcon from '../ChangeBooking/FlightIcon.svelte';
    import CarIcon from '../ChangeBooking/_CarIcon.svelte';
    import { formatFromApiDate } from '../../util/date';
    import { dateFormat } from '../../store/index.js';
    import TotalsTable from './_TotalsTable.svelte';
    import AlertIcon from './_AlertIcon.svelte';
    import HotelIcon from '../ChangeBooking/_HotelIcon.svelte';

    export let flights = null;
    export let hotels = null;
    export let cars = null;
    export let noBorder = false;
    export let changed = null;
    export let showPrice = false;

    function date(date) {
        return formatFromApiDate(date, $dateFormat.DATE_TIME_EXTENDED);
    }

    function hotelDate(date) {
      return dayjs(date).format($dateFormat.DATE_EXTENDED)
    }

    function hotelChangeFailed(hotel) {
      if (!changed) return hotel;
      let match = changed.Passengers[0].Hotels.find(h => h.Identifier === hotel.Identifier);
      let hasFailed = match.HasBeenUpdated === false;

      if (hasFailed) {
        const failedHotel = changed.Passengers[0].Hotels.find(h => h.HotelId === match.HotelId);
        hotel.DateFrom = failedHotel.DateFrom;
        hotel.DateTo = failedHotel.DateTo;
      }

      hotel.hasFailed = hasFailed;
      return hotel;
    }

    function carChangeFailed(car) {
      if (!changed) return;
      let match = changed.Passengers[0].Cars.find(c => c.Identifier === car.Identifier);
      if (!match) return;
      return match.HasBeenUpdated === false;
    }

    $: computedHotels = hotels && hotels.map(h => hotelChangeFailed(h));
</script>

<div class="sector-summary" class:no-border={noBorder}>
    {#if flights}
        <h2><i><FlightIcon /></i> Flight changes</h2>

        {#each flights as flight}
            <div class="sector-container">
                <div class="sector">
                    <div class="details">
                        <img
                            class="logo"
                            src={flight.CarrierImage}
                            alt="carrier logo" />
                        <div class="description">
                            <h3>
                                {flight.Departure.City} ({flight.Departure
                                    .Code}) to {flight.Arrival.City} ({flight
                                    .Arrival.Code})
                            </h3>
                            <span
                                >{flight.CarrierName}, {flight.Carrier}{flight.FlightNumber}</span>
                        </div>
                        <div class="from-to">
                            <div class="date-time">
                                <h4>Departure:</h4>
                                <span>{date(flight.Departure.Date)}</span>
                            </div>
                            <div class="date-time">
                                <h4>Arrival:</h4>
                                <span>{date(flight.Arrival.Date)}</span>
                            </div>
                        </div>
                    </div>

                    <div class="sector inner">
                        <span class="original">Original Flight</span>
                        <div class="details">
                            <img
                                class="logo"
                                src={flight.Parent.CarrierImage}
                                alt="carrier logo" />
                            <div class="description">
                                <h3>
                                    {flight.Parent.Departure.City} ({flight
                                        .Parent.Departure.Code}) to {flight
                                        .Parent.Arrival.City} ({flight.Arrival
                                        .Code})
                                </h3>
                                <span
                                    >{flight.Parent.CarrierName}, {flight.Parent
                                        .Carrier}{flight.Parent
                                        .FlightNumber}</span>
                            </div>
                            <div class="from-to">
                                <div class="date-time">
                                    <h4>Departure:</h4>
                                    <span
                                        >{date(
                                            flight.Parent.Departure.Date
                                        )}</span>
                                </div>
                                <div class="date-time">
                                    <h4>Arrival:</h4>
                                    <span
                                        >{date(
                                            flight.Parent.Arrival.Date
                                        )}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {#if showPrice}
                    <div class="price-difference blank">
                        <!-- {#each flight.Fees as fee}
                            <div class="break-down">
                                <span>{fee.Type === 'AirlineFee' ? 'Airline Fee' : fee.Type }</span>
                                <span class="amount">{formatCurrency(fee.Amount, 2)}<em
                                  >{flight.CurrencyCode}</em></span>
                            </div>
                        {/each}

                        <div class="break-down total { flight.Fees.length === 0 ? 'no-border' : ''} ">
                            <span>Total Change</span>
                            <span class="amount">{formatCurrency(flight.PayableAmount, 2)}<em
                              >{flight.CurrencyCode}</em></span>
                        </div> -->
                    </div>
                {/if}
            </div>
        {/each}
    {/if}

    {#if computedHotels}
      <h2><i><HotelIcon /></i> Hotel changes</h2>

      {#each computedHotels as hotel}
      <div class="sector-container">
        <div class="sector">
            <div class="details">
                <i class="logo">
                    <HotelIcon />
                </i>
                <div class="description">
                  <h3>
                      {hotel.HotelName}
                  </h3>
                  <span>{hotel.RoomName}</span>
                </div>

                <div class="from-to">
                  <div class="date-time">
                      <h4>Check-in:</h4>
                      <span>{hotelDate(hotel.DateFrom)}</span>
                  </div>
                  <div class="date-time">
                      <h4>Check-out:</h4>
                      <span>{hotelDate(hotel.DateTo)}</span>
                  </div>
                </div>
            </div>

            {#if hotel.hasFailed}
            <div class="failed">
              <AlertIcon /><h3>Your hotel booking failed to update</h3> 
              <p>Your hotel booking was unable to be changed. Original booking retained</p>
            </div>
            {/if}

            <div class="sector inner">
              <span class="original">Original booking</span>
              <div class="details">
                  <i class="logo">
                      <HotelIcon />
                  </i>
                  <div class="description">
                    <h3>
                        {hotel.HotelName}
                    </h3>
                    <span>{hotel.original.RoomName}</span>
                  </div>
  
                  <div class="from-to">
                    <div class="date-time">
                        <h4>Check-in:</h4>
                        <span>{hotelDate(hotel.original.DateFrom)}</span>
                    </div>
                    <div class="date-time">
                        <h4>Check-out:</h4>
                        <span>{hotelDate(hotel.original.DateTo)}</span>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        {#if showPrice}<div class="price-difference blank"></div>{/if}
      </div>
      {/each}

    {/if}

    {#if cars}
        <h2><i><CarIcon wide /></i> Car changes</h2>

        {#each cars as car}
            <div class="sector-container">
                <div class="sector">
                    <div class="details">
                        <i class="logo">
                            <CarIcon wide />
                        </i>
                        <div class="description">
                            <h3>
                                {car.Supplier.Title}
                            </h3>
                            <span>{car.Vehicle.Title}, {car.PickUp.City}</span>
                        </div>
                        <div class="from-to">
                            <div class="date-time">
                                <h4>Pick up:</h4>
                                <span>{date(car.PickUp.Date)}</span>
                            </div>
                            <div class="date-time">
                                <h4>Drop off:</h4>
                                <span>{date(car.DropOff.Date)}</span>
                            </div>
                        </div>
                    </div>

                    {#if carChangeFailed(car)}
                      <div class="failed">
                        <AlertIcon /><h3>Your car booking failed to update</h3> 
                        <p>Your car booking was unable to be changed. Original booking retained</p>
                      </div>
                    {/if}

                    <div class="sector inner">
                        <span class="original">Original car rental</span>
                        <div class="details">
                            <i class="logo">
                                <CarIcon wide />
                            </i>
                            <div class="description">
                                <h3>
                                    {car.Supplier.Title}
                                </h3>
                                <span>{car.Vehicle.Title}, {car.PickUp.City}</span>
                            </div>
                            <div class="from-to">
                                <div class="date-time">
                                    <h4>Pick up:</h4>
                                    <span>{date(car.original.PickUp.Date)}</span>
                                </div>
                                <div class="date-time">
                                    <h4>Drop off:</h4>
                                    <span>{date(car.original.DropOff.Date)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {#if showPrice}
                    <div class="price-difference no-padding">
                        <TotalsTable {car} failed={carChangeFailed(car)} />
                    </div>
                {/if}
            </div>
        {/each}
    {/if}
</div>

<style>
    .sector-summary {
        border: 1px solid #ced3da;
        border-radius: 8px;
        padding: 24px 24px 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2d3840;
        margin: 0 0 24px;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    h2 i {
        height: 20px;
    }

    .sector-container {
        display: flex;
        gap: 16px;
        align-items: flex-start;
    }

    .sector {
        display: flex;
        flex-direction: row;
        padding: 14px;
        background: #f3f6fc;
        border-radius: 8px;
        flex-wrap: wrap;
        margin: 0 0 24px;
    }

    .details {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .logo {
        width: 25px;
        height: 25px;
        margin: 0 12px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .description {
        display: flex;
        flex-direction: column;
        width: 305px;
        padding-right: 12px;
    }

    .description h3 {
        margin: 0 0 2px;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #2d3840;
    }

    .description span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2d3840;
    }

    .from-to {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .date-time {
        display: flex;
        margin: 0 0 2px;
        justify-content: space-between;
    }

    .date-time h4 {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #2d3840;
    }

    .date-time span {
        flex-grow: 1;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #2d3840;
        white-space: nowrap;
        min-width: 120px;
        text-align: right;
    }

    .sector.inner {
        background: #fff;
        flex-basis: 100%;
        position: relative;
        margin-top: 24px;
        margin-bottom: 0;
    }

    .original {
        height: 28px;
        padding: 0 12px;
        background: #2c3e50;
        border-radius: 40px;
        color: #fff;
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    .inner .description {
        width: 291px;
    }

    .inner .from-to {
        padding-right: 0;
    }

    .no-border {
        border: none;
        padding: 0;
    }

    .price-difference {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 14px;
        min-width: 251px;
        background: #f3f6fc;
        border-radius: 4px;
        padding: 16px;
    }

    .price-difference.no-padding {
      padding: 0;
    }

    .price-difference.blank {
        background: transparent;
    }

    /* .break-down {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 8px;
    }

    .break-down.total {
        padding: 14px 0 0;
        border-top: 1px solid white;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #141b22;
    }

    .break-down.total.no-border {
        border: none;
        padding: 0;
    }

    .amount {
        font-weight: 600;
    }

    .amount em {
      font-weight: 400;
      font-size: 8px;
      line-height: 21px;
      color: #141b22;
      font-style: normal;
      padding-left: 2px;
    } */

    .failed {
      margin: 10px 0 0;
      display: flex;
      border: 1px solid #DA1E28;
      padding: 18px;
      border-radius: 4px;
      flex-wrap:wrap;
    }

    .failed h3 {
      margin: 0 0 0 10px;
      font-weight: 600;
      font-size: 16px;
      color: #DA1E28;
    }

    .failed p {
      flex-shrink: 0;
      margin: 4px 0 0 30px;
      font-weight: 400;
      font-size: 14px;
      color: #2D3840;
    }
</style>
