<script>
  import icons from './iconData';

  export let type = icons[Object.keys(icons)[0]];

  export let IconData;
  $: {
    IconData = icons[type];
  }
</script>

{ #if IconData }
<svg class="icon" width="100%" height="100%" viewBox="{ IconData.viewBox }" focusable="false" role="presentation">
{ #if IconData.type === 'path' }
  <path fill="currentColor" d="{ IconData.data }"></path>
{ /if }
{ #if IconData.type === 'polygon' }
  <polygon fill="currentColor" points="{ IconData.data }"></polygon>
{ /if }
</svg>
{ /if }

<style>
  .icon {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
  }
</style>
