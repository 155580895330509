<script>
  import { onMount } from 'svelte';

  import PaymentGroup from './_PaymentGroup.svelte';

  export let itinerary;

  function createPriceSummary(itinerary) {
    const { Configurations } = JSON.parse(JSON.stringify(itinerary));
    const payments = Configurations.PaymentMethod.PaymentItems;
    let _sectors = [];

    let _priceSummary = {
      Flight: {
        total: 0,
        payments: [],
      },
      Hotel: {
        total: 0,
        payments: [],
      },
      Car: {
        total: 0,
        payments: [],
      },
      Rail: {
        total: 0,
        payments: [],
      },
      Shuttle: {
        total: 0,
        payments: [],
      },
    }

    Object.keys(_priceSummary).forEach(key => {
      const sectorType = `${key}s`;
      _sectors = _sectors.concat(itinerary[sectorType]);
    });
    
    // SHUTTLE PAYMENT SETUP 
    if (itinerary.Shuttles && itinerary.Shuttles.length > 0) {
      itinerary.Shuttles.forEach(shuttle => {
        _priceSummary.Shuttle.total += shuttle.Amount
        _priceSummary.Shuttle.payments.push({
          PaymentType: 'Invoice',
          Amount: shuttle.Amount,
          Type: 'Shuttle',
        })
      })
    }


    let i = _sectors.length;
    while (i--) {
      const sector = _sectors[i];
      const matchedPayment = payments.find(payment => {
        if (payment.Type === 'Seat') return;

        if (payment.SectorIdentifier) {
          return payment.SectorIdentifier === sector.Identifier
        } else {
          return payment.SectorGroupIdentifier === sector.GroupIdentifier;
        }
      });

      if (matchedPayment) {
        const total = sector.PayableAmount === 'number' ? sector.PayableAmount : sector.TotalAmount;
        _priceSummary[matchedPayment.Type].total += total;
        sector.total = total;

        if (matchedPayment.Type === 'Flight') {
          sector.title = sector.Carrier + sector.FlightNumber;
        } else if (matchedPayment.Type === 'Hotel') {
          sector.title = sector.HotelName;
        } else if (matchedPayment.Type === 'Car') {
          sector.title = `${sector.Supplier.Title} (${sector.Vehicle.Class})`;
        } else if (matchedPayment.Type === 'Shuttle') {
          sector.title = 'Airport Transfer Payment';
        } else {
          sector.title = 'Rail payment';
        }

        if (matchedPayment.sectors) {
          matchedPayment.sectors.push(sector);
        } else {
          matchedPayment.sectors = [sector];
        }
      }

      _sectors.splice(i, 1);
    }

    payments.forEach(payment => {
      if (payment.Type === 'Seat') return;
      _priceSummary[payment.Type].payments.push(payment);
    });

    return _priceSummary;
  };

  let priceSummary;

  onMount(() => {
    if (itinerary) priceSummary = createPriceSummary(itinerary);
  })

</script>

{#if priceSummary}
<div class="container">
  <div class="paymentDetails">

    {#if itinerary.Taxis.filter(item => item.Status === 'Booked').length > 0}
    <PaymentGroup
      groupType="taxi"
      {itinerary}
    >
    </PaymentGroup>
    {/if}

    {#if priceSummary.Flight.payments.length > 0}
    <PaymentGroup
      group="{priceSummary.Flight}"
      groupType="flight"
      {itinerary}
    >
    </PaymentGroup>
    {/if}

    {#if priceSummary.Hotel.payments.length > 0}
    <PaymentGroup
      group="{priceSummary.Hotel}"
      groupType="hotel"
      {itinerary}
    >
    </PaymentGroup>
    {/if}

    {#if priceSummary.Car.payments.length > 0}
    <PaymentGroup
      group="{priceSummary.Car}"
      groupType="car"
      {itinerary}
    >
    </PaymentGroup>
    {/if}

    {#if priceSummary.Shuttle.payments.length > 0 && priceSummary.Shuttle.total > 0}
    <PaymentGroup
      group="{priceSummary.Shuttle}"
      groupType="shuttle"
      {itinerary}
    >
    </PaymentGroup>
    {/if}

    {#if priceSummary.Rail.payments.length > 0}
    <PaymentGroup
      group="{priceSummary.Rail}"
      groupType="rail"
      {itinerary}
    >
    </PaymentGroup>

    {/if}
  </div>
</div>
{/if}

<style>
  .container {
    margin-top: 32px;
  }
</style>
