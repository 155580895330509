<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let optionActive = 'left';

    function handleClick(optionActive) {
        optionActive = optionActive
        dispatch('select', optionActive)
    }
</script>

<div class="switcher">
    <div class="switcher_option { optionActive === 'left' ? 'active' : '' }" on:click="{() => handleClick('left')}">
        <slot name="left">Left</slot>
    </div>
    <div class="switcher_option { optionActive === 'right' ? 'active' : '' }" on:click="{() => handleClick('right')}">
        <slot name="right">Right</slot>
    </div>
</div>

<style>
    .switcher {
        border: 1px solid var(--blue_4);
        border-radius: 3px;
        height: 32px;
        display: flex;
    }

    .switcher_option {
        color: var(--blue_4);
        font-size: 14px;
        font-weight: 500;
        /* padding: 7px 0; */
        text-align: center;
        user-select: none;
        flex-grow: 1;
        width: 50%;
        justify-content: center;
        align-items: center;
    }

    .switcher_option.active {
        background: var(--blue_4);
        color: #fff;
    }
</style>
