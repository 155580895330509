<script>
  import SectorStatusList from '../SectorStatusList/SectorStatusList.svelte';
  import { formatDate } from '../../util/date';
  import { groupSectorsByDate } from '../../util/itinerary';

  import {
    dateFormat,
  } from '../../store/index.js';

  export let itinerary;
  export let hotelFailure = false;

  $: {
    itinerary.Taxis = [];
  }
</script>

<div class="itineraryTimeline">
  {#each groupSectorsByDate(itinerary) as group}
  <div class="itineraryTimeline_group">
    <h3 class="itineraryTimeline_header_title">
      { formatDate(group.date, $dateFormat.DATE_EXTENDED) }
    </h3>

    <SectorStatusList {hotelFailure} sectors="{ group.sectors }"></SectorStatusList>
  </div>
  {/each}
</div>

<style>
  .itineraryTimeline_group:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .itineraryTimeline_header_title {
    color: #2c3e50;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 7px 0;
  }
</style>
