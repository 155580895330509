<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import Modal from '@ctm/ui/Modal';
  import { updateApproval, approveItinerary } from '../../http/approval';

  const dispatch = createEventDispatcher();

  export let approve = true;
  export let itinerary = undefined;
  export let isPta = undefined;
  export let targetElem;
  export let complete = () => { };
  export let cancel = () => { };
  export let onButtonClick = () => {
    if (isWaiting) return;
    complete();
  };

  let isWaiting = false;
  let title = 'Approve this request';
  let comment = '';
  let text = 'You can leave an optional comment for the requester if you choose.';


  onMount(() => {
    if (!approve) {
      title = 'Deny this request';
      text = 'Please provide a reason for denying this request';
      approve = false;
    }
  });


  function onModalOk() {
    isWaiting = true;
    const approvalRequest = isPta ? updateApproval : approveItinerary;

    const params = {
      itinerary,
      comment,
      approve
    };

    approvalRequest(params).then(res => {
      dispatch('approvalUpdated');
      complete();
    }).catch(err => {
      isWaiting = false;
    })
  }

  function onModalCancel() {
    if (isWaiting) return;
    cancel();
  }
</script>

<Modal
  {isWaiting}
  on:ok="{onModalOk}"
  okText="Submit"
  title="{title}"
  on:cancel="{onModalCancel}"
  isOkDisabled="{ !approve && comment.length === 0 }">

  <p>{text}</p>
  <textarea bind:value="{comment}"></textarea>

</Modal>

<style>
  p {
    margin-bottom: 40px;
  }

  textarea {
    width: 100%;
    height: 140px;
    border: 1px solid #D8DBDF;
    border-radius: 3px;
    background-color: #FFFFFF;
    padding: 10px;
    font-size: 14px;
  }
</style>
