<script>
    import LocationDetails from './_LocationDetails.svelte';
    import TaxiItemHeader from './_TaxiItemHeader.svelte';
    import {
      taxiSelectedPickUpTime,
    } from '../../store';
    export let details = null;
    export let searchMode = false;
</script>

{#if details}
    <div class="details">
        <TaxiItemHeader
            {searchMode}
            noPadding
            code={details.Code}
            on:viewBooking />

        <p class="car-type">
            {#if details.Code === 'C1'}
                White Standard Sedan<br />
                Seating for up to 4 passengers
            {:else if details.Code === 'M1'}
                Black Luxury Mercedes<br />
                Seating for up to 4 passengers
            {:else if details.Code === 'E1'}
                Switch Electric Cabs<br />
                Seating for up to 4 passengers
            {:else if details.Code === 'V1'}
                White Standard Van<br />
                Seating for up to 6 passengers
            {:else}
                Any Vehicle
            {/if}
        </p>

        <div class="extras">
            <LocationDetails item={details} pickUpTime={$taxiSelectedPickUpTime || details.PickUpTime} showTime />

            <div class="price">
                {details.TotalFare.toFixed(2)} <span>NZD</span>
            </div>
        </div>
    </div>
{/if}

<style>
    .price {
        font-weight: 600;
        font-size: 26px;
        line-height: 39px;
        text-align: right;
        margin: 0 0 16px;
    }

    .price span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .car-type {
        margin: 0 0 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        padding-left: 38px;
    }

    @media (min-width: 928px) {
        .details {
            background: #ffffff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            padding: 16px;
            margin: 0 0 24px;
            width: 100%;
        }

        .car-type {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            padding-left: 39px;
        }

        .extras {
            padding-left: 39px;
            display: flex;
        }

        .price {
            min-width: 83px;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
        }

        .price span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }
    }
</style>
