import { get } from 'svelte/store';

import {
  CONFIG,
  userDebtor
} from '../store/index.js';


export function getHeaders(isAuthenticated) {
  const headers = {};
  headers['X-api-version'] = CONFIG.API_VERSION;
  headers['Content-Type'] = 'application/json';

  if (isAuthenticated) {
    const { access_token } = JSON.parse(sessionStorage.getItem('oauth')) || {};
    headers['Authorization'] = `Bearer ${access_token}`;
  }

  const debtor = get(userDebtor);

  if (debtor) {
    headers['X-Api-Debtor'] = debtor.Name;
  }

  return headers;
}
