<script>
    import { createEventDispatcher } from 'svelte';
    import Card from '@ctm/ui/Card';
    import FailedIcon from './_FailedIcon.svelte';
    import RefreshIcon from './_RefreshIcon.svelte';
    import SuccessIcon from './_SuccessIcon.svelte';
    import {
        itinerarySearch,
        newSearch,
        removeFlight,
    } from '../../http/itinerary';

    const dispatch = createEventDispatcher();

    export let itinerary;

    let failed_passenger_names = [];
    let disabled = false;

    $: itineraryId = itinerary.Identifier;
    $: multi_pax = itinerary.Passengers.length > 1;
    $: single_fare = !multi_pax && itinerary.Passengers[0].Flights.length === 1;
    $: booking_partially_successful =
        multi_pax && itinerary.Passengers.find((p) => p.Pnr);
    $: failed_passenger_names = itinerary.Passengers.filter((p) => !p.Pnr).map(
        ({ Firstname, Lastname }) => {
            return `${Firstname} ${Lastname}`;
        }
    );

    async function handle_refresh() {
        disabled = true;
        sessionStorage.removeItem('makingBooking');

        const promises = [];
        const flights = [];

        if (itinerary.Passengers.length === 1) {
            // SINGLE PAX FLOW
            const passenger = itinerary.Passengers[0];

            itinerary.Passengers[0].Flights.forEach((flight) => {
                if (flight.BookingErrorType === 2) {
                    promises.push(
                        removeFlight(
                            itineraryId,
                            passenger.Identifier,
                            flight.Identifier
                        )
                    );

                    const flight_search_data = itinerary.Search.Flights.find(
                        (f) => f.Identifier === flight.SearchIdentifier
                    );
                    if (flight_search_data)
                        flights.push(format_flight(flight_search_data));
                    else
                        flights.push({
                            Origin: { Code: flight.Departure.Code },
                            Destination: { Code: flight.Arrival.Code },
                            Date: flight.Departure.Date,
                        });
                }
            });

            await Promise.all(promises);

            const new_search_payload = {
                ItineraryIdentifier: itineraryId,
                Flights: flights,
                Cars: [],
                Hotels: [],
                Rails: [],
                Passengers: [
                    {
                        Firstname: passenger.Firstname,
                        Lastname: passenger.Lastname,
                        FullName: passenger.FullName,
                        Identifier: passenger.Identifier,
                    },
                ],
                SearchTypes: ['Flight'],
            };

            await itinerarySearch({
                data: new_search_payload,
                id: itineraryId,
            });

            dispatch('refresh');
        } else {
            // MULTI PAX FLOW
            const new_itinerary_search_payload = {
                search: {
                    Passengers: [],
                    Flights: [],
                    Hotels: [],
                    Cars: [],
                    Rails: [],
                    OrderNumber: itinerary.OrderNumber,
                    Reason: itinerary.AgencyReasonCode
                },
            };

            const initial_search = JSON.parse(sessionStorage.getItem('initial_search'));
            if (!initial_search || initial_search.id != itinerary.Identifier) return window.location = `${window.location.origin}/#/search`;
            const initial_passengers = initial_search.payload.Passengers;
            const failed_passenger_names = itinerary.Passengers.filter(pax => !pax.Pnr).map(pax => `${pax.Firstname} ${pax.Lastname}`);
            new_itinerary_search_payload.search.Passengers = initial_passengers.filter(pax => failed_passenger_names.includes(`${pax.Firstname} ${pax.Lastname}`));

            itinerary.Search.Flights.forEach((flight) => {
                new_itinerary_search_payload.search.Flights.push(
                    format_flight(flight)
                );
            });

            itinerary.Search.Hotels.forEach((hotel) => {
                new_itinerary_search_payload.search.Hotels.push(
                    format_hotel(hotel)
                );
            });

            itinerary.Search.Cars.forEach((car) => {
                new_itinerary_search_payload.search.Cars.push(format_car(car));
            });

            const new_itinerary = await newSearch(new_itinerary_search_payload);
            sessionStorage.setItem(
                'search:itineraryId',
                new_itinerary.data.Identifier
            );
            dispatch('refresh');
        }
    }

    function format_flight(data) {
        return {
            Origin: { Code: data.Origin.Code },
            Destination: { Code: data.Destination.Code },
            Date: data.Date.slice(0, -6),
        };
    }

    function format_hotel(data) {
        return {
            DateFrom: data.DateFrom.slice(0, -6),
            DateTo: data.DateTo.slice(0, -6),
            Location: {
                Code: data.Location.Code,
                Name: data.Location.Name,
                Type: data.Location.Type,
            },
        };
    }

    function format_car(data) {
        return {
            PickUp: {
                Date: data.PickUp.Date.slice(0, -6),
                Code: data.PickUp.Code,
            },
            DropOff: {
                Date: data.DropOff.Date.slice(0, -6),
                Code: data.DropOff.Code,
            },
        };
    }

    function handle_pnr(pnr) {
        window.location.hash = `/manage-bookings/${pnr}`;
    }
</script>

<div
    class="booking-failed"
    class:single={!multi_pax || (multi_pax && !booking_partially_successful)}>
    {#if multi_pax}
        {#if booking_partially_successful}
            <div class="booking-failed-header">
                <div class="booking-failed-header-content">
                    <h2>Sorry, your booking partially failed</h2>

                    <p>
                        Not all travellers' bookings were successful.<br />
                        Please check below for details.
                    </p>
                </div>
            </div>
        {:else}
            <Card>
                <div class="booking-failed-details">
                    <h2>
                        <FailedIcon />Sorry, your booking failed
                    </h2>

                    <p>
                        One or more of your selections are no longer available.<br />
                        Please search again.
                    </p>

                    <button on:click={handle_refresh} {disabled}>
                        <RefreshIcon />Search again
                    </button>
                </div>
            </Card>
        {/if}
    {/if}

    {#if multi_pax}
        {#each itinerary.Passengers as pax}
            {#if pax.Pnr}
                <Card>
                    <div class="booking-failed-details">
                        <h2>
                            <SuccessIcon />

                            <span>
                                {pax.Firstname}
                                {pax.Lastname} - #{pax.Pnr}
                            </span>
                        </h2>

                        <button
                            class="ghost"
                            on:click={() => handle_pnr(pax.Pnr)}>
                            View booking
                        </button>
                    </div>
                </Card>
            {/if}
        {/each}

        {#if booking_partially_successful}
            <Card>
                <div class="booking-failed-details">
                    <h2>
                        <FailedIcon />
                        <span>{failed_passenger_names.join(', ')}</span>
                    </h2>

                    <p>
                        One or more of the fares selected are no longer
                        available.<br />
                        Please search again.
                    </p>

                    <button on:click={handle_refresh} {disabled}>
                        <RefreshIcon />Search again
                    </button>
                </div>
            </Card>
        {/if}
    {:else}
        <Card>
            <div class="booking-failed-details">
                <h2>
                    <FailedIcon />Sorry, your booking failed
                </h2>

                {#if single_fare}
                    <p>
                        The fare selected is no longer available.<br />
                        Please search again.
                    </p>
                {:else}
                    <p>
                        One or more of the fares selected are no longer
                        available.<br />
                        Please search again.
                    </p>
                {/if}

                <button on:click={handle_refresh}>
                    <RefreshIcon />Search again
                </button>
            </div>
        </Card>
    {/if}
</div>

<style>
    .booking-failed {
        background: #f3f6fc;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 64px 0 0;
        flex-grow: 1;
        align-items: center;
        gap: 24px;
    }

    .booking-failed.single {
        padding-top: 120px;
    }

    .booking-failed-header {
        background: #fff;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0 30px 0;
    }

    .booking-failed-header-content {
        width: 480px;
    }

    .booking-failed-header-content h2 {
        font-size: 21px;
        font-weight: 700;
    }

    .booking-failed-header-content p {
        margin-left: 0;
    }

    .booking-failed-details {
        width: 480px;
        padding: 25px;
        flex-shrink: 1;
        display: flex;
        flex-direction: column;
    }

    h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #2c3e50;
        font-size: 16px;
        font-weight: 600;
        gap: 10px;
    }

    p {
        color: #3f4f5f;
        font-size: 14px;
        margin: 0 0 24px 31px;
        line-height: 21px;
    }

    button {
        align-self: flex-end;
        display: flex;
        padding: 0 16px;
        height: 40px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #156af4;
        color: #fff;
        border: none;
    }

    button.ghost {
        background: transparent;
        outline: 1px solid #156af4;
        color: #156af4;
    }

    button:disabled {
        opacity: .5;
    }
</style>
