<script>
  import { onMount } from 'svelte';

  import Avatar from '@ctm/ui/Avatar';
  import Card from '@ctm/ui/Card';
  import Button, { options as buttonsOptions } from '@ctm/ui/Button';
  import { ExternalLink } from '@ctm/ui/Icons';
  import ApprovalProgressList from '../ApprovalProgressList/ApprovalProgressList.svelte';
  import FailedBookingMessage from './_FailedBookingMessage.svelte';
  import ConfirmationIntro from './_ConfirmationIntro.svelte';
  import ItineraryTimeline from '../ItineraryTimeline/ItineraryTimeline.svelte';
  import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
  import AirportTransfersBanner from '../AirportTransfersBanner/AirportTransfersBanner.svelte';
  import trackTransactions from './trackTransactions';
  import { passengerHasApproval } from '../../util/itinerary';
  import { getItineraryById } from '../../http/itinerary';
  import { formatDate } from '../../util/date';
  import getInitials from '../../util/getInitials';
  import cardIn from '../../transitions/cardIn';
  import ChangeConfirmation from './_ChangeConfirmation.svelte';
  
  import {
    CONFIG,
    dateFormat,
    track,
    trackingEvents,
    getItineraryId,
    setItineraryId,
    removeItineraryId,
  } from '../../store/index.js';

  export let settings = undefined;

  let itinerary = undefined;
  let travellers = [];
  let activeTraveller = null;
  let isWaiting = true;
  let PartialBooked = false;
  let hotelFailure = null;

  $: if (PartialBooked) hotelFailure = checkForHotelFailure();

  function checkForHotelFailure() {

    const saved_itinerary = JSON.parse(sessionStorage.getItem('itinerary-PUT-response'));
    if (!saved_itinerary) return;

    let passengerWithRoomNotAvailable = saved_itinerary.Passengers.find(p => p.Pnr === null && p.Hotels.find(h => h.Errors.find(e => e && e.Type && e.Type === 'RoomNotAvailable')));
    if (!passengerWithRoomNotAvailable) return;  

    let hotelWithRoomNotAvailable = passengerWithRoomNotAvailable.Hotels.find(h => h.Errors.find(e => e && e.Type && e.Type === 'RoomNotAvailable'));
    if (!hotelWithRoomNotAvailable) return;

    let otherPassengersWithSameHotelFailure = saved_itinerary.Passengers.filter(p => p.Identifier != passengerWithRoomNotAvailable.Identifier && p.Hotels.find(h => h.HotelId === hotelWithRoomNotAvailable.HotelId && h.Status === 'Failed' && h.Errors.length === 0))

    return {
      passengerWithRoomNotAvailable,
      hotelWithRoomNotAvailable,
      otherPassengersWithSameHotelFailure
    }
}


  
  function onManageBookingClick(pnr) {
    window.location.hash = `/manage-bookings/${pnr}`;
    track(trackingEvents.CONFIRMATION_MANAGE_BOOKINGS_CLICKED);
  }

  function onTravellerSelected(selected) {
    activeTraveller = selected;
  }

  let changed;

  onMount(() => {
    let itineraryId = getItineraryId('search');

    if (itineraryId) {
      setItineraryId('confirmation', itineraryId);
      removeItineraryId('search');
    } else {
      itineraryId = getItineraryId('confirmation');
    }

    if (!itineraryId) window.location.hash = `/search`;

    changed = JSON.parse(sessionStorage.getItem(`confirm-changed:${itineraryId}`));

    if (changed) {
      isWaiting = false;
      itinerary = changed;
      travellers = computeTravellers(changed.Passengers);
      activeTraveller = changed.Passengers[0];
      return;
    }

    function computeTravellers(passengers) {
      return passengers.map((pax) => {
          return {
            label: `${pax.Firstname} ${pax.Lastname}`,
            Identifier: pax.Identifier,
            status: pax.Status
          };
        });
    }

    if (itineraryId) getItineraryById(itineraryId)
      .then((response) => {
        const _itinerary = response.data;
        const _travellers = computeTravellers(_itinerary.Passengers);
        activeTraveller = _travellers[0];
        itinerary = _itinerary;
        travellers = _travellers;

        const hasBookingStatuses = ['Booked', 'PartialBooked'];
        if (itinerary.Status === 'PartialBooked') PartialBooked = true;

        const hasBookings = hasBookingStatuses.indexOf(itinerary.Status) > -1;
        const analyticsEnabled = CONFIG.ANALYTICS_ENABLED;
        if (analyticsEnabled && hasBookings) {
          const locationCode = window.sessionStorage.getItem('locationCode');
          trackTransactions(itinerary, locationCode);
        }
      })
      .finally(() => {
        isWaiting = false;
      });
  });
</script>

<div class="confirmation">
  { #if isWaiting }
  <FlexSpinner />
  { :else }

  { #if itinerary }
  <div class="intro">
    <div class="container">
      <ConfirmationIntro { itinerary } { travellers } { activeTraveller } { hotelFailure }
        supportEmail="{ settings.DebtorSupport.Email }" on:travellerSelected="{onTravellerSelected}" /> 
    </div>
  </div>

  <div class="main">
    <div class="container">
      {#if itinerary.Status !== 'Failed' && itinerary.IsShuttleAvailable}
        <div in:cardIn>
          <AirportTransfersBanner itineraryId={itinerary.Identifier} />
        </div>
      {/if}

      { #each itinerary.Passengers as pax, p }
      { #if pax.Status === 'Failed' && !hotelFailure }
        <div class="itineraryDetails" in:cardIn>
          <FailedBookingMessage itinerary="{ pax }" supportEmail="{ settings.DebtorSupport.Email }" />
        </div>
      { :else if pax.Pnr }
      <div class="itineraryDetails" in:cardIn>
        <Card>
          <div class="itineraryDetails_header">
            <h2>
              <span class="itineraryDetails_header_avatar">
                <Avatar alt="{ getInitials(pax.Firstname, pax.Lastname) }" textColour="#b0b7be" bgColour="#ebedef" />
              </span>
              <span class="itineraryDetails_header_title">
                { pax.Firstname } { pax.Lastname } - #{ pax.Pnr }
              </span>
            </h2>

            <div>
              <Button on:click="{() => onManageBookingClick(pax.Pnr)}" type="{ buttonsOptions.type.PRIMARY }"
                size="{ buttonsOptions.size.COMPACT }" Icon={ExternalLink}
                iconPosition="{ buttonsOptions.iconPosition.LEFT }">
                View booking
              </Button>
            </div>
          </div>

          {#if pax.IsTaxiAvailable}
            <div class="taxi-container">
              <AirportTransfersBanner taxiMode name={ `${pax.Firstname} ${pax.Lastname}` } pnr={pax.Pnr}  itineraryId={itinerary.Identifier}></AirportTransfersBanner>
            </div>
          {/if}

          <div class="cols">
            { #if changed }
              <ChangeConfirmation itinerary={pax} {changed} />
            {:else}
            <div class="itineraryStatusCol">
              <div class="itineraryTimelineCard">
                <div class="itineraryTimeline">
                  <ItineraryTimeline itinerary="{pax}"></ItineraryTimeline>
                </div>
              </div>
            </div>
            {/if}

            { #if itinerary.ChangeStatus === 'Creating' && passengerHasApproval(pax) }
            <div class="approvalStatusCol">
              <div class="approvalStatusCard">
                <h3>Approval</h3>

                <div class="approvalStatusCard_summary">
                  This booking needs to be fully approved before it expires on, <strong>{
                    formatDate(pax.TicketingTimeLimit, $dateFormat.DATE_TIME_EXTENDED) }</strong> in order to
                  avoid any applicable cancellation fees.
                </div>

                <div class="approvalStatusCard_progressList">
                  <ApprovalProgressList passengerItinerary="{pax}"></ApprovalProgressList>
                </div>
              </div>
            </div>
            { /if }
          </div>
        </Card>
      </div>
      { /if }
      {/each}


      {#if hotelFailure}
        <div class="itineraryDetails" in:cardIn>
          <Card>
            <div class="itineraryDetails_header">
              <h2>
                
                  {#if hotelFailure.otherPassengersWithSameHotelFailure.length === 0}
                    <span class="itineraryDetails_header_avatar">
                      <Avatar alt="{ getInitials(hotelFailure.passengerWithRoomNotAvailable.Firstname, hotelFailure.passengerWithRoomNotAvailable.Lastname) }" textColour="#b0b7be" bgColour="#ebedef" />
                    </span>
                  {/if}
                  <span class="itineraryDetails_header_title">
                    { hotelFailure.passengerWithRoomNotAvailable.Firstname } { hotelFailure.passengerWithRoomNotAvailable.Lastname }
                    {#each hotelFailure.otherPassengersWithSameHotelFailure as pax }
                      , {pax.Firstname } { pax.Lastname }
                    {/each}
                </span>
              </h2>

              <div>
                <Button isDisabled type="{ buttonsOptions.type.PRIMARY }"
                  size="{ buttonsOptions.size.COMPACT }" Icon={ExternalLink}
                  iconPosition="{ buttonsOptions.iconPosition.LEFT }">
                  View booking
                </Button>
              </div>
            </div>

            <div class="cols">
              <div class="itineraryStatusCol">
                <div class="itineraryTimelineCard">
                  <div class="itineraryTimeline">
                    <ItineraryTimeline hotelFailure itinerary="{hotelFailure.passengerWithRoomNotAvailable}"></ItineraryTimeline>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      {/if}
    </div>
  </div>
  { /if }
  { /if }
</div>

<style>
  .confirmation {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 64px 0 0 0;
  }

  .intro {
    background: #fff;
    display: flex;
    justify-content: center;
    padding: 48px 0;
  }

  .main {
    background: #f3f6fc;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 36px 0;
  }

  .itineraryDetails_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 21px;
  }

  .itineraryDetails_header h2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #2C3E50;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 10px 0;
  }

  .itineraryDetails_header_avatar {
    margin-right: 10px;
  }

  .itineraryDetails {
    animation: itineraryEnter 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
  }

  .itineraryDetails:not(:last-child) {
    margin-bottom: 24px;
  }

  .cols {
    display: flex;
    flex-direction: column;
    padding: 15px 32px 32px 32px;
  }

  .itineraryStatusCol {
    flex-grow: 1;
    margin-bottom: 18px;
  }

  .approvalStatusCol {
    flex-grow: 1;
    background: #F3F6FB;
    border-radius: 4px;
    padding: 18px;
  }

  .approvalStatusCol h3 {
    color: #2c3e50;
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 8px 0;
  }

  .approvalStatusCard_summary {
    color: #3f4f5f;
    font-size: 14px;
    margin-bottom: 35px;
  }

  .container {
    width: 100%;
    max-width: 980px;
    padding: 0 20px;
  }

  .taxi-container {
    padding: 0 21px;
  }

  @keyframes itineraryEnter {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (min-width: 768px) {
    .cols {
      flex-direction: row;
    }

    .itineraryStatusCol {
      margin-bottom: 0;
    }

    .approvalStatusCol {
      margin-left: 34px;
      width: 340px;
      flex-grow: 0;
    }
  }
</style>
