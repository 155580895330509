<script>
    import { onMount } from 'svelte';
    import NotificationIcon from './NotificationIcon.svelte';

    onMount(() => {
        window.Beamer && window.Beamer.init();
    });
</script>

<div class="notification beamerTrigger">
    <NotificationIcon />
</div>

<style>
    .notification {
        display: none;
    }

    @media (min-width: 768px) {
        .notification {
            display: block;
            color: #89949c;
            width: 35px;
            display: flex;
            margin-right: 22px;
            position: relative;
            left: 4px;
        }

        .notification:hover {
            color: #fff;
            opacity: 1 !important;
        }
    }
</style>
