<script>
  import { Car } from '@ctm/ui/Icons';
  import SectorStatusRow from './SectorStatusRow.svelte';

  export let sector;
</script>

<style>
  .icon {
    width: 100%;
    height: 100%;
  }
</style>

<SectorStatusRow iconType="car" status="{ sector.Status }">
  <div slot="icon" class="icon"><Car /></div>
  <div slot="title">{ sector.Supplier.Title }</div>
  <div slot="desc">{ sector.Vehicle.Class }</div>
</SectorStatusRow>
