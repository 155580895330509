<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let disabled = false;
    export let checked = false;
    export let id;

    function handleClick() {
        if (!disabled) {
            checked = !checked;   
        }
    }

    $: handleChange(checked);

    function handleChange() {
      dispatch('change', checked);
    }

</script>

<style>
    input[type='checkbox'] {
        -webkit-appearance: none;
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    .checkbox {
        display: flex;
        height: 16px;
        width: 16px;
        border: 1px solid #ced3da;
        border-radius: 2px;
        background-color: #ffffff;
        align-items: center;
        justify-content: center;
    }

    .disabled {
        background-color: #e8eaec;
        border: none;
    }

    .checked {
        background-color: #156af4;
        border: none;
    }

    svg {
        height: 10px;
        width: 12px;
    }
</style>

<div class="checkbox" class:checked class:disabled on:click={handleClick}>
    {#if checked}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 24"
            ><g fill="none" fill-rule="evenodd"
                ><path
                    fill="#fff"
                    d="M4.003 13.415a1.819 1.819 0 0 0-2.552-.06 1.773 1.773 0 0 0-.06 2.526l7.165 7.433a1.818 1.818 0 0 0 2.678-.071L28.465 3.32c.64-.75.549-1.87-.205-2.51a1.818 1.818 0 0 0-2.537.187L10.367 18.751a.806.806 0 0 1-1.19.031l-5.174-5.367z" /></g
            ></svg>
    {/if}
</div>

<input {id} {disabled} type="checkbox" bind:checked />
