<script>
    import dayjs from 'dayjs';
    import utc from 'dayjs/plugin/utc';
    dayjs.extend(utc);

    import DatePicker from '@ctm/ui/DatePicker';
    import LocationSelect from './_LocationSelect.svelte';
    import TaxiIcon from './_TaxiIcon.svelte';
    import TimeSelect from './_TimeSelect.svelte';

    import {
        taxiSelected,
        taxiSearchResults,
        taxiTravellerName,
        taxiTravellerPnr,
        taxiItinerarySectors,
        taxiPaxDetails,
        taxiPickUp,
        taxiDropOff,
        taxiSearching,
        taxiSelectedSuggestion,
    } from '../../store/index';
    import { onMount } from 'svelte';
    import { taxiSearch } from '../../http/taxis';

    $: firstDateTime = $taxiItinerarySectors[0].date;

    let time = null;
    let date = null;
    let minDate = null;

    $: showDateTime = $taxiPickUp && $taxiDropOff;
    $: canSearch = showDateTime && time && date && !$taxiSearching;

    async function handleSearch() {
        $taxiSearchResults = null;
        $taxiSearching = true;
        $taxiSelected = null;

        const origin = {
            latitude: `${$taxiPickUp.value.Latitude}`,
            longitude: `${$taxiPickUp.value.Longitude}`,
            description: `${$taxiPickUp.label}`,
        };

        const destination = {
            latitude: `${$taxiDropOff.value.Latitude}`,
            longitude: `${$taxiDropOff.value.Longitude}`,
            description: `${$taxiDropOff.label}`,
        };

        const PickUpTime = dayjs(
            `${date} ${time.value}`,
            'DD/MM/YYYY h:mm A'
        ).format('YYYY-MM-DDTHH:mm');

        try {
            let res = await taxiSearch({
                ...$taxiPaxDetails,
                PickUpTime,
                origin,
                destination,
            });

            $taxiSearchResults = res.map((item, i) => {
                item.Id = i;
                item.Code = item.CorporateCabsOptions.VehicleType;
                item.Origin = $taxiPickUp.value;
                item.Destination = $taxiDropOff.value;
                return item;
            });
        } catch (err) {
            //
        }

        $taxiSearching = false;
    }

    onMount(() => {
        if ($taxiItinerarySectors[0].sectorType === 'flight')
            time = dayjs(firstDateTime, 'YYYY-MM-DDTHH:mm').format('h:mm A');
        else time = '9:00 AM';
        date = new Date(firstDateTime);
        minDate = dayjs(firstDateTime, 'YYYY-MM-DD').format('DD/MM/YYYY');
    });

    $: updateDateTimeFromSuggestion($taxiSelectedSuggestion);

    let suggestions = {};
    function updateDateTimeFromSuggestion(suggestion) {
        if (!suggestion || showDateTime || suggestions['pick-up']) return;
        
        suggestions[suggestion.id] = true;

        const { sectorType, data, flightDirection } = suggestion;

        if (sectorType === 'hotel') {
            date = dayjs(data.DateTo, 'YYYY-MM-DD').format('DD/MM/YYYY');
            time = '9:00 AM';
        } else {
            date = dayjs(data[flightDirection].Date, 'YYYY-MM-DD').format(
                'DD/MM/YYYY'
            );
            time = dayjs(data[flightDirection].Date, 'YYYY-MM-DDTHH:mm').format(
                'h:mm A'
            );
        }
    }

    function handleClear(type) {
      delete suggestions[type];
    }
</script>

<div class="taxi-search-form">
    <div class="header">
        <TaxiIcon /> Taxis for {$taxiTravellerName} - #{$taxiTravellerPnr}
    </div>

    <div class="group">
        <label for="pick-up"
            >Pick-up Location
            <LocationSelect
                id="pick-up"
                placeholder="Enter Pick-up Location"
                bind:value={$taxiPickUp}
                on:clear={() => handleClear('pick-up')} />
        </label>

        {#if showDateTime}
            <div class="inline-group">
                <label for="pick-up-time"
                    >Pick-up Time
                    <TimeSelect id="pick-up-time" bind:value={time} />
                </label>

                <label for="pick-up-date"
                    >Pick-up Date
                    <DatePicker hasIcon={false} bind:date {minDate} />
                </label>
            </div>
        {/if}
    </div>

    <div class="group">
        <label for="drop-off"
            >Drop-off Location
            <LocationSelect
                id="drop-off"
                placeholder="Enter Drop-off Location"
                bind:value={$taxiDropOff}
                on:clear={() => handleClear('drop-off')} />
        </label>
    </div>
    <div class="footer">
        <button disabled={!canSearch} on:click={handleSearch}
            >Find Taxis</button>
    </div>
</div>

<style>
    .taxi-search-form {
        background: #fff;
        margin: 0 0 24px;
        padding: 0 0 24px;
        border-bottom: 1px solid #ebecf0;

        --CTM-DatePicker-input-height: 48px;
    }

    .header {
        display: flex;
        align-items: center;
        gap: 18px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        border-bottom: 1px solid #ebecf0;
        padding: 24px 0;
        margin: 0 0 24px;
    }

    label {
        color: #6d7880;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0 0 24px;
        display: flex;
        flex-direction: column;
        gap: 7px;
    }

    .inline-group {
        display: flex;
        justify-content: space-between;
        gap: 8px;
    }

    .inline-group label {
        flex-grow: 1;
        max-width: 50%;
    }

    .footer button {
        display: block;
        background: #156af4;
        border: none;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        height: 48px;
        width: 100%;
        border-radius: 4px;
    }

    .footer button:disabled {
        background: #156af44d;
    }

    :global(.taxi-search-form .datePicker .userInput) {
        font-size: 16px !important;
    }

    @media (min-width: 928px) {
        .taxi-search-form {
            width: 928px;
            padding: 32px;
            border-bottom: none;
            border-radius: 4px;
        }

        .header {
            padding: 0 0 24px;
        }

        .group {
            max-width: 610px;
        }
        .footer {
            display: flex;
            justify-content: flex-end;
        }

        .footer button {
            width: 89px;
            height: 34px;
            font-weight: 600;
            font-size: 12px;
        }
    }
</style>
