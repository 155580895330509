<script>
  import ExclaimMessage from '../ExclaimMessage/ExclaimMessage.svelte';

  let header = 'You are now signed out';
  let message = 'Thank you for using Lightning Book.';
</script>

<style>
  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }
</style>


<div class="container">
  <ExclaimMessage {header} {message} showExclaim="{false}"></ExclaimMessage>
</div>
