<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    import Avatar from '@ctm/ui/Avatar';
    import Card from '@ctm/ui/Card';
    import { Plane, Hotel, Car, Train, Return } from '@ctm/ui/Icons';
    import Shuttle from '../ManageBookings/_ShuttleIcon.svelte';
    import LB_Icon from '../Icon/Icon.svelte';
    import ApprovalStateLabel from '../ApprovalStateLabel/ApprovalStateLabel.svelte';
    import TaxiIcon from '../Taxis/_TaxiIcon.svelte';
    import getInitials from '../../util/getInitials';
    import { formatDate } from '../../util/date.js';
    import { dateFormat } from '../../store/index.js';

    export let itinerary = null;

    function onClick() {
        dispatch('select', itinerary);
    }

    $: ({
        ApprovalState,
        Status,
        FirstName,
        LastName,
        Pnr,
        TravelType,
        Locations,
        DepartureDate,
        ReturnDate,
        HasCarSegment,
        HasFlightSegment,
        HasHotelSegment,
        HasRailSegment,
        HasShuttleSegment,
        HasTaxiSegment,
    } = itinerary);
    $: PassengerInitials = getInitials(FirstName, LastName || '');
    $: notClickable = Status === 'Cancelled' || ['Cancelled', 'Declined', 'Expired'].includes(ApprovalState);
</script>

<Card on:click={onClick} isClickDisabled={notClickable}>
    <div class="itinerarySummaryCard">
        <div class="itinerarySummaryCard_header">
            <div class="itinerarySummaryCard_header_avatar">
                <Avatar
                    alt={PassengerInitials}
                    textColour="#b0b7be"
                    bgColour="#ebedef" />
            </div>

            <div class="itinerarySummaryCard_header_name">
                {FirstName}
                {LastName || ''}
            </div>

            <div
                class="itinerarySummaryCard_header_status
          itinerarySummaryCard_header_status-label">
                <ApprovalStateLabel
                    state={Status === 'Cancelled' ? Status : ApprovalState} />
            </div>
        </div>

        <div class="itinerarySummaryCard_body">
            <div class="detail detail-pnr">
                <div class="detail_header">PNR</div>
                <div class="detail_content">{Pnr}</div>
            </div>

            <div class="detail detail-trip">
                <div class="detail_header">Trip</div>
                <div class="detail_content detail_content-trip">
                    {#if TravelType === 'Return'}
                        <span>{Locations[0][0]}</span>
                        <span class="tripIcon">
                            <Return />
                        </span>
                        <span>{Locations[0][1]}</span>
                    {:else if ['OneWay', 'OpenJaw', 'Multi'].includes(TravelType)}
                        <div class="open-jaw">
                            {#each Locations as location, locationIndex}
                                <div class="stop">
                                    {#if location.length === 3 && location[0] === location[2]}
                                        {location[0]} <span class="icon"><Return /></span> {location[1]}
                                    {:else}
                                        {#each location as stop, stopIndex}
                                            {stop} {#if stopIndex < location.length - 1}<span class="icon"><LB_Icon type="oneway" /></span>{/if}
                                        {/each}
                                    {/if}
                                </div>
                                {#if locationIndex < Locations.length - 1}<div class="comma">,</div>{/if}
                            {/each}
                        </div>
                    {:else}
                        <span>{Locations[0] ? Locations[0][0] : '-'}</span>
                    {/if}
                </div>
            </div>

            <div class="detail detail-dates">
                <div class="detail_header">Travel Dates</div>
                <div class="detail_content">
                    {formatDate(DepartureDate, $dateFormat.DATE_EXTENDED)} - {formatDate(
                        ReturnDate,
                        $dateFormat.DATE_EXTENDED
                    )}
                </div>
            </div>

            <div class="detail detail-services">
                <div class="detail_content detail_content-services">
                    {#if HasFlightSegment}
                        <div class="serviceIcon"><Plane /></div>
                    {/if}
                    {#if HasHotelSegment}
                        <div class="serviceIcon"><Hotel /></div>
                    {/if}
                    {#if HasCarSegment}
                        <div class="serviceIcon"><Car /></div>
                    {/if}
                    {#if HasRailSegment}
                        <div class="serviceIcon"><Train /></div>
                    {/if}
                    {#if HasShuttleSegment}
                        <div class="serviceIcon"><Shuttle /></div>
                    {/if}
                    {#if HasTaxiSegment}
                        <div class="serviceIcon"><TaxiIcon /></div>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</Card>

<style>
    .itinerarySummaryCard_header {
        border-bottom: 1px solid #d8dbdf;
        display: flex;
        align-items: center;
        padding: 9px 16px;
    }

    .itinerarySummaryCard_header_name {
        color: #2c3e4f;
        font-size: 14px;
        font-weight: 600;
        margin-left: 7px;
    }

    .itinerarySummaryCard_header_status {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        padding-right: 6px;
    }

    .itinerarySummaryCard_header_status-label {
        display: none;
    }

    .itinerarySummaryCard_body {
        display: flex;
        flex-wrap: wrap;
        padding: 23px 17px;
        position: relative;
    }

    .detail {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding-bottom: 16px;
    }

    .detail-dates,
    .detail-trip {
        width: 100%;
    }

    .detail-services {
        padding-bottom: 0;
    }

    .detail:last-of-type {
        padding-bottom: 0;
    }

    .detail_header {
        color: #2c3e50;
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 2px 0;
        min-width: 70%;
    }

    .detail_content {
        color: #3f4f5f;
        font-size: 14px;
    }

    .detail_content-services {
        display: flex;
    }

    .serviceIcon {
        width: 24px;
        height: 24px;
        margin: 0 2px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .detail_content-trip {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .tripIcon {
        width: 14px;
        height: 14px;
        margin: 0 6px;
    }

    .open-jaw {
        display: flex;
        flex-wrap: wrap;
    }

    .comma {
        margin-right: 6px;
    }

    .open-jaw .stop {
        display: flex;
        gap: 6px;
        align-items: center;
        flex-wrap: wrap;
    }

    .open-jaw .stop .icon {
        display: flex;
        width: 14px;
        height: 14px;
    }

    @media (min-width: 1025px) {
        .itinerarySummaryCard_header_status-label {
            display: flex;
        }

        .itinerarySummaryCard_body {
            flex-direction: row;
        }

        .detail {
            flex: 1;
            padding-bottom: 0;
            padding-right: 20px;
        }

        .detail-dates {
            max-width: 350px;
            flex-grow: 2;
        }

        .detail-pnr {
            max-width: 186px;
        }

        .detail-trip {
            width: 20%;
            max-width: 200px;
        }

        .detail-services {
            align-items: flex-start;
            min-width: 80px;
        }
    }
</style>
