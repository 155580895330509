import axios from 'axios';
import {
  apiConfig,
} from '../store/index.js';
import { getHeaders } from './headers';

export async function getItineraries(params) {
  const { url, version } = apiConfig('itineraries', params);

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version,
    silentError: true
  };

  return axios(options);
}

export async function getItineraryByPNR(pnr) {
  const { url, version } = apiConfig('itineraries', { pnr });

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}

export async function getItineraryForChange(pnr) {
  const { url, version } = apiConfig('itineraryForChange', { pnr });

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}
