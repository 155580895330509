<script>
    import Select from 'svelte-select/src/Select.svelte';

    export let id = null;
    export let value;

    let hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    let items = [];
    hours.forEach((hour) => {
        items = [
            ...items,
            `${hour}:00 AM`,
            `${hour}:05 AM`,
            `${hour}:10 AM`,
            `${hour}:15 AM`,
            `${hour}:20 AM`,
            `${hour}:25 AM`,
            `${hour}:30 AM`,
            `${hour}:35 AM`,
            `${hour}:40 AM`,
            `${hour}:45 AM`,
            `${hour}:50 AM`,
            `${hour}:55 AM`,
        ];
    });
    hours.forEach((hour) => {
        items = [
            ...items,
            `${hour}:00 PM`,
            `${hour}:05 PM`,
            `${hour}:10 PM`,
            `${hour}:15 PM`,
            `${hour}:20 PM`,
            `${hour}:25 PM`,
            `${hour}:30 PM`,
            `${hour}:35 PM`,
            `${hour}:40 PM`,
            `${hour}:45 PM`,
            `${hour}:50 PM`,
            `${hour}:55 PM`,
        ];
    });
</script>

<Select {id} {items} bind:value isClearable={false} />
