<script>
  import { onMount } from "svelte";
  import { track, usersDebtor } from "../../store/index.js";

  export let itineraryId;
  export let taxiMode = false;
  export let pnr = null;
  export let name = null;

  onMount(() => {
    track("AIRPORT_TRANSFERS_BANNER_DISPLAYED", $usersDebtor);
  });

  function handleClick() {
    if (taxiMode) window.location = `${window.location.origin}/#/taxis/${itineraryId}/${pnr}`;
    else window.location = `${window.location.origin}/#/airport-transfers/${itineraryId}`;
  }

  $: headerText = taxiMode ? (name ? `Do you need to book a transfer for ${name}?` : 'Do you need to book a taxi?') : 'Do you need an airport transfer?';
  $: headerCopy = taxiMode ? 'Choose an exclusive use vehicle for your travel' : 'Choose a shared ride or exclusive use Supershuttle transfer for your traveller(s)';
  $: buttonText = taxiMode ? 'Search taxis' : 'Search Airport Transfers';
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: #b7d6f4;
    text-align: center;
    padding: 16px;
    margin: 0 0 24px;
  }

  .title {
    margin: 0 0 6px;
    color: #212b36;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  .copy {
    color: #4d555e;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px;
  }

  .button {
    height: 56px;
    border-radius: 4px;
    background-color: #1f78d1;
    outline: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 56px;
    border: none;
  }

  .button:hover {
    background-color: #084e8a;
  }

  @media (min-width: 992px) {
    .container {
      border-radius: 16px;
      background-color: #c3dffa;
      flex-direction: row;
      padding: 45px 40px;
      text-align: left;
      align-items: center;
      margin: 0 0 28px;
    }

    .content {
      flex-grow: 1;
      margin: 0 40px 0 0;
    }

    .title {
      font-size: 26px;
      font-weight: 600;
      line-height: 35px;
      margin: 0;
    }

    .copy {
      font-size: 16px;
      margin: 0;
      line-height: 24px;
    }

    .button {
      height: 40px;
      min-width: 223px;
      line-height: 40px;
      padding: 0 16px;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  }
</style>

<div class="container">
  <div class="content">
    <h2 class="title">{headerText}</h2>
    <p class="copy">
      {headerCopy}
    </p>
  </div>
  <button class="button" on:click={handleClick}>
    {buttonText}
  </button>
</div>
