<script>
  import classnames from 'classnames';

  import {
    bookingsActiveFilter,
    settings,
    track,
    trackingEvents
  } from '../../store/index.js';

  function onOptionClick(filter) {
    $bookingsActiveFilter = filter;
    track(trackingEvents.BOOKING_RESULTS_FILTER_ACTIONED, filter);
  }

  function filterOptionClassNames(key, bookingsActiveFilter) {
    return classnames({
      'filterOption-active': key === bookingsActiveFilter,
    });
  }
</script>

<div class="filters">
  <div
    class="filterOption { filterOptionClassNames('All', $bookingsActiveFilter) }"
    on:click="{() => onOptionClick('All')}"
  >
    All bookings
  </div>
  {#if $settings && !$settings.Approvals.DoNotShowApprovalTabs }
  <div
    class="filterOption { filterOptionClassNames('Pending', $bookingsActiveFilter) }"
    on:click="{() => onOptionClick('Pending')}"
  >
    Awaiting approvals
  </div>

  <div
    class="filterOption { filterOptionClassNames('Approved', $bookingsActiveFilter) }"
    on:click="{() => onOptionClick('Approved')}"
  >
    Approved
  </div>

  <div
    class="filterOption { filterOptionClassNames('Declined', $bookingsActiveFilter) }"
    on:click="{() => onOptionClick('Declined')}"
  >
    Denied
  </div>

  <div
    class="filterOption { filterOptionClassNames('Expired', $bookingsActiveFilter) }"
    on:click="{() => onOptionClick('Expired')}"
  >
    Expired
  </div>
  {/if}
</div>

<style>
  .filters {
    display: flex;
    flex-direction: column;
  }

  .filterOption {
    cursor: pointer;
    color: #8b959f;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
    width: 100%;
  }

  .filterOption-active {
    box-shadow: 0 -2px 0 0px #007aff inset;
    color: #2c3e50;
    font-size: 14px;
    transition: all 0.3s ease;
  }

  .filterOption-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .filterOption:hover {
    color: #000;
  }

  .filterOption:not(:last-of-type) {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    .filters {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .filterOption {
      width: auto;
    }

    .filterOption:not(:last-of-type) {
      margin-bottom: 0;
      margin-right: 35px;
    }
  }
</style>
