import axios from 'axios';

import { apiConfig } from '../store/index.js';
import { getHeaders } from './headers';

export async function getApprovers(params) {
  const { url, version } = apiConfig('itineraryPassengerApprovers', params);

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}

export async function updateApprover(params) {
  const { url, version } = apiConfig('itineraryPassengerApprovers', params);
  const {DebtorApproverId, FirstName, LastName, FullName, Email, Tier} = params.newApprover;

  const options = {
    url,
    method: 'PUT',
    data: {
      FirstName,
      LastName,
      FullName,
      Email,
      Tier,
      DebtorApproverId
    },
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}
