<script>
    import { createEventDispatcher, onMount } from 'svelte';

    import LeftChevron from '../AirportTransfers/Icons/LeftChevron.svelte';
    import SearchResults from './_SearchResults.svelte';
    import TaxisSearchForm from './_TaxisSearchForm.svelte';
    import ConfirmModal from './_ConfirmModal.svelte';
    import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';

    import { getItineraryById } from '../../http/itinerary';
    import { groupSectorsByDate } from '../../util/itinerary';

    const dispatch = createEventDispatcher();
    import {
        taxiSelected,
        taxiSearchResults,
        taxiShowConfirm,
        taxiItinerarySectors,
        taxiTravellerName,
        taxiTravellerPnr,
        taxiPaxDetails,
        taxiSearching,
        taxiPickUp,
        taxiDropOff,
        taxiPaxId,
    } from '../../store/index';
    

    function handleBooking() {
        $taxiShowConfirm = true;
    }

    export let itineraryId;
    export let pnr;

    $taxiTravellerName = null;
    $taxiPickUp = null;
    $taxiDropOff = null;
    $taxiTravellerPnr = null;
    $taxiSelected = null;
    $taxiSearchResults = null;
    $taxiShowConfirm = false;
    $taxiItinerarySectors = null;
    $taxiPaxDetails = null;
    $taxiSearching = false;

    onMount(async () => {
        const { data } = await getItineraryById(itineraryId);
        let paxSectors = [];
        let pax = data.Passengers.find((pax) => pax.Pnr === pnr);
        $taxiPaxId = pax.Identifier;
        
        $taxiTravellerName = pax.FullName;
        $taxiTravellerPnr = pnr;
        $taxiPaxDetails = {
            firstName: pax.Firstname,
            lastName: pax.Lastname,
            phone: '+' + pax.Mobile,
        };

        groupSectorsByDate(pax).forEach((day) => {
            paxSectors = [...paxSectors, ...day.sectors];
        });

        $taxiItinerarySectors = paxSectors;
    });
</script>

<div class="taxis">
    <div class="taxis-header">
        <button class="back" on:click={() => dispatch('back')}>
            <LeftChevron /> Back
        </button>

        <h1>Book taxis</h1>
        <p>
            Search and select from the taxi options below to add to your
            itinerary. Booking changes or cancellations must be made before date
            of booking.
        </p>
    </div>

    {#if $taxiItinerarySectors}
        <div class="taxi-content">
            <TaxisSearchForm />

            {#if $taxiSearchResults}
                <SearchResults />
            {/if}

            {#if $taxiSearching}
                <FlexSpinner />
            {/if}

            {#if $taxiSelected}
                <div class="book">
                    <button on:click={handleBooking}>Make Booking</button>
                </div>
            {/if}

            {#if $taxiShowConfirm}
                <ConfirmModal on:bookingMade />
            {/if}
        </div>
    {:else}
        <FlexSpinner />
    {/if}
</div>

<style>
    .taxis {
        background: #fff;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;
        padding: 64px 16px 0;
        min-height: 100vh;

        /* svelte-select css custom props */
        --height: 48px;
    }

    .taxis-header {
        width: 100%;
        padding: 16px 0;
        background: #fff;
        position: relative;
    }

    button.back {
        color: #007aff;
        background: #fff;
        display: flex;
        border: none;
        align-items: center;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 400;
        line-height: 22px;
        gap: 5px;
        padding: 0;
        height: 42px;
        position: absolute;
        top: 6px;
    }

    .taxis-header h1 {
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        line-height: 22px;
        padding: 0;
        margin: 0;
    }

    .taxis-header p {
        display: none;
    }

    :global(.taxis .selection) {
        font-size: 16px;
        color: #2d3840;
    }

    .book {
        display: flex;
        flex-direction: column;
        margin: 0 0 50px;
    }

    .book button {
        border: none;
        color: #fff;
        background: #156af4;
        height: 48px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
    }

    @media (min-width: 928px) {
        .taxis {
            padding: 64px 0 0;
            align-items: center;
            min-height: 100vh;
        }

        .taxis-header {
            width: 928px;
            padding: 72px 0;
        }

        .taxis-header .back {
            gap: 12px;
            top: 24px;
        }

        .taxis-header h1 {
            text-align: left;
            font-size: 30px;
            line-height: 45px;
        }

        .taxis-header p {
            display: block;
            margin: 0;
        }

        .taxi-content {
            width: 100%;
            background: #f3f6fc;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 48px 0;
            flex-grow: 1;
        }

        .book {
            padding: 12px 32px 32px;
            background: #fff;
            width: 928px;
            display: flex;
            align-items: flex-end;
        }

        .book button {
            padding: 0 16px;
            height: 34px;
            font-weight: 600;
            font-size: 12px;
        }
    }
</style>
