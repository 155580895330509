import axios from 'axios';
import { apiConfig } from '../store/index.js';
import { getHeaders } from './headers';

export async function getApprovers(itineraryId) {
  const { url, version } = apiConfig('itineraryApprovers', itineraryId);

  const options = {
    url,
    method: 'GET',
    headers: getHeaders(true),
    apiVersion: version
  };

  return axios(options);
}
