<script>
  import dayjs from "dayjs";
  import {createEventDispatcher, onDestroy} from 'svelte';
  import Card from '@ctm/ui/Card';
  import { TravelArrow, Hotel, Car, Train, ArrowDown } from '@ctm/ui/Icons';
  import { modal as modalService } from '@ctm/ui/Modal';

  import LbIcon from '../Icon/Icon.svelte';
  import Shuttle from './_ShuttleIcon.svelte';
  import { formatDate } from '../../util/date.js';
  import { ancillaryServicesAmountsFilter } from '../../util/itinerary.js';
  import { taxiCancel } from '../../http/taxis';
  import FareRulesModal from './_FareRulesModal.svelte';
  import HotelInfoModal from '../HotelInfoModal/HotelInfoModal.svelte';
  import TaxiIcon from '../Taxis/_TaxiIcon.svelte';
  import TrashIcon from './_TrashIcon.svelte';
  import FlexSpinner from '../FlexSpinner/FlexSpinner.svelte';
  import Cancel from "./_Cancel.svelte";

  import {
    dateFormat,
    DISABLE_SEATMAPS,
    NO_POLICY_BREAK,
    viewBookingPrintTotal
  } from '../../store/index.js';

  const dispatch = createEventDispatcher();

  export let updateSearchComponent = undefined;
  export let change = undefined;
  export let sector = undefined;
  export let sectorType = '';
  export let isPta = false;
  export let disableCancel = false;
  export let itinerary = null;
  export let isCancelling = false;

  let hasPayAtProperty = false;
  let hasTaxes = false;
  let payAtPropertyActive;
  let cancelling = false;

  function expandPayProperty() {
    payAtPropertyActive = !payAtPropertyActive;
  }

  $: {
    if (sector.Fees) {
      Object.keys(sector.Fees).forEach(i => {
      if (sector.Fees[i].Type === 'PayAtProperty') {
        hasPayAtProperty = true;
      } 
      if (sector.Fees[i].Type === 'Taxes') {
        hasTaxes = true;
      }
    })
    }
  }

  function showFareRules() {
    const params = {
      itineraryId: sector.ItineraryIdentifier,
      passengerId: sector.PassengerIdentifier,
      sectorType: sectorType,
      sectorId: sector.Identifier
    };

    const modal = modalService.open(FareRulesModal, {
      params
    });
  }

  function showHotelInfo(hotelData) {
    const params = {
      hotelData
    };

    const modal = modalService.open(HotelInfoModal, params);
  }

  const getStopDestination = (sector, stopIndex) => {
    if (sector.Stops.length === stopIndex + 1) {
      return sector.Arrival.Code;
    } else {
      return sector.Stops[stopIndex + 1].ArrivalCode;
    }
  };

  function totalPayAtProperty() {
    var total = 0;
    for(var i = 0; i < sector.Fees.length; i++){
        var fee = sector.Fees[i];
        if (fee.Type === 'PayAtProperty') {
          total += fee.Amount;
        }
    }
    return total.toFixed(2);
  }; 

  let totalSeats;
  $: {
    if (sectorType === 'flight') {
      const allFlightsSeats = [];
      let _totalSeats = 0;

      if (sector.Seat) {
        allFlightsSeats.push(sector.Seat);
        _totalSeats += 1;
      }

      sector.Stops.forEach(stop => {
        if (stop.Seat) {
          allFlightsSeats.push(stop.Seat);
          _totalSeats += 1;
        }
      });

      totalSeats = _totalSeats;
    }
  }

  let shuttleType = '';
  $: {
    if (sectorType === 'shuttle') {
      if (sector.ServiceLevel === 'Supershuttle' && sector.FareType === 'Shared Ride') {
        shuttleType = 'Shared Ride - Supershuttle';
      }

      if (sector.ServiceLevel === 'Supershuttle' && sector.FareType === 'Charter') {
        shuttleType = 'Exclusive Use - Supershuttle';
      }

      if (sector.ServiceLevel === 'Platinum' && sector.FareType === 'Charter') {
        shuttleType = 'Platinum - Supershuttle';
      }
    }
  }

  $:printTotal = $viewBookingPrintTotal === 'include';

  $:canCancelTaxi = handleCanCancelTaxi(sector);

  function handleCanCancelTaxi(taxi) {
    if (taxi && taxi.CustomProperties && !taxi.CustomProperties.IsCancelable) return;

    const service = dayjs(taxi.ServiceTime);
    const diff = service.diff(dayjs(), 'hour');

    return diff > 0;
  }

  let taxiCancelDetail = null;
  let taxiCancelTooLate = false;

  function handleCancelTaxi(taxi) {
    taxiCancelTooLate = !handleCanCancelTaxi(taxi);
    taxiCancelDetail = taxi;
  }

  let taxiCancelling = false;
  async function handleConfirmCancelTaxi() {
    taxiCancelling = true;
    const {PassengerIdentifier, Identifier} = taxiCancelDetail;

    try {
      await taxiCancel({PassengerIdentifier, SegmentIdentifiers: [Identifier]});
    } catch(err) {
      taxiCancelling = false;
    }
    
    handleSegmentCancel();
    taxiCancelDetail = null;
  }

  function handleSegmentCancel() {
    cancelling = true;
    dispatch('refreshItinerary');
  }

  onDestroy(() => {
    modalService.remove();
  }) 
</script>

{#if taxiCancelDetail}
<div class="cancel-taxi-backdrop">
  <div class="cancel-taxi-content">
    {#if taxiCancelTooLate}
      <h2>This taxi booking can no longer be cancelled</h2>
      <p>Only taxi bookings over an hour away can be cancelled.</p>
      <button class="close" on:click={() => taxiCancelDetail = null}>Close</button>
    {:else}
      <h2>Do you want to remove this transfer?</h2>
      <p>Removing this transfer will void this segment.</p>
      <form method="dialog">
        {#if !taxiCancelling}
          <button class="cancel" on:click={() => taxiCancelDetail = null }>Cancel</button>
        {/if}
        <button on:click={handleConfirmCancelTaxi}>
          {#if taxiCancelling}
          <FlexSpinner white />
          {:else}
          Confirm
          {/if}
        </button>
      </form>
    {/if}
  </div>
</div>

{/if}

<Card>
  <div class="itinerary_card" class:cancelling>
    <div class="itinerary_card_header">

      {#if !isPta && !disableCancel && sector.IsCancelableSegment}
        <div class="cancel-segment">
          {#if sector.has_departed}
            <div class="departed">Departed</div>
          {:else}
            <Cancel segment={sector} {sectorType} {itinerary} {isCancelling} on:cancelled={handleSegmentCancel}>
              <span class="remove-text" slot="button-text">Remove {sectorType}</span>
              <span slot="dialog-title-text">Do you want to remove this segment?</span>
              <span class="cancel-text" slot="dialog-text">Proceed to cancel and remove this segment from the itinerary. Cancellation charges may apply. 
                {#if ['hotel', 'car'].some(s => sectorType === s) && !sector.Refundable}This segment is non-refundable content.{/if}
                Do you wish to continue?
            </span>
              <span slot="success-text">Your request to cancel and remove this segment was successful.</span>
              <span slot="success-button">Close</span>
            </Cancel>
          {/if}
        </div>
      {/if}

      {#if sectorType === 'flight'}
      <div class="itinerary_card_sectorIcon">
        <img src="{sector.CarrierImage}" alt="{sector.CarrierName}" />
      </div>
      <div class="itinerary_card_title">
        <strong>
          {sector.Departure.City} ({sector.Departure.Code}) to {sector.Arrival.City} ({sector.Arrival.Code})
        </strong>
        <span>
          {sector.CarrierName}, {sector.Carrier + sector.FlightNumber}{#if sector.AircraftType} - {sector.AircraftType}{/if}
        </span>
      </div>
      {:else if sectorType === 'hotel'}
      <div class="itinerary_card_sectorIcon">
        <div class="icon">
          <Hotel />
        </div>
      </div>
      <div class="itinerary_card_title">
        <strong>{sector.HotelName}</strong>
        <span>{sector.Location.City}</span>
      </div>
      {:else if sectorType === 'car'}
      <div class="itinerary_card_sectorIcon">
        <div class="icon">
          <Car />
        </div>
      </div>
      <div class="itinerary_card_title">
        <strong>{sector.Supplier.Title}</strong>
        <span>{sector.Vehicle.Class}</span>
      </div>
      {:else if sectorType === 'rail'}
      <div class="itinerary_card_sectorIcon">
        <div class="icon">
          <Train />
        </div>
      </div>
      <div class="itinerary_card_title">
        <strong>{sector.Supplier}{sector.RailNumber}</strong>
        <span>{sector.SupplierName}</span>
      </div>
      {:else if sectorType === 'shuttle'}
      <div class="itinerary_card_sectorIcon">
        <div class="icon">
          <Shuttle />
        </div>
      </div>
      <div class="itinerary_card_title">
        <strong>{sector.PickupAddress.Location}, {sector.PickupAddress.District} to {sector.DropoffAddress.Location}, {sector.DropoffAddress.District}</strong>
        <span>Supershuttle</span>
      </div>
      {:else if sectorType === 'taxi'}
      <div class="itinerary_card_sectorIcon">
        <TaxiIcon />
      </div>
      <div class="itinerary_card_title">
        <strong>
          {#if sector.VehicleType === 'C1'}
            White Standard Sedan
          {:else if sector.VehicleType === 'M1'}
              Black Luxury Mercedes
          {:else if sector.VehicleType === 'E1'}
              Switch Electric Cabs
          {:else if sector.VehicleType === 'V1'}
              White Standard Van
          {:else}
              Any Vehicle
          {/if}
        </strong>
        <span>
          Corporate Cabs
        </span>
      </div>
        {#if canCancelTaxi && !taxiCancelling}
          <button class="cancel-taxi" on:click={() => handleCancelTaxi(sector)}><TrashIcon /> Cancel taxi</button>
        {/if}
      {/if}
    </div>
    <div class="itinerary_card_details {sectorType === 'rail' ? 'noBottomPadding' : ''}">
      {#if sectorType === 'flight'}
      <div class="primaryDetails">
        <div class="detail">
          <div class="detail_header">
            Depart
          </div>
          <div class="detail_content">
            {sector.Departure.Location} ({sector.Departure.Code})<br/>
            {formatDate(sector.Departure.Date, $dateFormat.DATE_TIME_THEN_DATE)}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Arrive
          </div>
          <div class="detail_content">
            {sector.Arrival.Location} ({sector.Arrival.Code})<br/>
            {formatDate(sector.Arrival.Date, $dateFormat.DATE_TIME_THEN_DATE)}
          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        <div class="detail">
          <div class="detail_header">
            Fare
          </div>
          <div class="detail_content">
            {sector.Detail.FlightClass}
            {#if sector.Detail.FlightBrandName}
            ({ sector.Detail.FlightBrandName })
            {/if}
            {#if false}
            <div>
              <a href="javascript:void(0);" on:click="{showFareRules}">Fare rules</a>
            </div>
            {/if}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            PNR
          </div>
          <div class="detail_content">
            {sector.BookingReference || 'Pending'}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Rates
          </div>
          <div class="detail_content">
            {sector.Detail.Rates}
          </div>
        </div>
        {#if sector.BaggageAmount > 0}
        <div class="detail">
          <div class="detail_header">
            Baggage options
          </div>
          <div class="detail_content">
            {#each sector.AncillaryServices as item}
            {#if item.QuantitySelected > 0 && item.Type === 'Baggage'}
            {item.Description}<br/>
            {/if}
            {/each}
          </div>
        </div>
        {/if}
        {#if totalSeats || !$DISABLE_SEATMAPS}
        <div class="detail">
          <div class="detail_header">
            Seats
          </div>
          <div class="detail_content">
            {#if totalSeats}
            {#if sector.Seat}
            <span class="seat">
                          { sector.Seat.OriginLocationCode } <span class="travelIcon"><TravelArrow /></span> { sector.Seat.LocationCode }<br/>
                      { sector.CarrierName } {sector.Seat.Carrier}{sector.Seat.FlightNumber}<br/>
                      {#if sector.Seat.SeatNumber}Seat: {sector.Seat.SeatNumber}{/if}
                      {#if !sector.Seat.SeatNumber}No seat selected{/if}
                      {#if sector.Seat.Status === 'Failed'}Invalid seat option{/if}
                      </span>
            {/if}
            {/if}
            {#if sector.Stops.length > 0}
            {#each sector.Stops as stop, i}
            {#if stop.Seat}
            {#if stop.Seat}
            <span class="seat">
              { stop.DepartsCode } <span class="travelIcon"><TravelArrow /></span> { getStopDestination(sector, i) } <br/>
              { stop.CarrierName } { stop.Seat.Carrier }{ stop.Seat.FlightNumber }  <br/>
              { stop.AircraftType } <br/>
              {#if stop.Seat.SeatNumber}Seat: { stop.Seat.SeatNumber }{/if}
              {#if !stop.Seat.SeatNumber}No seat selected{/if}
              {#if stop.Seat.Status === 'Failed'}Invalid seat option{/if}
              </span>
            {/if}
            {/if}
            {/each}
            {/if}
          </div>
        </div>
        {/if}
      </div>

      { #if updateSearchComponent }
      <div class="extraDetailsColumn extraDetailsColumn-search">
        <svelte:component this="{ updateSearchComponent }" {sector} bind:change />
      </div>
      { :else }

      <div class="extraDetailsColumn">
        <div class="detail policyBreak">
          <div class="detail_header">
            Policy break
          </div>
          <div class="detail_content">
            {sector.Policy.Title || $NO_POLICY_BREAK}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Note
          </div>
          <div class="detail_content">
            {#if sector.ContentWarning || sector.CurrencyCode != sector.BaseCurrency.Code ||
            sector.IsInternationalFlightNumber}
            {#if sector.ContentWarning}
            <span class="note">
              This content is Non-refundable, your company will still be charged if the booking is denied or expires.</span>
            {/if}

            {#if sector.CurrencyCode != sector.BaseCurrency.Code}
            <span class="note">
              This item will be charged in local currency ({sector.BaseCurrency.TotalAmount}<span class="unit">{sector.BaseCurrency.Code}</span>).
            </span>
            {/if}

            {#if sector.IsInternationalFlightNumber}
            <span class="note">
              This flight departs from an international terminal, please allow additional time to check-in.</span>
            {/if}
            {:else}
             -
            {/if}
          </div>
        </div>

        {#each sector.FlightPromotions as promo}
          <div class="detail">
            <div class="detail_header">
              Special Offer
            </div>
            <div class="detail_content">
              <span class="note">
                {promo.Description}
              </span>
            </div>
          </div>
        {/each}

        {#if sector.CarbonEmissionAmount > 0}
          <div class="detail">
            <div class="detail_header">
              Carbon Emissions
            </div>
            <div class="detail_content">
              {Math.round(sector.CarbonEmissionAmount)}kg
            </div>
          </div>
        {/if}

        <div class="detail">
          <div class="detail_header">
            Status
          </div>
          <div class="detail_content">
            {sector.VendorStatus}
          </div>
        </div>
      </div>

      <div class="extraDetailsColumn totals" class:print-total={printTotal}>
        <div class="pricingTable">
          <div class="pricingTable_item pricingTable_item-standout">
            <div class="pricingTable_item_row">
              <span class="pricingTable_item_label">Total</span>
              <span class="pricingTable_item_amount">{sector.TotalAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
            </div>
          </div>
        </div>
        <div class="pricingTable pricingTable-alternate">
          <div class="pricingTable_item_row">
            <span class="pricingTable_item_label">Lost savings</span>
            <span class="pricingTable_item_amount">{sector.LostSavingsAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
          </div>
        </div>
      </div>

      { /if }

      {/if}

      {#if sectorType === 'hotel'}
      <div class="primaryDetails">
        <div class="detail">
          <div class="detail_header">
            Check in
          </div>
          <div class="detail_content">
            {formatDate(sector.DateFrom, $dateFormat.DATE_EXTENDED)}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Check out
          </div>
          <div class="detail_content">
            {formatDate(sector.DateTo, $dateFormat.DATE_EXTENDED)}
          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        {#if sector.PaymentNarrative.Title}
        <div class="detail">
          <div class="detail_header">
            Payment arrangement
          </div>
          <div class="detail_content">
            {sector.PaymentNarrative.Title}
          </div>
        </div>
        {/if}
        <div class="detail">
          <div class="detail_header">
            Room type
          </div>
          <div class="detail_content">
            {sector.RoomName}
            {#if sector.BeddingConfiguration}
            <div>
                {sector.BeddingConfiguration}*
            </div>
            {/if}
            {#if sector.Allotment && !sector.IsUnlimitedAllotment}
            <div class="hotelAllotment">
              <div class="hotelAllotment_icon">
                <LbIcon type="allotment"></LbIcon>
              </div>
              <span>Allotment</span>
            </div>
            {/if}
            {#if sector.IsUnlimitedAllotment}
            <div class="hotelAllotment onRequest">
              <span>On Request</span>
            </div>
            {/if}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Supplier Confirmation Code
          </div>
          <div class="detail_content">
            {sector.ConfirmationCode || 'None'}
          </div>
        </div>
        {#if sector.GdsProvider === 'Expedia' && (sector.CheckinBeginTime || sector.CheckoutTime || sector.CheckinInstructions || sector.CheckinSpecialInstructions)}
          <div class="detail expediaDetail">
            <div class="detail_content">
              <span class="hotelInfoLink" on:click={() => showHotelInfo(sector)}>View check in and room information</span>
            </div>
          </div>
        {/if}
      </div>
      <div class="extraDetailsColumn">
        <div class="detail policyBreak">
          <div class="detail_header">
            Policy break
          </div>
          <div class="detail_content">
            {sector.Policy.Title || $NO_POLICY_BREAK}
          </div>
        </div>

        <div class="detail">
          <div class="detail_header">
            Special requests
          </div>
          <div class="detail_content">
            {sector.SpecialRequirements || 'None'}
          </div>
        </div>

        {#if sector.ContentWarning || sector.CurrencyCode != sector.BaseCurrency.Code }
        <div class="detail">
          <div class="detail_header">
            Note
          </div>
          <div class="detail_content">
            {#if sector.ContentWarning}
            <span class="note">This content is Non-refundable, your company will still be charged if the booking is denied or
                          expires.</span>
            {/if}

            {#if sector.CurrencyCode != sector.BaseCurrency.Code}
            <span class="note">This item will be charged in local currency ({sector.BaseCurrency.TotalAmount}<span
              class="unit">{sector.BaseCurrency.Code}</span>).</span>
            {/if}
          </div>
        </div>
        {/if}

        {#if sector.GdsProvider === 'Expedia'}
          <div class="detail">
            <div class="bedTypes">*Bed types are subject to availability</div>
          </div>
        {/if}

        {#if sector.CarbonEmissionAmount > 0}
          <div class="detail">
            <div class="detail_header">
              Carbon Emissions
            </div>
            <div class="detail_content">
              {Math.round(sector.CarbonEmissionAmount)}kg
            </div>
          </div>
        {/if}
      </div>

      <div class="extraDetailsColumn totals" class:print-total={printTotal}>
        <div class="pricingTable">
          <div class="pricingTable_item pricingTable_item-standout">
            <div class="pricingTable_item_row">
              <span class="pricingTable_item_label">Total</span>
              <span class="pricingTable_item_amount">{sector.TotalAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
            </div>
          </div>
          {#if sector.TaxAmount > 0}
            <div class="sectorServiceFee">
                <span class="serviceFee">Tax Recovery Charges &amp; Service Fees:</span>
                <span class="sectorPayProperty_amount"> 
                  {#if hasTaxes}
                    {#each sector.Fees as fee}
                      {#if fee.Type === 'Taxes'}
                        {(sector.TaxAmount - fee.Amount).toFixed(2)}
                      {/if}
                    {/each}
                    {:else}
                      {sector.TaxAmount}
                    {/if}
                  <span
                    class="sectorPayProperty_currency">{sector.CurrencyCode}</span></span>
            </div>
          {/if}
          {#if hasTaxes}
            {#each sector.Fees as fee}
              {#if fee.Type === 'Taxes'}
                <div class="sectorPayProperty">
                    {fee.Name} (included): 
                    <span class="sectorPayProperty_amount"> {fee.Amount} <span
                        class="sectorPayProperty_currency">{sector.CurrencyCode}</span></span>
                </div>
              {/if}
            {/each}
          {/if}
          {#if hasPayAtProperty}
          <div class="pricingTable_item pricingTable_item-standout">
            <span class="pricingTable_item_other">Other charges</span>
          </div>
            <div class="payPropertyTitle" on:click="{expandPayProperty}">
                <span class="payPropertyTitle_text">Pay at property</span>
                <div class="sectorPayProperty_icon {payAtPropertyActive ? 'animateArrow' : ''}"><ArrowDown /></div>
                <span class="payPropertyTotal"> {totalPayAtProperty()} <span class="sectorPayProperty_currency">{sector.CurrencyCode}</span></span>
            </div>
            {#each sector.Fees as fee}
              {#if fee.Type === 'PayAtProperty'}
                {#if payAtPropertyActive}
                  <div class="sectorPayProperty">
                      {fee.Name}: 
                      <span > {fee.Amount} <span
                          class="sectorPayProperty_currency">{sector.CurrencyCode}</span></span>
                  </div>
                {/if}
              {/if}
            {/each}
          {/if}
        </div>
        <div class="pricingTable pricingTable-alternate">
          <div class="pricingTable_item_row">
            <span class="pricingTable_item_label">Lost savings</span>
            <span class="pricingTable_item_amount">{sector.LostSavingsAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
          </div>
            {#if sector.CheapestHotelName && sector.CheapestHotelAmount}
            <div class="lostSavingCompared">
              <span class="lostSavingCompared_header">Compared against</span>
              <span>{sector.CheapestHotelName} - {sector.CheapestHotelAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
            </div>
            {/if}
        </div>
      </div>
      {/if}
      {#if sectorType === 'car'}
      <div class="primaryDetails">
        <div class="detail">
          <div class="detail_header">
            Pick up
          </div>
          <div class="detail_content">
            {formatDate(sector.PickUp.Date, $dateFormat.DATE_TIME_THEN_DATE)}<br/>
            {sector.PickUp.Location}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Drop off
          </div>
          <div class="detail_content">
            {formatDate(sector.DropOff.Date, $dateFormat.DATE_TIME_THEN_DATE)}<br/>
            {sector.DropOff.Location}
          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        <div class="detail">
          <div class="detail_header">
            Car type
          </div>
          <div class="detail_content">
            {sector.Vehicle.Title}
          </div>
        </div>
        <div class="detail policyBreak">
          <div class="detail_header">
            Policy break
          </div>
          <div class="detail_content">
            {sector.Policy.Title || $NO_POLICY_BREAK}
          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        <div class="detail">
          <div class="detail_header">
            Special requests
          </div>
          <div class="detail_content">
            {sector.SpecialRequests || 'None'}
          </div>
        </div>

        {#if sector.ContentWarning || sector.CurrencyCode != sector.BaseCurrency.Code}
        <div class="detail">
          <div class="detail_header">
            Note
          </div>
          <div class="detail_content">
            {#if sector.ContentWarning}
            <span class="note">This content is Non-refundable, your company will still be charged if the booking is denied or
                          expires.</span>
            {/if}

            {#if sector.CurrencyCode != sector.BaseCurrency.Code}
            <span class="note">This item will be charged in local currency ({sector.BaseCurrency.TotalAmount.toFixed(2)}<span
              class="unit">{sector.BaseCurrency.Code}</span>).</span>
            {/if}
          </div>
        </div>
        {/if}

        <div class="detail">
          <div class="detail_header">
            Supplier Confirmation Code
          </div>
          <div class="detail_content">
            {sector.ConfirmationCode || 'Pending'}
          </div>
        </div>

        {#if sector.CarbonEmissionAmount > 0}
          <div class="detail">
            <div class="detail_header">
              Carbon Emissions
            </div>
            <div class="detail_content">
              {Math.round(sector.CarbonEmissionAmount)}kg
            </div>
          </div>
        {/if}

      </div>
      <div class="extraDetailsColumn totals" class:print-total={printTotal}>
        <div class="pricingTable">
          <div class="pricingTable_item pricingTable_item-standout">
            <div class="pricingTable_item_row">
              <span class="pricingTable_item_label">Total</span>
              <span class="pricingTable_item_amount">{sector.TotalAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
            </div>
          </div>
        </div>
        <div class="pricingTable pricingTable-alternate">
          <div class="pricingTable_item_row">
            <span class="pricingTable_item_label">Lost savings</span>
            <span class="pricingTable_item_amount">{sector.LostSavingsAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
          </div>
        </div>
      </div>
      {/if}
      {#if sectorType === 'rail'}
      <div class="primaryDetails">
        <div class="detail">
          <div class="detail_header">
            Depart
          </div>
          <div class="detail_content">
            {sector.Departure.Location} ({sector.Departure.Code})<br/>
            {formatDate(sector.Departure.Date, $dateFormat.DATE_TIME_THEN_DATE)}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Arrive
          </div>
          <div class="detail_content">
            {sector.Arrival.Location} ({sector.Arrival.Code})<br/>
            {formatDate(sector.Arrival.Date, $dateFormat.DATE_TIME_THEN_DATE)}
          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        <div class="detail">
          <div class="detail_header">
            Ticket collection
          </div>
          <div class="detail_content">
            {sector.CollectionLocation}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Ticket collection pin
          </div>
          <div class="detail_content">
            {sector.CollectionPin}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Ticket
          </div>
          <div class="detail_content">
            {sector.Detail.FareDescription}
            {#each sector.Data as datum}
            {#if datum.Code === 'Short Description' || datum.Code === 'Route Restriction'}
            <br/>
            {datum.Value}
            {/if}
            {/each}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Seat allocation
          </div>
          <div class="detail_content">
            {#if sector.Stops.length === 0}
            {#if sector.SeatNumber}
            {sector.SeatNumber}
            {:else}
            --
            {/if}
            {/if}

            {#if sector.Stops.length > 0}
            <div class="seat">
              {sector.Departure.Code} <span class="travelIcon"><TravelArrow /></span>
              {sector.Stops[0].DepartsCode} <br/>
              {#if sector.SeatNumber}
              {sector.SeatNumber}
              {:else}
              --
              {/if}
            </div>
            {/if}

            {#each sector.Stops as stop, i}
            <div class="seat">
              {#if sector.Stops.length === i}
              {stop.DepartsCode} <span class="travelIcon"><TravelArrow /></span>
              {sector.Arrival.Code} <br/>
              {:else}
              {stop.DepartsCode} <span class="travelIcon"><TravelArrow /></span>
              {sector.Stops[i].ArrivalCode} <br/>
              {/if}

              {#if stop.SeatNumber}
              {stop.SeatNumber}
              {:else}
              --
              {/if}
            </div>
            {/each}

          </div>
        </div>
      </div>
      <div class="extraDetailsColumn">
        <div class="detail policyBreak">
          <div class="detail_header">
            Policy break
          </div>
          <div class="detail_content">
            {sector.Policy.Title || $NO_POLICY_BREAK}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Lost savings
          </div>
          <div class="detail_content">
            {sector.LostSavingsAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span>
          </div>
        </div>
        {#if sector.FeeAmount > 0 || sector.Fees.length > 0 ||
        ancillaryServicesAmountsFilter(sector.AncillaryServices).length > 0}

        {#if sector.Amount}
        <div class="detail">
          <div class="detail_header">
            Base fare
          </div>
          <div class="detail_content">
            {sector.Amount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span>
          </div>
        </div>
        {/if}

        {#each sector.Fees as fee}
        <div class="detail">
          <div class="detail_header">
            {fee.Type}
          </div>
          <div class="detail_content">
            {fee.Amount}<span class="unit">{sector.CurrencyCode}</span>
          </div>
        </div>
        {/each}

        {#each ancillaryServicesAmountsFilter(sector.AncillaryServices) as fee}
        <div class="detail">
          <div class="detail_header">
            {fee.Type}
          </div>
          <div class="detail_content">
            {fee.Amount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span>
          </div>
        </div>
        {/each}
        {/if}
      </div>
      <div class="extraDetailsColumn rail-totals" class:print-total={printTotal}>
        <div class="pricingTable">
          <div class="pricingTable_item pricingTable_item-standout">
            <span class="pricingTable_item_label">Total</span>
            <span class="pricingTable_item_amount">{sector.TotalAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
          </div>
        </div>
        <div class="pricingTable pricingTable-alternate">
          <div class="pricingTable_item_row">
            <span class="pricingTable_item_label">Lost savings</span>
            <span class="pricingTable_item_amount">{sector.LostSavingsAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
          </div>
        </div>

        <div class="detail">
          <div class="detail_header">
            PNR
          </div>
          <div class="detail_content">
            {#if sector.BookingReference}
            {sector.SupplierName} - #{sector.BookingReference}
            {:else}
            Pending
            {/if}
          </div>
        </div>
        <div class="detail">
          <div class="detail_header">
            Rates
          </div>
          <div class="detail_content">
            {sector.Detail.Rates}
          </div>
        </div>
        {#if sector.Detail.RailCardName}
        <div class="detail">
          <div class="detail_header">
            Rail card discounts
          </div>
          <div class="detail_content">
            {sector.Detail.RailCardName}
          </div>
        </div>
        {/if}
        {#if sector.ContentWarning || sector.CurrencyCode != sector.BaseCurrency.Code ||
        sector.IsInternationalFlightNumber}
        <div class="detail">
          <div class="detail_header">
            Note
          </div>
          <div class="detail_content">
            {#if sector.ContentWarning}
            <span class="note">This content is Non-refundable, your company will still be charged if the booking is denied or
                          expires.</span>
            {/if}

            {#if sector.CurrencyCode != sector.BaseCurrency.Code}
            <span class="note">This item will be charged in local currency ({sector.BaseCurrency.TotalAmount}<span
              class="unit">{sector.BaseCurrency.Code}</span>).</span>
            {/if}
          </div>
        </div>
        {/if}
        <div class="detail">
          <div class="detail_header">
            Special requests
          </div>
          <div class="detail_content">
            {sector.SpecialRequirements || 'None'}
          </div>
        </div>
      </div>
      {/if}

      {#if sectorType === 'shuttle'}
        <div class="primaryDetails">
          <div class="detail">
            <div class="detail_header">
              Pick-up Time
            </div>
            <div class="detail_content">
              {formatDate(sector.PickupTime, $dateFormat.DATE_TIME_THEN_DATE)}
            </div>
          </div>
          <div class="detail">
            <div class="detail_header">
              Estimated Arrival Time
            </div>
            <div class="detail_content">
              {formatDate(sector.DropoffTime, $dateFormat.DATE_TIME_THEN_DATE)}
            </div>
          </div>
        </div>

        <div class="extraDetailsColumn">
          <div class="detail">
            <div class="detail_header">Supplier Confirmation Number</div>
            <div class="detail_content">{sector.ReservationNumber}</div>
          </div>
          <div class="detail">
            <div class="detail_header">Shuttle Type</div>
            <div class="detail_content">{shuttleType}</div>
          </div>
          <div class="detail">
            <div class="detail_header">Passengers</div>
            <div class="detail_content">{sector.Pax}</div>
          </div>
          <div class="detail">
            <div class="detail_header">Optional Extras</div>
            <div class="detail_content">{#if sector.ExtraItems > 0}{sector.ExtraItems} x Other {:else} - {/if}</div>
          </div>
        </div>

        <div class="extraDetailsColumn">
          <div class="detail">
            <div class="detail_header">Note to Driver</div>
            <div class="detail_content">{sector.Notes || '-'}</div>
          </div>

          {#if sector.TotalAmount === 0}
            <div class="detail">
              <div class="detail_header">Primary Contact</div>
              <div class="detail_content">{sector.TravellerFirstName} {sector.TravellerSurname}</div>
            </div>
          {/if}
        </div>

        <div class="extraDetailsColumn totals" class:print-total={printTotal}>
          <div class="pricingTable">
            <div class="pricingTable_item pricingTable_item-standout">
              <span class="pricingTable_item_label">Total{#if sector.TotalAmount === 0}*{/if}</span>
              <span class="pricingTable_item_amount">{sector.TotalAmount.toFixed(2)}<span class="unit">{sector.CurrencyCode}</span></span>
            </div>
          </div>
          {#if sectorType === 'shuttle' && sector.TotalAmount === 0}
            <span class="primaryCharged">*The cost of the airport transfer was charged under the Primary Contact</span>
          {/if}
        </div>
      {/if}

      {#if sectorType === 'taxi'}
      <div class="primaryDetails">
        <div class="detail">
          <div class="detail_header">
            Pick-up Time
          </div>
          <div class="detail_content">
            {formatDate(sector.PickUpTime, $dateFormat.DATE_TIME_THEN_DATE)}
          </div>
        </div>
      </div>

      <div class="extraDetailsColumn double">
        <div class="detail">
          <div class="detail_header">Pick-up location</div>
          <div class="detail_content">
            {sector.PickupAddress.Title}<br />
            <span class="full-address">{sector.PickupAddress.FullAddress}</span>
          </div>
        </div>

        <div class="detail">
          <div class="detail_header">Drop-off location</div>
          <div class="detail_content">
            {sector.DropOffAddress.Title}<br />
            <span class="full-address">{sector.DropOffAddress.FullAddress}</span>
          </div>
        </div>
      
        <div class="detail">
          <div class="detail_header">Supplier Confirmation Code</div>
          <div class="detail_content">
            {sector.SupplierIdentifier}
          </div>
        </div>
      </div>

      <div class="extraDetailsColumn totals" class:print-total={printTotal}>
        <div class="pricingTable">
          <div class="pricingTable_item pricingTable_item-standout">
            <div class="pricingTable_item_row">
              <span class="pricingTable_item_label">Total</span>
              <span class="pricingTable_item_amount">{sector.TaxiSegmentInvoices[0].BaseTotalAmount.toFixed(2)}<span class="unit">{sector.TaxiSegmentInvoices[0].BaseCurrency}</span></span>
            </div>
          </div>
        </div>
      </div>
      {/if}    
    </div>

    {#if sectorType === 'rail' && !isPta }
    <div class="railSummary">
      This is not a travel ticket, an email will be sent with your travel credentials.
      <span class="link" on:click="{showFareRules}">View full fare conditions</span>. <br/>
      By booking your ticket you accept
      <a href="https://static.trainlinecontent.com/content/WEB/documents/NationalRail_ConditionsOfTravel_01102016.pdf"
         target="_blank">National Rail Conditions of Travel</a>.
    </div>
    {/if}
  </div>

</Card>

<style>
  .itinerary_card_header {
    border-bottom: 1px solid #D8DBDF;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px;
    position: relative;
    gap: 8px;
  }

  .itinerary_card_sectorIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .itinerary_card_sectorIcon img,
  .itinerary_card_sectorIcon .icon {
    width: 24px;
    height: 24px;
  }

  .itinerary_card_title {
    font-size: 14px;
    text-align: center;
  }

  .itinerary_card_title strong {
    font-size: 16px;
    display: block;
    margin-bottom: 1px;
  }

  .itinerary_card_title span {
    display: block;
  }

  .itinerary_card_details {
    padding: 23px 26px;
  }

  .itinerary_card_details.noBottomPadding {
    padding-bottom: 0;
  }

  .primaryDetails {
    border-bottom: 1px solid #D8DBDF;
    margin-bottom: 23px;
    padding-bottom: 23px;
  }

  .detail {
    margin-bottom: 23px;
  }

  .primaryDetails .detail:last-child {
    margin-bottom: 0;
  }

  .detail_header {
    color: #2C3E50;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
  }

  .detail_content {
    color: #3F4F5F;
    font-size: 14px;
  }

  .detail_content .seat,
  .detail_content .note {
    display: block;
    margin-bottom: 12px;
  }

  .detail_content a {
    color: #007aff;
  }

  .hotelAllotment {
    width: 92px;
    height: 25px;
    background: #F6DEBC;
    color: #FF8A05;
    border-radius: 4px;
    padding: 0 0 0 25px;
    position: relative;
    line-height: 25px;
    margin-top: 5px;
    font-weight: normal;
  }

  .hotelAllotment_icon {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .onRequest {
    padding: 0;
    text-align: center;
    background: #FFD8A2;
    color: #864E00; 
  }

  .travelIcon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 4px;
    position: relative;
    top: 5px;
  }

  .detail_content .unit,
  .pricingTable_item_row .unit {
    font-size: 10px;
    margin-left: 3px;
  }

  .railSummary {
    padding: 17px;
    letter-spacing: 0.31px;
    line-height: 19px;
    border-top: 1px solid #E9EBED;
  }

  .railSummary a,
  .railSummary span.link {
    color: #007AFF;
    text-decoration: none;
    border-bottom: 1px solid #007AFF;
  }

  .railSummary span.link:hover {
    cursor: pointer;
  }

  .pricingTable {
    -webkit-print-color-adjust: exact !important;
    background-color: #F3F6FC;
    border-radius: 4px;
    color: #2C3E50;
    padding: 16px 18px;
    margin-bottom: 4px;
  }

  .pricingTable_item {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    margin: 2px 0;
  }
  .pricingTable_item_row {
    font-size: 13px;
    font-weight: 500;
    margin: 2px 0;
    display: flex;
    justify-content: space-between;
  }

  .pricingTable_item-standout {
    font-size: 15px;
    font-weight: 700;
  }

  .pricingTable_item_amount span {
    font-size: 70%;
  }

  .pricingTable-alternate {
    background-color: #FFB2C1;
    color: #5D0014;
    padding-top: 9px;
    padding-bottom: 9px;
    flex-grow: 0;
    flex-direction: column;
  }

  .pricingTable_item_other {
    font-size: 13px;
    font-weight: 600;
    margin-top: 16px;
    border-bottom: 1px solid black;
    padding-bottom: 7px;
    width: 100%;
  }

  .sectorPayProperty,
  .sectorServiceFee {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    margin-top: 7px;
  }

  .payPropertyTitle {
    margin-top: 7px;
    display: flex;
    font-size: 13px;
    align-items: center;
  }

  .payPropertyTotal {
    font-size: 13px;
    flex-grow: 1;
    text-align: right;
    font-weight: 600;
    flex-wrap: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  }

  .sectorPayProperty_amount {
    flex-shrink: 0;
    align-items: flex-end;
  }

  .sectorServiceFee {
    align-items: flex-end;
  }
  .serviceFee {
    text-align: left;
  }

  .sectorPayProperty_currency {
    font-size: 70%;
  }

  .sectorPayProperty_icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    transition: transform 0.5s ease;
    transform-origin: center center;
  }

  .animateArrow {
    transform: rotate(-180deg) translateX(1px) translateZ(0);  
  }

  .lostSavingCompared {
    margin: 10px 0 0;
    border-top: 1px solid #5D0014;
    padding: 12px 0 0 0;
    font-size: 13px;
  }

  .lostSavingCompared_header {
    display: block;
    font-weight: 500;
  }

  .bedTypes {
    font-size: 11px;
    line-height: 13px;
    margin: 0 0 10px;
  }

  .hotelInfoLink {
    color: #007AFF;
    cursor: pointer;
  }

  .primaryCharged {
    color: #666666;
    font-size: 11px;
    line-height: 13px;
    padding: 15px;
    position: relative;
  }

  .cancel-taxi {
    border: 1px solid #DA1E28;
    color: #DA1E28;
    background: #fff;
    border-radius: 4px;
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .full-address {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #6D7880;
  }

  .cancel-taxi-backdrop {
    background: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .cancel-taxi-content {
    background: #fff;
    box-shadow: 0px 12px 24px -4px rgba(32, 43, 51, 0.24), 0px 8px 60px rgba(32, 43, 51, 0.32);
    border-radius: 8px;
    height: 190px;
    width: 572px;
    padding: 24px;
  }

  .cancel-taxi-content h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #141B22;
    margin: 0 0 16px;
  }

  .cancel-taxi-content p {
    color: #000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 27px;
  }

  .cancel-taxi-content form {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .cancel-taxi-content button {
    width: 108px;
    height: 48px;
    border-radius: 4px;
    border: none;
    background: #DA1E28;
    color: #fff;
    font-size: 16px;
  }

  .cancel-taxi-content button.cancel {
    background: #FFFFFF;
    border: 1px solid #156AF4;
    color: #156AF4;
  }

  .cancel-taxi-content button.close {
    background: #156AF4;
    border: none;
    color: #fff;
    align-self: flex-end;
  }

  .cancelling {
    opacity: .4;
  }

  .cancel-segment {
    order: 3;
  }

  .cancel-segment .departed {
    border: 1px solid #2C3E50;
    color: #2C3E50;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    padding: 10px 16px;
    text-align: center;
    border-radius: 3px;
  }

  .remove-text {
    text-transform: capitalize;
  }

  @media (min-width: 1025px), print {
    .itinerary_card {
      padding: 0;
    }

    .itinerary_card_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 26px 16px 16px;
      gap: 0;
    }

    .itinerary_card_sectorIcon {
      align-self: center;
      width: 50px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    .itinerary_card_sectorIcon img {
      width: 30px;
      height: 30px;
    }

    .itinerary_card_title {
      margin: 0;
      flex-grow: 1;
      text-align: left;
    }

    .itinerary_card_title span {
      display: inline;
    }

    .itinerary_card_details {
      display: flex;
      flex-direction: row;
    }

    .primaryDetails {
      border-bottom: none;
      border-right: 1px solid #D8DBDF;
      width: 40%;
      margin-right: 26px;
      padding-right: 16px;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .extraDetailsColumn {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      margin-right: 40px;
      width: 33.3333333%;
    }

    .extraDetailsColumn-search,
    .extraDetailsColumn.double {
      width: 66.6666666%;
    }

    .extraDetailsColumn:last-child {
      margin-right: 0;
    }

    .detail:last-child {
      margin-bottom: 0;
    }
  }

  @media print {
    :global(.card) {
      box-shadow: none !important;
      border: 1px solid #AAAAAA;
      border-radius: 4px;
      break-inside: avoid;
    }

    .totals,
    .rail-totals .pricingTable,
    .railSummary,
    .expediaDetail,
    .policyBreak,
    .cancel-segment {
      display: none;
    }

    .pricingTable-alternate {
      display: none !important;
    }

    .pricingTable_item_label {
      margin: 0 10px 0 0;
    }    

    .totals.print-total,
    .rail-totals.print-total .pricingTable {
      display: flex;
    }
  }
</style>
