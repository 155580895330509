export default function cardIn ( node, { delay = 0, duration = 100 } ) {
  const o = +getComputedStyle( node ).opacity;

  return {
    delay,
    duration,
    easing: function (t) {
      return Math.sin(t * Math.PI/2);
    },
    css: (t) => {
      return `opacity: ${t * o};transform:translateY(${10-(10 * t)}px)`;
    }
  };
}
