import axios from 'axios';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import {
  CONFIG,
  setTrackingIdentity,
  trackLogin,
  trackUser
} from '../store/index.js';

import { stringify } from '../util/query-string';
import { getHeaders } from './headers';

dayjs.extend(advancedFormat);

export async function login(username, password) {
  return authenticate(username, password)
    .then((response) => {
      const auth = response.data;
      auth.expires_at = dayjs().add(auth.expires_in, 'second').format('x');
      sessionStorage.setItem('oauth', JSON.stringify(auth));

      return getUserInfo(response.data);
    })
    .then((response) => {
      const { data } = response;

      setTrackingIdentity(data.UserIdentifier);
      trackLogin(data);

      let [firstName, ...lastName] = data.Name.split(' ');
      lastName = lastName.join(' ');
      trackUser(firstName, lastName, data.Email, data.DebtorIdentifier);

      sessionStorage.setItem('CURRENT_USER_userInfo', JSON.stringify(data));
      sessionStorage.setItem('IsFromAuth0', data.IsFromAuth0);

      return data;
    });
}

export async function getUserInfo() {
  const url = `${CONFIG.API_URL}Api/UserInfo`;
  const headers = getHeaders(true);
  headers['X-api-version'] = 20;

  const options = {
    url,
    method: 'GET',
    headers
  };

  return axios(options);
}

export async function authenticate(username, password) {
  const url = `${CONFIG.API_URL}Token`;

  const data = stringify({
    client_id: CONFIG.CLIENT_ID,
    client_secret: CONFIG.CLIENT_SECRET,
    grant_type: 'password',
    username: username,
    password: password
  });

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(),
    data,
    preventGlobalError: true
  };

  options.headers['Content-Type'] = 'text/plain';

  return axios(options);
}

export async function refresh(refreshToken) {
  const url = `${CONFIG.API_URL}Token`;

  const data = stringify({
    client_id: CONFIG.CLIENT_ID,
    client_secret: CONFIG.CLIENT_SECRET,
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  });

  const options = {
    url,
    method: 'POST',
    headers: getHeaders(),
    data
  };

  return axios(options);
}
