<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  import Avatar from '@ctm/ui/Avatar';
  import Card from '@ctm/ui/Card';
  import { Plane, Hotel, Car, Train, Return } from '@ctm/ui/Icons';
  import Shuttle from '../ManageBookings/_ShuttleIcon.svelte';
  import LB_Icon from '../Icon/Icon.svelte';
  import ApprovalStateLabel from '../ApprovalStateLabel/ApprovalStateLabel.svelte';
  import TaxiIcon from '../Taxis/_TaxiIcon.svelte';

  import getInitials from '../../util/getInitials';
  import { formatDate } from '../../util/date.js';

  import { dateFormat } from '../../store/index.js';
  
  export let itinerary = null;
  export let showManageBookingButton = false;
  export let isClickDisabled = false;
  export let showApprovalStatus = true;

  let ItinerarySectorTypes;
  $: {
    const sectorTypes = [];

    if (Passenger.FlightCount > 0) {
      sectorTypes.push('flight');
    }

    if (Passenger.HotelCount > 0) {
      sectorTypes.push('hotel');
    }

    if (Passenger.CarCount > 0) {
      sectorTypes.push('car');
    }

    if (Passenger.RailCount > 0) {
      sectorTypes.push('rail');
    }

    if (Passenger.ShuttleCount > 0) {
      sectorTypes.push('shuttle');
    }

    if (Passenger.TaxiCount > 0) {
      sectorTypes.push('taxi');
    }

    ItinerarySectorTypes = sectorTypes;
  }

  let Passenger;
  $: {
    Passenger = itinerary.Passengers[0];
  }

  let PassengerInitials;
  $: {
    PassengerInitials = getInitials(
      Passenger.Firstname,
      Passenger.Lastname || ''
    );
  }

  let isOfflineBooking;
  $: {
    isOfflineBooking = Passenger.IsOfflineBooking;
  }

  function onClick() {
    dispatch('select', itinerary);
  }
</script>

<style>
  .itinerarySummaryCard_header {
    border-bottom: 1px solid #d8dbdf;
    display: flex;
    align-items: center;
    padding: 9px 16px;
  }

  .itinerarySummaryCard_header_name {
    color: #2c3e4f;
    font-size: 14px;
    font-weight: 600;
    margin-left: 7px;
  }

  .itinerarySummaryCard_header_status {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 6px;
  }

  .itinerarySummaryCard_header_status-label {
    display: none;
  }

  .itinerarySummaryCard_body {
    display: flex;
    flex-wrap: wrap;
    padding: 23px 17px;
    position: relative;
  }

  .detail {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-bottom: 16px;
  }

  .detail-dates,
  .detail-trip {
    width: 100%;
  }

  .detail-services {
    padding-bottom: 0;
  }

  .detail-cta {
    display: flex;
    align-items: center;
    padding: 17px 0 0 0;
    position: relative;
    bottom: -6px;
    width: 100%;
    margin-top: 17px;
  }

  .detail-cta:before {
    background: #d8dbdf;
    content: "";
    position: absolute;
    top: 0;
    left: -17px;
    right: -17px;
    height: 1px;
  }

  .detail-offline {
    color: #3f4f5f;
    font-size: 12px;
    text-align: center;
    padding: 17px 0 0 0;
    position: relative;
    bottom: -6px;
    width: 100%;
    margin-top: 17px;
  }

  .detail-offline:before {
    background: #d8dbdf;
    content: "";
    position: absolute;
    top: 0;
    left: -17px;
    right: -17px;
    height: 1px;
  }

  .detail-offline strong {
    display: block;
  }

  .detail-offline span {
    display: block;
  }

  .detail:last-of-type {
    padding-bottom: 0;
  }

  .detail_header {
    color: #2c3e50;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 2px 0;
    min-width: 70%;
  }

  .detail_content {
    color: #3f4f5f;
    font-size: 14px;
  }

  .detail_content-services {
    display: flex;
  }

  .serviceIcon {
    width: 24px;
    height: 24px;
    margin: 0 2px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .detail_content-references {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .detail_content-trip {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tripIcon {
    width: 14px;
    height: 14px;
    margin: 0 6px;
  }

  .manageBookingLink {
    height: 30px;
    width: 139px;
    background-color: #007aff;
    text-align: center;
    color: #fff;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.15px;
    display: block;
    border-radius: 3px;
    transition: 0.2s all;
  }

  .manageBookingLink:hover {
    background: #006fe8;
  }

  @media (min-width: 1025px) {
    .itinerarySummaryCard_header_status-indicator {
      display: none;
    }

    .itinerarySummaryCard_header_status-label {
      display: flex;
    }

    .itinerarySummaryCard_body {
      flex-direction: row;
    }

    .detail {
      flex: 1;
      padding-bottom: 0;
      padding-right: 20px;
    }

    .detail-dates {
      max-width: 350px;
      flex-grow: 2;
    }

    .detail-bookingId {
      max-width: 186px;
    }

    .detail-references {
      width: 20%;
      max-width: 200px;
    }

    .detail-trip {
      width: 20%;
      max-width: 200px;
    }

    .detail-services {
      align-items: flex-start;
      min-width: 80px;
    }

    .detail-cta {
      padding: 0;
      display: flex;
      align-items: flex-end;
      width: 100%;
      margin-top: 0;
    }

    .detail-cta:before {
      display: none;
    }

    .detail-offline {
      padding: 0;
      min-width: 150px;
      width: auto;
      margin-top: 0;
    }

    .detail-offline:before {
      display: none;
    }
  }
</style>

<Card on:click={onClick} {isClickDisabled}>
  <div class="itinerarySummaryCard">
    <div class="itinerarySummaryCard_header">
      <div class="itinerarySummaryCard_header_avatar">
        <Avatar
          alt={PassengerInitials}
          textColour="#b0b7be"
          bgColour="#ebedef" />
      </div>

      <div class="itinerarySummaryCard_header_name">
        {Passenger.Firstname} {Passenger.Lastname || ''}
      </div>

      {#if showApprovalStatus}
        <div
          class="itinerarySummaryCard_header_status
          itinerarySummaryCard_header_status-indicator">
          <ApprovalStateLabel
            state={Passenger.ApprovalState}
            showLabel={false} />
        </div>

        <div
          class="itinerarySummaryCard_header_status
          itinerarySummaryCard_header_status-label">
          <ApprovalStateLabel state={Passenger.ApprovalState} autoBooked={Passenger.ApprovalLevel === 'None'} />
        </div>
      {/if}
    </div>
    

    <div class="itinerarySummaryCard_body">
       <div class="detail detail-bookingId">
          <div class="detail_header">Booking Id</div>
          <div class="detail_content">{Passenger.BookingId > 0 ? Passenger.BookingId : Passenger.Pnr}</div>
      </div>

      <div class="detail detail-references">
          <div class="detail_header">References</div>
          <div class="detail_content detail_content-references">
            {#each Passenger.SectorReferences as reference, referenceIndex}
                <span>
                {#if referenceIndex < Passenger.SectorReferences.length - 1}
                  {reference},&nbsp; 
                {:else}
                  {reference}
                {/if}
                </span>
            {/each}
          </div>
      </div>

      <div class="detail detail-trip">
        <div class="detail_header">Trip</div>
        <div class="detail_content detail_content-trip">
          {#if Passenger.TravelType === 'oneway'}
            <span>{Passenger.Locations[0]}</span>
            <span class="tripIcon">
              <LB_Icon type="oneway" />
            </span>
            <span>{Passenger.Locations[1]}</span>
          {:else if Passenger.TravelType === 'return'}
            <span>{Passenger.Locations[0]}</span>
            <span class="tripIcon">
              <Return />
            </span>
            <span>{Passenger.Locations[1]}</span>
          {:else if Passenger.TravelType === 'multi'}
            {#each Passenger.Locations as location, locationIndex}
              <span>{location}</span>
              {#if locationIndex < Passenger.Locations.length - 1}
                <span class="tripIcon">
                  <LB_Icon type="oneway" />
                </span>
              {/if}
            {/each}
          {:else}
            <span>{itinerary.OriginLocationCode}</span>
          {/if}
        </div>
      </div>

      <div class="detail detail-dates">
        <div class="detail_header">Travel Dates</div>
        <div class="detail_content">
          {formatDate(itinerary.FromDate, $dateFormat.DATE_EXTENDED)} - {formatDate(itinerary.ToDate, $dateFormat.DATE_EXTENDED)}
        </div>
      </div>

      <div class="detail detail-services">
        <div class="detail_content detail_content-services">

          {#each ItinerarySectorTypes as sectorType}
            <div class="serviceIcon">
              {#if sectorType === 'flight'}
                <Plane />
              {:else if sectorType === 'hotel'}
                <Hotel />
              {:else if sectorType === 'car'}
                <Car />
              {:else if sectorType === 'rail'}
                <Train />
              {:else if sectorType === 'shuttle'}
                <Shuttle />
              {:else if sectorType === 'taxi'}
                <TaxiIcon />
              {/if}
            </div>
          {/each}
        </div>
      </div>

      {#if showManageBookingButton && !isOfflineBooking}
        <div class="detail detail-cta">
          <div class="detail_content">
            <a
              href="/#/manage-bookings/{Passenger.Pnr}"
              target="_blank"
              class="manageBookingLink">
              Manage booking
            </a>
          </div>
        </div>
      {/if}

      {#if isOfflineBooking}
        <div class="detail detail-offline">
          <strong>Offline booking</strong>
          <span>Contact your dedicated travel consultant</span>
        </div>
      {/if}
    </div>
  </div>
</Card>
