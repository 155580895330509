import axios from 'axios';
import { CONFIG } from '../store/index.js';
import { getHeaders } from './headers';

export function locationPoi(term) {
    return new Promise((resolve, reject) => {
        const url = `${CONFIG.API_URL}api/location/poi?term=${term}&maxRows=10&searchTypes=Location`;

        const options = {
            url,
            method: 'GET',
            headers: getHeaders(true),
            apiVersion: 18,
        };

        axios(options)
            .then((res) => resolve(res.data))
            .catch((err) => {
                reject(err);
            });
    });
}

export function taxiSearch({firstName, lastName, phone, PickUpTime, origin, destination}) {
    return new Promise((resolve, reject) => {
        const url = `${CONFIG.API_URL}api/taxi/search`;

        const data = JSON.stringify([
            {
                firstName,
                lastName,
                phone,
                PickUpTime,
                origin,
                destination,
                passengers: 1,
            },
        ]);

        const options = {
            url,
            method: 'POST',
            headers: getHeaders(true),
            apiVersion: 21,
            data,
        };

        axios(options)
            .then((res) => {
              let sorted = res.data.Result.sort((a, b) => parseFloat(a.TotalFare) - parseFloat(b.TotalFare));
              resolve(sorted)
            })
            .catch((err) => {
                reject(err);
            });
    });
}

export function taxiBook({PassengerIdentifier, PickUpTime, Origin, Destination, VehicleType}) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/taxi/book`;

    const data = JSON.stringify([
        {
          PassengerIdentifier,
          PickUpTime,
          Origin,
          Destination,
          CorporateCabsOptions: { VehicleType },
          Passengers: 1,
        },
    ]);

    const options = {
        url,
        method: 'POST',
        headers: getHeaders(true),
        apiVersion: 21,
        data,
    };

    axios(options)
        .then((res) => resolve(res.data))
        .catch((err) => {
            reject(err);
        });
  });
}


export function taxiCancel({PassengerIdentifier, SegmentIdentifiers}) {
  return new Promise((resolve, reject) => {
    const url = `${CONFIG.API_URL}api/taxi/cancel`;

    const data = JSON.stringify({
      PassengerIdentifier,
      SegmentIdentifiers
    });

    const options = {
        url,
        method: 'POST',
        headers: getHeaders(true),
        apiVersion: 21,
        data,
    };

    axios(options)
        .then((res) => resolve(res.data))
        .catch((err) => {
            reject(err);
        });
  });
}
