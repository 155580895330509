export default function formatCurrency(amount = 0, precision = 0) {
  const float = amount.toFixed(precision);
  const decimalSplit = String(float).split('.');
  const formatAsCurrencyRegex = /(\d)(?=(\d{3})+(?!\d))/g;

  if(!decimalSplit[1] && precision > 0) {
    decimalSplit[1] = '0'.repeat(precision);
  }

  return decimalSplit.join('.').replace(formatAsCurrencyRegex, '$1,');
}
