<script>
    import dayjs from 'dayjs';

    import Lock from './LockIcon.svelte';
    import FlightIcon from './FlightIcon.svelte';
    import DatePicker from '@ctm/ui/DatePicker';
    import TimeSelect from '@ctm/ui/TimeSelect';
    import CalendarIcon from './CalendarIcon.svelte';
    import ChevronIcon from './ChevronIcon.svelte';
    import { formatFromApiDate, parseDate } from '../../util/date';
    import CarIcon from './_CarIcon.svelte';
    import HotelIcon from './_HotelIcon.svelte';

    export let sector = null;
    export let search = null;
    export let type = 'flight';

    let flightData = {};
    let carData = {};
    let hotelData = {};
    let minDate = dayjs().format('DD/MM/YYYY');

    function setupFlightData() {
        if (type != 'flight') return;
        flightData.time = travelDateToSearchTime(sector.Departure.Date);
        flightData.date = formatFromApiDate(
            sector.Departure.Date,
            'DD/MM/YYYY'
        );
    }

    function updateFlightSearch() {
        search = {
            ...flightData,
            sector,
        };
    }

    function setupCarData() {
        if (type != 'car') return;
        carData.pickTime = travelDateToSearchTime(sector.PickUp.Date);
        carData.pickDate = formatFromApiDate(sector.PickUp.Date, 'DD/MM/YYYY');
        carData.dropTime = travelDateToSearchTime(sector.DropOff.Date);
        carData.dropDate = formatFromApiDate(sector.DropOff.Date, 'DD/MM/YYYY');
    }

    function updateSearch(data) {
        search = {
            ...data,
            sector,
        };
    }

    function travelDateToSearchTime(travelDate) {
        let parsedDate = parseDate(travelDate, 'YYYY-MM-DDTHH:mm');

        if (parsedDate.get('m') > 30) {
            parsedDate = parsedDate.set('m', 30);
        }

        if (parsedDate.get('m') < 30) {
            parsedDate = parsedDate.set('m', 0);
        }

        return parsedDate.format('h:mmA');
    }

    function handleTimeSelect(e, carKey) {
        if (carKey) {
            carData[carKey] = e.detail;
        } else {
            flightData.time = e.detail;
        }
    }

    function setupHotelData() {
        hotelData.checkIn = formatFromApiDate(sector.DateFrom, 'DD/MM/YYYY');
        hotelData.checkOut = formatFromApiDate(sector.DateTo, 'DD/MM/YYYY');
    }

    $: if (type === 'flight') setupFlightData();
    $: updateFlightSearch(flightData);

    $: if (type === 'hotel') setupHotelData();
    $: minCheckOut = hotelData.checkIn && dayjs(hotelData.checkIn, 'DD/MM/YYYY').add(1, 'day').format('DD/MM/YYYY');
    $: updateSearch(hotelData);

    $: if (type === 'car') setupCarData();
    $: updateSearch(carData);
</script>

<div class="flight-search">
    <div class="header">
        <i>
            {#if type === 'flight'}
                <FlightIcon />
            {:else if type === 'hotel'}
                <HotelIcon />
            {:else}
                <CarIcon />
            {/if}
        </i>
        <div class="details">
            <h3>
                {#if type === 'flight'}
                    {sector.Departure.Location}
                {:else if type === 'hotel'}
                    {sector.HotelName}
                {:else}
                    {sector.Supplier.Title} - {sector.Vehicle.Class}
                {/if}
            </h3>

            <span>
                {#if type === 'flight'}
                    {sector.Arrival.Location} ({sector.Carrier}{sector.FlightNumber})
                {:else if type === 'hotel'}
                    {sector.RoomName}
                {:else}
                    {sector.Vehicle.Title}
                {/if}
            </span>
        </div>
    </div>

    {#if type === 'flight'}
        <div class="locations">
            <div class="location">
                <h4>From</h4>
                <div class="location-locked">
                    <label>{sector.Departure.Location}</label>
                    <i><Lock /></i>
                </div>
            </div>

            <div class="location">
                <h4>To</h4>
                <div class="location-locked">
                    <label>{sector.Arrival.Location}</label>
                    <i><Lock /></i>
                </div>
            </div>
        </div>
        <div class="date">
            <div class="date-fields">
                <div class="day">
                    <h4>Date</h4>
                    <div>
                        <i><CalendarIcon /></i>
                        <DatePicker
                            {minDate}
                            hasIcon={false}
                            bind:date={flightData.date} />
                    </div>
                </div>

                <div class="time">
                    <h4>Time</h4>
                    <div>
                        <i><ChevronIcon /></i>
                        <TimeSelect
                            on:select={handleTimeSelect}
                            value={flightData.time} />
                    </div>
                </div>
            </div>
        </div>
    {:else if type === 'hotel'}
        <div class="locations">
            <div class="location">
                <h4>Location</h4>
                <div class="location-locked">
                    <label
                        >{sector.Location.City}, {sector.Location.Country}
                        {sector.Location.Code}</label>
                    <i><Lock /></i>
                </div>
            </div>
        </div>

        <div class="date">
            <div class="date-fields hotel">
                <div class="day">
                    <h4>Check-in date</h4>
                    <div>
                        <i><CalendarIcon /></i>
                        <DatePicker
                            {minDate}
                            hasIcon={false}
                            bind:date={hotelData.checkIn} />
                    </div>
                </div>

                <div class="day">
                    <h4>Check-out date</h4>
                    <div>
                        <i><CalendarIcon /></i>
                        <DatePicker
                            minDate={minCheckOut}
                            hasIcon={false}
                            bind:date={hotelData.checkOut} />
                    </div>
                </div>
            </div>
        </div>
    {:else if type === 'car'}
        <div class="locations">
            <div class="location">
                <h4>Pick up city</h4>
                <div class="location-locked">
                    <label>{sector.PickUp.City}</label>
                    <i><Lock /></i>
                </div>
            </div>

            <div class="location">
                <h4>Location</h4>
                <div class="location-locked">
                    <label>{sector.PickUp.Location}</label>
                    <i><Lock /></i>
                </div>
            </div>
        </div>

        <div class="date border">
            <div class="date-fields">
                <div class="day">
                    <h4>Pick up date</h4>
                    <div>
                        <i><CalendarIcon /></i>
                        <DatePicker
                            {minDate}
                            hasIcon={false}
                            bind:date={carData.pickDate} />
                    </div>
                </div>

                <div class="time">
                    <h4>Pick up time</h4>
                    <div>
                        <i><ChevronIcon /></i>
                        <TimeSelect
                            on:select={(e) => handleTimeSelect(e, 'pickTime')}
                            value={carData.pickTime} />
                    </div>
                </div>
            </div>
        </div>

        <div class="locations">
            <div class="location">
                <h4>Drop off city</h4>
                <div class="location-locked">
                    <label>{sector.DropOff.City}</label>
                    <i><Lock /></i>
                </div>
            </div>

            <div class="location">
                <h4>Location</h4>
                <div class="location-locked">
                    <label>{sector.DropOff.Location}</label>
                    <i><Lock /></i>
                </div>
            </div>
        </div>

        <div class="date">
            <div class="date-fields">
                <div class="day">
                    <h4>Drop off date</h4>
                    <div>
                        <i><CalendarIcon /></i>
                        <DatePicker
                            minDate={carData.pickDate}
                            hasIcon={false}
                            bind:date={carData.dropDate} />
                    </div>
                </div>

                <div class="time">
                    <h4>Drop off time</h4>
                    <div>
                        <i><ChevronIcon /></i>
                        <TimeSelect
                            on:select={(e) => handleTimeSelect(e, 'dropTime')}
                            value={carData.dropTime} />
                    </div>
                </div>
            </div>
        </div>
    {/if}
</div>

<style>
    .flight-search {
        border: 1px solid #ced3da;
        border-radius: 8px;
        padding: 24px;
        display: flex;
        flex-direction: column;
        margin: 0 0 40px;
    }

    .flight-search h4 {
        color: #6d7880;
        font-size: 14px;
        line-height: 21px;
        margin: 0 0 7px;
        font-weight: normal;
    }

    .header {
        display: flex;
        gap: 10px;
        margin-bottom: 24px;
    }

    .header i {
        padding-top: 4px;
    }

    .details h3 {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }

    .details span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6d7880;
    }

    .locations {
        display: flex;
        justify-content: space-between;
        margin: 0 0 16px;
    }

    .location {
        width: calc(50% - 4.5px);
    }

    .location:only-child {
        width: 100%;
    }

    .location * {
        user-select: none;
        cursor: not-allowed;
    }

    .location-locked {
        border: 1px solid #ced3da;
        border-radius: 4px;
        background-color: #fafbfc;
        display: flex;
        align-items: center;
        padding: 12px 16px;
        font-size: 16px;
        height: 44px;
    }

    .location-locked label {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .location-locked i {
        display: flex;
        height: 20px;
        width: 15px;
        color: #bec4cd;
    }

    .date-fields {
        display: flex;
    }

    .day div {
        margin: 0 8px 0 0;
        position: relative;
    }

    .day i {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 18px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .date-fields.hotel {
        padding-top: 20px;
        gap: 20px;
        margin-bottom: 12px;
    }

    .date-fields.hotel .day {
        flex-grow: 1;
    }

    .date-fields.hotel .day div {
        margin: 0;
    }

    :global(.flight-search .userInput),
    :global(.flight-search .time .selectContainer) {
        height: 48px !important;

        background: transparent !important;
        background-color: transparent;
    }

    :global(.flight-search .time .time) {
        min-width: 120px !important;
        margin: 0 8px 0 0 !important;
    }

    :global(.flight-search .time .radioButtons) {
        height: 48px;
        width: 113px;
        border: none;
    }

    :global(.flight-search .time .button) {
        height: 48px;
        width: 57px;
        border: 1px solid #ced3da;
        border-radius: 4px 0 0 4px;
        background-color: #ffffff;
        color: #2d3840;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
    }

    :global(.flight-search .time .button.active) {
        border: 1px solid #156af4;

        background-color: #e5effc;
        color: #156af4;
    }

    :global(.flight-search .time .button.right) {
        border-radius: 0 4px 4px 0;
    }

    .time div {
        position: relative;
    }

    .time i {
        display: flex;
        align-self: center;
        justify-content: center;
        position: absolute;
        height: 8px;
        width: 14px;
        left: 92px;
        top: 19px;
    }

    :global(.flight-search .time .indicator) {
        display: none;
    }

    :global(.flight-search .datePicker_input .userInput) {
        padding-right: 30px !important;
    }

    .date.border {
        border-bottom: 1px solid #ebecf0;
        padding-bottom: 24px;
        margin-bottom: 24px;
    }
</style>
