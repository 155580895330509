<script>
  import { Hotel } from '@ctm/ui/Icons';
  import SectorStatusRow from './SectorStatusRow.svelte';
  import HotelReshop from './HotelReshop.svelte';

  export let sector;
  export let hotelFailure = false;
</script>

<style>
  .icon {
    width: 100%;
    height: 100%;
  }
</style>

<SectorStatusRow iconType="hotel" status="{ hotelFailure ? 'Pending' : sector.IsUnlimitedAllotment ? 'OnRequest' : sector.Status }">
  <div slot="icon" class="icon"><Hotel /></div>
  <div slot="title">{ sector.HotelName }</div>
  <div slot="desc">{ sector.Location.City }</div>
</SectorStatusRow>

{#if hotelFailure}
  <HotelReshop {sector} />
{/if}
