<script>
  import { createEventDispatcher } from "svelte";
  import Tick from "./Icons/Tick.svelte";

  const dispatch = createEventDispatcher();
  export let checked = false;
  export let disabled = false;

  function handleToggle() {
    checked = !checked;
    dispatch("toggled");
  }
</script>

<style>
  .checkbox {
    min-height: 24px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
    border: 1px solid #c4cdd5;
    border-radius: 2px;
    display: flex;
    background: #fff;
    color: #fff;
    align-items: center;
    justify-content: center;
    margin: 0 8px 0 0;
    padding: 2px;
  }

  .checkbox.checked {
    border-color: #1f78d1;
    background: #1f78d1;
  }

  .checkbox.disabled {
    border-color: #dfe3e8;
    background: #dfe3e8;
    pointer-events: none;
  }

  @media (min-width: 768px) {
    .checkbox {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      min-height: 16px;
      max-height: 16px;
    }
  }
</style>

<div class="checkbox" class:disabled class:checked on:click={handleToggle}>
  <Tick />
</div>
