import { formatForApiDate } from '../util/date';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import filter from 'lodash/filter';
import findKey from 'lodash/findKey';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';

export {
  approvalStates,
  addPaymentMethodsFromGroupsToItinerary,
  itineraryHasApproval,
  itineraryHasPreTripApproval,
  itineraryHasOnRequestHotel,
  itinerarySectorTypes,
  itinerarySearchTripType,
  setSearchChanges,
  groupSectorsByDate,
  groupSectorsForPayment,
  passengerHasApproval,
  passengerHasPreTripApproval,
  priceSummary,
  paymentCardName,
  ancillaryServicesAmountsFilter,
  airlineFeeTotal,
  flightChangeTotal
}

function passengerHasApproval(pax) {
  const approvalRequiredLevels = ['Passive', 'PreTrip'];
  return approvalRequiredLevels.includes(pax.ApprovalLevel);
}

function passengerHasPreTripApproval(pax) {
  const approvalRequiredLevels = ['PreTrip'];
  return approvalRequiredLevels.includes(pax.ApprovalLevel);
}

function passengerHasOnRequestHotel(pax) {
  return pax.Hotels.find(hotel => hotel.IsUnlimitedAllotment);
}

function itineraryHasApproval(itinerary) {
  return some(itinerary.Passengers, (pax) => {
    return passengerHasApproval(pax);
  });
}

function itineraryHasPreTripApproval(itinerary) {
  return some(itinerary.Passengers, (pax) => {
    return passengerHasPreTripApproval(pax);
  });
}

function itineraryHasOnRequestHotel(itinerary) {
  return some(itinerary.Passengers, (pax) => {
    return passengerHasOnRequestHotel(pax);
  });
}

function itinerarySectorTypes(itinerary) {
  const sectorTypesInItinerary = [];

  if(itinerary.Flights.length > 0) {
    sectorTypesInItinerary.push('flight');
  }

  if(itinerary.Hotels.length > 0) {
    sectorTypesInItinerary.push('hotel');
  }

  if(itinerary.Cars.length > 0) {
    sectorTypesInItinerary.push('car');
  }

  if(itinerary.Rails.length > 0) {
    sectorTypesInItinerary.push('rail');
  }

  return sectorTypesInItinerary;
}

function itinerarySearchTripType(itinerary) {
  let travelMode = 'Land Only';
  let travelDirection = '';

  if (itinerary.Search.Flights.length) {
    travelMode = 'Flight';

    if (itinerary.Search.Flights.length === 1) {
      travelDirection = 'One-way';
    }

    if (itinerary.Search.Flights.length > 1) {
      travelDirection = 'Multi-leg';
    }

    if (itinerary.Search.Flights.length === 2 && itinerary.Search.Flights[0].Origin.Code === itinerary.Search.Flights[1].Destination.Code) {
      travelDirection = 'Return';
    }
  }

  if (itinerary.Search.Rails.length) {
    travelMode = 'Rail';

    if (itinerary.Search.Rails.length === 1) {
      travelDirection = 'One-way';
    }

    if (itinerary.Search.Rails.length > 1) {
      travelDirection = 'Multi-leg';
    }

    if (itinerary.Search.Rails.length === 2 && itinerary.Search.Rails[0].Origin.Code === itinerary.Search.Rails[1].Destination.Code) {
      travelDirection = 'Return';
    }
  }

  return `${travelMode} ${travelDirection}`;
}

function setSearchChanges(args) {
  const {itinerary, searchData, sectorType } = args;
  const itineraryWithSearchChanges = Object.assign({}, itinerary);

  if (sectorType === 'flights') {
    let flights = [];

    Object.values(searchData).forEach((item) => {
        let sector = item.sector;

        flights.push({
          ParentIdentifier: sector.Identifier,
          Date: formatForApiDate(`${item.date} ${item.time}`, 'DD/MM/YYYY h:mmA'),
          Destination: {Code: sector.Arrival.Code},
          Origin: {Code: sector.Departure.Code}
        });    
    });
    
    itineraryWithSearchChanges.Search.Flights = sortBy(flights, 'Date');
    itineraryWithSearchChanges.Search.Hotels = [];
    itineraryWithSearchChanges.Search.SearchTypes = ['Flight'];
  }

  if (sectorType === 'hotels') {
    let hotels = [];

    Object.values(searchData).forEach((item) => {
      let sector = item.sector;
      
      hotels.push({
          ParentIdentifier: sector.Identifier,
          DateFrom: formatForApiDate(item.checkIn, 'DD/MM/YYYY'),
          DateTo: formatForApiDate(item.checkOut, 'DD/MM/YYYY'),
          Location: sector.Location,
          HotelId: sector.HotelId
      });    
    
    })
    
    itineraryWithSearchChanges.Search.Hotels = sortBy(hotels, 'DateFrom');
    sessionStorage.setItem('change:HotelIds',  JSON.stringify(itineraryWithSearchChanges.Search.Hotels.map(hotel => hotel.HotelId)));
    itineraryWithSearchChanges.Search.Flights = [];
    itineraryWithSearchChanges.Search.SearchTypes = ['Hotel'];
  }

  return itineraryWithSearchChanges; 
}

function groupSectorsByDate(travellerItinerary) {
  const groupedSectors = [];

  travellerItinerary.Flights.forEach((sector) => {
    if (sector.Status != 'Cancelled') addToGroup(sector.Departure.Date, sector.Arrival.Date, sector, 'flight');
  });

  travellerItinerary.Hotels.forEach((sector) => {
    if (sector.Status != 'Cancelled') addToGroup(sector.DateFrom, sector.DateTo, sector, 'hotel');
  });

  travellerItinerary.Cars.forEach((sector) => {
    if (sector.Status != 'Cancelled') addToGroup(sector.PickUp.Date, sector.DropOff.Date, sector, 'car');
  });

  travellerItinerary.Rails.forEach((sector) => {
    addToGroup(sector.Departure.Date, sector.Arrival.Date, sector, 'rail');
  });

  if (travellerItinerary.Shuttles) travellerItinerary.Shuttles.forEach((sector) => {
    addToGroup(sector.PickupTime, sector.DropoffTime, sector, 'shuttle');
  });

  if (travellerItinerary.Taxis) travellerItinerary.Taxis.forEach((sector) => {
    if (sector.Status === 'Booked') addToGroup(sector.PickUpTime, sector.DropOffTime, sector, 'taxi');
  });

  function formatToGroupDate(date) {
    return `${date.substr(0, 10)}T00:00`;
  }

  function addToGroup(startDate, endDate, sector, sectorType) {
    const groupDate = formatToGroupDate(startDate);

    if (sectorType === 'shuttle') {
      startDate = startDate + '+12:00';
    }

    const groupSector = Object.assign({}, {
      date: sectorType === 'hotel' ? `${startDate.substr(0, 10)}T23:59` : startDate,
      endDate,
      sectorType,
      data: sector
    });

    const group = groupedSectors.find((grouped) => {
      return grouped.date === groupDate;
    });

    if (group) {
      group.sectors.push(groupSector);
    } else {
      groupedSectors.push({
        date: groupDate,
        sectors: [groupSector]
      });
    }
  } 

  groupedSectors.map(sectorGrp => {
    sectorGrp.sectors = sectorGrp.sectors.sort((a, b) => new Date(b.date) - new Date(a.date)).reverse();
  })

  return sortBy(groupedSectors, (group) => {
    return group.date;
  });
}

function groupSectorsForPayment(itinerary, paymentMethods) {
  const sectorsGroupsForPayment = [];

  const pendingFlights = itinerary.Passengers[0].Flights.filter((flight) => {
    return flight.Status === 'Pending';
  });

  pendingFlights.forEach((flight) => {
    const sectorGroup = sectorsGroupsForPayment.find((group) => {
      return group.groupIdentifier === flight.GroupIdentifier;
    });

    if(sectorGroup) {
      sectorGroup.sectors.push(flight);
    } else {
      let preSelectedPaymentMethod = undefined;

      const activePaymentMethodForGroup = itinerary.Passengers[0].Configurations.PaymentMethod.PaymentItems.find((paymentItem) => {
        return paymentItem.SectorGroupIdentifier === flight.GroupIdentifier;
      });

      if(activePaymentMethodForGroup) {
        preSelectedPaymentMethod = activePaymentMethodForGroup.Card || activePaymentMethodForGroup.Invoice;
      }

      if(!preSelectedPaymentMethod) {
        const invoiceForGroup = paymentMethods.find((paymentMethod) => {
          const isInvoice = paymentMethod.Payment.PaymentType === 'Invoice';
          const isGroup = paymentMethod.SectorGroups.includes(flight.GroupIdentifier);
          return isInvoice && isGroup;
        });

        if(invoiceForGroup) {
          preSelectedPaymentMethod = invoiceForGroup.Payment;
        }
      }

      sectorsGroupsForPayment.push({
        groupIdentifier: flight.GroupIdentifier,
        sectors: [flight],
        selectedPaymentMethod: preSelectedPaymentMethod,
        paymentMethods: paymentMethodsForGroup(paymentMethods, flight.GroupIdentifier)
      });
    }
  });

  function paymentMethodsForGroup(paymentMethods, groupIdentifier) {
    const filteredPaymentMethods = paymentMethods.filter((paymentMethod) => {
      return paymentMethod.SectorGroups.includes(groupIdentifier);
    });

    return filteredPaymentMethods.map((paymentMethod) => {
      return paymentMethod.Payment;
    });
  }

  return sectorsGroupsForPayment;
}

const approvalStates = {
  Auto: {
    id: 'auto',
    message: 'Approved',
    class: 'approvalApproved' //#48c761
  },
  Pending: {
    id: 'pending',
    message: 'Awaiting Approval',
    class: 'approvalPending' //#ff8a05
  },
  Approved: {
    id: 'approved',
    message: 'Approved',
    class: 'approvalApproved' //#48c761
  },
  Declined: {
    id: 'denied',
    message: 'Denied',
    class: 'approvalDenied' //#ff274b
  },
  Expired: {
    id: 'expired',
    message: 'Expired',
    class: 'approvalExpired' //#2C3E50
  }
};

function priceSummary(itinerary, sectorGroup) {
  const priceTotals = {
    Flight: [],
    Rail: [],
    Hotel: [],
    Car: [],
    Seat: [],
    flights: 0,
    rails: 0,
    hotels: 0,
    cars: 0,
    seats: 0
  };

  const sectors = flatten(sectorGroup.map((group) => {
    const _results = [];

    group.sectors.forEach((sector) => {
      const sectorObj = sector.data;
      sectorObj.sectorType = sector.sectorType;

      _results.push(sectorObj);
      
      let recursivePosition = sectorObj.Parent;
      let hasParent = recursivePosition;

      while (hasParent) {
        if (recursivePosition) {
          recursivePosition.sectorType = sectorObj.sectorType;
          _results.push(recursivePosition);
          recursivePosition = recursivePosition.Parent;
        } else {
          hasParent = false;
        }
      }
    });

    return _results;
  }));

  itinerary.Configurations.PaymentMethod.PaymentItems.forEach((paymentMethod) => {
    paymentMethod = addSectorsItemsToPaymentMethod(paymentMethod, sectors);

    if (paymentMethod.Invoice) {
      priceTotals[paymentMethod.Type].push(paymentMethod.Invoice);
    } else {
      priceTotals[paymentMethod.Type].push(paymentMethod.Card);
    }
  });

  sectors.forEach((sector) => {
    if (sector.sectorType === 'flight') {
      priceTotals.flights += sector.PayableAmount;
    }

    if (sector.sectorType === 'rail') {
      priceTotals.rails += sector.TotalAmount;
    }

    if (sector.sectorType === 'hotel') {
      priceTotals.hotels += sector.TotalAmount;
    }

    if (sector.sectorType === 'car') {
      priceTotals.cars += sector.TotalAmount;
    }
  });

  return priceTotals;
}

function paymentCardName(type) {
  let cardName = '';

  switch (type) {
    case 'VI':
      cardName = 'Visa';
      break;
    case 'CA':
      cardName = 'Mastercard';
      break;
    case 'AX':
      cardName = 'American Express';
      break;
    case 'DC':
      cardName = 'Diners';
      break;
    default:
      cardName = '';
  }

  return cardName
}

const railSeatPreferenceTypes = {
  POSITION: 'SeatReservationPosition',
  DIRECTION: 'SeatReservationDirection',
  COACH_TYPE: 'SeatReservationCoachType',
  ATTRIBUTE: 'SeatReservationPreferenceAttribute'
};

function ancillaryServicesAmountsFilter(ancillaryServices) {
  return filter(ancillaryServices, (service) => {
    return service.QuantitySelected > 0 && !findKey(railSeatPreferenceTypes, (preferenceType) => {
      return preferenceType === service.Type;
    });
  });
}

function addPaymentMethodsFromGroupsToItinerary(itinerary, paymentGroups) {
  itinerary.Passengers.forEach((pax) => {
    pax.Configurations.PaymentMethod.PaymentItems = paymentGroups.map((groupForPayment) => {
      if (!groupForPayment.selectedPaymentMethod) return;
      const paymentType = groupForPayment.selectedPaymentMethod.PaymentType;

      return {
        Card: paymentType === 'Card' ? groupForPayment.selectedPaymentMethod : null,
        Invoice: paymentType === 'Invoice' ? groupForPayment.selectedPaymentMethod : null,
        SectorGroupIdentifier: groupForPayment.groupIdentifier,
        SectorIdentifier: '',
        Type: 'Flight'
      }
    });
  });

  return itinerary;
}

function airlineFeeTotal(itinerary) {
  let total = 0;

  itinerary.Passengers.forEach((pax) => {
    pax.Flights.forEach((flight) => {
      if(flight.Status === 'Pending') {
        total += flight.Fees.reduce((feesTotal, fee) => {
          return feesTotal + (fee.Type === 'AirlineFee' ? fee.Amount : 0);
        }, 0);
      }
    });
  });

  return total;
}

function flightChangeTotal(itinerary) {
  let total = 0;

  itinerary.Passengers.forEach((pax) => {
    pax.Flights.forEach((flight) => {
      if(flight.Status === 'Pending') {
        total += flight.Amount;
      }
    });
  });

  return total;
}
